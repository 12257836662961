import React, { useState, useEffect } from 'react';
import { Field, Formik } from 'formik';
import PropTypes from 'prop-types';

// Styles
import { FormStyled, Row, Column } from '../../../css/styles/form';
import { SelectStyled } from '../../../css/styles/select';
import CustomSelect from '../../components/CustomSelect';
import { CustomSelectStyled } from '../../components/CustomSelect/styles';

const handleValidate = ({ frequency }) => {
  const errors = {};
  if (!frequency) errors.frequency = 'OBRIGATÓRIO';
  return errors;
};

const MaintenancePlanFreqForm = props => {
  const {
    onNew,
    maintenancePlanFreq,
    maintenanceServices,
    maintenanceService,
  } = props;

  const [
    maintenanceServicesToDelete,
    setMaintenanceServicesToDelete,
  ] = useState([]);

  const [maintenanceServicesToAdd, setMaintenanceServicesToAdd] = useState([]);

  const [selectedOptions, setSelectedOptions] = useState([]);


  const handleSubmit = props => async (
    values,
    { setSubmitting, resetForm }
  ) => {
    if (props.maintenancePlanFreq) {
      await props.onUpdate({ ...values, maintenanceServicesToAdd });
    } else {
      await props.onCreation({ ...values, maintenanceServicesToAdd });
    }
    setSubmitting(false);
    resetForm();

    setSelectedOptions([]);

    setMaintenanceServicesToDelete([]);
    setMaintenanceServicesToAdd([]);
  };

  useEffect(() => {
    if (maintenancePlanFreq) {
      const existingOptions = maintenancePlanFreq?.maintenanceplanfreqservices?.map(
        service => ({
          value: service.maintenanceservice.uuid,
          label: service.maintenanceservice.description,
        })
      );
      setSelectedOptions(existingOptions);
    }
  }, [maintenancePlanFreq]);

  const handleOnChange = (newSelectedOptions, setFieldValue) => {
    const getMaintenancePlanFreqServicesUUIDs = () => {
      return maintenancePlanFreq
        ? maintenancePlanFreq?.maintenanceplanfreqservices?.map(service => ({
            serviceUuid: service.maintenanceservice.uuid,
            planFreqUuid: service.uuid,
          }))
        : [];
    };

    // // if (newSelectedOptions) {
    // //   setFieldValue();
    // // }

    // Caso todos os itens sejam removidos
    if (newSelectedOptions === null || newSelectedOptions.length === 0) {
      const removedValues = selectedOptions.map(option => option.value);
      setFieldValue('maintenanceService', []);
      setSelectedOptions([]);

      const maintenancePlanFreqServices = getMaintenancePlanFreqServicesUUIDs();

      selectedOptions.forEach(option => {
        const matchedService = maintenancePlanFreqServices.find(
          service => service.serviceUuid === option.value
        );
        if (matchedService) {
          setMaintenanceServicesToDelete(prevState => [
            ...prevState,
            matchedService.planFreqUuid,
          ]);
        }
      });

      removedValues.forEach(value => {
        const matchedIndex = maintenanceServicesToAdd.findIndex(
          serviceUuid => serviceUuid === value
        );
        if (matchedIndex !== -1) {
          setMaintenanceServicesToAdd(prevState =>
            prevState.filter(serviceUuid => serviceUuid !== value)
          );
        }
      });

      return;
    }

    if (Array.isArray(newSelectedOptions)) {
      const removedValues = selectedOptions
        .filter(
          option =>
            !newSelectedOptions.some(
              selected => selected.value === option.value
            )
        )
        .map(option => option.value);

      const addedValues = newSelectedOptions
        .filter(
          selected =>
            !selectedOptions.some(option => option.value === selected.value)
        )
        .map(option => option.value);

      setSelectedOptions(newSelectedOptions);
      setFieldValue(
        'maintenanceService',
        newSelectedOptions.map(option => option.value)
      );

      const maintenancePlanFreqServices = getMaintenancePlanFreqServicesUUIDs();

      // Verificar e adicionar ao array de deleção
      removedValues.forEach(value => {
        const matchedService = maintenancePlanFreqServices.find(
          service => service.serviceUuid === value
        );
        if (matchedService) {
          setMaintenanceServicesToDelete(prevState => [
            ...prevState,
            matchedService.planFreqUuid,
          ]);
        }
      });

      addedValues.forEach(value => {
        const matchedService = maintenancePlanFreqServices.find(
          service => service.serviceUuid === value
        );
        if (!matchedService) {
          setMaintenanceServicesToAdd(prevState => [...prevState, value]);
        }
      });

      removedValues.forEach(value => {
        const matchedIndex = maintenanceServicesToAdd.findIndex(
          serviceUuid => serviceUuid === value
        );
        if (matchedIndex !== -1) {
          setMaintenanceServicesToAdd(prevState =>
            prevState.filter(serviceUuid => serviceUuid !== value)
          );
        }
      });
    }
  };

  return (
    <Formik
      enableReinitialize
      initialValues={{
        maintenanceplanUuid: maintenancePlanFreq
          ? maintenancePlanFreq.maintenanceplanUuid
          : '',
        frequency: maintenancePlanFreq?.frequency ?? '',
        observation: maintenancePlanFreq?.observation ?? '',
        maintenanceService: maintenanceService ? [maintenanceService.uuid] : [],
        maintenanceServices: maintenanceServices ?? [],
        maintenancePlanFreq: maintenancePlanFreq
          ? maintenancePlanFreq.uuid
          : '',
        maintenancePlanFreqs: maintenancePlanFreq
          ? maintenancePlanFreq.maintenanceplanfreqservices
          : [],
        maintenanceServicesToDelete: maintenanceServicesToDelete || [],
        selectedOptions: maintenancePlanFreq ? selectedOptions : [],
      }}
      validate={handleValidate}
      onSubmit={handleSubmit(props)}
    >
      {({
        values,
        handleReset,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
        isSubmitting,
        dirty,
        errors,
        touched,
      }) => (
        <FormStyled>
          <h1>
            {maintenancePlanFreq ? 'ALTERAR FREQUENCIA' : 'NOVA FREQUENCIA'}
          </h1>
          <ul>
            <Row>
              <Column>
                <strong>FREQUÊNCIA</strong>
                <input
                  id="frequency"
                  name="frequency"
                  type="number"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.frequency}
                />
                {errors.frequency && touched.frequency && (
                  <span>{errors.frequency}</span>
                )}
              </Column>
            </Row>

            <Row>
              <Column>
                <strong>SERVIÇOS</strong>
                <CustomSelect
                  name="maintenanceService"
                  id="maintenanceService"
                  styles={CustomSelectStyled}
                  options={maintenanceServices.map(item => ({
                    value: item.uuid,
                    label: item.description,
                  }))}
                  value={selectedOptions}
                  getOptionValue={item => item.value}
                  getOptionLabel={item => item.label}
                  onBlur={handleBlur}
                  onChange={selectedOptions =>
                    handleOnChange(selectedOptions, setFieldValue)
                  }
                  isMulti={true}
                  isSearchable
                  isLoading={false}
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  placeholder="Selecione uma opção"
                  loadingMessage={() => 'Buscando serviços de manutenção'}
                  noOptionsMessage={() =>
                    'Não há serviços de manutenção para exibir'
                  }
                />
                {errors.maintenanceService && touched.maintenanceService && (
                  <span>{errors.maintenanceService}</span>
                )}
              </Column>
            </Row>

            <Row>
              <Column>
                <strong>OBSERVAÇÃO</strong>
                <textarea
                  id="observation"
                  name="observation"
                  type="text"
                  value={values.observation}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </Column>
            </Row>
            <Row>
              <div className="buttons-container">
                <button type="submit" disabled={isSubmitting}>
                  SALVAR
                </button>
                <button
                  type="button"
                  onClick={handleReset}
                  disabled={!dirty || isSubmitting}
                >
                  RESET
                </button>
                {maintenancePlanFreq && (
                  <button
                    type="button"
                    onClick={onNew}
                    disabled={(!dirty && !maintenancePlanFreq) || isSubmitting}
                  >
                    LIMPAR
                  </button>
                )}
              </div>
            </Row>
          </ul>
        </FormStyled>
      )}
    </Formik>
  );
};

MaintenancePlanFreqForm.propTypes = {
  maintenancePlanFreqs: PropTypes.arrayOf(
    PropTypes.shape({
      uuid: PropTypes.string,
      maintenancePlanUuid: PropTypes.string,
      frequency: PropTypes.number,
      observation: PropTypes.string,
    })
  ),
  maintenancePlanFreq: PropTypes.shape({
    uuid: PropTypes.string,
    maintenancePlanUuid: PropTypes.string,
    frequency: PropTypes.number,
    observation: PropTypes.string,
  }),
};

export default MaintenancePlanFreqForm;

