import React, { Component } from 'react';
import ReactLoading from 'react-loading';
import { toast } from 'react-toastify';
import API from '../../services/api';
import HolidayForm from './form';
import HolidayList from './list';
import PropTypes from 'prop-types';

// Styles
import { SubContainer } from '../../css/styles/wrapper';

class HolidayContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      holiday: null,
      holidays: [],
      loading: false,
      base: null
    };
  }

  refHolidays = React.createRef();

  getList = async value => {
    this.setState({
      loading: true,
    });
    const { onHolidayList } = this.props;

    try {
      const response = await API.get('/holidays');
      this.setState({
        holidays: response.data,
        loading: false,
      });
      onHolidayList(response.data, value);
    } catch (err) {
      toast.error('Um erro aconteceu durante o carregamento!');
      this.setState({
        loading: false,
      });
    }
  };

  getBases = async value => {
    this.setState({
      loading: true,
    });
    const { onBasesList } = this.props;

    try {
      const response = await API.get('/bases');
      this.setState({
        bases: response.data,
        loading: false,
      });
      onBasesList(response.data, value);
    } catch (err) {
      toast.error('Um erro aconteceu durante o carregamento!');
      this.setState({
        loading: false,
      });
    }
  };

  handleCreation = async value => {
    const { onHolidaySubmit } = this.props;

    const data = {
      description: value.description.toUpperCase(),
      date: value.date,
      baseUuid: value.base ? value.base.uuid : null,
    };

    try {
      const response = await API.post('/holidays', { ...data });

      this.getList(response.data, value);
      this.handleNew();
      toast.success('Feriado criado com sucesso!');
      onHolidaySubmit();
    } catch (error) {
      toast.error('Um erro aconteceu durante o cadastro!');
    }
  };

  handleNew = () => {
    this.setState({
      holiday: null,
      base: null
    });
  };

  handleEdit = async object => {
    let response;
    if (object.baseUuid) {
      response = await API.get(`/bases/${object.baseUuid}`);
      const { uuid, description } = response.data;
      this.setState({ 
        base: { 
        uuid,
        description
       } });
    }
    this.setState({
      holiday: object,
    });
  };

  handleUpdate = async value => {
    const { holiday } = this.state;
    const { onHolidaySubmit } = this.props;

    const data = {
      description: value.description.toUpperCase(),
      date: value.date,
      baseUuid: value.base ? value.base.uuid : null,
    };

    try {
      await API.put(`/holidays/${holiday.uuid}`, { ...data });

      this.getList();
      this.handleNew();
      toast.success('Feriado atualizado com sucesso.');
      onHolidaySubmit();
    } catch (error) {
      toast.error('Um erro aconteceu durante a atualização!');
    }
  };

  handleDelete = async uuid => {
    const { onHolidaySubmit } = this.props;

    try {
      await API.delete(`/holidays/${uuid}`);

      this.getList();
      this.handleNew();
      toast.success('Feriado deletado com sucesso!');
      onHolidaySubmit();
    } catch (error) {
      toast.error('Houve um erro durante a exclusão');
    }
  };

  render() {
    const { holiday, loading, base } = this.state;
    const { holidayForm, holidays, bases } = this.props;
    return (
      <SubContainer visible={holidayForm}>
        <div ref={this.refHolidays} />

        <HolidayForm
          holiday={holiday}
          bases={bases}
          base={base}
          onCreation={this.handleCreation}
          onUpdate={this.handleUpdate}
          onNew={this.handleNew}
        />

        {loading ? (
          <ReactLoading type="spin" color="#011826" height={40} width={40} />
        ) : (
          <HolidayList
            holidays={holidays}
            onEdit={this.handleEdit}
            onDelete={this.handleDelete}
          />
        )}
      </SubContainer>
    );
  }
}

HolidayContainer.propTypes = {
  holidayForm: PropTypes.bool.isRequired,
  holidays: PropTypes.arrayOf(
    PropTypes.shape({
      uuid: PropTypes.string,
      description: PropTypes.string,
      date: PropTypes.string,
    })
  ),
  bases: PropTypes.arrayOf(
    PropTypes.shape({
      uuid: PropTypes.string,
      description: PropTypes.string,
    })
  ),
  onHolidayList: PropTypes.func.isRequired,
  onHolidaySubmit: PropTypes.func.isRequired,
};

export default HolidayContainer;

