import React, { Component } from "react";
import ReactLoading from 'react-loading';
import { toast } from 'react-toastify';
import swal from '@sweetalert/with-react';
import PropTypes from 'prop-types';
import API from '../../services/api';
import { SubContainer } from "../../css/styles/wrapper";
import MaintenanceServiceGroupForm from "./form";

class MaintenanceServiceGroupContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      maintenanceServiceGroup: null,
      maintenanceServiceGroups: [],
    }

    this.getList();
  }

  componentDidMount() {
    this.getList();
  }

  refMaintenanceServiceGroup = React.createRef();

  getList = async value => {
    this.setState({ loading: true });

    const { onMaintenanceServiceGroupsList } = this.props;

    try {
      const response = await API.get('/maintenanceservicegroups');

      this.setState({
        maintenanceServiceGroups: response.data,
        loading: false,
      });

      onMaintenanceServiceGroupsList(response.data, value);
    } catch (error) {
      toast.error('Um erro aconteceu durante o carregamento!');
      this.setState({ loading: false });
    }
  }

  handleCreation = async object => {
    const { onMaintenanceServiceGroupSubmit } = this.props;
    this.setState({
      loading: true,
    });

    const data = {
      description: object.description.toUpperCase() || null,
    }

    try {
      await API.post('/maintenanceservicegroups', data);
      this.setState({
        loading: false,
      });
      toast.success('Grupo de serviços criado com sucesso!');
      this.handleNew();
      this.getList();
      onMaintenanceServiceGroupSubmit();
    } catch (error) {
      toast.error('Um erro aconteceu durante a criação!');
      this.setState({
        loading: false,
      });
    }
  }

  handleEdit = object => {
    this.setState({
      maintenanceServiceGroup: object
    });
  };

  handleNew = () => {
    this.setState({
      maintenanceServiceGroup: null
    });
  };

  handleUpdate = async object => {
    const { onMaintenanceServiceGroupSubmit } = this.props;
    const { maintenanceServiceGroup } = this.state;
    this.setState({
      loading: true,
    });

    const data = {
      description: object.description.toUpperCase() || null,
    }

    try {
      await API.put(`/maintenanceservicegroups/${maintenanceServiceGroup.uuid}`, data);
      this.setState({
        loading: false,
      });
      onMaintenanceServiceGroupSubmit();
      toast.success('Grupo de serviços atualizado com sucesso!');
      this.handleNew();
      this.getList();
    } catch (error) {
      toast.error('Um erro aconteceu durante a atualização!');
    }
  }

  handleDelete = async () => {
    const { onMaintenanceServiceGroupSubmit } = this.props;
    const { maintenanceServiceGroup } = this.state;

    try {
      const confirmDelete = await swal({
        dangerMode: true,
        title: 'Deseja excluir este grupo de serviços?',
        buttons: {
          cancel: 'Não',
          confirm: 'Sim',
        },
      });

      if (confirmDelete) {
        await API.delete(`/maintenanceservicegroups/${maintenanceServiceGroup.uuid}`);

        onMaintenanceServiceGroupSubmit();
        toast.success('Grupo de serviços deletado com sucesso!');
        this.handleNew();
        this.getList();
      }
    } catch (error) {
      toast.error('Um erro aconteceu durante a exclusão!');
    }
  }


  render() {
    const {
      maintenanceServiceGroupForm,
    } = this.props;

    const{
      maintenanceServiceGroup,
      maintenanceServiceGroups,
      loading
    } = this.state;

    return (
      <SubContainer visible={maintenanceServiceGroupForm}>
        <div ref={this.refMaintenanceServiceGroup} />
        {loading ? (
          <ReactLoading type="spin" color="#011826" height={40} width={40} />
        ) : (
          <MaintenanceServiceGroupForm
            maintenanceServiceGroup={maintenanceServiceGroup}
            maintenanceServiceGroups={maintenanceServiceGroups}
            onCreation={this.handleCreation}
            onEdit={this.handleEdit}
            onUpdate={this.handleUpdate}
            onNew={this.handleNew}
            onDelete={this.handleDelete}
          />
        )}
      </SubContainer>
    );
  }
}

MaintenanceServiceGroupContainer.propTypes = {
  onMaintenanceServiceGroupSubmit: PropTypes.func.isRequired,
  onMaintenanceServiceGroupsList: PropTypes.func.isRequired,
}

export default MaintenanceServiceGroupContainer;
