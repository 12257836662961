import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import Select from 'react-select';

// Styles
import { FormStyled, Row, Column } from '../../css/styles/form';
import { SelectStyled } from '../../css/styles/select';

const handleValidate = ({ description }) => {
  const errors = {};
  if (!description) errors.description = 'OBRIGATÓRIO';
  return errors;
};

const handleSubmit = props => async (values, { setSubmitting, resetForm }) => {
  if (!props.cost) {
    await props.onCreation({ ...values });
  } else {
    await props.onUpdate({ ...values });
  }
  setSubmitting(false);
  resetForm();
};

const CostForm = props => {
  const { onDelete, onNew, onEdit, cost, costs } = props;

  return (
    <Formik
      enableReinitialize
      initialValues={{
        description: cost ? cost.description : '',
      }}
      validate={handleValidate}
      onSubmit={handleSubmit(props)}
    >
      {({
        values,
        handleChange,
        handleBlur,
        handleReset,
        errors,
        touched,
        setFieldValue,
        isSubmitting,
        dirty,
      }) => (
        <FormStyled>
          <h1>NOVO CENTRO DE CUSTO</h1>

          <ul>
            <Row>
              <Column>
                <strong>DESCRIÇÃO</strong>
                <textarea
                  id="description"
                  name="description"
                  type="text"
                  value={values.description}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />

                {errors.description && touched.description && (
                  <span>{errors.description}</span>
                )}
              </Column>
            </Row>

            <Row>
              <div className="buttons-container">
                <button type="submit" disabled={isSubmitting}>
                  SALVAR
                </button>

                <button
                  type="button"
                  onClick={handleReset}
                  disabled={!dirty || isSubmitting}
                >
                  RESET
                </button>

                {cost && (
                  <>
                    <button type="button" onClick={onNew}>
                      LIMPAR
                    </button>

                    <button className="delete" type="button" onClick={onDelete}>
                      EXCLUIR
                    </button>
                  </>
                )}
              </div>
            </Row>
          </ul>

          <hr />

          <ul>
            <Row>
              <Column>
                <strong>CENTRO DE CUSTO</strong>
                <Select
                  styles={SelectStyled}
                  id="cost"
                  value={values.cost}
                  placeholder="Selecione um centro de custo"
                  onChange={selectedOption => {
                    setFieldValue('cost', selectedOption);
                    onEdit(selectedOption);
                  }}
                  isSearchable
                  options={costs}
                  getOptionLabel={item => item.description}
                  getOptionValue={item => item.uuid}
                  name="cost"
                  isLoading={false}
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  loadingMessage={() => 'Buscando centro de custos'}
                  noOptionsMessage={() => 'Não há centro de custos cadastrados'}
                />
              </Column>
            </Row>
          </ul>
        </FormStyled>
      )}
    </Formik>
  );
};

CostForm.propTypes = {
  cost: PropTypes.shape({
    description: PropTypes.string,
  }),
  costs: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.string,
    })
  ),
  onNew: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
};

CostForm.defaultProps = {
  costs: [],
  cost: null,
};

export default CostForm;
