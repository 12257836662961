// 52:46  error  Must use destructuring props assignment    react/destructuring-assignment

// 52:57  error  'onLogout' is missing in props validation  react/prop-types

import React, { Component } from 'react';

import PropTypes from 'prop-types';

import { Route, Link } from 'react-router-dom';

import iconLogout from '../../../img/icons/icon-logout.png';

import { UserImage } from './styles';

class Menu extends Component {
  static propTypes = {
    onLogout: PropTypes.func.isRequired,

    username: PropTypes.string.isRequired,

    empresa: PropTypes.string.isRequired,

    photo: PropTypes.string.isRequired,
  };

  btnMenu = () => {
    const menu = document.querySelector('.main-menu');

    const menulink = document.querySelector('.main-menu nav a');

    menu.classList.remove('ativo');

    menulink.classList.remove('ativo');
  };

  render() {
    const { onLogout, username, empresa, photo, team } = this.props;

    const authMaintanance = team === 'GERENCIA' || team === 'OFICINA';
    const authFinancial = team === 'GERENCIA' || team === 'FINANCIA';
    const authPayment =
      team === 'GERENCIA' || team === 'FINANCIA' || team === 'PAGAMENTO';
    const authRefuel =
      team === 'GERENCIA' || team === 'ABASTECIMENTO' || team === 'OFICINA';
    const authService = team === 'GERENCIA' || team === 'OFICINA';
    const authPieceInventory = team === 'GERENCIA' || team === 'OFICINA';
    const authEmployee = team === 'GERENCIA';
    return (
      <div className="main-menu">
        <div className="scrollmenu">
          <div className="content">
            <div className="icon-menu" onClick={this.btnMenu}>
              <span />

              <span />

              <span />
            </div>

            <div className="user">
              <UserImage src={photo} alt="" />

              <p>{username}</p>

              <p>
                <small>{empresa}</small>
              </p>
            </div>

            <nav>
              <Route>
                <Link onClick={this.btnMenu} to="/home">
                  HOME
                </Link>
                {authMaintanance && (
                  <Link onClick={this.btnMenu} to="/maintenances">
                    MANUTENÇÕES
                  </Link>
                )}
                {authMaintanance && (
                  <Link onClick={this.btnMenu} to="/maintenanceplans">
                    PLANOS DE MANUTENÇÃO
                  </Link>
                )}
                {authRefuel && (
                  <Link onClick={this.btnMenu} to="/refuels">
                    ABASTECIMENTOS
                  </Link>
                )}
                {authFinancial && (
                  <Link onClick={this.btnMenu} to="/financials">
                    FINANÇAS
                  </Link>
                )}
                {authService && (
                  <Link onClick={this.btnMenu} to="/services">
                    SERVIÇOS
                  </Link>
                )}
                {authPayment && (
                  <Link onClick={this.btnMenu} to="/payments">
                    PAGAMENTO FUNCIONÁRIOS
                  </Link>
                )}
                {authPieceInventory && (
                  <Link onClick={this.btnMenu} to="/inventories">
                    ESTOQUE DE PEÇAS
                  </Link>
                )}
                {authEmployee && (
                  <Link onClick={this.btnMenu} to="/employees">
                    FUNCIONÁRIOS
                  </Link>
                )}
              </Route>
            </nav>

            <div className="logout" onClick={onLogout}>
              <p>Logout</p>

              <img src={iconLogout} alt="" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Menu;
