import React, { Component } from 'react';
import ReactLoading from 'react-loading';
import { toast } from 'react-toastify';
import swal from '@sweetalert/with-react';
import PropTypes from 'prop-types';
import API from '../../services/api';

// Styles
import { SubContainer } from '../../css/styles/wrapper';
import MaintenanceServiceForm from './form';

class MaintenanceServiceContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      maintenanceService: null,
      maintenanceServices: [],
      maintenanceServiceGroup: null,
    };

    this.getList();
  }

  componentDidMount() {
    this.getList();
  }

  refMaintenanceService = React.createRef();

  getList = async value => {
    const { onMaintenanceServicesList } = this.props;
    this.setState({ loading: true });

    try {
      const response = await API.get('/maintenanceservices');

      this.setState({
        maintenanceServices: response.data,
        loading: false,
      });
      onMaintenanceServicesList(response.data, value);
    } catch (error) {
      toast.error('Um erro aconteceu durante o carregamento!');
      this.setState({ loading: false });
    }
  }

  handleCreation = async object => {
    const { onMaintenanceServiceSubmit } = this.props;

    this.setState({
      loading: true,
    });

    const data = {
      description: object.description.toUpperCase() || null,
      maintenanceservicegroupUuid: object.maintenanceServiceGroup.uuid || null,
    }

    try {
      await API.post('/maintenanceservices', data);
      this.setState({
        loading: false,
      });
      onMaintenanceServiceSubmit();
      toast.success('Serviço criado com sucesso!');
      this.handleNew();
      this.getList();
    } catch (error) {
      toast.error('Um erro aconteceu durante a criação!');
      this.setState({
        loading: false,
      });
    }
  }

  handleEdit = async object => {
    try {
      const response = await API.get(`/maintenanceservicegroups/${object.maintenanceservicegroupUuid}`);
      const { uuid, description } = response.data;

      this.setState({
        maintenanceServiceGroup: {
          uuid,
          description,
        }
      });

    } catch {
      this.setState({
        maintenanceServiceGroup: null,
      });
      toast.error('Um erro aconteceu durante o carregamento!');
    }

    this.setState({
      maintenanceService: object,
    });
  };

  handleNew = () => {
    this.setState({
      maintenanceService: null,
      maintenanceServiceGroup: null,
    });
  };

  handleUpdate = async object => {
    const { onMaintenanceServiceSubmit } = this.props;
    const { maintenanceService } = this.state;
    this.setState({
      loading: true,
    });

    const data = {
      description: object.description.toUpperCase() || null,
      maintenanceservicegroupUuid: object.maintenanceServiceGroup.uuid || null,
    }

    try {
      await API.put(`/maintenanceservices/${maintenanceService.uuid}`, data);
      this.setState({
        loading: false,
      });
      onMaintenanceServiceSubmit();
      toast.success('Serviço atualizado com sucesso!');
      this.handleNew();
      this.getList();
    } catch (error) {
      toast.error('Um erro aconteceu durante a atualização!');
    }
  }

  handleDelete = async () => {
    const { onMaintenanceServiceSubmit } = this.props;
    const { maintenanceService } = this.state;

    try {
      const confirmDelete = await swal({
        dangerMode: true,
        title: 'Deseja excluir este grupo de serviços?',
        buttons: {
          cancel: 'Não',
          confirm: 'Sim',
        },
      });

      if (confirmDelete) {
        await API.delete(`/maintenanceservices/${maintenanceService.uuid}`);

        onMaintenanceServiceSubmit();
        toast.success('Serviço de manutenção deletado com sucesso!');
        this.handleNew();
        this.getList();
      }
    } catch (error) {
      toast.error('Um erro aconteceu durante a exclusão!');
    }
  }

  render() {
    const {
      maintenanceServiceForm,
      maintenanceServiceGroups,
    } = this.props;
    const {
      loading,
      maintenanceService,
      maintenanceServices,
      maintenanceServiceGroup,
    } = this.state;

    return (
      <SubContainer visible={maintenanceServiceForm}>
        <div ref={this.refMaintenanceService} />
        {loading ? (
          <ReactLoading type="spin" color="#011826" height={40} width={40} />
        ) : (
          <MaintenanceServiceForm
            maintenanceService={maintenanceService}
            maintenanceServices={maintenanceServices}
            maintenanceServiceGroup={maintenanceServiceGroup}
            maintenanceServiceGroups={maintenanceServiceGroups}
            onCreation={this.handleCreation}
            onEdit={this.handleEdit}
            onUpdate={this.handleUpdate}
            onNew={this.handleNew}
            onDelete={this.handleDelete}
          />
        )}
      </SubContainer>
    );
  }
}

MaintenanceServiceContainer.propTypes = {
  onMaintenanceServiceSubmit: PropTypes.func.isRequired,
  onMaintenanceServicesList: PropTypes.func.isRequired,
  maintenanceServiceGroups: PropTypes.arrayOf(
    PropTypes.shape({
      uuid: PropTypes.string,
      description: PropTypes.string,
    },
  )),
}

export default MaintenanceServiceContainer;
