import React, { useRef, useState } from 'react';
import moment from 'moment';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { FormStyled, Row, Column } from '../../css/styles/form';
import { SelectStyled } from '../../css/styles/select';

const handleValidate = ({
  paymentCreditHistoryCode,
  paymentEntryForm,
  paymentPurpose,
  paymentTransactionType,
  paymentDate

}) => {
  const errors = {};
  if (!paymentCreditHistoryCode) errors.paymentCreditHistoryCode = 'OBRIGATÓRIO';
  if (!paymentEntryForm) errors.paymentEntryForm = 'OBRIGATÓRIO';
  if (paymentPurpose === '') errors.paymentPurpose = 'OBRIGATÓRIO';
  if (!paymentTransactionType) errors.paymentTransactionType = 'OBRIGATÓRIO';
  if (moment(paymentDate).isBefore(moment(1, 'days'))) errors.paymentDate = 'DATA PRECISA SER SUPERIOR A DATA ATUAL';

  return errors;
};

const handleSubmit = props => async (values, { setSubmitting }) => {
  if (props.paymentEmployees.length < 1) {
    toast.error('SEM FUNCIONÁRIOS NA REMESSA', { autoClose: 5000 });
    setSubmitting(false);
  } else {
    await props.onCreation({ ...values });

    setSubmitting(false);
  }
};

const PaymentForm = props => {
  const [employeeCount, setEmployeeCount] = useState(props.paymentEmployees.length);
  const [totalValue, setTotalValue] = useState(0);

  React.useEffect(() => {
    setEmployeeCount(props.paymentEmployees.length);
    setTotalValue(
      props.paymentEmployees.reduce((acc, curr) => acc + Number(curr.paymentValue), 0).toFixed(2).replace('.', ',')
    )
  }, [props.paymentEmployees]);

  const formRef = useRef();

  const {
    paymentCreditHistoryCode,
    paymentEntryForm,
    paymentPurpose,
    paymentTransactionType,
    paymentDate,
    onDownload,
    downloadRelesed,
    loading,
  } = props;

  const paymentCreditHistoryCodeOptions = [
    {
      value: '0021',
      label: '0021 - LÍQUIDO DE VENCIMENTO',
    },
    {
      value: '0087',
      label: '0087 - CRÉDITO LÍQUIDO DE FÉRIAS',
    },
    {
      value: '2002',
      label: '2002 - ADIANTAMENTO DE SALÁRIO',
    },
    {
      value: '2005',
      label: '2005 - CRÉDITO CONTA SALÁRIO',
    },
    {
      value: '2007',
      label: '2007 - CRÉDITO SALÁRIO',
    },
    {
      value: '2029',
      label: '2029 - PAGAMENTO DE 13 SALÁRIOS',
    },
    {
      value: '2030',
      label: '2030 - PAGAMENTO DE 14 SALÁRIOS',
    },
    {
      value: '2031',
      label: '2031 - PAGAMENTO DE ABONO',
    },
    {
      value: '2032',
      label: '2032 - PAGAMENTO DE BENEFÍCIOS',
    },
    {
      value: '2033',
      label: '2033 - PAGAMENTO DE BÔNUS',
    },
    {
      value: '2034',
      label: '2034 - PAGAMENTO DE GRATIFICAÇÃO',
    },
    {
      value: '2035',
      label: '2035 - PAGAMENTO DE PRÊMIO',
    },
    {
      value: '2041',
      label: '2041 - PARTICIPAÇÃO NOS LUCROS',
    },
    {
      value: '3239',
      label: '3239 - PAGAMENTO DE RESCISÃO',
    },
    {
      value: '3259',
      label: '3259 - LÍQUIDO PROVENTOS',
    },
    {
      value: '3278',
      label: '3278 - PAGAMENTO PENSÃO',
    },
    {
      value: '3304',
      label: '3304 - REMUNERAÇÃO',
    }
  ];

  const paymentEntryFormOptions = [
    {
      value: '01',
      label: '01 - CRÉDITO EM CONTA CORRENTE',
    },
    {
      value: '05',
      label: '05 - CRÉDITO EM CONTA POUPANÇA',
    },
  ];

  const paymentTransactionTypeOptions = [
    {
      value: '0',
      label: '0 - INCLUSÃO',
    },
    // {
    //   value: '3',
    //   label: '3 - EXTORNO OU DEVOLUÇÃO (SOMENTE PARA RETORNO)',
    // },
    {
      value: '5',
      label: '5 - ALTERAÇÃO',
    },
    {
      value: '9',
      label: '9 - EXCLUSÃO',
    },
  ];

  const paymentPurposeOptions = [
    {
      value: '01',
      label: '01 - CRÉDITO EM CONTA CORRENTE',
    },
    {
      value: '02',
      label: '02 - PAGAMENTO DE ALUGUEL / CONDOMÍNIO',
    },
    {
      value: '03',
      label: '03 - PAGAMENTO DE DUPLICATAS E TÍTULOS',
    },
    {
      value: '04',
      label: '04 - PAGAMENTO DE DIVIDENDOS',
    },
    {
      value: '05',
      label: '05 - PAGAMENTO DE MENSALIDADES ESCOLARES',
    },
    {
      value: '06',
      label: '06 - PAGAMENTO DE SALÁRIO',
    },
    {
      value: '07',
      label: '07 - PAGAMENTO A FORNECEDOR / HONORÁRIOS',
    },
    {
      value: '08',
      label: '08 - PAGAMENTO DE CÂMBIO / FUNDOS / BOLSAS',
    },
    {
      value: '09',
      label: '09 - REPASSE DE ARRECADAÇÃO / PAGAMENTO DE TRIBUTOS'
    },
    {
      value: '11',
      label: '11 - DOC / TED PARA POUPANÇA',
    },
    {
      value: '12',
      label: '12 - DOC / TED PARA DEPÓSITO JUDICIAL',
    },
    {
      value: '13',
      label: '13 - PENSÃO ALIMENTÍCIA',
    },
    {
      value: '14',
      label: '14 - RESTITUIÇÃO DE IMPOSTO DE RENDA',
    },
    {
      value: '99',
      label: '99 - OUTROS',
    },
  ];

  return (
    <Formik
      ref={formRef}
      enableReinitialize
      initialValues={{
        paymentCreditHistoryCode: paymentCreditHistoryCode || null,
        paymentEntryForm: paymentEntryForm || null,
        paymentPurpose: paymentPurpose || null,
        paymentTransactionType: paymentTransactionType || null,
        paymentDate: paymentDate || moment().add(1, 'day').format('YYYY-MM-DD'),
        downloadRelesed,
        loading,
      }}
      validate={handleValidate}
      onSubmit={handleSubmit(props)}
    >
      {({
        values,
        handleChange,
        handleBlur,
        errors,
        touched,
        setFieldValue,
        isSubmitting,
        dirty,
      }) => (
        <FormStyled>
          <h1>REMESSA</h1>

          <ul>
            <Row>
              <Column>
                <strong>CÓDIGO HISTÓRICO DE CRÉDITO</strong>
                <Select
                  styles={SelectStyled}
                  id="paymentCreditHistoryCode"
                  value={values.paymentCreditHistoryCode}
                  placeholder="Selecione o código de histórico"
                  onChange={selectedOption => {
                    setFieldValue('paymentCreditHistoryCode', selectedOption);
                  }}
                  isSearchable
                  options={paymentCreditHistoryCodeOptions}
                  getOptionLabel={item => item.label}
                  getOptionValue={item => item.value}
                  name="paymentCreditHistoryCode"
                  isLoading={false}
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  loadingMessage={() => 'Buscando códigos'}
                  noOptionsMessage={() => 'Não há códigos para exibir'}
                />

                {errors.paymentCreditHistoryCode && touched.paymentCreditHistoryCode && (
                  <span>{errors.paymentCreditHistoryCode}</span>
                )}
              </Column>
            </Row>

            <Row>
              <Column>
                <strong>TIPO DE MOVIMENTO</strong>
                <Select
                  styles={SelectStyled}
                  id="paymentTransactionType"
                  value={values.paymentTransactionType}
                  placeholder="Selecione o tipo de movimento"
                  onChange={selectedOption => {
                    setFieldValue('paymentTransactionType', selectedOption);
                  }}
                  isSearchable
                  options={paymentTransactionTypeOptions}
                  getOptionLabel={item => item.label}
                  getOptionValue={item => item.value}
                  name="paymentTransactionType"
                  isLoading={false}
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  loadingMessage={() => 'Buscando códigos'}
                  noOptionsMessage={() => 'Não há códigos para exibir'}
                />

                {errors.paymentTransactionType && touched.paymentTransactionType && (
                  <span>{errors.paymentTransactionType}</span>
                )}
              </Column>
            </Row>

            <Row>
              <Column>
                <strong>FORMA DE LANÇAMENTO</strong>
                <Select
                  styles={SelectStyled}
                  id="paymentEntryForm"
                  value={values.paymentEntryForm}
                  placeholder="Selecione a forma de lançamento"
                  onChange={selectedOption => {
                    setFieldValue('paymentEntryForm', selectedOption);
                  }}
                  isSearchable
                  options={paymentEntryFormOptions}
                  getOptionLabel={item => item.label}
                  getOptionValue={item => item.value}
                  name="paymentEntryForm"
                  isLoading={false}
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  loadingMessage={() => 'Buscando códigos'}
                  noOptionsMessage={() => 'Não há códigos para exibir'}
                />

                {errors.paymentEntryForm && touched.paymentEntryForm && (
                  <span>{errors.paymentEntryForm}</span>
                )}
              </Column>
            </Row>

            <Row>
              <Column>
                <strong>FINALIDADE DO DOC, TED CIP E TED STR</strong>
                <Select
                  styles={SelectStyled}
                  id="paymentPurpose"
                  value={values.paymentPurpose}
                  placeholder="Selecione o código de histórico"
                  onChange={selectedOption => {
                    setFieldValue('paymentPurpose', selectedOption);
                  }}
                  isSearchable
                  options={paymentPurposeOptions}
                  getOptionLabel={item => item.label}
                  getOptionValue={item => item.value}
                  name="paymentPurpose"
                  isLoading={false}
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  loadingMessage={() => 'Buscando códigos'}
                  noOptionsMessage={() => 'Não há códigos para exibir'}
                />

                {errors.paymentPurpose && touched.paymentPurpose && (
                  <span>{errors.paymentPurpose}</span>
                )}

                {errors.paymentPurpose && touched.paymentPurpose && (
                  <span>{errors.paymentPurpose}</span>
                )}
              </Column>
            </Row>

            <Row>
              <Column>
                <strong>DATA DO PAGAMENTO</strong>
                <input
                  id="paymentDate"
                  name="paymentDate"
                  type="date"
                  value={values.paymentDate}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  min={moment().add(1, 'day').format('YYYY-MM-DD')}
                />

                {errors.paymentDate && touched.paymentDate && (
                  <span>{errors.paymentDate}</span>
                )}
              </Column>
            </Row>

            <div className='info'>
              <strong>VALOR DA REMESSA: </strong>
              <strong>R$ {totalValue}</strong>
            </div>

            <div className='info'>
              <strong>FUNCIONÁRIOS NA REMESSA:</strong>
              <strong>{employeeCount}</strong>
            </div>

            <Row>
              <div className='buttons-container'>
                <button type="submit" disabled={!dirty || isSubmitting}>
                  GERAR ARQUIVO
                </button>

                <button
                  type="button"
                  onClick={onDownload}
                  disabled={!downloadRelesed}
                >
                  {
                    loading ? 'CARREGANDO...' : 'BAIXAR ARQUIVO'
                  }
                </button>
              </div>
            </Row>

          </ul>

        </FormStyled>
      )}
    </Formik>
  );
};

PaymentForm.propTypes = {
  onCreation: PropTypes.func.isRequired,
  onDownload: PropTypes.func.isRequired,
  paymentCreditHistoryCode: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  }),
  paymentEntryForm: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  }),
  paymentPurpose: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  }),
  paymentTransactionType: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  }),
  paymentEmployees: PropTypes.arrayOf(PropTypes.object),
  paymentDate: PropTypes.string,
  downloadRelesed: PropTypes.bool,
  loading: PropTypes.bool,
};

PaymentForm.defaultProps = {
  paymentCreditHistoryCode: null,
  paymentEntryForm: null,
  paymentPurpose: null,
  paymentTransactionType: null,
  paymentEmployees: [],
  paymentDate: moment().add(1, 'day').format('YYYY-MM-DD'),
  downloadRelesed: false,
  loading: false,
}

export default PaymentForm;
