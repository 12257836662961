import React, { Component } from 'react';
import ReactLoading from 'react-loading';
import moment from 'moment';
import { toast } from 'react-toastify';
import swal from '@sweetalert/with-react';
import io from 'socket.io-client';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import FinancialForm from './form';
import FinancialFilterForm from './filterForm';
import FinancialList from './list';
import EquipmentContainer from '../Equipment';
import PartnerContainer from '../Partner';
import CostContainer from '../Cost';
import AccountTransferContainer from '../AccountTransfer';
import FinancialInstallmentContainer from './FinancialInstallment';
import FinancialCostContainer from './FinancialCost';
import API from '../../services/api';
import Parameters from '../../services/parameters';
import replaceComma from '../../Utilities/replaceComma';

// Styles
import { Wrapper, Container, Content } from '../../css/styles/wrapper';

class FinancialContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      financialItems: [],

      financialItem: null,

      loading: true,

      installmentForm: true,

      financialcostForm: false,

      previousMove: null,

      partnerForm: false,

      partner: null,

      partnerUuid: null,

      partners: [],

      costForm: false,

      cost: null,

      costUuid: null,

      costs: [],

      number: '',

      installmentType: '',

      accountTransferForm: false,

      equipmentForm: false,

      equipment: null,

      equipments: [],

      accountFilter: null,

      accounts: [],

      begin: moment.utc().format('YYYY-MM-DD'),

      end: moment.utc().format('YYYY-MM-DD'),

      type: false,

      installments: [],

      financialcosts: [],
    };

    // MAITENANCE BIND

    this.handleCreation = this.handleCreation.bind(this);

    this.handleUpdate = this.handleUpdate.bind(this);

    this.handleEdit = this.handleEdit.bind(this);

    this.handleDelete = this.handleDelete.bind(this);

    this.handleNew = this.handleNew.bind(this);
  }

  refFinancial = React.createRef();

  componentDidMount() {
    this.registerToSocket();
    this.getList();
  }

  registerToSocket = () => {
    const socket = io(Parameters.URL_API);

    socket.on('financial', () => this.getList());
  };

  scrollToMyRef = myRef => {
    window.scrollTo(0, myRef.current.offsetTop);
  };

  getList = async values => {
    this.setState({
      loading: true,
    });

    if (values) {
      await this.setState({
        begin: values.begin || moment().format('YYYY-MM-DD'),
        end: values.end || moment().format('YYYY-MM-DD'),
        accountUuid: values.account ? values.account.uuid : null,
        accountFilter: values.account ? values.account.uuid : null,
        partnerUuid: values.partner ? values.partner.uuid : null,
        costUuid: values.cost ? values.cost.uuid : null,
        number: values.number || null,
        installmentType: !values.account ? values.installmentType : null,
      });
    }

    try {
      const {
        begin,
        end,
        type,
        accountUuid,
        number,
        installmentType,
        partnerUuid,
        costUuid,
      } = this.state;

      const response = await API.get(
        `installments/all/${type ? 'A%20RECEBER' : 'A%20PAGAR'}/${begin ||
          moment().format('YYYY-MM-DD')}/${end ||
          moment().format('YYYY-MM-DD')}/${partnerUuid || null}/${number ||
          null}/${accountUuid || null}/${costUuid || null}/${installmentType ||
          null}`
      );

      this.setState({
        financialItems: response.data.values,
        loading: false,
        previousMove: response.data.previousMove || null,
      });
    } catch (err) {
      toast.error('Um erro aconteceu durante o carregamento!');
      this.setState({
        loading: false,
      });
    }
  };

  handleType = async () => {
    await this.setState(prevState => ({
      type: !prevState.type,
    }));
    this.getList();
  };

  handleCreation = async object => {
    const valueFormatted = replaceComma(object.value);
    const taxesFormatted = replaceComma(object.taxes);

    const data = {
      description: object.description || null,
      costUuid: (object.cost && object.cost.uuid) || null,
      date: moment.utc(object.date).format('YYYY-MM-DD'),
      type: object.type,
      observation: object.observation || null,
      equipmentUuid: (object.equipment && object.equipment.uuid) || null,
      value: valueFormatted,
      taxes: taxesFormatted || 0,
      number: object.number,
      partnerUuid: (object.partner && object.partner.uuid) || null,
      due_date: moment.utc(object.due_date).format('YYYY-MM-DD'),
      installments: object.installments,
      interval: object.interval,
      ISS: object.ISS || 0,
      INSS: object.INSS || 0,
      PIS: object.PIS || 0,
      COFINS: object.COFINS || 0,
      CSLL: object.CSLL || 0,
      IR: object.IR || 0,
    };

    try {
      const response = await API.post('/financials', { ...data });

      const financialcostData = {
        financialUuid: response.data.uuid,
        costUuid: (object.cost && object.cost.uuid) || null,
        value: null,
      };

      await API.post('/financialcosts', { ...financialcostData });

      await this.getList();

      const { financialItems } = this.state;
      const financialItem = financialItems.filter(
        item => item.financialUuid === response.data.uuid
      );

      this.setState({
        financialItem: financialItem[0],
      });

      toast.success('Cadastro concluído com sucesso!');
    } catch (err) {
      toast.error('Um erro aconteceu durante o cadastro!');
    }
  };

  handleNew = () => {
    this.setState({
      financialItem: null,
      equipment: null,
      cost: null,
      partner: null,
      accountTransferForm: false,
      equipmentForm: false,
      partnerForm: false,
      installmentForm: true,
      financialcostForm: false,
      costForm: false,
    });

    this.scrollToMyRef(this.refFinancial);
  };

  handleUpdate = async object => {
    const { financialItem } = this.state;

    const valueFormatted = replaceComma(object.value);
    const taxesFormatted = replaceComma(object.taxes);

    const data = {
      description: object.description || '',
      date: moment.utc(object.date).format('YYYY-MM-DD'),
      type: object.type,
      observation: object.observation || '',
      equipmentUuid: (object.equipment && object.equipment.uuid) || null,
      value: valueFormatted,
      taxes: taxesFormatted || 0,
      number: object.number,
      partnerUuid: (object.partner && object.partner.uuid) || null,
      ISS: object.ISS || 0,
      INSS: object.INSS || 0,
      PIS: object.PIS || 0,
      COFINS: object.COFINS || 0,
      CSLL: object.CSLL || 0,
      IR: object.IR || 0,
    };

    try {
      const response = await API.put(
        `/financials/${financialItem.financial.uuid}`,
        {
          ...data,
        }
      );

      await this.getList();

      const { financialItems } = this.state;
      const newfinancialItem = financialItems.filter(
        item => item.financialUuid === response.data.uuid
      );

      this.setState({
        financialItem: newfinancialItem[0],
      });

      toast.success('Atualização concluída com sucesso!');
    } catch (err) {
      toast.error('Um erro aconteceu durante a atualização!');
    }
  };

  handleDelete = async financialItem => {
    try {
      const confirmDelete = await swal({
        dangerMode: true,

        text: `Confirma a exclusão do conta ${financialItem.financial.type}?`,

        buttons: {
          cancel: 'Não',

          confirm: 'Sim',
        },
      });

      if (confirmDelete) {
        await API.delete(`financials/${financialItem.financial.uuid}`);
        await this.getList();
        this.handleNew();
        toast.success('Exclusão concluída com sucesso!');
      }
    } catch (err) {
      toast.error('Um erro aconteceu durante a exclusão!');
    }
  };

  handleEdit = financialItem => {
    this.setState({
      financialItem,
    });

    this.scrollToMyRef(this.refFinancial);
  };

  // EQUIPMENTS HELPERS

  handleEquipmentsList = (equipments, equipment) => {
    this.setState({
      equipments,
      equipment,
    });
  };

  handleEquipmentFormState() {
    const { equipmentForm } = this.state;
    this.setState({
      equipmentForm: !equipmentForm,
      costForm: false,
      partnerForm: false,
      accountTransferForm: false,
      financialcostForm: false,
    });
  }
  // PARTNERS HELPERS

  handlePartnersList = (partners, partner) => {
    this.setState({
      partners,
      partner,
    });
  };

  handlePartnerFormState() {
    const { partnerForm } = this.state;

    this.setState({
      partnerForm: !partnerForm,
      costForm: false,
      equipmentForm: false,
      accountTransferForm: false,
      financialcostForm: false,
    });
  }

  // COSTS HELPERS

  handleCostsList = (costs, cost) => {
    this.setState({
      costs,

      cost,
    });
  };

  handleCostFormState() {
    const { costForm } = this.state;

    this.setState({
      costForm: !costForm,
      equipmentForm: false,
      partnerForm: false,
      accountTransferForm: false,
      financialcostForm: false,
    });
  }

  // ACCOUNTS HELPERS

  handleAccountsList = accounts => {
    this.setState({
      accounts,
    });
  };

  handleAccountTransfersFormState() {
    const { accountTransferForm } = this.state;

    this.setState({
      accountTransferForm: !accountTransferForm,
      costForm: false,
      equipmentForm: false,
      partnerForm: false,
      financialcostForm: false,
    });
  }

  // INSTALLMENTS HELPERS

  handleInstallmentsList = installments => {
    this.setState({
      installments,
    });
  };

  handleInstallmentsSubmit = async () => {
    try {
      const response = await API.get('/accounts/');
      this.setState({
        accounts: response.data,
      });
      this.getList();
    } catch (err) {
      toast.error('Um erro aconteceu durante o carregamento!');
    }
  };

  // FINANCIAL COSTS HELPERS

  handleFinancialsCostsList = financialcosts => {
    this.setState({
      financialcosts,
    });
  };

  handleFinancialCostFormState() {
    const { financialcostForm } = this.state;

    this.setState({
      financialcostForm: !financialcostForm,
      costForm: false,
      equipmentForm: false,
      partnerForm: false,
      accountTransferForm: false,
    });
  }

  // EXCEL

  exportToCSV = (csvData, fileName) => {
    const fileType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

    const fileExtension = '.xlsx';

    const ws = XLSX.utils.json_to_sheet(csvData);

    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };

    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

    const data = new Blob([excelBuffer], { type: fileType });

    FileSaver.saveAs(data, fileName + fileExtension);
  };

  generateExcelData = data => {
    const {
      begin,

      end,

      accounts,

      accountFilter,

      previousMove,
    } = this.state;

    const accountData = accounts.filter(
      account => account.uuid === accountFilter
    );

    const arrayExcel = [];

    let total = 0;

    let totalDia = 0;

    let inicial = accountFilter ? accountData[0].balance : 0;

    data.forEach((reportLine, index) => {
      const {
        due_date,

        financial,

        value,

        paid_value,

        item,
      } = reportLine;

      const VENCIMENTO = new Date(due_date)

        .toLocaleString('pt-BR', {
          timeZone: 'UTC',
        })

        .substring(0, 10);

      const tipo = financial.type;

      const PARCEIRO = `${financial.partner.name} - ${item}`;

      const NUMERO = financial.number;

      let VALOR = 0;

      VALOR =
        paid_value || value
          ? (paid_value || value).toLocaleString('pt-BR', {
              minimumFractionDigits: 2,
            })
          : '0,00';

      if (!accountFilter) {
        let DATA = null;

        let TOTAL_DATA = null;

        // soma total dia, não entra no strato

        if (
          data[index + 1] &&
          data[index + 1].due_date.substring(0, 10) ===
            due_date.substring(0, 10)
        ) {
          totalDia += value;
        } else {
          totalDia += value;

          DATA = new Date(due_date)

            .toLocaleString('pt-BR', {
              timeZone: 'UTC',
            })

            .substring(0, 10);

          TOTAL_DATA = totalDia
            ? totalDia.toLocaleString('pt-BR', {
                minimumFractionDigits: 2,
              })
            : '0,00';

          totalDia = 0;
        }

        // financial.type

        total += paid_value || value;

        const objeto = {
          VENCIMENTO,

          PARCEIRO,

          NUMERO,

          VALOR,

          DATA,

          TOTAL_DATA,
        };

        arrayExcel.push(objeto);
      } else {
        inicial =
          tipo === 'A RECEBER' ? inicial - paid_value : inicial + paid_value;

        const TIPO = tipo;

        const objeto = {
          VENCIMENTO,

          PARCEIRO,

          TIPO,

          NUMERO,

          VALOR,
        };

        arrayExcel.push(objeto);
      }
    });

    total = total
      ? total.toLocaleString('pt-BR', {
          minimumFractionDigits: 2,

          maximumFractionDigits: 2,
        })
      : '0,00';

    let atual = 0;

    if (accountFilter) {
      atual = previousMove
        ? accountData[0].balance - previousMove
        : accountData[0].balance;
    }

    const objetoTotal = {
      NUMERO: accountFilter ? `SALDO FINAL:` : `TOTAL:`,

      VALOR: accountFilter
        ? atual
          ? atual.toLocaleString('pt-BR', {
              minimumFractionDigits: 2,
            })
          : '0,00'
        : total,
    };

    const objetoVazio = {};

    const formatedBegin = new Date(begin).toLocaleDateString('pt-BR', {
      timeZone: 'UTC',
    });

    const formatedEnd = new Date(end).toLocaleDateString('pt-BR', {
      timeZone: 'UTC',
    });

    const objetoInicio = {
      NUMERO: 'INICIO:',

      VALOR: formatedBegin,
    };

    const objetoFim = {
      NUMERO: 'FIM:',

      VALOR: formatedEnd,
    };

    if (accountFilter) {
      const objetoInicial = {
        VENCIMENTO: 'BANCO:',

        PARCEIRO: accountData[0].name,

        NUMERO: `SALDO INICIAL:`,

        VALOR: inicial
          ? inicial.toLocaleString('pt-BR', {
              minimumFractionDigits: 2,
            })
          : '0,00',
      };

      arrayExcel.push(objetoVazio);

      arrayExcel.push(objetoInicio);

      arrayExcel.push(objetoInicial);

      arrayExcel.push(objetoVazio);

      arrayExcel.push(objetoFim);

      arrayExcel.push(objetoTotal);

      if (previousMove) {
        const objetoMovimetacao = {
          NUMERO: `MOVIMENTACAO:`,

          VALOR: previousMove
            ? previousMove.toLocaleString('pt-BR', {
                minimumFractionDigits: 2,
              })
            : '0,00',
        };

        const objetoAtual = {
          NUMERO: `SALDO  ATUAL:`,

          VALOR: accountData[0].balance
            ? accountData[0].balance.toLocaleString('pt-BR', {
                minimumFractionDigits: 2,
              })
            : '0,00',
        };

        arrayExcel.push(objetoVazio);

        arrayExcel.push(objetoMovimetacao);

        arrayExcel.push(objetoAtual);
      }
    } else {
      arrayExcel.push(objetoVazio);

      arrayExcel.push(objetoTotal);

      arrayExcel.push(objetoVazio);

      arrayExcel.push(objetoInicio);

      arrayExcel.push(objetoFim);
    }

    return arrayExcel;
  };

  render() {
    const {
      financialItem,
      financialItems,
      type,
      begin,
      end,
      accountFilter,
      partners,
      accounts,
      loading,
      accountTransferForm,
      partnerForm,
      costForm,
      equipmentForm,
      equipments,
      equipment,
      partner,
      costs,
      cost,
      installmentForm,
      financialcostForm,
      installments,
      financialcosts,
    } = this.state;

    let nomeArquivo = '';

    if (accountFilter) {
      nomeArquivo = `EXTRATO DE ${moment
        .utc(begin)
        .format('DD/MM/YYYY')} A ${moment.utc(end).format('DD/MM/YYYY')}`;
    } else {
      nomeArquivo = `CONTAS ${type ? 'A RECEBER' : 'A PAGAR'} DE ${moment
        .utc(begin)
        .format('DD/MM/YYYY')} A ${moment.utc(end).format('DD/MM/YYYY')}`;
    }

    let excelData = null;

    if (financialItems && financialItems[0]) {
      excelData = this.generateExcelData(financialItems);
    }

    return (
      <Wrapper>
        <Container>
          <header className="financial">
            <h1> {type ? 'RECEBIMENTOS' : 'PAGAMENTOS'}</h1>

            <div className="financial-buttons-container">
              <button type="button" onClick={this.handleNew}>
                NOVO
              </button>
              {excelData && (
                <button
                  type="button"
                  onClick={() => this.exportToCSV(excelData, nomeArquivo)}
                >
                  EXCEL
                </button>
              )}
              {!accountFilter && (
                <button type="button" onClick={this.handleType}>
                  {type ? 'EXIBIR PAGAMENTOS' : 'EXIBIR RECEBIMENTOS'}
                </button>
              )}
            </div>
          </header>

          <Content>
            <FinancialFilterForm
              partners={partners}
              accounts={accounts}
              costs={costs}
              onList={this.getList}
            />

            {!loading ? (
              <>
                {financialItems && financialItems[0] ? (
                  <FinancialList
                    financialItems={financialItems}
                    loading={loading}
                    onEdit={this.handleEdit}
                    onDelete={this.handleDelete}
                  />
                ) : (
                  <strong>LISTA VAZIA</strong>
                )}
              </>
            ) : (
              <ReactLoading
                type="spin"
                color="#011826"
                height={40}
                width={40}
              />
            )}

            <nav>
              <div className="container-grid">
                <button
                  type="button"
                  onClick={() => this.handleEquipmentFormState()}
                >
                  VEÍCULO
                </button>
                <button
                  type="button"
                  onClick={() => this.handleCostFormState()}
                >
                  CENTRO DE CUSTO
                </button>
                <button
                  type="button"
                  onClick={() => this.handleAccountTransfersFormState()}
                >
                  TRANSFERÊNCIAS
                </button>
                <button
                  type="button"
                  onClick={() => this.handlePartnerFormState()}
                >
                  FORNECEDOR OU CLIENTE
                </button>
              </div>
            </nav>

            <EquipmentContainer
              onEquipmentsList={this.handleEquipmentsList}
              onEquipmentSubmit={() => this.handleEquipmentFormState()}
              equipmentForm={equipmentForm}
            />
            <PartnerContainer
              onPartnersList={this.handlePartnersList}
              onPartnerSubmit={() => this.handlePartnerFormState()}
              partnerForm={partnerForm}
            />

            <CostContainer
              onCostsList={this.handleCostsList}
              onCostSubmit={() => this.handleCostFormState()}
              costForm={costForm}
            />

            <AccountTransferContainer
              onAccountList={this.handleAccountsList}
              onAccountTransferSubmit={() =>
                this.handleAccountTransfersFormState()
              }
              accountTransferForm={accountTransferForm}
            />

            <div ref={this.refFinancial} />

            <FinancialForm
              onCreation={this.handleCreation}
              onUpdate={this.handleUpdate}
              onNew={this.handleNew}
              onDelete={this.handleDelete}
              financialItem={financialItem}
              equipments={equipments}
              equipment={equipment}
              partners={partners}
              partner={partner}
              costs={costs}
              cost={cost}
              installments={installments}
            />

            {financialItem && (
              <>
                {financialcosts && financialcosts[0] && (
                  <nav>
                    <div>
                      <button
                        type="button"
                        onClick={() => this.handleFinancialCostFormState()}
                      >
                        CENTRO DE CUSTO DE FINANÇAS
                      </button>
                    </div>
                  </nav>
                )}

                <FinancialCostContainer
                  financial={financialItem.financial}
                  onFinancialCostList={this.handleFinancialsCostsList}
                  financialcostForm={financialcostForm}
                  costs={costs}
                />

                <FinancialInstallmentContainer
                  financialItem={financialItem}
                  onInstallmentSubmit={this.handleInstallmentsSubmit}
                  onInstallmentsList={this.handleInstallmentsList}
                  installmentForm={installmentForm}
                  accounts={accounts}
                />
              </>
            )}
          </Content>
        </Container>
      </Wrapper>
    );
  }
}

export default FinancialContainer;
