import React, { Component } from 'react';
import ReactLoading from 'react-loading';
import { toast } from 'react-toastify';
import swal from '@sweetalert/with-react';
import io from 'socket.io-client';
import PropTypes from 'prop-types';
import PartnerForm from './form';
import API from '../../services/api';
import Parameters from '../../services/parameters';

// Styles
import { SubContainer } from '../../css/styles/wrapper';

class PartnerContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      partner: null,
      partners: [],
      loading: false,
    };

    this.handleCreation = this.handleCreation.bind(this);
    this.getList();
  }

  componentDidMount() {
    this.registerToSocket();
  }

  registerToSocket = () => {
    const socket = io(Parameters.URL_API);

    socket.on('partner', () => this.getList());
  };

  refAccount = React.createRef();

  getList = async value => {
    this.setState({ loading: true });
    const { onPartnersList } = this.props;

    try {
      const response = await API.get('/partners');
      this.setState({ partners: response.data, loading: false });
      onPartnersList(response.data, value);
    } catch (err) {
      toast.error('Um erro aconteceu durante o carregamento!');
      this.setState({ loading: false });
    }
  };

  handleCreation = async object => {
    const { onPartnerSubmit } = this.props;

    try {
      const response = await API.post('/partners', { ...object });

      this.getList(response.data);
      this.handleNew();
      toast.success('Fornecedor ou Cliente criado com sucesso!');
      onPartnerSubmit();
    } catch (err) {
      toast.error('Um erro aconteceu durante o cadastro!');
    }
  };

  handleEdit = object => {
    this.setState({ partner: object });
  };

  handleNew = () => {
    this.setState({ partner: null });
  };

  handleUpdate = async object => {
    const { onPartnerSubmit } = this.props;
    const { partner } = this.state;

    try {
      await API.put(`partners/${partner.uuid}`, { ...object });

      this.getList();
      this.handleNew();
      toast.success('Fornecedor ou Cliente atualizado com sucesso.');
      onPartnerSubmit();
    } catch (err) {
      toast.error('Um erro aconteceu durante a atualização!');
    }
  };

  handleDelete = async () => {
    const { onPartnerSubmit } = this.props;
    const { partner } = this.state;

    try {
      const confirmDelete = await swal({
        dangerMode: true,
        text: 'Confirma a exclusão do fornecedor ou cliente?',
        buttons: {
          cancel: 'Não',
          confirm: 'Sim',
        },
      });

      if (confirmDelete) {
        await API.delete(`partners/${partner.uuid}`);

        this.getList();
        this.handleNew();
        toast.success('Fornecedor ou Cliente deletado com sucesso!');
        onPartnerSubmit();
      }
    } catch (err) {
      toast.error('Não é possível excluir clientes relacionados a serviços!');
    }
  };

  render() {
    const { partnerForm } = this.props;
    const { partner, partners, loading } = this.state;

    return (
      <SubContainer visible={partnerForm}>
        <div ref={this.refAccount} />
        {loading ? (
          <ReactLoading type="spin" color="#011826" height={40} width={40} />
        ) : (
          <PartnerForm
            partner={partner}
            partners={partners}
            onCreation={this.handleCreation}
            onEdit={this.handleEdit}
            onUpdate={this.handleUpdate}
            onNew={this.handleNew}
            onDelete={this.handleDelete}
          />
        )}
      </SubContainer>
    );
  }
}

PartnerContainer.propTypes = {
  partnerForm: PropTypes.bool.isRequired,
  onPartnersList: PropTypes.func.isRequired,
  onPartnerSubmit: PropTypes.func.isRequired,
};

export default PartnerContainer;
