import React from 'react';
import moment from 'moment';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import Select from 'react-select';

import {
  FilterFormStyled,
  FilterRow,
  FilterColumn,
} from '../../css/styles/filter';
import { SelectStyled } from '../../css/styles/select';

const handleValidate = ({ begin, end }) => {
  const errors = {};

  if (!begin) errors.begin = 'OBRIGATÓRIO';

  if (moment(begin).isAfter(end))
    errors.end = 'DATA FINAL NÃO PODE SER MENOR QUE INICIAL';

  if (!end) errors.end = 'OBRIGATÓRIO';

  return errors;
};

const handleSubmit = props => async (values, { setSubmitting }) => {
  await props.onServicesList({ ...values });

  setSubmitting(false);
};

const ServiceFilterForm = props => {
  const { begin, end, toggle, bases, baseSearch } = props;

  const baseOptions = [
    {
      value: null,
      description: 'Filtrar sem base',
    },
    ...bases,
  ]

  return (
    <Formik
      enableReinitialize
      initialValues={{
        begin: begin || moment().format('YYYY-MM-DD'),

        end:
          end ||
          moment()
            .add(7, 'days')
            .format('YYYY-MM-DD'),
        toggle: toggle,
        baseSearch: baseSearch,
      }}
      validate={handleValidate}
      onSubmit={handleSubmit(props)}
    >
      {({
        values,
        handleChange,
        handleBlur,
        errors,
        touched,
        isSubmitting,
        setFieldValue,
      }) => (
        <FilterFormStyled>
          <h1>FILTROS</h1>

          <ul>
            <FilterRow>
              <FilterColumn>
                <strong>DATA DE ABERTURA</strong>
                <input
                  id="begin"
                  name="begin"
                  type="date"
                  value={values.begin}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />

                {errors.begin && touched.begin && <span>{errors.begin}</span>}
              </FilterColumn>

              <FilterColumn>
                <strong>DATA DE FECHAMENTO</strong>
                <input
                  id="end"
                  name="end"
                  type="date"
                  value={values.end}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />

                {errors.end && touched.end && <span>{errors.end}</span>}
              </FilterColumn>
            </FilterRow>

            <FilterRow>
            <FilterColumn>
                <strong>BASE</strong>
                <Select
                  styles={SelectStyled}
                  id="baseSearch"
                  value={values.baseSearch}
                  placeholder="Selecione uma base"
                  onChange={selectedOption => {
                    setFieldValue('baseSearch', selectedOption);
                  }}
                  isSearchable
                  options={baseOptions}
                  getOptionLabel={item => item.description}
                  getOptionValue={item => item.uuid}
                  name="baseSearch"
                  isLoading={false}
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  loadingMessage={() => 'Buscando bases'}
                  noOptionsMessage={() => 'Não há bases cadastradas'}
                />
              </FilterColumn>

              <FilterColumn>
                <strong>EXIBIR POR</strong>
                <fieldset>
                  <input
                    type="radio"
                    id="equipments"
                    name="toggle"
                    value="equipments"
                    checked={values.toggle === 'equipments'}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <label htmlFor="equipments">
                    <strong>EQUIPAMENTOS</strong>
                  </label>
                </fieldset>
                <br/>
                <fieldset>
                  <input
                    type="radio"
                    id="employees"
                    name="toggle"
                    value="employees"
                    checked={values.toggle === 'employees'}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <label htmlFor="employees">
                    <strong>FUNCIONÁRIOS</strong>
                  </label>
                </fieldset>
              </FilterColumn>
            </FilterRow>

            <FilterRow>
              <button type="submit" disabled={isSubmitting}>
                BUSCAR
              </button>
            </FilterRow>
          </ul>
        </FilterFormStyled>
      )}
    </Formik>
  );
};

ServiceFilterForm.propTypes = {
  begin: PropTypes.string,
  end: PropTypes.string,
  toggle: PropTypes.string,
  bases: PropTypes.arrayOf(PropTypes.shape({
    uuid: PropTypes.string,
    description: PropTypes.string,
  })),
  base: PropTypes.shape({
    uuid: PropTypes.string,
    description: PropTypes.string,
  }),
  baseSearch: PropTypes.shape({
    uuid: PropTypes.string,
    description: PropTypes.string,
  }),
};

ServiceFilterForm.defaultProps = {
  begin: '',
  end: '',
  toggle: '',
  bases: [],
  base: null,
  baseSearch: null,
};

export default ServiceFilterForm;
