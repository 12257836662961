import React, { Component } from 'react';
import ReactLoading from 'react-loading';
import swal from 'sweetalert';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import PieceContainer from '../../Piece';
import MaintenancePieceForm from './form';
import MaintenancePieceList from './list';
import API from '../../../services/api';

// Styles
import { SubContainer } from '../../../css/styles/wrapper';

class MaintenancePieceContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      maintenancePiece: null,
      maintenancePieces: null,
      pieceForm: false,
      piece: null,
      pieces: [],
    };

    this.handleCreation = this.handleCreation.bind(this);
  }

  componentDidMount() {
    this.getList();
  }

  refMaintenancePieces = React.createRef();

  scrollToMyRef = myRef => {
    window.scrollTo(0, myRef.current.offsetTop);
  };

  getList = async value => {
    const { maintenance, onMaintenancePiecesList } = this.props;

    this.setState({
      loading: true,
    });

    try {
      const response = await API.get(`/maintenancepieces/${maintenance.uuid}`);

      this.setState({
        maintenancePieces: response.data,
        loading: false,
      });

      onMaintenancePiecesList(response.data, value);
    } catch (err) {
      toast.error('Um erro aconteceu durante o carregamento!');
      this.setState({ loading: false });
    }
  };

  handleCreation = async object => {
    const {
      maintenance,
      onMaintenancePieceSubmit,
      onMaintenancePieceEdit,
    } = this.props;

    const piece = this.state.piece;


    const data = {
      maintenanceUuid: maintenance.uuid,

      pieceUuid: object.piece.uuid,

      quantity: object.quantity,

      value: object.value,
    };

    try {
      const response = await API.post('/maintenancepieces', { ...data });

      this.getList(response.data);
      await onMaintenancePieceEdit({
        uuid: response.data.maintenanceUuid,
      });
      this.handleNew();
      onMaintenancePieceSubmit();
      toast.success('Peça da manutenção cadastrado com sucesso!');
    } catch (err) {
      toast.error('Um erro aconteceu durante o cadastro!');
    }
  };

  handleEdit = object => {
    this.setState({ maintenancePiece: object, piece: null });
  };

  handleNew = () => {
    this.setState({ maintenancePiece: null, piece: null, pieceForm: false });
  };

  handleUpdate = async object => {
    const {
      onMaintenancePieceSubmit,
      onMaintenancePiecesList,
      onMaintenancePieceEdit,
    } = this.props;

    const { maintenancePiece } = this.state;


    const data = {
      pieceUuid: object.piece.uuid,

      quantity: object.quantity,

      value: object.value,
    };

    try {
      const response = await API.put(
        `/maintenancepieces/${maintenancePiece.uuid}`,
        {
          ...data,
        }
      );

      await this.getList(response.data);
      await onMaintenancePiecesList(maintenancePiece.maintenanceUuid);
      await onMaintenancePieceEdit({
        uuid: this.state.maintenancePiece.maintenanceUuid,
      });
      this.handleNew();
      onMaintenancePieceSubmit();
      toast.success('Peça da manutenção atualizado com sucesso.');
    } catch (error) {
      toast.error('Um erro aconteceu durante a atualização!');
    }
  };

  handleDelete = async item => {
    this.handleEdit(item);
    const {
      onMaintenancePieceSubmit,
      onMaintenancePiecesList,
      onMaintenancePieceEdit,
    } = this.props;

    // const { maintenancePiece } = this.state;

    try {
      const confirmDelete = await swal({
        dangerMode: true,

        text: 'Confirma a exclusão da peça da manutenção?',

        buttons: {
          cancel: 'Não',

          confirm: 'Sim',
        },
      });

      if (confirmDelete) {
        await API.delete(`maintenancepieces/${item.uuid}`);

        await onMaintenancePiecesList(
          this.state.maintenancePieces.maintenanceUuid
        );
        await onMaintenancePieceEdit({
          uuid: this.state.maintenancePiece.maintenanceUuid,
        });
        this.handleNew();
        toast.success('Peça da manutenção deletado com sucesso!');
        onMaintenancePieceSubmit();
      }
    } catch (err) {
      toast.error('Um erro aconteceu durante a exclusão!');
    }
  };

  // PIECES HELPERS

  handlePiecesList = (pieces, piece) => {
    this.setState({
      pieces,

      piece,

      maintenancePiece: null,
    });
  };
  // PIECES HELPERS

  handlePiecesList = (pieces, piece) => {
    this.setState({
      pieces,

      piece,

      maintenancePiece: null,
    });
  };

  handlePieceFormState() {
    const { pieceForm } = this.state;

    this.setState({
      pieceForm: !pieceForm,
    });
  }

  render() {
    const { maintenancePieceForm } = this.props;

    const {
      maintenancePiece,
      maintenancePieces,
      loading,
      pieceForm,
      pieces,
      piece,
    } = this.state;

    return (
      <SubContainer visible={maintenancePieceForm}>
        <div ref={this.refMaintenancePiece} />

        <nav>
          <h1>PEÇAS DE MANUTENÇÃO</h1>

          <button type="button" onClick={() => this.handlePieceFormState()}>
            PEÇA
          </button>
        </nav>

        <PieceContainer
          onPiecesList={this.handlePiecesList}
          onPieceSubmit={() => this.handlePieceFormState()}
          pieceForm={pieceForm}
        />

        {loading ? (
          <ReactLoading type="spin" color="#011826" height={40} width={40} />
        ) : (
          <MaintenancePieceList
            maintenancePieces={maintenancePieces}
            onEdit={this.handleEdit}
            onDelete={this.handleDelete}
          />
        )}

        <MaintenancePieceForm
          onUpdate={this.handleUpdate}
          onNew={this.handleNew}
          onCreation={this.handleCreation}
          maintenancePiece={maintenancePiece}
          pieces={pieces}
          piece={piece}
        />
      </SubContainer>
    );
  }
}

MaintenancePieceContainer.propTypes = {
  maintenance: PropTypes.shape({
    uuid: PropTypes.string,
  }).isRequired,
  maintenancePieces: PropTypes.arrayOf(),
  onMaintenancePiecesList: PropTypes.func.isRequired,
  onMaintenancePieceSubmit: PropTypes.func.isRequired,
  maintenancePieceForm: PropTypes.bool.isRequired,
};

export default MaintenancePieceContainer;
