import React from 'react';
import { Formik } from 'formik';
import PropTypes from 'prop-types';

import { FormStyled, Row, Column } from '../../css/styles/form';

const handleValidate = ({ closing_description, closing_date, time }) => {
  const errors = {};

  // if (!closing_description) errors.closing_description = 'OBRIGATÓRIO';
  if (!closing_date) errors.closing_date = 'OBRIGATÓRIO';
  if (!time) errors.time = 'OBRIGATÓRIO';

  return errors;
};

const handleSubmit = props => async (values, { setSubmitting }) => {
  await props.onClose({ ...values });

  setSubmitting(false);
};

const TodoForm = props => {
  const { maintenance } = props;

  return (
    <Formik
      enableReinitialize
      initialValues={{
        closing_description: !maintenance.closing_date
          ? ''
          : maintenance.closing_description,

        closing_date: !maintenance.closing_date
          ? new Date().toISOString().substring(0, 10)
          : maintenance.closing_date.substring(0, 10),

        time: !maintenance
          ? ''
          : maintenance.closing_date
          ? maintenance.closing_date.substring(11, 16)
          : '',
      }}
      validate={handleValidate}
      onSubmit={handleSubmit(props)}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleReset,
        dirty,
        isSubmitting,
      }) => (
        <FormStyled>
          <h1>FECHAMENTO</h1>

          <ul>
            <Row>
              <Column>
                <strong>DATA DE FECHAMENTO</strong>
                <input
                  id="closing_date"
                  name="closing_date"
                  type="date"
                  value={values.closing_date}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />

                {errors.closing_date && touched.closing_date && (
                  <span>{errors.closing_date}</span>
                )}
              </Column>
              <Column>
                <strong>HORA DE FECHAMENTO</strong>
                <input
                  id="time"
                  name="time"
                  type="time"
                  value={values.time}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />

                {errors.time && touched.time && <span>{errors.time}</span>}
              </Column>
            </Row>

            <Row>
              <Column>
                <strong>DESCRIÇÃO DE FECHAMENTO</strong>
                <input
                  id="closing_description"
                  name="closing_description"
                  type="text"
                  value={values.closing_description}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />

                {errors.closing_description && touched.closing_description && (
                  <span>{errors.closing_description}</span>
                )}
              </Column>
            </Row>

            <Row>
              <div className="buttons-container">
                <button type="submit" disabled={isSubmitting}>
                  FINALIZAR
                </button>

                <button
                  type="button"
                  onClick={handleReset}
                  disabled={!dirty || isSubmitting}
                >
                  RESET
                </button>
              </div>
            </Row>
          </ul>
        </FormStyled>
      )}
    </Formik>
  );
};

TodoForm.propTypes = {
  maintenance: PropTypes.shape({
    closing_date: PropTypes.string,
    closing_description: PropTypes.string,
  }).isRequired,
};

export default TodoForm;
