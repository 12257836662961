import React from 'react';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import Select from 'react-select';
import moment from 'moment';

// Styles
import { FormStyled, Row, Column } from '../../../css/styles/form';
import { SelectStyled } from '../../../css/styles/select';

const handleValidate = ({ start_date, start_time, employee }) => {
  const errors = {};

  if (!start_date) errors.start_date = 'OBRIGATÓRIO';
  if (!start_time) errors.start_time = 'OBRIGATÓRIO';
  if (!employee) errors.employee = 'OBRIGATÓRIO';

  return errors;
};

const handleSubmit = props => async (values, { setSubmitting, resetForm }) => {
  if (props.maintenanceEmployee) {
    await props.onUpdate({ ...values });
  } else {
    await props.onCreation({ ...values });
  }

  setSubmitting(false);
  resetForm();
};

const MaintenanceEmployeeForm = props => {
  const { onNew, employees, employee, maintenanceEmployee } = props;

  return (
    <Formik
      enableReinitialize
      initialValues={{
        start_date: maintenanceEmployee
          ? maintenanceEmployee.start.substring(0, 10)
          : moment().format('YYYY-MM-DD'),

        start_time: maintenanceEmployee
          ? maintenanceEmployee.start.substring(11, 16)
          : moment.utc().format('HH:mm'),

        end_date:
          maintenanceEmployee && maintenanceEmployee.end
            ? maintenanceEmployee.end.substring(0, 10)
            : moment().format('YYYY-MM-DD'),

        end_time:
          maintenanceEmployee && maintenanceEmployee.end
            ? maintenanceEmployee.end.substring(11, 16)
            : moment.utc().format('HH:mm'),

        employee: maintenanceEmployee
          ? maintenanceEmployee.employee
          : employee || null,
      }}
      validate={handleValidate}
      onSubmit={handleSubmit(props)}
    >
      {({
        values,
        handleReset,
        handleBlur,
        handleChange,
        errors,
        touched,
        setFieldValue,
        isSubmitting,
        dirty,
      }) => (
        <FormStyled>
          <h1>
            {maintenanceEmployee
              ? 'ALTERAR FUNCIONÁRIO DA MANUTENÇÃO'
              : 'NOVO FUNCIONÁRIO DA MANUTENÇÃO'}
          </h1>

          <ul>
            <Row>
              <Column>
                <strong>FUNCIONÁRIO</strong>
                <Select
                  styles={SelectStyled}
                  id="employee"
                  value={values.employee}
                  placeholder="Selecione um funcionário"
                  onChange={selectedOption =>
                    setFieldValue('employee', selectedOption)
                  }
                  isSearchable
                  options={employees}
                  getOptionLabel={item => item.name}
                  getOptionValue={item => item.uuid}
                  name="employee"
                  isLoading={false}
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  loadingMessage={() => 'Buscando funcionários'}
                  noOptionsMessage={() => 'Não há funcionários cadastrados'}
                />
                {errors.employee && touched.employee && (
                  <span>{errors.employee}</span>
                )}
              </Column>
            </Row>

            <Row>
              <Column>
                <strong>DATA INICIAL</strong>
                <input
                  id="start_date"
                  name="start_date"
                  type="date"
                  value={values.start_date}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />

                {errors.start_date && touched.start_date && (
                  <span>{errors.start_date}</span>
                )}
              </Column>

              <Column>
                <strong>HORA INICIAL</strong>
                <input
                  id="start_time"
                  name="start_time"
                  type="time"
                  value={values.start_time}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />

                {errors.start_time && touched.start_time && (
                  <span>{errors.start_time}</span>
                )}
              </Column>
            </Row>

            <Row>
              <Column>
                <strong>DATA FINAL</strong>
                <input
                  id="end_date"
                  name="end_date"
                  type="date"
                  value={values.end_date}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />

                {errors.end_date && touched.end_date && (
                  <span>{errors.end_date}</span>
                )}
              </Column>

              <Column>
                <strong>HORA FINAL</strong>
                <input
                  id="end_time"
                  name="end_time"
                  type="time"
                  value={values.end_time}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />

                {errors.end_time && touched.end_time && (
                  <span>{errors.end_time}</span>
                )}
              </Column>
            </Row>

            <Row>
              <div className="buttons-container">
                <button type="submit" disabled={isSubmitting}>
                  SALVAR
                </button>

                <button
                  type="button"
                  onClick={handleReset}
                  disabled={!dirty || isSubmitting}
                >
                  RESET
                </button>

                {maintenanceEmployee && (
                  <button
                    type="button"
                    onClick={onNew}
                    disabled={(!dirty && !maintenanceEmployee) || isSubmitting}
                  >
                    LIMPAR
                  </button>
                )}
              </div>
            </Row>
          </ul>
        </FormStyled>
      )}
    </Formik>
  );
};

MaintenanceEmployeeForm.propTypes = {
  employees: PropTypes.arrayOf(
    PropTypes.shape({
      uuid: PropTypes.string,
      name: PropTypes.string,
    })
  ),
  employee: PropTypes.shape({
    uuid: PropTypes.string,
    name: PropTypes.string,
  }),
  maintenanceEmployee: PropTypes.shape({
    start: PropTypes.string,
    end: PropTypes.string,
    employee: PropTypes.shape({
      uuid: PropTypes.string,
      name: PropTypes.string,
    }),
  }),
  onNew: PropTypes.func.isRequired,
};

MaintenanceEmployeeForm.defaultProps = {
  employees: [],
  employee: null,
  maintenanceEmployee: null,
};

export default MaintenanceEmployeeForm;
