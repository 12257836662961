import React from 'react';
import { IoMdTrash } from 'react-icons/io';
import PropTypes from 'prop-types';

import { ListStyled, ListItem } from '../../../css/styles/list';

const listItem = props =>
  props.maintenanceToMaintenancePlanFreqs.map(item => (
    <ListItem
      color="#011826"
      strongColor="#fff"
      key={item.uuid}
      onClick={() => props.onEdit(item)}
    >
      <div className="info">
      <div>
          <strong className="title">PLANO: </strong>
          <strong>
            {item.maintenanceplanfreq.maintenanceplan && item.maintenanceplanfreq.maintenanceplan.description}
          </strong>
        </div>
        <div>
          <strong className="title">FREQUENCIA: </strong>
          <strong>
            {item.maintenanceplanfreq && item.maintenanceplanfreq.frequency}
          </strong>
        </div>

        <div>
          <strong className="title">OBSERVAÇÃO: </strong>
          <strong>{item.observation && item.observation}</strong>
        </div>
      </div>

      <button
        type="button"
        onClick={e => {
          e.stopPropagation();
          props.onDelete(item);
        }}
        className="delete"
      >
        <IoMdTrash size={20} color="#bf1523" />
      </button>
    </ListItem>
  ));

function MaintenanceToMaintenancePlanFreqList(props) {
  const { maintenanceToMaintenancePlanFreqs } = props;
  return (
    <>
      {maintenanceToMaintenancePlanFreqs &&
      maintenanceToMaintenancePlanFreqs.length > 0 ? (
        <ListStyled>{listItem(props)}</ListStyled>
      ) : (
        <strong>LISTA VAZIA</strong>
      )}
    </>
  );
}

MaintenanceToMaintenancePlanFreqList.propTypes = {
  maintenanceToMaintenancePlanFreqs: PropTypes.arrayOf(
    PropTypes.shape({
      uuid: PropTypes.string,
      observation: PropTypes.string,
    })
  ),
};

MaintenanceToMaintenancePlanFreqList.defaultProps = {
  maintenanceToMaintenancePlanFreqs: [],
};

export default MaintenanceToMaintenancePlanFreqList;
