import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactLoading from 'react-loading';
import { toast } from 'react-toastify';
import io from 'socket.io-client';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import swal from '@sweetalert/with-react';
import moment from 'moment';
import RefuelForm from './form';
import RefuelList from './list';
import RefuelFilterForm from './filterForm';
import EquipmentContainer from '../Equipment';
import TankContainer from '../Tank';
import API from '../../services/api';
import Parameters from '../../services/parameters';

// Styles
import { Wrapper, Container, Content } from '../../css/styles/wrapper';

class RefuelContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      refuels: [],
      refuelFilter: null,
      bases: [],
      base: null,
      refuel: null,
      loading: true,
      type: null,
      tank: null,
      tankList: [],
      tankForm: false,
      equipmentForm: false,
      equipment: null,
      equipmentUuid: null,
      baseUuid: null,
      equipments: [],
      group: false,
      begin: moment.utc().format('YYYY-MM-DD'),
      end: moment.utc().format('YYYY-MM-DD'),
    };

    // MAITENANCE BIND

    this.handleCreation = this.handleCreation.bind(this);
    this.handleUpdate = this.handleUpdate.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleNew = this.handleNew.bind(this);

    // EQUIPMENT BIND

    this.handleEquipmentsList = this.handleEquipmentsList.bind(this);
    this.handleEquipmentFormState = this.handleEquipmentFormState.bind(this);
  }

  refRefuel = React.createRef();

  componentDidMount() {
    this.registerToSocket();
    this.getBases();
    this.getEquipments();
    this.getList();
  }

  registerToSocket = () => {
    const socket = io(Parameters.URL_API);

    socket.on('refuel', () => this.getList());
  };

  getList = async values => {
    this.setState({
      loading: true,
    });

    if (values) {
      this.setState({
        begin: values.begin || null,
        end: values.end || null,
        group: values.group || false,
        equipmentUuid: values.equipment ? values.equipment.uuid : null,
        type: values.type || null,
        baseUuid: values.base ? values.base.uuid : null,
      });
    }

    try {
      const { begin, end, group, equipmentUuid, type, baseUuid } = this.state;
      console.log(
        `/refuels/${begin || null}/${end || null}/${equipmentUuid ||
          null}/${group || null}/${type || null}/${baseUuid || null}`
      );

      const response = await API.get(
        `/refuels/${begin || null}/${end || null}/${equipmentUuid ||
          null}/${group || null}/${type || null}/${baseUuid || null}`
      );

      console.log(response.data);

      this.setState({
        refuels: response.data,
        loading: false,
        tankForm: false,
        equipmentForm: false,
      });
    } catch (err) {
      console.log(err);
      toast.error('Um erro aconteceu durante o carregamento!')
      this.setState({
        loading: false,
      });
    }

    this.handleTankList();
  };

  scrollToMyRef = myRef => {
    window.scrollTo(0, myRef.current.offsetTop);
  };

  handleCreation = async object => {
    const data = {
      date: `${object.opening_date}T${object.time}`,
      type: object.type,
      equipmentUuid: object.equipment ? object.equipment.uuid : null,
      km: object.km || 1,
      timer01: object.timer01 || 1,
      timer02: object.timer02 || 1,
      qty: object.km ? object.qty : 0,
      value: object.km ? object.value : 0,
      tankUuid: object.tank ? object.tank.uuid : null,
      baseUuid: object.base ? object.base.uuid : null,
    };

    try {
      await API.post('/refuels', { ...data });
      console.log(data)
      await this.getList();
      this.handleNew();
      this.handleTankList();
      this.getEquipments();
      toast.success('Abastecimento criado com sucesso!');
    } catch (err) {
      toast.error('Um erro aconteceu durante o cadastro!');
    }
  };

  handleEdit = async object => {
    if (object.type === 'INTERNO') {
      try {
        const response = await API.get(`/tanks/${object.tankUuid}`);

        this.setState({
          refuel: {
            ...object,
            tank: response.data,
          },
          tank: response.data,
        });
      } catch (error) {
        console.log(error);
        toast.error('Um erro aconteceu durante o carregamento!');
        this.setState({
          refuel: {
            ...object,
            tank: null,
          },
          tank: null,
        });
      }
    } else if (object.type === 'EXTERNO') {
      this.setState({
        refuel: object,
        tank: null,
      });
    }
    this.setState({
      equipmentForm: false,
      tankForm: false,
    });

    this.scrollToMyRef(this.refRefuel);
  };

  handleUpdate = async object => {
    const { refuel } = this.state;

    const data = {
      date: `${object.opening_date}T${object.time}`,
      type: object.type,
      // equipmentUuid: object.equipment,
      km: object.km || 0,
      timer01: object.timer01 || 0,
      timer02: object.timer02 || 0,
      qty: object.km ? object.qty : 0,
      value: object.km ? object.value : 0,
      tankUuid: object.type === 'INTERNO' ? object.tank.uuid : null,
    };

    try {
      await API.put(`/refuels/${refuel.uuid}`, {
        ...data,
      });
      await this.getList();
      this.handleNew();
      this.handleTankList();
      this.getEquipments();
      toast.success('Abastecimento atualizado com sucesso!');
    } catch (err) {
      console.log(err);
      toast.error('Um erro aconteceu durante a atualização!');
    }
  };

  handleDelete = async uuid => {
    try {
      const confirmDelete = await swal({
        dangerMode: true,
        text: 'Confirma a exclusão do abastecimento?',
        buttons: {
          cancel: 'Não',
          confirm: 'Sim',
        },
      });

      if (confirmDelete) {
        await API.delete(`refuels/${uuid}`);
        toast.success('Abastecimento deletado com sucesso!');
        await this.getList();
        this.getEquipments();
        this.handleNew();
      }
    } catch (err) {
      toast.error('Um erro aconteceu durante a exclusão!');
    }
  };

  getBases = async value => {
    this.setState({
      loading: true,
    });

    try {
      const response = await API.get('/bases');
      this.setState({
        bases: response.data,
        loading: false,
      });
    } catch (err) {
      toast.error('Um erro aconteceu durante o carregamento!');
      this.setState({
        loading: false,
      });
    }
  };

  handleNew = () => {
    this.setState({
      refuel: null,
      equipment: null,
      equipmentForm: false,
      tankForm: false,
      tank: null,
    });

    this.scrollToMyRef(this.refRefuel);
  };

  // EQUIPMENTS HELPERS

  handleEquipmentsList = (equipments, equipment) => {
    this.setState({
      equipments,

      equipment,
    });
  };

  getEquipments = async () => {
    try {
      const response = await API.get('/equipments');
      const equipments = response.data;
      
      this.setState({ equipments });
    } catch (err) {
      toast.error('Erro ao carregar a lista de equipamentos.');
    }
  };

  handleEquipmentFormState() {
    const { equipmentForm } = this.state;

    this.setState({
      equipmentForm: !equipmentForm,
    });
  }

  // TANKS HELPERS

  handleTankList = async tanks => {
    if (tanks) {
      this.setState({
        tankList: tanks,
      });
    } else {
      try {
        this.setState({
          loading: true,
        });

        const response = await API.get('/tanks');

        this.setState({
          tankList: response.data,
          loading: false,
        });
      } catch (error) {
        console.log(error);
        toast.error('Um erro aconteceu durante o carregamento!');
        this.setState({
          loading: false,
        });
      }
    }
  };

  handleTankFormState() {
    const { tankForm } = this.state;
    this.setState({
      tankForm: !tankForm,
      equipmentForm: false,
    });
  }

  exportToCSV = (csvData, fileName) => {
    const fileType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

    const fileExtension = '.xlsx';

    const ws = XLSX.utils.json_to_sheet(csvData);

    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };

    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

    const data = new Blob([excelBuffer], { type: fileType });

    FileSaver.saveAs(data, fileName + fileExtension);
  };

  generateExcelData = data => {
    // const {
    //   refuels,
    //   refuelFilter,
    // } = this.state;

    const arrayExcel = [];

    data.forEach((reportLine, index) => {
      const { qty, tank, km, timer01, timer02, type, equipment, base, value, date } = reportLine;

      const DATA = moment(date).format('DD/MM/YYYY');
      const PLACA = equipment?.code ?? '';
      const TANQUE = tank?.name ?? '';
      const TIPO = type;
      const BASE = base?.description ?? '';
      const QUANTIDADE = qty;
      const KM_INICIAL = km;
      const HORIMETRO_INICIAL = timer01;
      const HORIMETRO_DOIS = timer02;
      const VALOR = value.toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      });
      const TOTAL = (value * qty).toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      });
      console.log(qty, type, 'ooooooooooooooooooooooooooooooooooooo');

      // total += paid_value || value;

      const objeto = {
        DATA,
        PLACA,
        TANQUE,
        KM_INICIAL,
        HORIMETRO_INICIAL,
        HORIMETRO_DOIS,
        BASE,
        TIPO,
        QUANTIDADE,
        VALOR,
        TOTAL,
      };

      arrayExcel.push(objeto);
    });
    // const SUBTOTAL = arrayExcel.reduce((price, acprice) =>
    //   price + acprice.TOTAL, 0

    // )
    // arrayExcel.push({SUBTOTAL})
    return arrayExcel;
  };

  render() {
    const { useruuid } = this.props;

    const {
      equipments,
      refuels,
      loading,
      group,
      tank,
      tankForm,
      equipmentForm,
      refuel,
      equipment,
      begin,
      end,
      tankList,
      base,
      bases,
    } = this.state;

    let oilChange = null;
    let oilChangeEquipments = [];

    if (equipments) {
      
      oilChange = equipments.filter(
        equipment => equipment.maintenances.length > 0
      );

      oilChangeEquipments = oilChange.map(equipment => {
        let manutencoesPreventivas = equipment.maintenances.filter(
          maintenance => {
            return (
              maintenance.type !== 'CORRETIVA' &&
              maintenance.status === 'FECHADA'
            );
          }
        );
        manutencoesPreventivas = manutencoesPreventivas.sort((a, b) => {
          return a.createdAt < b.createdAt;
        });
        return {
          ...equipment,
          preventiveMaintenances: manutencoesPreventivas,
        };
      });
    }

    let nomeArquivo = `${moment(new Date()).format('DDMMYYYYHHmmss')}_${moment(
      begin
    ).format('DDMMYYYY')}-${moment(end).format(
      'DDMMYYYY'
    )}-RELATORIO_ABASTECIMENTO`;

    let excelData = null;

    if (refuels && refuels[0]) {
      excelData = this.generateExcelData(refuels);
    }

    return (
      <Wrapper>
        <Container>
          <header>
            <h1>ABASTECIMENTOS</h1>
            <div className="financial-buttons-container">
              <button type="button" onClick={this.handleNew}>
                NOVO
              </button>
              {excelData && (
                <button
                  type="button"
                  onClick={() => this.exportToCSV(excelData, nomeArquivo)}
                >
                  EXCEL
                </button>
              )}
            </div>
          </header>

          <Content>
            <RefuelFilterForm
              onList={this.getList}
              equipments={equipments}
              bases={bases}
              begin={begin}
              end={end}
            />

            {!loading ? (
              <>
                {refuels && refuels[0] ? (
                  <>
                    <RefuelList
                      onList={this.getList}
                      refuels={refuels}
                      onEdit={this.handleEdit}
                      onDelete={this.handleDelete}
                      group={group}
                      useruuid={useruuid}
                    />
                  </>
                ) : (
                  <strong>LISTA VAZIA</strong>
                )}
              </>
            ) : (
              <ReactLoading
                type="spin"
                color="#011826"
                height={40}
                width={40}
              />
            )}

            <nav>
              <h1>ABASTECIMENTO</h1>

              <div className="buttons-container">
                <button
                  type="button"
                  onClick={() => this.handleEquipmentFormState()}
                >
                  VEÍCULO
                </button>

                <button
                  type="button"
                  onClick={() => this.handleTankFormState()}
                >
                  TANQUE
                </button>
              </div>
            </nav>

            {tankList &&
              tankList.map((item, index) => (
                <h1 key={index}>
                  {item.name}: {item.tank}
                </h1>
              ))}

            <EquipmentContainer
              onEquipmentsList={this.handleEquipmentsList}
              onEquipmentSubmit={() => this.handleEquipmentFormState()}
              equipmentForm={equipmentForm}
            />

            <TankContainer
              onTankList={this.handleTankList}
              onTankRefuelSubmit={() => this.handleTankFormState()}
              tankForm={tankForm}
              tankList={tankList}
            />

            <div ref={this.refRefuel} />

            <RefuelForm
              onCreation={this.handleCreation}
              onUpdate={this.handleUpdate}
              onNew={this.handleNew}
              bases={bases}
              base={base}
              refuel={refuel}
              equipments={equipments}
              equipment={equipment}
              useruuid={useruuid}
              tank={tank}
              tankList={tankList}
            />
          </Content>
        </Container>
      </Wrapper>
    );
  }
}

RefuelContainer.propTypes = {
  useruuid: PropTypes.string,
};

RefuelContainer.defaultProps = {
  useruuid: '',
};

export default RefuelContainer;
