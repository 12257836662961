import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { IoMdTrash } from 'react-icons/io';

// Styles
import { ListStyled, ListItem } from '../../css/styles/list';

const listItem = props =>
  props.employeeList.map(item => (
    <ListItem
      color="#011826"
      strongColor="#fff"
      key={item.uuid}
      onClick={() => props.onEdit(item)}
    >
      {/* {console.log(item)}  */}
      <div className="info">
        <div>
          <strong className="title">NOME: </strong>
          <strong>{item.name}</strong>
        </div>
        <div>
          <strong className="title">CNH: </strong>
          <strong>{item.cnh}</strong>
        </div>
        <div>
          <strong className="title">TELEFONE: </strong>
          <strong>{item.telephone}</strong>
        </div>
        <div>
          <strong className="title">DOCUMENTO: </strong>
          <strong>{item.document}</strong>
        </div>

        <div>
          <strong className="title">DATA DE CADASTRO: </strong>
          <strong>{moment(item.createdAt).format('DD/MM/YYYY')}</strong>
        </div>
      </div>
      <button
        type="button"
        onClick={e => {
          e.stopPropagation();

          props.onDelete(item.uuid);
        }}
        className="delete"
      >
        <IoMdTrash size={20} color="#bf1523" />
      </button>
    </ListItem>
  ));

function EmployeeList(props) {
  const { employeeList } = props;

  return (
    <>
      {employeeList && employeeList.length > 0 ? (
        <ListStyled>
          <header>
            <div className="total">
              <h1>TOTAL:</h1>
              <p>{employeeList.length}</p>
            </div>
          </header>
          {listItem(props)}
        </ListStyled>
      ) : (
        <strong>LISTA VAZIA</strong>
      )}
    </>
  );
}

// holidayList.propTypes = {
//   holidays: PropTypes.arrayOf(
//     PropTypes.shape({
//       uuid: PropTypes.string,
//       description: PropTypes.string,
//       date: PropTypes.string,
//     }),
//   ),
// }

// holidayList.defaultProps = {
//   holidays: [],
// }

export default EmployeeList;

