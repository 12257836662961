import React from 'react';
import moment from 'moment';
import { IoMdTrash } from 'react-icons/io';

import PropTypes from 'prop-types';

// Styles

import { ListStyled, ListItem } from '../../../css/styles/list';

const listItem = props =>
  props.financialcosts.map(item => (
    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
    <ListItem
      color="#011826"
      strongColor="#fff"
      key={item.uuid}
      onClick={() => props.onEdit(item)}
    >
      <div className="info">
        <div>
          <strong className="title">DATA:</strong>
          <strong>{moment(item.createdAt).format('DD/MM/YYYY')}</strong>
        </div>
        <div>
          <strong className="title">CUSTO:</strong>
          <strong>{item.cost.description}</strong>
        </div>
        <div>
          <strong className="title">VALOR:</strong>
          <strong>{item.value}</strong>
        </div>
      </div>

      {props.financialcosts.length > 1 && (
        <button
          type="button"
          onClick={e => {
            e.stopPropagation();

            props.onDelete(item.uuid);
          }}
          className="delete"
        >
          <IoMdTrash size={20} color="#bf1523" />
        </button>
      )}
    </ListItem>
  ));

function FinicialCostList(props) {
  const { financialcosts } = props;

  return (
    <>
      {financialcosts && financialcosts.length > 0 ? (
        <ListStyled>
          {listItem(props)}
        </ListStyled>
      ) : (
        <strong>LISTA VAZIA</strong>
      )}
    </>
  );
}

FinicialCostList.propTypes = {
  financialcosts: PropTypes.arrayOf(
    PropTypes.shape({
      uuid: PropTypes.string,

      createdAt: PropTypes.string,

      valor: PropTypes.number,
    })
  ),
};

FinicialCostList.defaultProps = {
  financialcosts: [],
};

export default FinicialCostList;
