import styled from 'styled-components';

import { Form } from 'formik';

export const FormStyled = styled(Form)`
  width: 100%;

  max-width: 900px;

  padding: 32px 64px;

  margin-bottom: 16px;

  box-sizing: border-box;

  border-radius: 4px;

  -webkit-box-shadow: 0px 6px 14px -2px rgba(0, 0, 0, 0.34);

  -moz-box-shadow: 0px 6px 14px -2px rgba(0, 0, 0, 0.34);

  box-shadow: 0px 6px 14px -2px rgba(0, 0, 0, 0.34);

  background: #fff;

  hr {
    height: 1px;
    width: 100%;
    background: #ddd;

    margin: 32px 0;
    border: 0;
  }

  h1 {
    color: #000;

    font-size: 20px;

    font-weight: bold;
  }

  ul {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .employee-info {
      width: 100%;
      display: flex;
      flex-direction: column;
      font-size: 14px;
      padding-top: 16px;
      gap: 8px;

      div {
        display: flex;
        flex-wrap: wrap;

        p {
          font-weight: bold;
          color: #0568a6;
          margin: 0;
          margin-left: 8px;
        }
      }
    }
  }

  .info {
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 8px;
    align-items: center;
    font-size: 14px;
    text-align: center;
    padding-top: 16px;

    strong {
      & + strong {
        color: #f10;
        margin: 0;
        font-weight: bolder;
      }
    }
  }

  @media screen and (max-width: 768px) {
    padding: 24px;
  }

  @media screen and (max-width: 520px) {
    h1 {
      text-align: center;
    }

    .info {
      flex-direction: column;
    }
  }
`;

export const Row = styled.li`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;

  button {
    height: 40px;

    min-width: 132px;

    border-radius: 4px;

    padding: 0 15px;

    box-sizing: border-box;

    background: #59d99d;

    font-size: 16px;

    font-weight: bold;

    color: #fff;

    margin-top: 32px;

    &:hover {
      background: rgba($color: #59d99d, $alpha: 0.2);
    }

    &.delete {
      background: #bf1523;

      background: rgba($color: '#bf1523', $alpha: 0.2);
    }

    &:disabled {
      background: #ddd;

      color: #999;

      cursor: default;
    }
  }

  .buttons-container {
    display: flex;
    gap: 16px;
  }

  @media screen and (max-width: 768px) {
    .buttons-container {
      width: 100%;
      display: grid;
      grid-template-columns: 1fr;
      gap: 16px;
      margin-top: 16px;

      button {
        width: 100%;
        margin-top: 0;
      }
    }
  }

  @media screen and (max-width: 520px) {
    flex-direction: column;

    .buttons-container {
      display: flex;
      flex-direction: column;
      gap: 16px;
    }
  }
`;

export const Column = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 32px;
  padding: 0;


  strong {
    font-size: 14px;

    color: #000;

    margin-bottom: 16px;
  }

  input {
    box-sizing: border-box;

    width: 100%;

    height: 40px;

    border: 1px solid #999;

    border-radius: 4px;

    font-size: 16px;

    padding: 0 15px;

    color: #333;

    display: flex;

    &:disabled {
      background: none;

      color: #999;

      cursor: default;
    }
  }

  textarea {
    box-sizing: border-box;

    width: 100%;

    height: 60px;

    border: 1px solid #999;

    border-radius: 4px;

    font-size: 16px;

    color: #333;

    padding: 15px 0 0 15px;

    font-size: 16px;

    color: #333;

    outline: none;

    font-family: Arial, Helvetica, sans-serif;

    &:disabled {
      background: none;

      color: #999;

      cursor: default;
    }
  }

  select {
    box-sizing: border-box;

    width: 100%;

    height: 40px;

    background: #fff;

    border: 1px solid #999;

    border-radius: 4px;

    font-size: 16px;

    color: #333;

    padding: 0 15px;

    &:disabled {
      background: none;

      color: #999;

      cursor: default;
    }
  }

  span {
    margin-top: 8px;

    color: #bf1523;

    font-size: 14px;

    font-weight: bold;
  }

  div.weekly-frequency {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    background-color: #f2f2f2;
    padding: 16px 0;
    border-radius: 4px;

    div {
      display: flex;
      flex-direction: column;
      align-items: center;

      input {
        width: 32px;
        height: 32px;
      }
    }
  }

  @media screen and (max-width: 520px) {

  div.weekly-frequency {
    div {
      input {
        width: 24px;
        height: 24px;
      }
    }
  }
  }
`;
