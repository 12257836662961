import React, { Component } from 'react';

import ReactLoading from 'react-loading';

import swal from 'sweetalert';

import { toast } from 'react-toastify';

import PropTypes from 'prop-types';

import FinancialCostForm from './form';

import FinancialCostList from './list';

import API from '../../../services/api';

// Styles

import { SubContainer } from '../../../css/styles/wrapper';

class FinicialCostContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,

      financialcost: null,

      financialcosts: [],
    };
  }

  componentDidMount() {
    this.getList();
  }

  componentDidUpdate(prevProps) {
    const { financial } = this.props;
    if (prevProps.financial !== financial) {
      this.handleNew();
      this.getList();
    }
  }

  refFinancialCost = React.createRef();

  scrollToMyRef = myRef => {
    window.scrollTo(0, myRef.current.offsetTop);
  };

  getList = async () => {
    const { onFinancialCostList, financial } = this.props;

    this.setState({
      loading: true,
    });

    try {
      const response = await API.get(`financialcosts/list/${financial.uuid}`);
      this.setState({
        loading: false,
        financialcosts: response.data,
      });

      onFinancialCostList(response.data);
    } catch (err) {
      toast.error('Um erro aconteceu durante o carregamento!');
      this.setState({
        loading: false,
      });
    }
  };

  handleEdit = object => {
    this.setState({ financialcost: object });
  };

  handleNew = () => {
    this.setState({ financialcost: null });
  };

  handleCreate = async object => {
    const { financial } = this.props;

    const data = {
      value: object.value || null,

      financialUuid: financial.uuid,

      costUuid: object.cost.uuid,
    };

    try {
      await API.post(`/financialcosts`, {
        ...data,
      });

      this.getList();
      this.handleNew();
      toast.success('Custo cadastrado com sucesso.');
    } catch (error) {
      toast.error('Um erro aconteceu durante o cadastro!');
    }
  };

  handleUpdate = async object => {
    const { financial } = this.props;

    const { financialcost } = this.state;

    const data = {
      value: object.value || null,

      financialUuid: financial.uuid,

      costUuid: object.cost.uuid,
    };

    try {
      await API.put(`/financialcosts/${financialcost.uuid}`, {
        ...data,
      });

      this.getList();
      this.handleNew();
      toast.success('Custo atualizado com sucesso.');
    } catch (error) {
      toast.error('Um erro aconteceu durante a atualização!');
    }
  };

  handleDelete = async uuid => {
    try {
      const confirmDelete = await swal({
        dangerMode: true,

        text: 'Confirma a exclusão do custo?',

        buttons: {
          cancel: 'Não',

          confirm: 'Sim',
        },
      });

      if (confirmDelete) {
        await API.delete(`financialcosts/${uuid}`);
        this.getList();
        this.handleNew();
        toast.success('Custo deletado com sucesso!');
      }
    } catch (err) {
      toast.error('Um erro aconteceu durante a exclusão!');
    }
  };

  render() {
    const { costs, financialcostForm } = this.props;

    const { financialcost, financialcosts, loading } = this.state;

    return (
      <SubContainer visible={financialcostForm}>
        <div ref={this.refFinancialCost} />

        <header>
          <h1>CUSTOS DAS FINANÇAS</h1>
        </header>

        {loading ? (
          <ReactLoading type="spin" color="#011826" height={40} width={40} />
        ) : (
          <FinancialCostList
            financialcosts={financialcosts}
            onEdit={this.handleEdit}
            onDelete={this.handleDelete}
          />
        )}

        <FinancialCostForm
          onUpdate={this.handleUpdate}
          onCreation={this.handleCreate}
          onNew={this.handleNew}
          financialcost={financialcost}
          financialcosts={financialcosts}
          costs={costs}
        />
      </SubContainer>
    );
  }
}

FinicialCostContainer.propTypes = {
  financialcostForm: PropTypes.bool.isRequired,

  onFinancialCostList: PropTypes.func.isRequired,

  financial: PropTypes.shape({
    uuid: PropTypes.string,
  }),

  costs: PropTypes.arrayOf(PropTypes.shape({})),
};

FinicialCostContainer.defaultProps = {
  financial: null,

  costs: [],
};

export default FinicialCostContainer;
