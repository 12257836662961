import React from 'react';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import Select from 'react-select';

// Styles
import { FormStyled, Row, Column } from '../../../css/styles/form';
import { SelectStyled } from '../../../css/styles/select';

const handleValidate = ({ description, maintenanceService, maintenanceServiceGroup }) => {
  const errors = {};

  if (!description) errors.description = 'OBRIGATÓRIO';
  if (!maintenanceService) errors.maintenanceService = 'OBRIGATÓRIO';

  return errors;
};

const handleSubmit = props => async (values, { setSubmitting, resetForm }) => {
  if (props.maintenanceToMaintenanceService) {
    await props.onUpdate({ ...values });
  } else {
    await props.onCreation({ ...values });
  }

  setSubmitting(false);
  resetForm();
};

const MaintenanceToMaintenanceServiceForm = props => {
  const {
    onNew,
    maintenanceToMaintenanceService,
    maintenanceService,
    maintenanceServices,
  } = props;

  return (
    <Formik
      enableReinitialize
      initialValues={{
        description: maintenanceToMaintenanceService ? maintenanceToMaintenanceService.description : '',
        maintenanceService: maintenanceService || null,
      }}
      validate={handleValidate}
      onSubmit={handleSubmit(props)}
    >
      {({
        values,
        handleReset,
        handleBlur,
        handleChange,
        errors,
        touched,
        setFieldValue,
        isSubmitting,
        dirty,
      }) => (
        <FormStyled>
          <h1>
            {maintenanceToMaintenanceService
              ? 'ALTERAR SERVIÇO DA MANUTENÇÃO'
              : 'NOVO SERVIÇO DA MANUTENÇÃO'}
          </h1>

          <ul>
            <Row>
              <Column>
                <strong>SERVIÇO</strong>
                <Select
                  styles={SelectStyled}
                  id="maintenanceService"
                  value={values.maintenanceService}
                  placeholder="Selecione um serviço"
                  onChange={selectedOption =>
                    setFieldValue('maintenanceService', selectedOption)
                  }
                  isSearchable
                  options={maintenanceServices}
                  getOptionLabel={item => item.description}
                  getOptionValue={item => item.uuid}
                  name="maintenanceService"
                  isLoading={false}
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  loadingMessage={() => 'Buscando serviços'}
                  noOptionsMessage={() => 'Não há serviços cadastrados'}
                />
                {errors.maintenanceService && touched.maintenanceService && (
                  <span>{errors.maintenanceService}</span>
                )}
              </Column>
            </Row>

            <Row>
              <Column>
                <strong>DESCRIÇÃO</strong>
                <input
                  id="description"
                  name="description"
                  type="text"
                  value={values.description}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />

                {errors.description && touched.description && (
                  <span>{errors.description}</span>
                )}
              </Column>
            </Row>

            <Row>
              <div className="buttons-container">
                <button type="submit" disabled={isSubmitting}>
                  SALVAR
                </button>

                <button
                  type="button"
                  onClick={handleReset}
                  disabled={!dirty || isSubmitting}
                >
                  RESET
                </button>

                {maintenanceToMaintenanceService && (
                  <button
                    type="button"
                    onClick={onNew}
                    disabled={(!dirty && !maintenanceToMaintenanceService) || isSubmitting}
                  >
                    LIMPAR
                  </button>
                )}
              </div>
            </Row>
          </ul>
        </FormStyled>
      )}
    </Formik>
  );
};

MaintenanceToMaintenanceServiceForm.propTypes = {
  onCreation: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onNew: PropTypes.func.isRequired,
  maintenanceToMaintenanceService: PropTypes.shape({
    uuid: PropTypes.string,
    description: PropTypes.string,
  }),
  maintenanceToMaintenanceServices: PropTypes.arrayOf(
    PropTypes.shape({
      uuid: PropTypes.string,
      description: PropTypes.string,
    }),
  ),
  maintenanceService: PropTypes.shape({
    uuid: PropTypes.string,
    description: PropTypes.string,
  }),
  maintenanceServices: PropTypes.arrayOf(
    PropTypes.shape({
      uuid: PropTypes.string,
      description: PropTypes.string,
    }),
  ),
};

MaintenanceToMaintenanceServiceForm.defaultProps = {
  maintenanceToMaintenanceService: null,
  maintenanceToMaintenanceServices: [],
  maintenanceService: null,
  maintenanceServices: [],
};

export default MaintenanceToMaintenanceServiceForm;
