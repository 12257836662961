import React, { Component } from 'react';

import ReactLoading from 'react-loading';

import swal from 'sweetalert';

import { toast } from 'react-toastify';

import PropTypes from 'prop-types';

import API from '../../../services/api';
import { SubContainer } from '../../../css/styles/wrapper';
import MaintenancePlanFreqForm from './form';
import MaintenancePlanFreqsList from './list';

class MaintenancePlanFreqContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      maintenancePlan: null,
      maintenancePlanFreq: null,
      maintenancePlanFreqs: [],
      maintenanceService: [],
      maintenanceServices: [],
      maintenancePlanFreqServices: [],
      maintenanceServicesToDelete: [],
      maintenanceServicesToAdd: [],
    };

    this.handleCreation = this.handleCreation.bind(this);
  }
  componentDidMount() {
    this.getMaintenanceServices();
  }
  componentDidUpdate(prevProps) {
    const { maintenancePlan } = this.props;
    if (prevProps.maintenancePlan !== maintenancePlan) {
      this.handleNew();
      this.getList();
      this.getMaintenanceServices();
    }
  }

  refMaintenancePlanFreqs = React.createRef();

  scrollToMyRef = myRef => {
    window.scrollTo(0, myRef.current.offsetTop);
  };

  getList = async () => {
    const { onMaintenancePlanFreqsList, maintenancePlan } = this.props;

    this.setState({
      loading: true,
    });

    try {
      const response = await API.get(
        `maintenanceplanfreqs/${maintenancePlan.uuid}  `
      );
      this.setState({
        loading: false,
        maintenancePlanFreqs: response.data,
      });

      onMaintenancePlanFreqsList(response.data);
    } catch (error) {
      this.setState({
        loading: false,
      });
    }
  };

  getMaintenanceServices = async () => {
    try {
      const response = await API.get('/maintenanceservices');
      this.setState({
        maintenanceServices: response.data,
        loading: false,
      });
    } catch (error) {
      toast.error('Um erro aconteceu durante o carregamento!');
      this.setState({ loading: false });
    }
  };

  
  handleCreationMaintenancePlanFreqService = async (
    previousResponse,
    array
  ) => {
    const { onMaintenancePlanFreqSubmit } = this.props;

    try {
      array.map(async value => {
        const data = {
          maintenanceplanfreqUuid: previousResponse.uuid,
          maintenanceserviceUuid: value,
        };
        const response = await API.post('/maintenanceplanfreqservices', {
          ...data,
        });
        
        return response;
      });
      
      this.handleNew();
      onMaintenancePlanFreqSubmit();
      toast.success('Serviço de manutenção criado com sucesso!');
    } catch (err) {
      toast.error('Um erro aconteceu durante o cadastro de serviços da frequência!');
    }
  };

  handleUpdateMaintenancePlanFreqService = async planFreqServiceUuid => {
    const { onMaintenancePlanFreqSubmit } = this.props;
    try {
      planFreqServiceUuid.map(async planFreqServiceUuid => {
        await API.put(`/maintenanceplanfreqservices/${planFreqServiceUuid}`);
      });
      toast.success('Serviço de manutenção editado com sucesso!');
      onMaintenancePlanFreqSubmit();
    } catch (err) {
      toast.error('Um erro aconteceu durante a edição!');
    }
  };

  handleSoftDeleteMaintenancePlanFreqService = async planFreqServicesUuid => {
    const { onMaintenancePlanFreqSubmit } = this.props;

    try {
      planFreqServicesUuid.map(async planFreqServiceUuid => {
        const response =await API.delete(`/maintenanceplanfreqservices/${planFreqServiceUuid}`);

        return response
      });
      
      
      this.handleNew();
      onMaintenancePlanFreqSubmit();
      toast.success('Serviço de manutenção excluído com sucesso!');
    } catch (err) {
      toast.error('Um erro aconteceu durante DELEÇÃO!');
    }
  };

  handleCreation = async object => {
    const {
      maintenancePlan,
      onMaintenancePlanFreqSubmit,
      onMaintenancePlanFreqsList,
      onMaintenancePlanFreqEdit,
    } = this.props;

    const data = {
      maintenanceplanUuid: maintenancePlan.uuid,

      observation: object.observation,

      frequency: object.frequency,
    };

    const servicesToAdd = object.maintenanceServicesToAdd;

    try {
      const response = await API.post('/maintenanceplanfreqs', { ...data });

      onMaintenancePlanFreqEdit({
        uuid: response.data.maintenanceplanUuid,
      });
      if (servicesToAdd.length > 0) {
        this.handleCreationMaintenancePlanFreqService(
          response.data,
          servicesToAdd
        );
      }
      onMaintenancePlanFreqSubmit();
      this.handleNew();
      toast.success('Frequência de manutenção criada com sucesso!');
    } catch (err) {
      toast.error('Uma erro aconteceu durante o carregamento!');
    }
  };

  handleEdit = object => {
    this.setState({
      maintenancePlanFreq: object,
    });
  };

  handleNew = () => {
    this.setState({
      maintenancePlanFreq: null,
      maintenanceService: [],
      maintenancePlanFreqService: null,
      maintenancePlanFreqServices: [],
      maintenanceServicesToDelete: [],
      maintenanceServicesToAdd: [],
    });
  };

  handleUpdate = async object => {
    const {
      maintenancePlan,
      onMaintenancePlanFreqSubmit,
      onMaintenancePlanFreqEdit,
    } = this.props;

    const { maintenancePlanFreq } = this.state;

    const data = {
      maintenanceplanUuid: maintenancePlan.uuid,

      observation: object.observation,

      frequency: object.frequency,
    };

    const servicesToDelete = object.maintenanceServicesToDelete;
    const servicesToAdd = object.maintenanceServicesToAdd;

    try {
      const response = await API.put(
        `/maintenanceplanfreqs/${maintenancePlanFreq.uuid}`,
        data
      );

      onMaintenancePlanFreqEdit({
        uuid: this.state.maintenancePlanFreq.maintenanceplanUuid,
      });
      if (servicesToAdd.length !== 0) {
        this.handleCreationMaintenancePlanFreqService(
          response.data,
          servicesToAdd
        );
      }

      // if (servicesToDelete.length !== 0) {
      //   const confirmDelete = await swal({
      //     dangerMode: true,

      //     text: 'Confirma a exclusão da frequência do(s) serviço(s)?',

      //     buttons: {
      //       cancel: 'Não',

      //       confirm: 'Sim',
      //     },
      //   });

        if (servicesToDelete.length > 0) {
          this.handleSoftDeleteMaintenancePlanFreqService(servicesToDelete);
        }
      // }
      // servicesToDelete = []

      onMaintenancePlanFreqSubmit();
      this.handleNew();
      toast.success('Frequência de manutenção alterada com sucesso!');
    } catch (err) {
      toast.error('Um erro aconteceu durante a atualização!');
    }
  };

  handleDelete = async uuid => {
    this.handleEdit(uuid);
    const {
      onMaintenancePlanFreqsSubmit,
      onMaintenancePlanFreqsList,
      onMaintenancePlanFreqsEdit,
    } = this.props;
    try {
      const confirmDelete = await swal({
        dangerMode: true,

        text: 'Confirma a exclusão da frequência do serviço?',

        buttons: {
          cancel: 'Não',

          confirm: 'Sim',
        },
      });

      if (confirmDelete) {
        await API.delete(`/maintenanceplanfreqs/${uuid}`);

        toast.success('Frequência de manutenção excluída com sucesso!');
        this.handleNew();
        this.getList();
      }
    } catch (error) {
      toast.error('Uma erro aconteceu durante a exclusão!');
    }
  };

  render() {
    const {
      maintenancePlan,
      maintenancePlanFreqForm,
      maintenancePlanFreqs,
      maintenanceServicesToDelete,
    } = this.props;
    const {
      maintenanceServices,
      maintenanceService,
      maintenancePlanFreq,
      maintenancePlanFreqServices,
      loading,
    } = this.state;

    return (
      <SubContainer visible={maintenancePlanFreqForm}>
        <div ref={this.refMaintenancePlanFreqs} />
        {loading ? (
          <ReactLoading type="spin" color="#011826" height={40} width={40} />
        ) : (
          <MaintenancePlanFreqsList
            maintenancePlanFreqs={maintenancePlanFreqs}
            onEdit={this.handleEdit}
            onDelete={this.handleDelete}
          />
        )}

        <MaintenancePlanFreqForm
          onCreation={this.handleCreation}
          onUpdate={this.handleUpdate}
          onNew={this.handleNew}
          maintenanceServices={maintenanceServices}
          maintenanceService={maintenanceService}
          maintenancePlanFreqs={maintenancePlanFreqs}
          maintenancePlanFreq={maintenancePlanFreq}
          maintenancePlanFreqServices={maintenancePlanFreqServices}
          maintenanceServicesToDelete={this.setMaintenanceServicesToDelete}
          maintenanceServicesToAdd={this.setMaintenanceServicesToAdd}
          // selectedOptions={this.setSelectedOptions}
        />
      </SubContainer>
    );
  }
}

MaintenancePlanFreqContainer.propTypes = {
  onMaintenancePlanFreqsList: PropTypes.func.isRequired,

  maintenancePlan: PropTypes.shape({
    maintenanceplanUuid: PropTypes.string,
  }),

  maintenancePlanFreqs: PropTypes.arrayOf(),
  maintenanceServices: PropTypes.arrayOf(),
  maintenancePlanFreq: PropTypes.shape({
    uuid: PropTypes.string,
    maintenancePlanUuid: PropTypes.string,
    frequency: PropTypes.string,
    observation: PropTypes.string,
  }),
  onMaintenancePlanFreqsList: PropTypes.func.isRequired,
  onMaintenancePlanFreqSubmit: PropTypes.func.isRequired,
};

export default MaintenancePlanFreqContainer;

