import React from 'react';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import Moment from 'moment';
import Select from 'react-select';

import { FormStyled, Row, Column } from '../../css/styles/form';
import { SelectStyled } from '../../css/styles/select';
import moment from 'moment';

const handleValidate = props => ({
  opening_date,
  type,
  time,
  equipment,
  base,
  km,
  timer01,
  timer02,
  qty,
  value,
  tank,
}) => {
  const errors = {};

  if (!tank && type === 'INTERNO') errors.tank = 'OBRIGATÓRIO';
  if (!opening_date) errors.opening_date = 'OBRIGATÓRIO';
  if (!time) errors.time = 'OBRIGATÓRIO';
  if (!type) errors.type = 'OBRIGATÓRIO';
  if (!qty) errors.qty = 'OBRIGATÓRIO';
  if (!value) errors.value = 'OBRIGATÓRIO';
  if (!equipment) errors.equipment = 'OBRIGATÓRIO';
  if (!km) errors.km = 'OBRIGATÓRIO';
  if (!base) errors.base = 'OBRIGATÓRIO';

  const selectedEquipment = props.equipments.filter(
    object => object.uuid === equipment
  );

  if (
    selectedEquipment &&
    type !== 'EXTERNO' &&
    selectedEquipment.type === 'CAVALO'
  ) {
    if (!km) errors.km = 'OBRIGATÓRIO';
    if (!timer01) errors.timer01 = 'OBRIGATÓRIO';
    if (!timer02) errors.timer02 = 'OBRIGATÓRIO';

    if (
      !props.refuel &&
      selectedEquipment.odometers[0] &&
      selectedEquipment.odometers[0].km > km
    ) {
      errors.km = 'KM menor que o anterior!';
      errors.timer01 = 'Horímetro do veículo menor que o anterior';
      errors.timer02 = 'Horímetro do equipamento menor que o anterior';
    }
  }

  return errors;
};

const handleSubmit = props => async (values, { setSubmitting }) => {
  if (!props.refuel) {
    await props.onCreation({ ...values });
  } else {
    await props.onUpdate({ ...values });
  }

  setSubmitting(false);
};

const RefuelForm = props => {
  const {
    equipments,
    equipment,
    useruuid,
    bases,
    base,
    tank,
    refuel,
    onNew,
    tankList,
  } = props;

  const hora_atual = Moment().format('HH:mm');

  const typeOptions = [
    {
      value: 'INTERNO',
      label: 'INTERNO',
    },
    {
      value: 'EXTERNO',
      label: 'EXTERNO',
    },
  ];

  return (
    <Formik
      enableReinitialize
      initialValues={{
        base: base || null,
        opening_date: !refuel
          ? moment().format('YYYY-MM-DD')
          : refuel.date.substring(0, 10),
        time: !refuel ? hora_atual : refuel.date.substring(11, 16),
        type: !refuel ? '' : refuel.type,
        equipment:
          refuel && refuel.equipment ? refuel.equipment : equipment || '',
        km: refuel ? refuel.km : '1',
        timer01: refuel ? refuel.timer01 : '1',
        timer02: refuel ? refuel.timer02 : '1',
        qty: refuel ? refuel.qty : '',
        value: refuel
          ? refuel.value
          : tank && tank.tank_refuels && tank.tank_refuels[0]
          ? tank.tank_refuels[0].value
          : '',
        tank: refuel ? refuel.tank : tank,
      }}
      validate={handleValidate(props)}
      onSubmit={handleSubmit(props)}
    >
      {({
        values,
        handleChange,
        handleBlur,
        handleReset,
        errors,
        touched,
        setFieldValue,
        isSubmitting,
        dirty,
      }) => (
        <FormStyled>
          <h1>{refuel ? 'ALTERAR ABASTECIMENTO' : 'NOVO ABASTECIMENTO'}</h1>

          <ul>
            <Row>
              <Column>
                <strong>BASE</strong>
                <Select
                  styles={SelectStyled}
                  id="base"
                  value={values.base}
                  placeholder="Selecione uma base"
                  onChange={selectedOption => {
                    setFieldValue('base', selectedOption);
                  }}
                  isSearchable
                  options={bases}
                  getOptionLabel={item => item.description}
                  getOptionValue={item => item.uuid}
                  name="base"
                  isLoading={false}
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  loadingMessage={() => 'Buscando bases'}
                  noOptionsMessage={() => 'Não há bases para exibir'}
                />

                {errors.base && touched.base && <span>{errors.base}</span>}
              </Column>
            </Row>
            <Row>
              <Column>
                <strong>TANQUE</strong>
                <Select
                  styles={SelectStyled}
                  id="tank"
                  value={values.tank}
                  placeholder="Selecione um tanque"
                  onChange={selectedOption => {
                    setFieldValue('tank', selectedOption);
                    setFieldValue(
                      'value',
                      refuel
                        ? refuel.value
                        : selectedOption &&
                          selectedOption.tank_refuels &&
                          selectedOption.tank_refuels[0]
                        ? selectedOption.tank_refuels[0].value
                        : ''
                    );
                  }}
                  isSearchable
                  options={tankList}
                  getOptionLabel={item => item.name}
                  getOptionValue={item => item.uuid}
                  name="tank"
                  isLoading={false}
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  loadingMessage={() => 'Buscando tanques'}
                  noOptionsMessage={() => 'Não há tanques cadastrados'}
                />

                {errors.tank && touched.tank && <span>{errors.tank}</span>}
              </Column>
            </Row>

            <Row>
              <Column>
                <strong>DATA DE ABERTURA</strong>
                <input
                  id="opening_date"
                  name="opening_date"
                  type="date"
                  value={values.opening_date}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />

                {errors.opening_date && touched.opening_date && (
                  <span>{errors.opening_date}</span>
                )}
              </Column>
              <Column>
                <strong>HORA DE ABERTURA</strong>
                <input
                  id="time"
                  name="time"
                  type="time"
                  value={values.time}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />

                {errors.time && touched.time && <span>{errors.time}</span>}
              </Column>
            </Row>

            <Row>
              <Column>
                <strong>TIPO</strong>
                <select
                  id="type"
                  name="type"
                  value={values.type}
                  onChange={handleChange}
                  onBlur={handleBlur}
                >
                  <option value="" />
                  {typeOptions.map(type => (
                    <option key={type.value} value={type.value}>
                      {type.label}
                    </option>
                  ))}
                </select>

                {errors.type && touched.type && <span>{errors.type}</span>}
              </Column>
            </Row>

            <Row>
              <Column>
                <strong>VEÍCULO ABASTECIDO</strong>
                {refuel ? (
                  <input
                    type="text"
                    defaultValue={refuel.equipment.code}
                    disabled
                  />
                ) : (
                  <>
                    <Select
                      styles={SelectStyled}
                      id="equipment"
                      value={values.equipment}
                      placeholder="Selecione um veículo"
                      onChange={selectedOption => {
                        setFieldValue('equipment', selectedOption);
                      }}
                      isSearchable
                      options={equipments}
                      getOptionLabel={item => item.code}
                      getOptionValue={item => item.uuid}
                      name="equipment"
                      isLoading={false}
                      components={{
                        IndicatorSeparator: () => null,
                      }}
                      loadingMessage={() => 'Buscando veículos'}
                      noOptionsMessage={() => 'Não há veículos cadastrados'}
                    />

                    {errors.equipment && touched.equipment && (
                      <span>{errors.equipment}</span>
                    )}
                  </>
                )}
              </Column>
            </Row>
            <Row>
              <Column>
                <strong>KM</strong>
                <input
                  disabled={
                    !refuel
                      ? false
                      : !(
                          useruuid === '757b3456-08c1-45fc-9b66-75df65251611' ||
                          useruuid === 'a7181fa4-b0b6-4992-844d-8772e462c6df' ||
                          useruuid === '7eff000c-91d9-4c54-b597-79bd93938325'
                        )
                  }
                  id="km"
                  name="km"
                  type="number"
                  value={values.km}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />

                {errors.km && touched.km && <span>{errors.km}</span>}
              </Column>
              <Column>
                <strong>HORÍMETRO CAMINHÃO</strong>
                <input
                  disabled={
                    !refuel
                      ? false
                      : !(
                          useruuid === '757b3456-08c1-45fc-9b66-75df65251611' ||
                          useruuid === 'a7181fa4-b0b6-4992-844d-8772e462c6df' ||
                          useruuid === '7eff000c-91d9-4c54-b597-79bd93938325'
                        )
                  }
                  id="timer01"
                  name="timer01"
                  type="number"
                  value={values.timer01}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />

                {errors.timer01 && touched.timer01 && (
                  <span>{errors.timer01}</span>
                )}
              </Column>
              <Column>
                <strong>HORÍMETRO EQUIPAMENTO</strong>
                <input
                  disabled={
                    !refuel
                      ? false
                      : !(
                          useruuid === '757b3456-08c1-45fc-9b66-75df65251611' ||
                          useruuid === 'a7181fa4-b0b6-4992-844d-8772e462c6df' ||
                          useruuid === '7eff000c-91d9-4c54-b597-79bd93938325'
                        )
                  }
                  id="timer02"
                  name="timer02"
                  type="number"
                  value={values.timer02}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />

                {errors.timer02 && touched.timer02 && (
                  <span>{errors.timer02}</span>
                )}
              </Column>
            </Row>
            <Row>
              <Column>
                <strong>LITROS</strong>
                <input
                  id="qty"
                  name="qty"
                  type="number"
                  value={values.qty}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />

                {errors.qty && touched.qty && <span>{errors.qty}</span>}
              </Column>
              <Column>
                <strong>VALOR</strong>
                <input
                  disabled={values.type !== 'EXTERNO' && values.refuel !== ''}
                  id="value"
                  name="value"
                  type="number"
                  value={values.value}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />

                {errors.value && touched.value && <span>{errors.value}</span>}
              </Column>
            </Row>

            <Row>
              <div className="buttons-container">
                <button type="submit" disabled={isSubmitting}>
                  SALVAR
                </button>

                <button
                  type="button"
                  onClick={handleReset}
                  disabled={!dirty || isSubmitting}
                >
                  RESET
                </button>

                {refuel && (
                  <button type="button" onClick={onNew}>
                    LIMPAR
                  </button>
                )}
              </div>
            </Row>
          </ul>
        </FormStyled>
      )}
    </Formik>
  );
};

RefuelForm.propTypes = {
  equipments: PropTypes.arrayOf(
    PropTypes.shape({
      uuid: PropTypes.string,
      code: PropTypes.string,
    })
  ),
  equipment: PropTypes.shape({
    uuid: PropTypes.string,
    code: PropTypes.string,
  }),
  onNew: PropTypes.func.isRequired,
  useruuid: PropTypes.string.isRequired,
  tank: PropTypes.shape({
    tank_refuels: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.number,
      })
    ),
  }),
  refuel: PropTypes.shape({
    type: PropTypes.string,
    km: PropTypes.number,
    timer01: PropTypes.number,
    timer02: PropTypes.number,
    qty: PropTypes.number,
    value: PropTypes.number,
    date: PropTypes.string,
    equipment: PropTypes.shape({
      uuid: PropTypes.string,
      code: PropTypes.string,
    }),
    tank: PropTypes.shape({
      uuid: PropTypes.string,
      name: PropTypes.string,
    }),
  }),
  tankList: PropTypes.arrayOf(PropTypes.object),
};

RefuelForm.defaultProps = {
  equipments: [],
  equipment: null,
  tank: null,
  refuel: null,
  tankList: [],
};

export default RefuelForm;
