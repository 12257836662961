import React from 'react';
import { IoMdTrash } from 'react-icons/io';
import PropTypes from 'prop-types';

// Styles
import { ListStyled, ListItem } from '../../css/styles/list';

const listItem = props =>
  props.refuels.map(item => (
    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
    <ListItem
      color="#011826"
      strongColor="#fff"
      key={item.uuid}
      onClick={() => props.onEdit(item)}
    >
      <div className="info">
        <div>
          <strong className="title">DATA:</strong>
          <strong>
            {new Date(item.date)
              .toLocaleString('pt-BR', {
                timeZone: 'UTC',
              })
              .substring(0, 10)}
          </strong>
        </div>

        <div>
          <strong className="title">PLACA:</strong>
          <strong>{item.equipment.code}</strong>
        </div>

        <div>
          <strong className="title">TIPO:</strong>
          <strong>{item.type}</strong>
        </div>

        <div>
          <strong className="title">KM ATUAL:</strong>
          <strong>{item.km}</strong>
        </div>

        <div>
          <strong className="title">QUANTIDADE:</strong>
          <strong>{item.qty}</strong>
        </div>

        <div>
          <strong className="title">VALOR:</strong>
          <strong>
            {item.value.toLocaleString('pt-BR', {
              maximumFractionDigits: 2,
            })}
          </strong>
        </div>

        <div>
          <strong className="title">MEDIA:</strong>
          <strong>
            {item.media &&
              item.media.toLocaleString('pt-BR', {
                maximumFractionDigits: 2,
              })}
          </strong>
        </div>
        <div>
          <strong className="title">TOTAL:</strong>
          <strong>
            {(item.value * item.qty).toLocaleString('pt-BR', {
              maximumFractionDigits: 2,
            })}
          </strong>
        </div>
      </div>

      <button
        type="button"
        onClick={e => {
          e.stopPropagation();
          props.onDelete(item.uuid);
        }}
        className="delete"
      >
        <IoMdTrash size={20} color="#bf1523" />
      </button>
    </ListItem>
  ));

const listItemGroup = props =>
  props.refuels.map(item => (
    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
    <li key={item.uuid} onClick={() => props.onEdit(item)}>
      <div className="info">
        <div>
          <strong className="title">PLACA:</strong>
          <strong>{item.equipment.code}</strong>
        </div>

        <div>
          <strong className="title">INICIAL:</strong>
          <strong>
            {item.begin.toLocaleString('pt-BR', {
              maximumFractionDigits: 2,
            })}
          </strong>
        </div>

        <div>
          <strong className="title">FINAL:</strong>
          <strong>
            {item.end.toLocaleString('pt-BR', {
              maximumFractionDigits: 2,
            })}
          </strong>
        </div>

        <div>
          <strong className="title">RODADO:</strong>
          <strong>
            {item.rode.toLocaleString('pt-BR', {
              maximumFractionDigits: 2,
            })}
          </strong>
        </div>

        <div>
          <strong className="title">ABASTECIDO:</strong>
          <strong>
            {item.refuel.toLocaleString('pt-BR', {
              maximumFractionDigits: 2,
            })}
          </strong>
        </div>

        <div>
          <strong className="title">VALOR:</strong>
          <strong>
            {item.value.toLocaleString('pt-BR', {
              maximumFractionDigits: 2,
            })}
          </strong>
        </div>

        <div>
          <strong className="title">MEDIA:</strong>
          <strong>
            {item.media.toLocaleString('pt-BR', {
              maximumFractionDigits: 2,
            })}
          </strong>
        </div>
        <div>
          <strong className="title">TOTAL:</strong>
          <strong>
            {(item.value * item.qty).toLocaleString('pt-BR', {
              maximumFractionDigits: 2,
            })}
          </strong>
        </div>
      </div>
    </li>
  ));

function RefuelList(props) {
  const { refuels, group } = props;

  return (
    <>
      {refuels && refuels.length > 0 ? (
        <>
          <ListStyled>
            {group ? (
              <>
                <header>
                  <div className="total">
                    <h1>TOTAL:</h1>
                    <p>{refuels.length}</p>
                  </div>
                </header>
              </>
            ) : (
              <></>
            )}

            {group ? listItemGroup(props) : listItem(props)}
          </ListStyled>
        </>
      ) : (
        <strong>LISTA VAZIA</strong>
      )}
    </>
  );
}

RefuelList.propTypes = {
  refuels: PropTypes.arrayOf(
    PropTypes.shape({
      uuid: PropTypes.string,
      createdAt: PropTypes.string,
      rode: PropTypes.string,
      refuel: PropTypes.string,
      value: PropTypes.number,
      media: PropTypes.string,
      origin: PropTypes.string,
      destiny: PropTypes.string,
      equipment: PropTypes.shape({
        code: PropTypes.string,
      }),
      tank: PropTypes.shape({
        uuid: PropTypes.string,
        name: PropTypes.string,
      })
    })
  ),
  group: PropTypes.bool,
};

RefuelList.defaultProps = {
  refuels: [],
  group: false,
};

export default RefuelList;
