import React from 'react';

import { Formik } from 'formik';

import PropTypes from 'prop-types';

import Select from 'react-select';

// Styles

import { FormStyled, Row, Column } from '../../../css/styles/form';

import { SelectStyled } from '../../../css/styles/select';

const handleValidate = ({ cost }) => {
  const errors = {};

  if (!cost) errors.cost = 'OBRIGATÓRIO';

  return errors;
};

const handleSubmit = props => async (values, { setSubmitting, resetForm }) => {
  if (props.financialcost) {
    await props.onUpdate({ ...values });
  } else {
    await props.onCreation({ ...values });
  }

  setSubmitting(false);
  resetForm();
};

const FinancialCostForm = props => {
  const { onNew, financialcost, costs } = props;

  return (
    <Formik
      enableReinitialize
      initialValues={{
        cost: financialcost ? financialcost.cost : null,

        value: financialcost ? financialcost.value : '',
      }}
      validate={handleValidate}
      onSubmit={handleSubmit(props)}
    >
      {({
        values,

        handleReset,

        handleBlur,

        handleChange,

        errors,

        touched,

        setFieldValue,

        isSubmitting,

        dirty,
      }) => (
        <FormStyled>
          <h1> {financialcost ? 'ALTERAR CUSTO' : 'NOVO CUSTO'}</h1>

          <ul>
            <Row>
              <Column>
                <strong>CUSTO</strong>

                <Select
                  styles={SelectStyled}
                  id="cost"
                  value={values.cost}
                  placeholder="Selecione um custo"
                  onChange={selectedOption =>
                    setFieldValue('cost', selectedOption)
                  }
                  isSearchable
                  options={costs}
                  getOptionLabel={item => item.description}
                  getOptionValue={item => item.uuid}
                  name="cost"
                  isLoading={false}
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  loadingMessage={() => 'Buscando custos'}
                  noOptionsMessage={() => 'Não há custos cadastrados'}
                />

                {errors.cost && touched.cost && <span>{errors.cost}</span>}
              </Column>
            </Row>

            <Row>
              <Column>
                <strong>VALOR</strong>

                <input
                  id="value"
                  name="value"
                  type="number"
                  value={values.value}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />

                {errors.value && touched.value && <span>{errors.value}</span>}
              </Column>
            </Row>

            <Row>
              <div className="buttons-container">
                <button type="submit" disabled={isSubmitting}>
                  SALVAR
                </button>

                <button
                  type="button"
                  onClick={handleReset}
                  disabled={!dirty || isSubmitting}
                >
                  RESET
                </button>

                {financialcost && (
                  <button
                    type="button"
                    onClick={onNew}
                    disabled={(!dirty && !financialcost) || isSubmitting}
                  >
                    LIMPAR
                  </button>
                )}
              </div>
            </Row>
          </ul>
        </FormStyled>
      )}
    </Formik>
  );
};

FinancialCostForm.propTypes = {
  costs: PropTypes.arrayOf(
    PropTypes.shape({
      uuid: PropTypes.string,
      name: PropTypes.string,
    })
  ),

  financialcost: PropTypes.shape({
    value: PropTypes.number,

    cost: PropTypes.shape({
      uuid: PropTypes.string,
      name: PropTypes.string,
    }),
  }),

  onNew: PropTypes.func.isRequired,
};

FinancialCostForm.defaultProps = {
  costs: [],
  financialcost: null,
};

export default FinancialCostForm;
