import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  padding-top: 64px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
`;

export const Content = styled.div`
  width: 100%;
  max-width: 900px;
  margin: 0 64px;
  padding: 64px;
  border-radius: 4px;
  background: #fff;
  -webkit-box-shadow: 0px 6px 14px -2px rgba(0, 0, 0, 0.34);
  -moz-box-shadow: 0px 6px 14px -2px rgba(0, 0, 0, 0.34);
  box-shadow: 0px 6px 14px -2px rgba(0, 0, 0, 0.34);

  ul {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    li {
      display: flex;
      align-items: center;
      justify-content: center;

      & + li {
        margin-top: 32px;
      }

      a {
        width: 280px;
        padding: 5px 15px;
        background: #055aab;
        font-size: 16px;
        color: #fff;

        display: flex;
        align-items: center;
        justify-content: center;

        &:hover {
          background: rgba($color: #055aab, $alpha: 0.2);
        }

        &:disabled {
          background: #ddd;

          color: #999;

          cursor: default;
        }
      }
    }
  }
`;
