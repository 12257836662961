import React, { useRef } from 'react';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import Select from 'react-select';

import { FormStyled, Row, Column } from '../../css/styles/form';
import { SelectStyled } from '../../css/styles/select';


const handleValidate = ({ paymentEmployee, paymentValue }) => {
  const errors = {};
  if (!paymentEmployee) errors.paymentEmployee = 'OBRIGATÓRIO';
  if (!paymentValue) errors.paymentValue = 'OBRIGATÓRIO';
  return errors;
};

const handleSubmit = props => async (values, { setSubmitting, resetForm }) => {
  await props.onCreation({ ...values });

  setSubmitting(false);
  resetForm();
};

const PaymentEmployeeForm = props => {
  const formRef = useRef();

  const {
    employees,
    paymentEmployee,
    handlePaymentEmployee,
    employeeInformationIsComplete,
  } = props;


  employees.sort((previous, next) => {
    const namePrevious = previous.name.toUpperCase();
    const nameNext = next.name.toUpperCase();

    if (namePrevious < nameNext) {
      return -1;
    }
    if (namePrevious > nameNext) {
      return 1;
    }
    return 0;
  });

  return (
    <Formik
      ref={formRef}
      enableReinitialize
      initialValues={{
        employees,
        paymentValue: '',
        paymentEmployee: paymentEmployee ? paymentEmployee : null,
        employeeInformationIsComplete,
      }}
      validate={handleValidate}
      onSubmit={handleSubmit(props)}
    >
      {({
        values,
        handleReset,
        handleBlur,
        handleChange,
        errors,
        touched,
        isSubmitting,
        dirty,
        setFieldValue,
      }) => (
        <FormStyled>
          <h1>FUNCIONÁRIO REMESSA</h1>

          <ul>
            <Row>
              <Column>
                <strong>FUNCIONÁRIO</strong>
                <Select
                  styles={SelectStyled}
                  id="paymentEmployee"
                  value={values.paymentEmployee}
                  placeholder="Selecione um funcionário"
                  onChange={selectedOption => {
                    setFieldValue('paymentEmployee', selectedOption);
                    handlePaymentEmployee(selectedOption)
                  }}
                  isSearchable
                  options={employees}
                  getOptionLabel={item => item.name}
                  getOptionValue={item => item.uuid}
                  name="paymentEmployee"
                  isLoading={false}
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  loadingMessage={() => 'Buscando funcionários'}
                  noOptionsMessage={() => 'Não há funcionários cadastrados'}
                />
              </Column>
            </Row>

            <div className='employee-info'>
              <div>
                <strong>NOME:</strong>
                <p>{paymentEmployee && paymentEmployee.name ? paymentEmployee.name : ''}</p>
              </div>
              <div>
                <strong>CPF:</strong>
                <p>{paymentEmployee && paymentEmployee.document ? paymentEmployee.document : ''}</p>
              </div>
              <div>
                <strong>CONTA:</strong>
                <p>{paymentEmployee && paymentEmployee.account ? `${paymentEmployee.account}` : ''}</p>
              </div>
              <div>
                <strong>DIGITO VERIFICADOR DA CONTA:</strong>
                <p>{paymentEmployee && paymentEmployee.account_verifying_digit ? `${paymentEmployee.account_verifying_digit}` : ''}</p>
              </div>
              <div>
                <strong>AGÊNCIA:</strong>
                <p>{paymentEmployee && paymentEmployee.branch ? paymentEmployee.branch : ''}</p>
              </div>
              <div>
                <strong>BANCO:</strong>
                <p>{paymentEmployee && paymentEmployee.bank ? paymentEmployee.bank : ''}</p>
              </div>
              <div>
                <strong>CHAVE DE IDENTIFICAÇÃO:</strong>
                <p>{paymentEmployee && paymentEmployee.key ? paymentEmployee.key : ''}</p>
              </div>
            </div>

            <Row>
              <Column>
                <strong>VALOR</strong>
                <input
                  id="paymentValue"
                  name="paymentValue"
                  type="text"
                  value={values.paymentValue}
                  placeholder="Ex: 1350,00"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />

                {errors.paymentValue && touched.paymentValue && (
                  <span>{errors.paymentValue}</span>
                )}
              </Column>
            </Row>

            <Row>
              <div className='buttons-container'>
                <button type="submit" disabled={!dirty || isSubmitting || !employeeInformationIsComplete}>
                  ADICIONAR À REMESSA
                </button>
              </div>
            </Row>
          </ul>
        </FormStyled>
      )}
    </Formik>
  )
}

PaymentEmployeeForm.propTypes = {
  onCreation: PropTypes.func.isRequired,
  handlePaymentEmployee: PropTypes.func.isRequired,
  employees: PropTypes.arrayOf(PropTypes.object),
  paymentEmployee: PropTypes.object,
  employeeInformationIsComplete: PropTypes.bool,
}

PaymentEmployeeForm.defaultProps = {
  employees: [],
  paymentEmployee: null,
  employeeInformationIsComplete: false,
}


export default PaymentEmployeeForm;
