import React, { Component, useState } from 'react';
import Select from 'react-select';
import { CustomSelectStyled } from './styles';

class CustomSelect extends Component {
  render() {
    const {
      placeholder,
      options,
      value,
      onChange,
      labelKey,
      valueKey,
    } = this.props;

    const getOptionLabel = option => {
      if (typeof option === 'object') {
        return option[labelKey] || option.label || option.value || '';
      }
      return option;
    };

    const getOptionValue = option => option[valueKey] || option.value;

    return (
      <Select
        styles={CustomSelectStyled}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        options={options}
        isMulti={true}
        getOptionLabel={getOptionLabel}
        getOptionValue={getOptionValue}
      />
    );
  }
}

CustomSelect.defaultProps = {
  labelKey: 'label',
  valueKey: 'value',
};

export default CustomSelect;
