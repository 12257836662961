import React from 'react';
import { IoMdTrash } from 'react-icons/io';
import PropTypes from 'prop-types';

// Styles
import { ListStyled, ListItem } from '../../../css/styles/list';

const listItem = props =>
  props.maintenanceEmployees.map(item => (
    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
    <ListItem
      color="#011826"
      strongColor="#fff"
      key={item.uuid}
      onClick={() => props.onEdit(item)}
    >
      <div className="info">
        <div>
          <strong className="title">NOME:</strong>
          <strong>{item.employee && item.employee.name}</strong>
        </div>

        <div>
          <strong className="title">INÍCIO:</strong>
          <strong>
            {new Date(item.start).toLocaleString('pt-BR', {
              timeZone: 'UTC',
            })}
          </strong>
        </div>

        <div>
          <strong className="title">FINAL:</strong>
          <strong>
            {item.end
              ? new Date(item.end).toLocaleString('pt-BR', {
                  timeZone: 'UTC',
                })
              : ''}
          </strong>
        </div>
      </div>

      <button
        type="button"
        onClick={e => {
          e.stopPropagation();
          props.onDelete(item);
        }}
        className="delete"
      >
        <IoMdTrash size={20} color="#bf1523" />
      </button>
    </ListItem>
  ));

function maintenanceEmployeeList(props) {
  const { maintenanceEmployees } = props;

  return (
    <>
      {maintenanceEmployees && maintenanceEmployees.length > 0 ? (
        <ListStyled>{listItem(props)}</ListStyled>
      ) : (
        <strong>LISTA VAZIA</strong>
      )}
    </>
  );
}

maintenanceEmployeeList.propTypes = {
  maintenanceEmployees: PropTypes.arrayOf(
    PropTypes.shape({
      uuid: PropTypes.string,
      start: PropTypes.string,
      end: PropTypes.string,
    })
  ),
};

maintenanceEmployeeList.defaultProps = {
  maintenanceEmployees: [],
};

export default maintenanceEmployeeList;
