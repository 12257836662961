import React, { Component } from 'react';
import API from '../../services/api';
import moment from 'moment';
import ReactLoading from 'react-loading';
import { toast } from 'react-toastify';
import swal from 'sweetalert';
import { Wrapper, Container, Content } from '../../css/styles/wrapper';
import MaintenancePlanList from './list';
import MaintenancePlanForm from './form';
import MaintenancePlanFreqsContainer from './MaintenancePlanFreqs';

class MaintenancePlanContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      maintenancePlans: [],

      maintenancePlan: null,

      maintenancePlanFreq: null,

      maintenancePlanFreqs: [],

      maintenancePlanFreqForm: false,

      maintenanceServices: [],

      loading: true,

      //   closedList: false,
    };
    this.handleCreation = this.handleCreation.bind(this);

    this.handleUpdate = this.handleUpdate.bind(this);

    // this.handleClose = this.handleClose.bind(this);

    this.handleEdit = this.handleEdit.bind(this);

    this.handleMaintenancePlanGet = this.handleMaintenancePlanGet.bind(this);

    this.handleDelete = this.handleDelete.bind(this);

    this.handleNew = this.handleNew.bind(this);

    this.handleMaintenancePlanFreqFormState = this.handleMaintenancePlanFreqFormState.bind(
      this
    );
  }

  refMaintenancePlan = React.createRef();

  componentDidMount() {
    this.getList();
  }

  scrollToMyRef = myRef => {
    window.scrollTo(0, myRef.current.offsetTop);
  };

  getList = async values => {
    this.setState({ loading: true });

    try {
      const response = await API.get('/maintenanceplans');
      this.setState({
        maintenancePlans: response.data,
        loading: false,
      });
    } catch (err) {
      toast.error('Um erro aconteceu durante o carregamento!');
      this.setState({
        loading: false,
      });
    }
  };

  handleMaintenancePlanFreqFormState() {
    const { maintenancePlanFreqForm } = this.state;

    this.setState({
      maintenancePlanFreqForm: !maintenancePlanFreqForm,
    });
  }

  handleMaintenancePlanFreqsList = maintenancePlanFreqs => {
    this.setState({
      maintenancePlanFreqs: maintenancePlanFreqs,
    });
  };

  handleCreation = async object => {
    const data = {
      uuid: object.uuid,
      description: object.description.toUpperCase(),
    };

    try {
      await API.post('/maintenanceplans', { ...data });
      this.getList();
      this.handleNew();
      toast.success('Plano de manutenção criado com sucesso!');
    } catch (err) {
      toast.error('Um erro aconteceu durante o carregamento!');
    }
  };

  handleEdit = async object => {
    this.setState({
      maintenancePlan: null,
      maintenancePlanFreqForm: false,
    });

    this.handleMaintenancePlanGet(object.uuid);
    this.scrollToMyRef(this.refMaintenancePlan);
  };

  handleUpdate = async object => {
    const { maintenancePlan } = this.state;

    const data = {
      description: object.description.toUpperCase(),
    };
    try {
      await API.put(`/maintenanceplans/${maintenancePlan.uuid}`, data);
      this.getList();
      this.handleNew();
      toast.success('Plano de manutenção editado com sucesso!');
    } catch (err) {
      toast.error('Um erro aconteceu durante o carregamento!');
    }
  };

  handleDelete = async uuid => {
    try {
      const confirmDelete = await swal({
        dangerMode: true,

        text: 'Confirma a exclusão da manutenção?',

        buttons: {
          cancel: 'Não',

          confirm: 'Sim',
        },
      });

      if (confirmDelete) {
        await API.delete(`/maintenanceplans/${uuid}`);

        await this.getList();
        toast.success('Plano de manutenção excluído com sucesso!');
        this.handleNew();
      }
    } catch (err) {
      toast.error('Um erro aconteceu durante o carregamento!');
    }
  };

  handleNew = () => {
    this.setState({
      maintenancePlan: null,
      maintenancePlanFreq: null,
      maintenancePlanFreqForm: false,
      maintenancePlanFreqs: null,
    });

    // this.scrollToMyRef(this.refMaintenancePlan);
  };

  handleMaintenancePlanGet = async uuid => {
    try {
      const response = await API.get(`/maintenanceplans/${uuid}`);
      this.setState({
        maintenancePlan: response.data,
      });
    } catch (err) {
      toast.error('Um erro aconteceu durante o carregamento!');
    }
  };

  render() {
    const {
      maintenancePlan,
      maintenancePlans,
      maintenancePlanFreq,
      maintenancePlanFreqs,
      maintenancePlanFreqForm,
      loading,
    } = this.state;

    return (
      <Wrapper>
        <Container>
          <header>
            <h1>PLANOS DE MANUTENÇÃO</h1>
            <div className="buttons-container">
              <button type="button" onClick={this.handleNew}>
                NOVO PLANO
              </button>
            </div>
          </header>
          <Content>
            {!loading ? (
              <>
                {maintenancePlans && maintenancePlans[0] ? (
                  <>
                    <MaintenancePlanList
                      maintenancePlans={maintenancePlans}
                      loading={loading}
                      onEdit={this.handleEdit}
                      onDelete={this.handleDelete}
                    />
                  </>
                ) : (
                  <strong>LISTA VAZIA</strong>
                )}
              </>
            ) : (
              <ReactLoading
                type="spin"
                color="#011826"
                height={40}
                width={40}
              />
            )}

            <div ref={this.refMaintenancePlan} />
            <MaintenancePlanForm
              onCreation={this.handleCreation}
              onUpdate={this.handleUpdate}
              onNew={this.handleNew}
              maintenancePlan={maintenancePlan}
            />
            {maintenancePlan && (
              <>
                <nav>
                  <div className="container-full">
                    <button
                      type="button"
                      onClick={() => this.handleMaintenancePlanFreqFormState()}
                    >
                      FREQUÊNCIAS DE MANUTENÇÃO
                    </button>
                  </div>
                </nav>
              </>
            )}

            <MaintenancePlanFreqsContainer
              maintenancePlan={maintenancePlan}
              maintenancePlanFreqs={maintenancePlanFreqs}
              // maintenancePlanFreq={maintenancePlanFreq}
              onMaintenancePlanFreqsList={this.handleMaintenancePlanFreqsList}
              onMaintenancePlanFreqSubmit={
                this.handleMaintenancePlanFreqFormState
              }
              maintenancePlanFreqForm={maintenancePlanFreqForm}
              onMaintenancePlanFreqEdit={this.handleEdit}
            />
            {/* </>
            )} */}
          </Content>
        </Container>
      </Wrapper>
    );
  }
}

export default MaintenancePlanContainer;

