import React from 'react';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import Select from 'react-select';

// Styles
import { FormStyled, Row, Column } from '../../css/styles/form';
import { SelectStyled } from '../../css/styles/select';

const handleValidate = ({ qty, origin, destiny }) => {
  const errors = {};

  if (!qty) errors.qty = 'OBRIGATÓRIO';
  if (!origin) errors.origin = 'OBRIGATÓRIO';
  if (!destiny) errors.destiny = 'OBRIGATÓRIO';

  return errors;
};

const handleSubmit = props => async (values, { setSubmitting, resetForm }) => {
  await props.onCreation({ ...values });
  setSubmitting(false);
  resetForm();
};

const AccountTransferForm = props => {
  const { accounts } = props;

  return (
    <Formik
      enableReinitialize
      initialValues={{
        qty: '',

        origin: null,

        destiny: null,
      }}
      validate={handleValidate}
      onSubmit={handleSubmit(props)}
    >
      {({
        values,
        handleChange,
        handleBlur,
        handleReset,
        errors,
        touched,
        isSubmitting,
        setFieldValue,
        dirty,
      }) => (
        <FormStyled>
          <h1>NOVA TRANSFERÊNCIA INTERNA</h1>

          <ul>
            <Row>
              <Column>
                <strong>VALOR</strong>
                <input
                  id="qty"
                  name="qty"
                  type="number"
                  value={values.qty}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />

                {errors.qty && touched.qty && <span>{errors.qty}</span>}
              </Column>
            </Row>

            <Row>
              <Column>
                <strong>ORIGEM</strong>
                <Select
                  styles={SelectStyled}
                  id="origin"
                  value={values.origin}
                  placeholder="Selecione uma conta"
                  onChange={selectedOption => {
                    setFieldValue('origin', selectedOption);
                  }}
                  isSearchable
                  options={accounts}
                  getOptionLabel={item =>
                    `${item.name} (${
                      item.balance
                        ? item.balance.toLocaleString('pt-BR', {
                            minimumFractionDigits: 2,
                          })
                        : '0,00'
                    })`
                  }
                  getOptionValue={item => item.uuid}
                  name="origin"
                  isLoading={false}
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  loadingMessage={() => 'Buscando contas'}
                  noOptionsMessage={() => 'Não há contas cadastrados'}
                />
                {errors.origin && touched.origin && (
                  <span>{errors.origin}</span>
                )}
              </Column>
            </Row>

            <Row>
              <Column>
                <strong>DESTINO</strong>
                <Select
                  styles={SelectStyled}
                  id="destiny"
                  value={values.destiny}
                  placeholder="Selecione uma conta"
                  onChange={selectedOption => {
                    setFieldValue('destiny', selectedOption);
                  }}
                  isSearchable
                  options={accounts}
                  getOptionLabel={item =>
                    `${item.name} (${
                      item.balance
                        ? item.balance.toLocaleString('pt-BR', {
                            minimumFractionDigits: 2,
                          })
                        : '0,00'
                    })`
                  }
                  getOptionValue={item => item.uuid}
                  name="destiny"
                  isLoading={false}
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  loadingMessage={() => 'Buscando contas'}
                  noOptionsMessage={() => 'Não há contas cadastrados'}
                />
                {errors.destiny && touched.destiny && (
                  <span>{errors.destiny}</span>
                )}
              </Column>
            </Row>

            <Row>
              <div className="buttons-container">
                <button type="submit" disabled={isSubmitting || !dirty}>
                  SALVAR
                </button>

                <button
                  type="button"
                  onClick={handleReset}
                  disabled={!dirty || isSubmitting}
                >
                  RESET
                </button>
              </div>
            </Row>
          </ul>
        </FormStyled>
      )}
    </Formik>
  );
};

AccountTransferForm.propTypes = {
  accounts: PropTypes.arrayOf(
    PropTypes.shape({
      uuid: PropTypes.string,
      name: PropTypes.string,
      balance: PropTypes.number,
    })
  ),
};

AccountTransferForm.defaultProps = {
  accounts: [],
};

export default AccountTransferForm;
