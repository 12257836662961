import React from 'react';
import moment from 'moment';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import Select from 'react-select';

import {
  FilterFormStyled,
  FilterRow,
  FilterColumn,
} from '../../css/styles/filter';
import { SelectStyled } from '../../css/styles/select';

const handleValidate = ({ begin, end }) => {
  const errors = {};

  if (!begin) errors.begin = 'OBRIGATÓRIO';

  if (moment(begin).isAfter(end))
    errors.end = 'DATA FINAL NÃO PODE SER MENOR QUE INICIAL';

  if (!end) errors.end = 'OBRIGATÓRIO';

  return errors;
};

const handleSubmit = props => async (values, { setSubmitting }) => {
  await props.onList({ ...values });

  setSubmitting(false);
};

const RefuelFilterForm = props => {
  const { equipments, bases, base } = props;

  const baseOptions = [
    {
      value: null,
      description: 'Filtrar sem base',
    },
    ...bases,
  ]
  
  const typeOptions = [
    {
      value: 'INTERNO',
      label: 'INTERNO',
    },
    {
      value: 'EXTERNO',
      label: 'EXTERNO',
    },
  ];

  return (
    <Formik
      enableReinitialize
      initialValues={{
        begin: new Date().toISOString().substring(0, 10),

        end: new Date().toISOString().substring(0, 10),

        group: false,

        type: '',

        equipment: null,

        base: base
      }}
      validate={handleValidate}
      onSubmit={handleSubmit(props)}
    >
      {({
        values,
        handleChange,
        handleBlur,
        handleReset,
        errors,
        touched,
        setFieldValue,
        dirty,
        isSubmitting,
      }) => (
        <FilterFormStyled>
          <h1>FILTROS</h1>

          <ul>
            <FilterRow>
              <FilterColumn>
                <strong>DATA INICIAL</strong>
                <input
                  id="begin"
                  name="begin"
                  type="date"
                  value={values.begin}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />

                {errors.begin && touched.begin && <span>{errors.begin}</span>}
              </FilterColumn>

              <FilterColumn>
                <strong>DATA FINAL</strong>
                <input
                  id="end"
                  name="end"
                  type="date"
                  value={values.end}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />

                {errors.end && touched.end && <span>{errors.end}</span>}
              </FilterColumn>
            </FilterRow>

            <FilterRow>
              <FilterColumn>
                <strong>TIPO</strong>
                <select
                  id="type"
                  name="type"
                  value={values.type}
                  onChange={handleChange}
                  onBlur={handleBlur}
                >
                  <option value="" />
                  {typeOptions.map(type => (
                    <option key={type.value} value={type.value}>
                      {type.label}
                    </option>
                  ))}
                </select>

                {errors.type && touched.type && <span>{errors.type}</span>}
              </FilterColumn>
            </FilterRow>

            <FilterRow>
              <FilterColumn>
                <strong>VEÍCULO</strong>
                <Select
                  styles={SelectStyled}
                  id="equipment"
                  value={values.equipment}
                  placeholder="Selecione um veículo"
                  onChange={selectedOption =>
                    setFieldValue('equipment', selectedOption)
                  }
                  isSearchable
                  options={equipments}
                  getOptionLabel={item => item.code}
                  getOptionValue={item => item.uuid}
                  name="equipment"
                  isLoading={false}
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  loadingMessage={() => 'Buscando veículos'}
                  noOptionsMessage={() => 'Não há veículos cadastrados'}
                />

                {errors.equipment && touched.equipment && (
                  <span>{errors.equipment}</span>
                )}
              </FilterColumn>
            </FilterRow>
            <FilterRow>
              <FilterColumn>
                <strong>BASE</strong>
                <Select
                  styles={SelectStyled}
                  id="base"
                  value={values.base}
                  placeholder="Selecione uma base"
                  onChange={selectedOption => {
                    setFieldValue('base', selectedOption);
                  }}
                  isSearchable
                  options={baseOptions}
                  getOptionLabel={item => item.description}
                  getOptionValue={item => item.uuid}
                  name="baseUuid"
                  isLoading={false}
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  loadingMessage={() => 'Buscando bases'}
                  noOptionsMessage={() => 'Não há bases cadastradas'}
                />
              </FilterColumn>
            </FilterRow>

            <FilterRow>
              <div className="buttons-container">
                <button type="submit" disabled={isSubmitting}>
                  BUSCAR
                </button>

                <button
                  type="button"
                  onClick={handleReset}
                  disabled={isSubmitting || !dirty}
                >
                  RESET
                </button>
              </div>
            </FilterRow>
          </ul>
        </FilterFormStyled>
      )}
    </Formik>
  );
};

RefuelFilterForm.propTypes = {
  equipments: PropTypes.arrayOf(
    PropTypes.shape({
      uuid: PropTypes.string,
      code: PropTypes.string,
    })
  ),
  bases: PropTypes.arrayOf(PropTypes.shape({
    uuid: PropTypes.string,
    description: PropTypes.string,
  })),
  base: PropTypes.shape({
    uuid: PropTypes.string,
    description: PropTypes.string,
  }),
};

RefuelFilterForm.defaultProps = {
  equipments: [],
  bases: [],
  base: null,
};

export default RefuelFilterForm;
