import React, { Component } from 'react';
import ReactLoading from 'react-loading';
import swal from 'sweetalert';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import EmployeeContainer from '../../Employee';
import MaintenanceEmployeeForm from './form';
import MaintenanceEmployeeList from './list';
import API from '../../../services/api';

// Styles
import { SubContainer } from '../../../css/styles/wrapper';

class MaintenanceEmployeeContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      maintenanceEmployee: null,
      employeeForm: false,
      employee: null,
      employees: [],
    };

    this.handleCreation = this.handleCreation.bind(this);
  }

  refMaintenanceEmployees = React.createRef();

  scrollToMyRef = myRef => {
    window.scrollTo(0, myRef.current.offsetTop);
  };

  handleCreation = async object => {
    const { maintenance, onMaintenanceEmployeeSubmit, onMaintenanceEmployeesList, onMaintenanceEmployeeEdit } = this.props;

    const data = {
      maintenanceUuid: maintenance.uuid,

      employeeUuid: object.employee.uuid,

      start: `${object.start_date}T${object.start_time}`,

      end:
        object.end_date && object.end_time
          ? `${object.end_date}T${object.end_time}`
          : null,
    };

    try {
      const response = await API.post('/maintenanceemployees', { ...data });

      await onMaintenanceEmployeesList(response.data.maintenanceUuid);
      await onMaintenanceEmployeeEdit({
        uuid: response.data.maintenanceUuid
      })
      this.handleNew();
      toast.success('Funcionário da manutenção cadastrado com sucesso!');
      onMaintenanceEmployeeSubmit();
    } catch (err) {
      toast.error('Um erro aconteceu durante o cadastro!');
    }
  };

  handleEdit = object => {
    this.setState({ maintenanceEmployee: object, employee: null });
  };

  handleNew = () => {
    this.setState({ maintenanceEmployee: null, employee: null });
  };

  handleUpdate = async object => {
    const { onMaintenanceEmployeeSubmit, onMaintenanceEmployeesList, onMaintenanceEmployeeEdit } = this.props;

    const { maintenanceEmployee } = this.state;

    const data = {
      employeeUuid: object.employee.uuid,

      start: `${object.start_date}T${object.start_time}`,

      end:
        object.end_date && object.end_time
          ? `${object.end_date}T${object.end_time}`
          : null,
    };

    try {
      await API.put(`/maintenanceemployees/${maintenanceEmployee.uuid}`, {
        ...data,
      });

      await onMaintenanceEmployeesList(maintenanceEmployee.maintenanceUuid);
      await onMaintenanceEmployeeEdit({
        uuid: this.state.maintenanceEmployee.maintenanceUuid
      })
      this.handleNew();
      toast.success('Funcionário da manutenção atualizado com sucesso.');
      onMaintenanceEmployeeSubmit();
    } catch (error) {
      toast.error('Um erro aconteceu durante a atualização!');
    }
  };

  handleDelete = async object => {
    this.handleEdit(object)
    const { onMaintenanceEmployeeSubmit, onMaintenanceEmployeesList, onMaintenanceEmployeeEdit } = this.props;

    try {
      const confirmDelete = await swal({
        dangerMode: true,

        text: 'Confirma a exclusão do funcionário da manutenção?',

        buttons: {
          cancel: 'Não',

          confirm: 'Sim',
        },
      });

      if (confirmDelete) {
        await API.delete(`maintenanceemployees/${object.uuid}`);
        await onMaintenanceEmployeesList(this.state.maintenanceEmployee.maintenanceUuid);
        await onMaintenanceEmployeeEdit({
          uuid: this.state.maintenanceEmployee.maintenanceUuid
        })
        this.handleNew();
        toast.success('Funcionário da manutenção deletado com sucesso!');
        onMaintenanceEmployeeSubmit();

      }
    } catch (err) {
      toast.error('Um erro aconteceu durante a exclusão!');
    }
  };

  // EMPLOYEES HELPERS

  handleEmployeesList = (employees, employee) => {
    this.setState({
      employees,

      employee,

      maintenanceEmployee: null,
    });
  };

  handleEmployeeFormState() {
    const { employeeForm } = this.state;

    this.setState({
      employeeForm: !employeeForm,
    });
  }

  render() {
    const { maintenanceEmployeeForm, maintenanceEmployees } = this.props;

    const {
      maintenanceEmployee,
      loading,
      employeeForm,
      employees,
      employee,
    } = this.state;
    return (
      <SubContainer visible={maintenanceEmployeeForm}>
        <div ref={this.refMaintenanceEmployees} />

        <nav>
          <h1>FUNCIONÁRIOS DE MANUTENÇÃO</h1>

          <button
            type="button"
            onClick={() => this.handleEmployeeFormState()}
          >
            FUNCIONÁRIO
          </button>
        </nav>

        <EmployeeContainer
          onEmployeesList={this.handleEmployeesList}
          onEmployeeSubmit={() => this.handleEmployeeFormState()}
          employeeForm={employeeForm}
        />

        {loading ? (
          <ReactLoading type="spin" color="#011826" height={40} width={40} />
        ) : (
          <MaintenanceEmployeeList
            maintenanceEmployees={maintenanceEmployees}
            onEdit={this.handleEdit}
            onDelete={this.handleDelete}
          />
        )}

        <MaintenanceEmployeeForm
          onUpdate={this.handleUpdate}
          onNew={this.handleNew}
          onCreation={this.handleCreation}
          maintenanceEmployee={maintenanceEmployee}
          employees={employees}
          employee={employee}
        />
      </SubContainer>
    );
  }
}

MaintenanceEmployeeContainer.propTypes = {
  maintenance: PropTypes.shape({
    uuid: PropTypes.string,
  }).isRequired,
  maintenanceEmployees: PropTypes.arrayOf(),
  onMaintenanceEmployeesList: PropTypes.func.isRequired,
  onMaintenanceEmployeeSubmit: PropTypes.func.isRequired,
  maintenanceEmployeeForm: PropTypes.bool.isRequired,
};

export default MaintenanceEmployeeContainer;
