import React from 'react';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import Radio from '@material-ui/core/Radio';
import FormControl from '@material-ui/core/FormControl';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Select from 'react-select';

import { FormStyled, Row, Column } from '../../../css/styles/form';
import { SelectStyled } from '../../../css/styles/select';

const handleValidate = ({
  due_date,

  account,

  value,

  paid_value,

  payment_date,
}) => {
  const errors = {};

  if (!due_date) errors.due_date = 'OBRIGATÓRIO';

  if (!value) errors.value = 'OBRIGATÓRIO';

  if (payment_date && !account) errors.account = 'OBRIGATÓRIO';

  if (!payment_date && account) errors.payment_date = 'OBRIGATÓRIO';

  if (!payment_date && paid_value) errors.payment_date = 'OBRIGATÓRIO';

  return errors;
};

const handleSubmit = props => async (values, { setSubmitting, resetForm }) => {
  if (props.installment && props.installment.uuid) {
    await props.onUpdate({ ...values });
  } else {
    await props.onCreation({ ...values });
  }
  setSubmitting(false);
  resetForm();
};

const InstallmentForm = props => {
  const { accounts, installment, onBaixa, pay_off, onNew } = props;

  return (
    <Formik
      enableReinitialize
      initialValues={{
        due_date: installment ? installment.due_date.substring(0, 10) : '',

        payment_date:
          installment && installment.payment_date
            ? installment.payment_date.substring(0, 10)
            : '',

        value: installment ? installment.value : '',

        paid_value: installment ? installment.paid_value : '',

        pay_off: pay_off || false,

        account: (installment && installment.account) || null,
      }}
      validate={handleValidate}
      onSubmit={handleSubmit(props)}
    >
      {({
        values,
        handleChange,
        handleBlur,
        handleReset,
        errors,
        touched,
        isSubmitting,
        setFieldValue,
        dirty,
      }) => (
        <FormStyled>
          <h1>PARCELAS</h1>

          <ul>
            <Row>
              <Column>
                <strong>VENCIMENTO</strong>
                <input
                  id="due_date"
                  name="due_date"
                  type="date"
                  value={values.due_date}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />

                {errors.due_date && touched.due_date && (
                  <span>{errors.due_date}</span>
                )}
              </Column>

              <Column>
                <strong>DATA DE PAGAMENTO</strong>
                <input
                  id="payment_date"
                  name="payment_date"
                  type="date"
                  value={values.payment_date}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />

                {errors.payment_date && touched.payment_date && (
                  <span>{errors.payment_date}</span>
                )}
              </Column>
            </Row>
            <Row>
              <Column>
                <strong>VALOR</strong>
                <input
                  id="value"
                  name="value"
                  type="text"
                  value={values.value}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />

                {errors.value && touched.value && <span>{errors.value}</span>}
              </Column>

              <Column>
                <strong>VALOR PAGO</strong>
                <input
                  id="paid_value"
                  name="paid_value"
                  type="text"
                  value={values.paid_value}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />

                {errors.paid_value && touched.paid_value && (
                  <span>{errors.paid_value}</span>
                )}
              </Column>
            </Row>

            <Row>
              <Column>
                <FormControl style={{ width: '90%' }}>
                  <label
                    htmlFor="pay_off"
                    style={{
                      fontSize: 14,
                      fontWeight: 'bold',
                      textAlign: 'left',
                      marginTop: 16,
                    }}
                  >
                    QUITAÇÃO
                  </label>
                  <RadioGroup
                    id="pay_off"
                    aria-label="pay_off"
                    name="pay_off"
                    value={values.pay_off}
                    onChange={handleChange}
                    row
                    InputLabelProps={{ shrink: true }}
                  >
                    <FormControlLabel
                      value="false"
                      control={<Radio />}
                      label="Não"
                      labelPlacement="end"
                    />
                    <FormControlLabel
                      value="true"
                      control={<Radio />}
                      label="Sim"
                      labelPlacement="end"
                    />
                  </RadioGroup>
                </FormControl>
              </Column>
            </Row>

            <Row>
              <Column>
                <strong>CONTA</strong>
                <Select
                  styles={SelectStyled}
                  id="account"
                  value={values.account}
                  placeholder="Selecione uma conta"
                  onChange={selectedOption => {
                    setFieldValue('account', selectedOption);
                  }}
                  isSearchable
                  options={accounts}
                  getOptionLabel={item =>
                    `${item.name} (${
                      item.balance
                        ? item.balance.toLocaleString('pt-BR', {
                            minimumFractionDigits: 2,
                          })
                        : '0,00'
                    }) `
                  }
                  getOptionValue={item => item.uuid}
                  name="account"
                  isLoading={false}
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  loadingMessage={() => 'Buscando contas'}
                  noOptionsMessage={() => 'Não há contas cadastradas'}
                />

                {errors.account && touched.account && (
                  <span>{errors.account}</span>
                )}
              </Column>
            </Row>

            <Row>
              <div className="buttons-container">
                {!installment ||
                  (!installment.payment_date && (
                    <>
                      <button type="submit" disabled={isSubmitting || !dirty}>
                        SALVAR
                      </button>

                      <button
                        type="button"
                        onClick={handleReset}
                        disabled={!dirty || isSubmitting}
                      >
                        RESET
                      </button>
                    </>
                  ))}

                {installment && installment.payment_date && (
                  <button className="delete" type="button" onClick={onBaixa}>
                    CANCELAR BAIXA
                  </button>
                )}

                {installment && (
                  <button
                    type="button"
                    onClick={onNew}
                    disabled={(!dirty && !installment) || isSubmitting}
                  >
                    LIMPAR
                  </button>
                )}
              </div>
            </Row>
          </ul>
        </FormStyled>
      )}
    </Formik>
  );
};

InstallmentForm.propTypes = {
  accounts: PropTypes.arrayOf(
    PropTypes.shape({
      uuid: PropTypes.string,
      name: PropTypes.string,
      balance: PropTypes.number,
    })
  ),
  installment: PropTypes.shape({
    payment_date: PropTypes.string,
    paid_value: PropTypes.string,
    account: PropTypes.shape({
      uuid: PropTypes.string,
    }),
    value: PropTypes.string,
    due_date: PropTypes.string,
  }),
  onBaixa: PropTypes.func.isRequired,
  pay_off: PropTypes.bool,
  onNew: PropTypes.func.isRequired,
};

InstallmentForm.defaultProps = {
  accounts: [],
  installment: null,
  pay_off: false,
};

export default InstallmentForm;
