export const USER_TOKEN = 'USER_TOKEN';

export const isAuthenticated = () => localStorage.getItem(USER_TOKEN) !== null;

export const getToken = () => localStorage.getItem(USER_TOKEN);

export const login = token => {
  localStorage.setItem(USER_TOKEN, token);
};

export const logout = () => {
  localStorage.removeItem(USER_TOKEN);

  window.location.reload();
};
