import React from 'react';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import Select from 'react-select';

import { FormStyled, Row, Column } from '../../css/styles/form';
import { SelectStyled } from '../../css/styles/select';

const handleValidate = props => ({
  date,

  type,

  value,

  number,

  partner,

  cost,

  due_date,
}) => {
  const errors = {};

  if (!number) errors.number = 'OBRIGATÓRIO';

  if (!date) errors.date = 'OBRIGATÓRIO';

  if (!type) errors.type = 'OBRIGATÓRIO';

  //  if (!equipmentUuid) errors.equipmentUuid = 'OBRIGATÓRIO';

  if (!value) errors.value = 'OBRIGATÓRIO';

  // if (!taxes) errors.taxes = 'OBRIGATÓRIO';

  if (!partner) errors.partner = 'OBRIGATÓRIO';

  if (!cost) errors.cost = 'OBRIGATÓRIO';

  if (!due_date && !props.financial) errors.due_date = 'OBRIGATÓRIO';

  return errors;
};

const handleSubmit = props => async (values, { setSubmitting, resetForm }) => {
  if (!props.financialItem) {
    await props.onCreation({ ...values });
  } else {
    await props.onUpdate({ ...values });
  }

  setSubmitting(false);
  resetForm();
};

const handleForOptions = length => {
  const array = [];

  // eslint-disable-next-line no-plusplus
  for (let x = 1; x < length; x++) {
    const installment = {
      label: x,
      value: x,
    };

    array.push(installment);
  }

  return array;
};

const FinancialForm = props => {
  const {
    equipments,
    equipment,
    partners,
    partner,
    costs,
    cost,
    financialItem,
    installments,
    onNew,
    onDelete,
  } = props;

  const typeOptions = [
    {
      value: 'A PAGAR',
      label: 'A PAGAR',
    },
    {
      value: 'A RECEBER',
      label: 'A RECEBER',
    },
  ];

  const installmentsOptions = handleForOptions(61);

  const intervalOptions = handleForOptions(31);

  let paidInstallments = [];

  paidInstallments = installments.filter(
    installment => installment.paid_value && installment.payment_date
  );

  return (
    <Formik
      enableReinitialize
      initialValues={{
        description: !financialItem ? '' : financialItem.financial.description,

        date: !financialItem
          ? new Date().toISOString().substring(0, 10)
          : financialItem.financial.date.substring(0, 10),

        type: !financialItem ? '' : financialItem.financial.type,

        observation: !financialItem ? '' : financialItem.financial.observation,

        equipment:
          financialItem && financialItem.financial.equipment
            ? financialItem.financial.equipment
            : equipment || null,

        cost: cost || null,

        partner:
          financialItem && financialItem.financial.partner
            ? financialItem.financial.partner
            : partner || null,

        value: financialItem ? financialItem.value : '',

        taxes: financialItem ? financialItem.financial.taxes : '',

        number: financialItem ? financialItem.financial.number : '',

        due_date: financialItem ? financialItem.due_date.substring(0, 10) : '',

        installments: 1,

        interval: 0,
      }}
      validate={handleValidate(props)}
      onSubmit={handleSubmit(props)}
    >
      {({
        values,
        handleChange,
        handleBlur,
        handleReset,
        errors,
        touched,
        isSubmitting,
        setFieldValue,
        dirty,
      }) => (
        <FormStyled>
          <h1>{financialItem ? 'ALTERAR' : 'NOVA'}</h1>
          <ul>
            <Row>
              <Column>
                <strong>TIPO</strong>
                <select
                  id="type"
                  name="type"
                  value={values.type}
                  onChange={handleChange}
                  onBlur={handleBlur}
                >
                  <option value="" />
                  {typeOptions.map(type => (
                    <option key={type.value} value={type.value}>
                      {type.label}
                    </option>
                  ))}
                </select>

                {errors.type && touched.type && <span>{errors.type}</span>}
              </Column>
            </Row>

            <Row>
              <Column>
                <strong>NUMERO</strong>
                <input
                  id="number"
                  name="number"
                  type="text"
                  value={values.number}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />

                {errors.number && touched.number && (
                  <span>{errors.number}</span>
                )}
              </Column>
            </Row>

            <Row>
              <Column>
                <strong>DATA DE EMISSÃO</strong>
                <input
                  id="date"
                  name="date"
                  type="date"
                  value={values.date}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />

                {errors.date && touched.date && <span>{errors.date}</span>}
              </Column>
              <Column>
                <strong>VALOR</strong>
                <input
                  id="value"
                  name="value"
                  type="text"
                  disabled={financialItem}
                  value={values.value}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />

                {errors.value && touched.value && <span>{errors.value}</span>}
              </Column>
              <Column>
                <strong>IMPOSTO A RETER</strong>
                <input
                  id="taxes"
                  name="taxes"
                  type="text"
                  value={values.taxes}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />

                {errors.taxes && touched.taxes && <span>{errors.taxes}</span>}
              </Column>
            </Row>

            <Row>
              <Column>
                <strong>PRIMEIRO VENCIMENTO</strong>
                <input
                  disabled={financialItem}
                  id="due_date"
                  name="due_date"
                  type="date"
                  value={values.due_date}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />

                {errors.due_date && touched.due_date && (
                  <span>{errors.due_date}</span>
                )}
              </Column>
              <Column>
                <strong>PARCELAS</strong>
                <select
                  disabled={financialItem}
                  id="installments"
                  name="installments"
                  value={values.installments}
                  onChange={handleChange}
                  onBlur={handleBlur}
                >
                  <option value="" />
                  {installmentsOptions.map(item => (
                    <option key={item.value} value={item.value}>
                      {item.label}
                    </option>
                  ))}
                </select>

                {errors.installments && touched.installments && (
                  <span>{errors.installments}</span>
                )}
              </Column>
              <Column>
                <strong>INTERVALO (DIAS)</strong>
                <select
                  disabled={financialItem}
                  id="interval"
                  name="interval"
                  value={values.interval}
                  onChange={handleChange}
                  onBlur={handleBlur}
                >
                  <option value={0}>DIA</option>
                  {intervalOptions.map(interval => (
                    <option key={interval.value} value={interval.value}>
                      {interval.label}
                    </option>
                  ))}
                </select>

                {errors.interval && touched.interval && (
                  <span>{errors.interval}</span>
                )}
              </Column>
            </Row>

            <Row>
              <Column>
                <strong>FORNECEDOR OU CLIENTE</strong>
                <Select
                  styles={SelectStyled}
                  id="partner"
                  value={values.partner}
                  placeholder="Selecione um funcionário"
                  onChange={selectedOption => {
                    setFieldValue('partner', selectedOption);
                  }}
                  isSearchable
                  options={partners}
                  getOptionLabel={item => item.name}
                  getOptionValue={item => item.uuid}
                  name="partner"
                  isLoading={false}
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  loadingMessage={() => 'Buscando funcionários'}
                  noOptionsMessage={() => 'Não há funcionários cadastrados'}
                />

                {errors.partner && touched.partner && (
                  <span>{errors.partner}</span>
                )}
              </Column>
            </Row>

            <Row>
              <Column>
                <strong>VEÍCULO</strong>
                <Select
                  styles={SelectStyled}
                  id="equipment"
                  value={values.equipment}
                  placeholder="Selecione um veículo"
                  onChange={selectedOption => {
                    setFieldValue('equipment', selectedOption);
                  }}
                  isSearchable
                  options={equipments}
                  getOptionLabel={item => item.code}
                  getOptionValue={item => item.uuid}
                  name="equipment"
                  isLoading={false}
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  loadingMessage={() => 'Buscando veículos'}
                  noOptionsMessage={() => 'Não há veículos cadastrados'}
                />

                {errors.equipment && touched.equipment && (
                  <span>{errors.equipment}</span>
                )}
              </Column>
            </Row>

            {!financialItem && (
              <Row>
                <Column>
                  <strong>CENTRO DE CUSTO</strong>
                  <Select
                    styles={SelectStyled}
                    id="cost"
                    value={values.cost}
                    placeholder="Selecione um centro de custo"
                    onChange={selectedOption => {
                      setFieldValue('cost', selectedOption);
                    }}
                    isSearchable
                    options={costs}
                    getOptionLabel={item => item.description}
                    getOptionValue={item => item.uuid}
                    name="cost"
                    isLoading={false}
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                    loadingMessage={() => 'Buscando centro de custo'}
                    noOptionsMessage={() =>
                      'Não há centros de custo cadastrados'
                    }
                  />

                  {errors.cost && touched.cost && <span>{errors.cost}</span>}
                </Column>
              </Row>
            )}

            <Row>
              <Column>
                <strong>DESCRIÇÃO</strong>
                <textarea
                  id="description"
                  name="description"
                  type="text"
                  value={values.description}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />

                {errors.description && touched.description && (
                  <span>{errors.description}</span>
                )}
              </Column>
            </Row>

            <Row>
              <Column>
                <strong>OBSERVAÇÃO</strong>
                <input
                  id="observation"
                  name="observation"
                  type="text"
                  value={values.observation}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />

                {errors.observation && touched.observation && (
                  <span>{errors.observation}</span>
                )}
              </Column>
            </Row>

            <Row>
              <div className="buttons-container">
                <button type="submit" disabled={isSubmitting || !dirty}>
                  SALVAR
                </button>

                <button
                  type="button"
                  onClick={handleReset}
                  disabled={!dirty || isSubmitting}
                >
                  RESET
                </button>

                {financialItem && (
                  <button
                    type="button"
                    onClick={onNew}
                    disabled={(!dirty && !financialItem) || isSubmitting}
                  >
                    LIMPAR
                  </button>
                )}

                {financialItem && !(paidInstallments && paidInstallments[0]) && (
                  <button
                    className="delete"
                    type="button"
                    onClick={() => onDelete(financialItem)}
                    disabled={(!dirty && !financialItem) || isSubmitting}
                  >
                    EXCLUIR
                  </button>
                )}
              </div>
            </Row>
          </ul>
        </FormStyled>
      )}
    </Formik>
  );
};

FinancialForm.propTypes = {
  costs: PropTypes.arrayOf(
    PropTypes.shape({
      uuid: PropTypes.string,
      description: PropTypes.string,
    })
  ),
  equipments: PropTypes.arrayOf(
    PropTypes.shape({
      uuid: PropTypes.string,
      code: PropTypes.string,
    })
  ),
  partners: PropTypes.arrayOf(
    PropTypes.shape({
      uuid: PropTypes.string,
      name: PropTypes.string,
    })
  ),
  financialItem: PropTypes.shape({
    value: PropTypes.number,
    due_date: PropTypes.string,
    financial: PropTypes.shape({
      uuid: PropTypes.string,
      date: PropTypes.string,
      number: PropTypes.string,
      description: PropTypes.string,
      observation: PropTypes.string,
      type: PropTypes.string,
      partner: PropTypes.shape({
        uuid: PropTypes.string,
      }),
      equipment: PropTypes.shape({
        uuid: PropTypes.string,
      }),
      cost: PropTypes.shape({
        uuid: PropTypes.string,
      }),
      taxes: PropTypes.number,
    }),
  }),
  equipment: PropTypes.shape({
    uuid: PropTypes.string,
  }),
  cost: PropTypes.shape({
    uuid: PropTypes.string,
  }),
  partner: PropTypes.shape({
    uuid: PropTypes.string,
  }),
  onNew: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  installments: PropTypes.arrayOf(PropTypes.shape({})),
};

FinancialForm.defaultProps = {
  costs: [],
  cost: null,
  equipments: [],
  equipment: null,
  partners: [],
  partner: null,
  financialItem: null,
  installments: [],
};

export default FinancialForm;
