import React, { Component } from 'react';

import { Router, Route } from 'react-router-dom';

import { ToastContainer, toast } from 'react-toastify';

// import io from 'socket.io-client';

import API from './services/api';

// import Parameters from './services/parameters';

import history from './services/history';

import 'react-toastify/dist/ReactToastify.css';

// Routes

import Routes from '../routes';

// Views

import Login from './views/Login';

// Components

import Menu from './views/components/Menu/index';

import Header from './views/components/Header/index';

// Images

// Css

import './css/main.scss';

// const socket = io(Parameters.URL_API);

// para testes no localhost:

// const socket = io('http://Parameters.URL_API');

class App extends Component {
  state = {
    isAuth: false,

    username: '',

    useruuid: '',

    photo: '',

    teamName: '',
  };

  componentDidMount() {
    const token = localStorage.getItem('USER_TOKEN');

    if (!token) {
      localStorage.removeItem('USER_USERNAME');

      localStorage.removeItem('USER_UUID');

      localStorage.removeItem('USER_PHOTO');

      localStorage.removeItem('USER_TOKEN');

      localStorage.removeItem('USER_TEAM');

      localStorage.removeItem('USER');
    }

    const username = localStorage.getItem('USER_USERNAME');

    const useruuid = localStorage.getItem('USER_UUID');

    const photo = localStorage.getItem('USER_PHOTO');

    const teamName = localStorage.getItem('USER_TEAM');

    if (username && useruuid) {
      this.setState({
        isAuth: true,

        username,

        useruuid,

        photo,

        teamName,
      });
    }
  }

  /**



   * @argument message = mensagem texto puro do toast



   * @argument linkOnClick caso preenchido com uma rota, ao clicar no toast,



   * redireciona a página para a rota informada



   */

  notifyErrorTextOnClick = (message, linkOnClick) => {
    const routeLink = !linkOnClick || linkOnClick === '' ? null : linkOnClick;

    toast.error(message, {
      position: toast.POSITION.BOTTOM_RIGHT,

      onClick: () => {
        if (routeLink) history.push(routeLink);
      },
    });
  };

  notifyErrorText = message => {
    toast.error(message, {
      position: toast.POSITION.BOTTOM_RIGHT,
    });
  };

  handleLogin = async (email, passwd, lembrar = false) => {
    try {
      const logado = await API.post(
        `auth/login`,

        { username: email, password: passwd },

        { headers: { 'Content-Type': 'application/json' } }
      );

      const { userteams } = logado.data.user;

      const { name: teamName } = userteams[0] && userteams[0].team;

      if (lembrar) {
        this.saveLocalStorage(
          logado.data.user.name,

          logado.data.user.uuid,

          logado.data.user.photo,

          logado.data.token,

          teamName
        );
      }

      this.setState({
        isAuth: true,

        username: logado.data.user.name,

        useruuid: logado.data.user.uuid,

        photo: logado.data.user.photo,

        teamName,
      });

      history.push('/home');

      // console.log('logado.data', logado.data);
    } catch (err) {
      // console.log(err);

      if (err.response) {
        return err.response.status;
      }

      return err;
    }

    return true;
  };

  handleLogout = () => {
    history.push('/');

    this.setState({
      isAuth: false,
    });

    localStorage.removeItem('USER_USERNAME');

    localStorage.removeItem('USER_UUID');

    localStorage.removeItem('USER_PHOTO');

    localStorage.removeItem('USER_TOKEN');

    localStorage.removeItem('USER_TEAM');

    localStorage.removeItem('USER');
  };

  /**



   * Esse método de login DEVE SER MELHORADO, pois esta solução é temporária



   * e abre brechas de segurança.



   */

  saveLocalStorage = (USERNAME, UUID, PHOTO, TOKEN, TEAM) => {
    localStorage.setItem('USER_USERNAME', USERNAME);

    localStorage.setItem('USER_UUID', UUID);

    localStorage.setItem('USER_PHOTO', PHOTO);

    localStorage.setItem('USER_TOKEN', TOKEN);

    localStorage.setItem('USER_TEAM', TEAM);
  };

  /**



   * Essa função foi criada para que o `useruuid` mais recente e atualizado



   * do state seja buscado sem risco de pegar um valor vazio ou desatualizado.



   * Sem isso, ao buscar no componentDidMout, vinha vazio o state.



   */

  getUserUuidFromState = () => {
    const { useruuid } = this.state;

    return useruuid;
  };

  render() {
    const { isAuth, username, useruuid, photo, teamName } = this.state;

    const authMaintanance = teamName === 'GERENCIA' || teamName === 'OFICINA';

    const authRefuel =
      teamName === 'GERENCIA' ||
      teamName === 'ABASTECIMENTO' ||
      teamName === 'OFICINA';

    const authFinancial = teamName === 'GERENCIA' || teamName === 'FINANCIA';

    const authPayment =
      teamName === 'GERENCIA' ||
      teamName === 'FINANCIA' ||
      teamName === 'PAGAMENTO';

    const authService = teamName === 'GERENCIA' || teamName === 'OFICINA';

    const authPieceInventory =
      teamName === 'GERENCIA' || teamName === 'OFICINA';

    const authEmployee =
      teamName === 'GERENCIA'

    return (
      <div className="App">
        <Router history={history}>
          {!isAuth && (
            <Route
              path="*"
              render={props => (
                <Login {...props} handleLogin={this.handleLogin} />
              )}
            />
          )}

          {isAuth ? (
            <div>
              <Menu
                onLogout={this.handleLogout}
                username={username}
                empresa=""
                photo={photo}
                team={teamName}
              />

              <Header />

              <ToastContainer hideProgressBar={false} autoClose={5000} />
            </div>
          ) : null}

          <Routes
            isAuth={isAuth}
            authMaintanance={authMaintanance}
            authRefuel={authRefuel}
            authFinancial={authFinancial}
            authService={authService}
            authPayment={authPayment}
            authPieceInventory={authPieceInventory}
            authEmployee={authEmployee}
            teamName={teamName}
            useruuid={useruuid}
          />
        </Router>
      </div>
    );
  }
}

export default App;
