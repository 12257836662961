import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import { Container, Content } from './styles';

export default function Home({ team }) {
  const authMaintanance = team === 'GERENCIA' || team === 'OFICINA';
  const authFinancial = team === 'GERENCIA' || team === 'FINANCIA';
  const authRefuel =
    team === 'GERENCIA' || team === 'ABASTECIMENTO' || team === 'OFICINA';
  const authService = team === 'GERENCIA' || team === 'OFICINA';
  const authPayment =
    team === 'GERENCIA' || team === 'FINANCIA' || team === 'PAGAMENTO';

  const authPieceInventory = team === 'GERENCIA' || team === 'OFICINA';

  const authEmployee = team === 'GERENCIA';

  return (
    <Container>
      <Content>
        <ul>
          {authMaintanance && (
            <li>
              <Link to="/maintenances">MANUTENÇÕES</Link>
            </li>
          )}
          {authMaintanance && (
            <li>
              <Link to="/maintenanceplans">PLANOS DE MANUTENÇÃO</Link>
            </li>
          )}
          {authRefuel && (
            <li>
              <Link to="/refuels">ABASTECIMENTOS</Link>
            </li>
          )}
          {authFinancial && (
            <li>
              <Link to="/financials">FINANÇAS</Link>
            </li>
          )}
          {authService && (
            <li>
              <Link to="/services">SERVIÇOS DE VEÍCULOS</Link>
            </li>
          )}
          {authPayment && (
            <li>
              <Link to="/payments">PAGAMENTO FUNCIONÁRIOS</Link>
            </li>
          )}

          {authPieceInventory && (
            <li>
              <Link to="/inventories">ESTOQUE DE PEÇAS</Link>
            </li>
          )}
          {authEmployee && (
            <li>
              <Link to="/employees">FUNCIONÁRIOS</Link>
            </li>
          )}
        </ul>
      </Content>
    </Container>
  );
}

Home.propTypes = {
  team: PropTypes.string.isRequired,
};
