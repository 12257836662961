import React from 'react';
import { IoMdTrash } from 'react-icons/io';
import PropTypes from 'prop-types';

// Styles
import { ListStyled, ListItem } from '../../../css/styles/list';

const listItem = props =>
  props.maintenanceToMaintenanceServices.map(item => (
    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
    <ListItem
      color="#011826"
      strongColor="#fff"
      key={item.uuid}
      onClick={() => props.onEdit(item)}
    >
      <div className="info">
        <div>
          <strong className="title">SERVIÇO:</strong>
          <strong>{item.maintenanceservice && item.maintenanceservice.description}</strong>
        </div>

        <div>
          <strong className="title">GRUPO:</strong>
          <strong>{
            item.maintenanceservice &&
            item.maintenanceservice.maintenanceservicegroup &&
            item.maintenanceservice.maintenanceservicegroup.description
          }</strong>
        </div>

        <div>
          <strong className="title">DESCRIÇÃO:</strong>
          <strong>{item.description && item.description}</strong>
        </div>
      </div>

      <button
        type="button"
        onClick={e => {
          e.stopPropagation();
          props.onDelete(item);
        }}
        className="delete"
      >
        <IoMdTrash size={20} color="#bf1523" />
      </button>
    </ListItem>
  ));

function MaintenanceToMaintenanceServiceList(props) {
  const { maintenanceToMaintenanceServices } = props;

  return (
    <>
      {maintenanceToMaintenanceServices && maintenanceToMaintenanceServices.length > 0 ? (
        <ListStyled>
          {listItem(props)}
        </ListStyled>
      ) : (
        <strong>LISTA VAZIA</strong>
      )}
    </>
  );
}

MaintenanceToMaintenanceServiceList.propTypes = {
  maintenanceToMaintenanceServices: PropTypes.arrayOf(
    PropTypes.shape({
      uuid: PropTypes.string,
      description: PropTypes.string,
    }),
  ),
}

MaintenanceToMaintenanceServiceList.defaultProps = {
  maintenanceToMaintenanceServices: [],
}

export default MaintenanceToMaintenanceServiceList;
