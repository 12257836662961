import React, { Component } from 'react';
import moment from 'moment';
import ReactLoading from 'react-loading';
import { toast } from 'react-toastify';
import io from 'socket.io-client';
import swal from '@sweetalert/with-react';
import ServiceFilterForm from './filterForm';
import EquipmentServiceForm from './form';
import EquipmentContainer from '../Equipment';
import PartnerContainer from '../Partner';
import EmployeeContainer from '../Employee';
import HolidayContainer from '../Holiday';
import ServiceEquipmentEmployeeContainer from './ServiceEquipmentEmployee';
import Scheduler from '../components/Scheduler';
import API from '../../services/api';
import Parameters from '../../services/parameters';

// Styles
import { Wrapper, Container, Content } from '../../css/styles/wrapper';
import ServiceExtraDaysContainer from './ServiceExtraDays';

class ServiceContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      bases: [],
      base: null,
      baseSearch: null,
      equipments: [],
      equipmentsOptions: [],
      equipment: null,
      serviceequipmentemployees: [],
      serviceequipment: null,
      serviceExtraDays: [],
      serviceExtraDay: null,
      partners: [],
      partner: null,
      employees: [],
      employee: null,
      service: null,
      services: [],
      loading: true,
      begin: moment().format('YYYY-MM-DD'),
      end: moment()
        .add(7, 'days')
        .format('YYYY-MM-DD'),
      toggle: 'equipments',
      equipmentForm: false,
      partnerForm: false,
      employeeForm: false,
      holidayForm: false,
      weeklyFrequency: [0, 1, 2, 3, 4, 5, 6],
      holidays: [],
    };

    this.handleCreation = this.handleCreation.bind(this);
    this.handleUpdate = this.handleUpdate.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleNew = this.handleNew.bind(this);
  }

  refMaintenance = React.createRef();

  componentDidMount() {
    this.registerToSocket();

    this.getList();
    this.getBases();
    this.getHolidays();
    this.getExtraDays();
  }

  registerToSocket = () => {
    const socket = io(Parameters.URL_API);

    socket.on('Service', () => this.getList());
  };

  getBases = async () => {
    try {
      const response = await API.get('/bases');
      this.setState({
        bases: response.data,
        loading: false,
      });
    } catch (err) {
      toast.error('Um erro aconteceu durante o carregamento!');
      this.setState({
        loading: false,
      });
    }
  };

  getExtraDays = async () => {

    try {
      const response = await API.get(
        `/serviceextradays`
      );
      this.setState({
        serviceExtraDays: response.data,
        loading: false,
      });
    } catch (err) {
      toast.error('Um erro aconteceu durante o carregamento');
      this.setState({
        loading: false,
      });
    }
  };

  getHolidays = async () => {
    try {
      const response = await API.get('/holidays');
      this.setState({
        holidays: response.data,
        loading: false,
      });
    } catch (err) {
      toast.error('Um erro aconteceu durante o carregamento!');
      this.setState({
        loading: false,
      });
    }
  };

  handleHolidayFormState() {
    const { holidayForm } = this.state;

    this.setState({
      holidayForm: !holidayForm,
      equipmentForm: false,
      partnerForm: false,
      employeeForm: false,
    });
  }

  handleHolidayList = (holidays, holiday) => {
    this.setState({
      holidays,
      holiday,
    });
  };

  getList = async values => {
    this.setState({
      loading: true,
    });

    if (values) {
      await this.setState({
        begin: values.begin || moment().format('YYYY-MM-DD'),
        end:
          values.end ||
          moment()
            .add(7, 'days')
            .format('YYYY-MM-DD'),
        toggle: values.toggle || 'equipments',
      });
    }

    try {
      const { begin, end } = this.state;

      const response = await API.get(
        `/equipments/services/${begin || moment().format('YYYY-MM-DD')}/${end ||
          moment()
            .add(7, 'days')
            .format('YYYY-MM-DD')}`
      );

      if (values && values.baseSearch && values.baseSearch.uuid) {
        await this.setState({
          baseSearch: {
            uuid: values.baseSearch.uuid,
            description: values.baseSearch.description,
          },
        });

        response.data = response.data.filter(item => {
          let { serviceequipments } = item;

          serviceequipments = serviceequipments.filter(serviceequipment => {
            const { service } = serviceequipment;
            return service.baseUuid === this.state.baseSearch.uuid;
          });

          return serviceequipments.length > 0;
        });
      }

      this.setState({
        equipments: response.data,
        equipment: null,
        serviceequipment: null,
        loading: false,
      });
    } catch (err) {
      toast.error('Um erro aconteceu durante o carregamento!');
      this.setState({ loading: false });
    }
  };

  scrollToMyRef = myRef => {
    window.scrollTo(0, myRef.current.offsetTop);
  };

  handleCreation = async value => {
    const data = {
      opening_date: value.opening_date,
      closing_date: value.closing_date,
      partnerUuid: value.partner.uuid,
      equipmentUuid: value.equipment.uuid,
      baseUuid: value.base.uuid,
      observation: value.observation,
      cost: value.cost,
      type: value.type.value,
      mobilization: value.mobilization || 0,
      other_cost: value.other_cost || 0,
      weekly_frequency: value.weekly_frequency,
    };

    try {
      const response = await API.post('/equipments/services', { ...data });

      const { conflicts } = response.data;

      const employeeData = {
        description: value.description,
        employeeUuid: value.employee.uuid,
        serviceequipmentUuid: response.data
          ? response.data.serviceequipment.uuid
          : null,
      };

      try {
        await API.post('/serviceequipmentemployees/', {
          ...employeeData,
        });

        this.setState({
          weeklyFrequency: [0, 1, 2, 3, 4, 5, 6],
        });
      } catch (err) {
        toast.error('Falha no cadastro de funcionário');
      }

      await this.getList();
      this.handleNew();

      if (conflicts && conflicts.length > 0) {
        const conflitos = conflicts[0].serviceequipments;

        conflitos.forEach(item => {
          const servicePartner = item.service.partner.name;
          const serviceStatus = item.service.type;
          const conflictList = `${servicePartner} - ${serviceStatus}`;
          toast.warning(conflictList);
        });

        toast.error(
          `Serviço cadastrado mas com ${conflitos.length} conflitos !`
        );
      } else {
        toast.success('Serviço cadastrado com sucesso sem nenhum conflito!');
      }
    } catch (err) {
      toast.error('Um erro aconteceu durante o cadastro!');
    }
  };

  handleNew = () => {
    this.setState({
      equipment: null,
      base: null,
      serviceequipment: null,
      equipmentForm: false,
      partnerForm: false,
      employeeForm: false,
      weeklyFrequency: [0, 1, 2, 3, 4, 5, 6],
    });

    this.scrollToMyRef(this.refMaintenance);
  };

  handleUpdate = async value => {
    const { serviceequipment } = this.state;

    const data = {
      opening_date: value.opening_date,
      closing_date: value.closing_date,
      partnerUuid: value.partner.uuid,
      observation: value.observation,
      equipmentUuid: value.equipment.uuid,
      baseUuid: value.base.uuid,
      cost: value.cost,
      type: value.type.value,
      mobilization: value.mobilization || 0,
      other_cost: value.other_cost || 0,
      weekly_frequency: value.weekly_frequency,
    };

    try {
      const response = await API.put(
        `/equipments/services/${serviceequipment.uuid}/${serviceequipment.service.uuid}`,
        { ...data }
      );

      this.handleEdit(response.data);
      this.getList();
      toast.success('Serviço atualizado com sucesso!');
    } catch (err) {
      toast.error('Um erro aconteceu durante a atualização!');
    }
  };

  handleDelete = async uuid => {
    try {
      const confirmDelete = await swal({
        dangerMode: true,

        text: 'Confirma a exclusão de serviço?',

        buttons: {
          cancel: 'Não',

          confirm: 'Sim',
        },
      });

      if (confirmDelete) {
        await API.delete(`/equipments/services/${uuid}`);
        await this.getList();
        this.handleNew();
        toast.success('Serviço deletado com sucesso!');
      }
    } catch (err) {
      toast.error('Um erro aconteceu durante a exclusão!');
    }
  };

  handleEdit = async (equipment, serviceequipment, baseUuid) => {
    let response;
    if (baseUuid) {
      response = await API.get(`/bases/${baseUuid}`);
      const { uuid, description } = response.data;

      this.setState({
        base: {
          uuid,
          description,
        },
      });
    } else {
      this.setState({
        base: null,
      });
    }

    this.setState({
      equipment,
      serviceequipment,
      weeklyFrequency: serviceequipment
        ? serviceequipment.service.weekly_frequency
        : [0, 1, 2, 3, 4, 5, 6],
    });

    this.scrollToMyRef(this.refMaintenance);
  };

  // EQUIPMENTS HELPERS

  handleEquipmentsList = (equipmentsOptions, equipment) => {
    this.setState({
      equipmentsOptions,
      equipment,
    });
  };

  handleEquipmentFormState() {
    const { equipmentForm } = this.state;

    this.setState({
      equipmentForm: !equipmentForm,
      employeeForm: false,
      partnerForm: false,
      holidayForm: false,
    });
  }

  // PARTNERS HELPERS

  handlePartnersList = (partners, partner) => {
    this.setState({
      partners,
      partner,
    });
  };

  handlePartnerFormState() {
    const { partnerForm } = this.state;

    this.setState({
      partnerForm: !partnerForm,
      employeeForm: false,
      equipmentForm: false,
      holidayForm: false,
    });
  }

  // EMPLOYEES HELPERS

  handleEmployeesList = (employees, employee) => {
    this.setState({
      employees,
      employee,
    });
  };

  handleEmployeeFormState() {
    const { employeeForm } = this.state;

    this.setState({
      employeeForm: !employeeForm,
      equipmentForm: false,
      partnerForm: false,
      holidayForm: false,
    });
  }

  // SERVICE EQUIPMENT EMPLOYEE HELPERS

  handleServiceEquipmentEmployeesList = serviceequipmentemployees => {
    this.setState({
      serviceequipmentemployees,
    });
  };

  //SERVICE EXTRA DAYS HELPERS

  handleServiceExtraDaysList = serviceExtraDay => {
    this.setState({
      serviceExtraDay,
    });
  };

  handleChangeWeekFrequency = (index, values, setFieldValue) => {
    const currentWeeklyFrequency = values.weekly_frequency || [];

    if (currentWeeklyFrequency.includes(index)) {
      const updatedWeeklyFrequency = currentWeeklyFrequency.filter(
        item => item !== index
      );
      setFieldValue('weekly_frequency', updatedWeeklyFrequency);
    } else {
      const updatedWeeklyFrequency = [...currentWeeklyFrequency, index];
      setFieldValue('weekly_frequency', updatedWeeklyFrequency);
    }
  };

  render() {
    const {
      loading,
      equipment,
      equipments,
      serviceequipmentemployees,
      equipmentsOptions,
      serviceequipment,
      partners,
      partner,
      employees,
      employee,
      service,
      services,
      serviceExtraDays,
      serviceExtraDay,
      begin,
      end,
      toggle,
      equipmentForm,
      partnerForm,
      employeeForm,
      serviceExtraDaysForm,
      bases,
      base,
      baseSearch,
      weeklyFrequency,
      holidays,
      holidayForm,
    } = this.state;

    return (
      <Wrapper>
        <Container>
          <header>
            <h1>SERVIÇOS DE VEÍCULOS</h1>
            <button type="button" onClick={this.handleNew}>
              NOVO
            </button>
          </header>

          <Content>
            <ServiceFilterForm
              onServicesList={this.getList}
              begin={begin}
              end={end}
              toggle={toggle}
              bases={bases}
              base={base}
              baseSearch={baseSearch}
            />

            {!loading ? (
              <>
                {equipments && equipments[0] ? (
                  <>
                    <Scheduler
                      equipments={equipments}
                      begin={begin}
                      end={end}
                      onEdit={this.handleEdit}
                      toggle={toggle}
                      holidays={holidays}
                      extraDays={serviceExtraDays}
                      bases={bases}
                    />
                  </>
                ) : (
                  <strong>LISTA VAZIA</strong>
                )}
              </>
            ) : (
              <ReactLoading
                type="spin"
                color="#011826"
                height={40}
                width={40}
              />
            )}

            <nav className="center">
              <div>
                <button
                  type="button"
                  onClick={() => this.handleEquipmentFormState()}
                >
                  VEÍCULO
                </button>
                <button
                  type="button"
                  onClick={() => this.handlePartnerFormState()}
                >
                  CLIENTE
                </button>
                <button
                  type="button"
                  onClick={() => this.handleEmployeeFormState()}
                >
                  FUNCIONÁRIO
                </button>
                <button
                  type="button"
                  onClick={() => this.handleHolidayFormState()}
                >
                  FERIADO
                </button>
              </div>
            </nav>

            <EquipmentContainer
              onEquipmentsList={this.handleEquipmentsList}
              onEquipmentSubmit={() => this.handleEquipmentFormState()}
              equipmentForm={equipmentForm}
            />

            <PartnerContainer
              onPartnersList={this.handlePartnersList}
              onPartnerSubmit={() => this.handlePartnerFormState()}
              partnerForm={partnerForm}
            />

            <EmployeeContainer
              onEmployeesList={this.handleEmployeesList}
              onEmployeeSubmit={() => this.handleEmployeeFormState()}
              employeeForm={employeeForm}
            />

            <HolidayContainer
              holidayForm={holidayForm}
              holidays={holidays}
              bases={bases}
              onHolidayList={this.handleHolidayList}
              onHolidaySubmit={() => this.handleHolidayFormState()}
            />

            <div ref={this.refMaintenance} />

            <EquipmentServiceForm
              onCreation={this.handleCreation}
              onUpdate={this.handleUpdate}
              onNew={this.handleNew}
              onDelete={this.handleDelete}
              handleChangeWeekFrequency={this.handleChangeWeekFrequency}
              equipments={equipmentsOptions}
              equipment={equipment}
              serviceequipment={serviceequipment}
              partners={partners}
              partner={partner}
              employees={employees}
              employee={employee}
              serviceequipmentemployees={serviceequipmentemployees}
              base={base}
              bases={bases}
              service={service}
              services={services}
              serviceExtraDays={serviceExtraDays}
              weeklyFrequency={weeklyFrequency}
            />

            {serviceequipment && (
              <ServiceEquipmentEmployeeContainer
                serviceEquipment={serviceequipment}
                employees={employees}
                employee={employee}
                onServiceEquipmentEmployeesList={
                  this.handleServiceEquipmentEmployeesList
                }
              />
            )}
            {serviceequipment && (
              <ServiceExtraDaysContainer
                serviceequipment={serviceequipment}
                service={service}
                services={services}
                serviceExtraDays={serviceExtraDays}
                serviceExtraDay={serviceExtraDay}
                onServiceExtraDaysList={this.handleServiceExtraDaysList}
                serviceExtraDaysForm={serviceExtraDaysForm}
              />
            )}
          </Content>
        </Container>
      </Wrapper>
    );
  }
}

export default ServiceContainer;
