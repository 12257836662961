import React from 'react';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import Select from 'react-select';

import {
  FilterFormStyled,
  FilterRow,
  FilterColumn,
} from '../../css/styles/filter';
import { SelectStyled } from '../../css/styles/select';

const handleValidate = () => {
  const errors = {};

  return errors;
};

const handleSubmit = props => async (values, { setSubmitting }) => {
  await props.onList({ ...values });

  setSubmitting(false);
};
const PieceInventoryFilterForm = props => {
  const { pieces, piece } = props;


  return (
    <Formik
      enableReinitialize={false}
      initialValues={{
        piece: piece ? piece : '',
        document: '',
      }}
      validate={handleValidate}
      onSubmit={handleSubmit(props)}
    >
      {({
        dirty,
        handleReset,
        isSubmitting,
        values,
        handleChange,
        handleBlur,
        setFieldValue,
        errors,
        touched,
      }) => (
        <FilterFormStyled>
          <h1>FILTROS</h1>

          <FilterRow>
            <FilterColumn>
              <strong>DOCUMENTO</strong>
              <input
                id="document"
                name="document"
                type="text"
                value={values.document}
                onChange={handleChange}
                onBlur={handleBlur}
                error={!!(errors.document && touched.document)}
              />
              {errors.document && touched.document && (
                <span>{errors.document}</span>
              )}
            </FilterColumn>
          </FilterRow>

          <FilterRow>
            <FilterColumn>
              <strong>PEÇA</strong>
              <Select
                styles={SelectStyled}
                id="piece"
                name="piece"
                value={
                  values.piece
                    ? pieces.find(piece => piece.uuid === values.piece)
                    : null
                }
                placeholder="Selecione uma peça"
                onChange={selectedOption => {
                  setFieldValue(
                    'piece',
                    selectedOption ? selectedOption.uuid : null
                  );
                }}
                isSearchable
                options={pieces}
                isLoading={false}
                isClearable
                getOptionLabel={item => item.description}
                getOptionValue={item => item.uuid}
                components={{
                  IndicatorSeparator: () => null,
                }}
                loadingMessage={() => 'Buscando bases'}
                noOptionsMessage={() => 'Não há bases para exibir'}
              />
            </FilterColumn>
          </FilterRow>
          <FilterRow>
            <div className="buttons-container">
              <button type="submit" disabled={isSubmitting}>
                BUSCAR
              </button>

              <button
                type="button"
                onClick={handleReset}
                disabled={!dirty || isSubmitting}
              >
                RESET
              </button>
            </div>
          </FilterRow>
        </FilterFormStyled>
      )}
    </Formik>
  );
};

PieceInventoryFilterForm.propTypes = {
  onList: PropTypes.func.isRequired,
  pieces: PropTypes.arrayOf(PropTypes.shape(PropTypes.string)),
};

PieceInventoryFilterForm.defaultProps = {
  pieces: [],
};

export default PieceInventoryFilterForm;

