import React, { useEffect, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import moment from 'moment';
import PropTypes from 'prop-types';

// Styles
import { Container, Table, Tbody, Tr, Td, Button } from './styles';

function SchedulerByEquipments({
  begin,
  end,
  equipments,
  onEdit,
  holidays,
  extraDays,
  bases,
}) {
  const [period, setPeriod] = useState([]);
  const [schedule, setSchedule] = useState([]);

  useEffect(() => {
    const periodTime = moment(end).diff(begin, 'days');
    const periodArray = [];
    let scheduleArray = [];
    const weekdays = ['DOM', 'SEG', 'TER', 'QUA', 'QUI', 'SEX', 'SAB'];

    // eslint-disable-next-line no-plusplus
    for (let index = 0; index <= periodTime; index++) {
      const date = moment(begin).add(index, 'days');
      const weekday =
        weekdays[new Date(moment(date).format('MM/DD/YYYY')).getDay()];

      const day = {
        date,
        weekday,
        weekdayindex: new Date(moment(date).format('MM/DD/YYYY')).getDay(),
      };
      periodArray.push(day);
    }

    equipments.forEach(equipment => {
      const scheduleItem = {
        code: equipment.code,
        services: [],
      };
      scheduleItem.services = equipment.serviceequipments.map(item => {
        const { service } = item;
        const {
          uuid,
          opening_date,
          closing_date,
          type,
          observation,
          partner,
          baseUuid,
          weekly_frequency,
          serviceextradays,
        } = service;
        let string = '';
        if (
          item.serviceequipmentemployees &&
          item.serviceequipmentemployees[0]
        ) {
          string += '<br /> Funcionários: <br />';
          item.serviceequipmentemployees.forEach(serviceequipmentemployee => {
            string += `${serviceequipmentemployee.employee.name}`;
            if (serviceequipmentemployee.description) {
              string += ` - ${serviceequipmentemployee.description}`;
            }
            string += '<br />';
          });
        } else {
          string += '<br />';
        }

        if (observation) {
          string += `<br />Observação: <br />${observation}`;
        }

        const data_tip = `
        ${partner.name}
        <br />
        ${string}`;

        return {
          uuid,
          begin: moment(opening_date),
          end: moment(closing_date).add(1, 'days'),
          partner_name: partner.name,
          data_tip,
          type,
          baseUuid,
          weekly_frequency,
          serviceextradays,
        };
      });

      scheduleArray.push(scheduleItem);
    });

    setPeriod(periodArray);
    setSchedule(scheduleArray);
  }, [begin, end, equipments]);

  const handleItemSelect = uuid => {
    let selectedItem = null;

    equipments.forEach(equipment => {
      const serviceequipment = equipment.serviceequipments.find(
        item => item.service.uuid === uuid
      );

      if (serviceequipment) {
        selectedItem = {
          equipment,
          serviceequipment,
          baseUuid: serviceequipment.service.baseUuid,
        };
      }
    });

    onEdit(
      selectedItem.equipment,
      selectedItem.serviceequipment,
      selectedItem.baseUuid
    );
  };

  const handleServicesRow = (item, serviceIndex) => {
    const service = item.services ? item.services[serviceIndex] : null;
    const list = [];
    let index = 0;
    let countButtons = 0;
    const holidaysDates = holidays.map(holiday =>
      moment(holiday.date).format('YYYY-MM-DD')
    );
    const extraDaysDates = service?.serviceextradays.map(extraDay =>
      moment(extraDay.extraDay).format('YYYY-MM-DD')
    );

    while (index < period.length) {
      const targetDate = moment(period[index].date).format('YYYY-MM-DD');
      let targetDateHolidays = holidays.filter(
        holiday => moment(holiday.date).format('YYYY-MM-DD') === targetDate
      );
      let isHoliday = holidaysDates.includes(targetDate);
      let isExtraDay = extraDaysDates.includes(targetDate);

      if (isExtraDay) {
        isHoliday = false;
      } else {
        if (isHoliday) {
          targetDateHolidays.forEach(holiday => {
            if (!holiday.baseUuid) {
              isHoliday = true;
            } else if (holiday.baseUuid !== service.baseUuid) {
              isHoliday = false;
            }
          });
        }
      }

      if (
        service &&
        moment(period[index].date).isBetween(service.begin, service.end)
      ) {
        list.push(
          <Td colSpan={1} data-html="true" data-tip={service.data_tip}>
            <Button
              serviceType={service.type}
              currentDate={period[index].weekdayindex}
              weeklyFrequency={service.weekly_frequency}
              isHoliday={isHoliday}
              isExtraDay={isExtraDay}
              type="button"
              onClick={() => handleItemSelect(service.uuid)}
            >
              <strong>
                {countButtons === 0
                  ? `${service.partner_name.substring(0, 16)}${
                      service.partner_name.length >= 16 ? '...' : ''
                    }`
                  : ''}
              </strong>
            </Button>
          </Td>
        );
        countButtons++;
        index++;
      } else {
        list.push(<Td>-</Td>);
        // eslint-disable-next-line no-plusplus
        index++;
      }
    }

    return list;
  };

  return (
    <Container>
      <Table>
        <Tbody>
          <Tr>
            <Td />
            {period.map(day => (
              <Td key={day.date.format('DD/MM')} style={{ width: '130px' }}>
                <div>
                  <small>{day.weekday}</small>
                  <strong>{day.date.format('DD/MM')}</strong>
                </div>
              </Td>
            ))}
          </Tr>
          {schedule.map(item => (
            <>
              <Tr>
                <Td rowSpan={item.services.length + 1} className="equipment">
                  <div>{item.code}</div>
                </Td>
              </Tr>
              {item.services &&
                item.services[0] &&
                item.services.map((service, index) => (
                  <Tr>{handleServicesRow(item, index)}</Tr>
                ))}
              <ReactTooltip type="info" effect="float" place="bottom" />
            </>
          ))}
        </Tbody>
      </Table>
    </Container>
  );
}

SchedulerByEquipments.propTypes = {
  begin: PropTypes.string,
  end: PropTypes.string,
  equipments: PropTypes.arrayOf(PropTypes.shape({})),
  toggle: PropTypes.string,
  onEdit: PropTypes.func.isRequired,
  holidays: PropTypes.arrayOf(PropTypes.shape({})),
  extraDays: PropTypes.arrayOf(PropTypes.shape({})),
};

SchedulerByEquipments.defaultProps = {
  begin: null,
  end: null,
  equipments: [],
  toggle: 'equipments',
  holidays: [],
  extraDays: [],
};

export default SchedulerByEquipments;

