import React, { useRef } from 'react';
import moment from 'moment';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import Select from 'react-select';

import { FormStyled, Row, Column } from '../../css/styles/form';
import { SelectStyled } from '../../css/styles/select';

const handleValidate = ({
  opening_date,
  closing_date,
  partner,
  type,
  equipment,
  cost,
  base,
}) => {
  const errors = {};

  if (!opening_date) errors.opening_date = 'OBRIGATÓRIO';

  if (!closing_date) errors.closing_date = 'OBRIGATÓRIO';

  if (moment(opening_date).isAfter(closing_date))
    errors.closing_date = 'DATA FINAL NÃO PODE SER MENOR QUE INICIAL';

  if (!partner) errors.partner = 'OBRIGATÓRIO';

  if (!type) errors.type = 'OBRIGATÓRIO';

  if (!equipment) errors.equipment = 'OBRIGATÓRIO';

  if (!cost) errors.cost = 'OBRIGATÓRIO';

  if (!base) errors.base = 'OBRIGATÓRIO';
  return errors;
};

const handleClear = () => {};

const handleSubmit = props => async (values, { setSubmitting, resetForm }) => {
  if (!props.equipment) {
    await props.onCreation({ ...values });
  } else {
    await props.onUpdate({ ...values });
  }

  setSubmitting(false);
  resetForm();
};

const ServiceForm = props => {
  const formRef = useRef();

  const {
    bases,
    base,
    equipments,
    partners,
    employees,
    serviceequipmentemployees,
    equipment,
    partner,
    serviceequipment,
    onDelete,
    weeklyFrequency,
    handleChangeWeekFrequency,
  } = props;

  const onNew = () => props.onNew();

  const Types = [
    {
      label: 'Confirmado',
      value: 'Confirmado',
    },
    {
      label: 'Pré-agendado',
      value: 'Pré-agendado',
    },
    {
      label: 'No radar',
      value: 'No radar',
    },
  ];

  const week = ['DOM', 'SEG', 'TER', 'QUA', 'QUI', 'SEX', 'SÁB'];

  return (
    <Formik
      ref={formRef}
      enableReinitialize
      initialValues={{
        opening_date: !serviceequipment
          ? moment().format('YYYY-MM-DD')
          : serviceequipment.service.opening_date.substring(0, 10),
        closing_date: !serviceequipment
          ? moment().format('YYYY-MM-DD')
          : serviceequipment.service.closing_date.substring(0, 10),
        partner: !serviceequipment
          ? partner || ''
          : serviceequipment.service.partner,
        type:
          serviceequipment && serviceequipment.service.type
            ? {
                label: serviceequipment.service.type,
                value: serviceequipment.service.type,
              }
            : '',
        employee: null,
        description: '',
        equipment: equipment || '',
        serviceequipmentemployees: serviceequipmentemployees || [],
        cost: serviceequipment ? serviceequipment.cost : '',
        mobilization: serviceequipment ? serviceequipment.mobilization : '',
        other_cost: serviceequipment ? serviceequipment.other_cost : '',
        observation: !serviceequipment
          ? ''
          : serviceequipment.service.observation,
        base: base || null,
        weekly_frequency: weeklyFrequency,
      }}
      validate={handleValidate}
      onSubmit={handleSubmit(props)}
      resetForm={handleClear}
    >
      {({
        values,
        handleReset,
        handleBlur,
        handleChange,
        errors,
        touched,
        isSubmitting,
        dirty,
        setFieldValue,
      }) => (
        <FormStyled>
          <h1>{equipment ? 'ALTERAR SERVIÇO' : 'NOVO SERVIÇO'}</h1>
          <ul>
            <Row>
              <Column>
                <strong>BASE</strong>
                <Select
                  styles={SelectStyled}
                  id="base"
                  value={values.base}
                  placeholder="Selecione uma base"
                  onChange={selectedOption => {
                    setFieldValue('base', selectedOption);
                  }}
                  isSearchable
                  options={bases}
                  getOptionLabel={item => item.description}
                  getOptionValue={item => item.uuid}
                  name="base"
                  isLoading={false}
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  loadingMessage={() => 'Buscando bases'}
                  noOptionsMessage={() => 'Não há bases para exibir'}
                />

                {errors.base && touched.base && <span>{errors.base}</span>}
              </Column>
            </Row>

            <Row>
              <Column>
                <strong>TIPO</strong>
                <Select
                  styles={SelectStyled}
                  id="type"
                  value={values.type}
                  placeholder="Selecione um tipo"
                  onChange={selectedOption => {
                    setFieldValue('type', selectedOption);
                  }}
                  isSearchable
                  options={Types}
                  getOptionLabel={item => item.label}
                  getOptionValue={item => item.value}
                  name="type"
                  isLoading={false}
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  loadingMessage={() => 'Buscando tipos'}
                  noOptionsMessage={() => 'Não há tipos para exibir'}
                />

                {errors.type && touched.type && <span>{errors.type}</span>}
              </Column>
            </Row>

            <Row>
              <Column>
                <strong>VEÍCULO</strong>
                <Select
                  styles={SelectStyled}
                  id="equipment"
                  value={values.equipment}
                  placeholder="Selecione um veículo"
                  onChange={selectedOption => {
                    setFieldValue('equipment', selectedOption);
                  }}
                  isSearchable
                  options={equipments}
                  getOptionLabel={item => item.code}
                  getOptionValue={item => item.uuid}
                  name="equipment"
                  isLoading={false}
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  loadingMessage={() => 'Buscando veículos'}
                  noOptionsMessage={() => 'Não há veículos cadastrados'}
                />

                {errors.equipment && touched.equipment && (
                  <span>{errors.equipment}</span>
                )}
              </Column>
            </Row>
            <Row>
              <Column>
                <strong>CLIENTE</strong>

                <Select
                  styles={SelectStyled}
                  id="partner"
                  value={values.partner}
                  placeholder="Selecione um cliente"
                  onChange={selectedOption => {
                    setFieldValue('partner', selectedOption);
                  }}
                  isSearchable
                  options={partners}
                  getOptionLabel={item => item.name}
                  getOptionValue={item => item.uuid}
                  name="partner"
                  isLoading={false}
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  loadingMessage={() => 'Buscando clientes'}
                  noOptionsMessage={() => 'Não há clientes cadastrados'}
                />

                {errors.partner && touched.partner && (
                  <span>{errors.partner}</span>
                )}
              </Column>
            </Row>

            <Row>
              <Column>
                <strong>DATA DE ABERTURA</strong>
                <input
                  id="opening_date"
                  name="opening_date"
                  type="date"
                  value={values.opening_date}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />

                {errors.opening_date && touched.opening_date && (
                  <span>{errors.opening_date}</span>
                )}
              </Column>
              <Column>
                <strong>DATA DE FECHAMENTO</strong>
                <input
                  id="closing_date"
                  name="closing_date"
                  type="date"
                  value={values.closing_date}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />

                {errors.closing_date && touched.closing_date && (
                  <span>{errors.closing_date}</span>
                )}
              </Column>
            </Row>

            <Row>
              <Column>
                <strong>FREQUÊNCIA SEMANAL</strong>
                <div className="weekly-frequency">
                  {week.map((day, index) => (
                    <div key={index}>
                      <input
                        type="checkbox"
                        id={`day${index}`}
                        name={`weekly_frequency[${index}]`}
                        checked={values.weekly_frequency.includes(index)}
                        onChange={() => handleChangeWeekFrequency(index, values, setFieldValue)}
                      />
                      <label htmlFor={`day${index}`}>{day}</label>
                    </div>
                  ))}
                </div>
              </Column>
            </Row>

            <Row>
              <Column>
                <strong>OBSERVAÇÃO</strong>
                <input
                  id="observation"
                  name="observation"
                  type="text"
                  value={values.observation}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />

                {errors.observation && touched.observation && (
                  <span>{errors.observation}</span>
                )}
              </Column>
            </Row>

            <Row>
              <Column>
                <strong>CUSTO</strong>
                <input
                  id="cost"
                  name="cost"
                  type="text"
                  value={values.cost}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />

                {errors.cost && touched.cost && <span>{errors.cost}</span>}
              </Column>
            </Row>

            <Row>
              <Column>
                <strong>CUSTO DE MOBILIZAÇÃO</strong>
                <input
                  id="mobilization"
                  name="mobilization"
                  type="text"
                  value={values.mobilization}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />

                {errors.mobilization && touched.mobilization && (
                  <span>{errors.mobilization}</span>
                )}
              </Column>
            </Row>

            <Row>
              <Column>
                <strong>OUTROS CUSTOS</strong>
                <input
                  id="other_cost"
                  name="other_cost"
                  type="text"
                  value={values.other_cost}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />

                {errors.other_cost && touched.other_cost && (
                  <span>{errors.other_cost}</span>
                )}
              </Column>
            </Row>

            {!equipment ? (
              <>
                <Row>
                  <Column>
                    <strong>FUNCIONÁRIO</strong>

                    <Select
                      styles={SelectStyled}
                      id="employee"
                      value={values.employee}
                      placeholder="Selecione um funcionário"
                      onChange={selectedOption =>
                        setFieldValue('employee', selectedOption)
                      }
                      isSearchable
                      options={employees}
                      getOptionLabel={item => item.name}
                      getOptionValue={item => item.uuid}
                      name="employee"
                      isLoading={false}
                      components={{
                        IndicatorSeparator: () => null,
                      }}
                      loadingMessage={() => 'Buscando funcionários'}
                      noOptionsMessage={() => 'Não há funcionários cadastrados'}
                    />

                    {errors.employee && touched.employee && (
                      <span>{errors.employee}</span>
                    )}
                  </Column>
                </Row>

                <Row>
                  <Column>
                    <strong>DESCRIÇÃO FUNCIONÁRIO</strong>

                    <textarea
                      id="description"
                      name="description"
                      type="text"
                      value={values.description}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />

                    {errors.description && touched.description && (
                      <span>{errors.description}</span>
                    )}
                  </Column>
                </Row>
              </>
            ) : (
              <></>
            )}

            <Row>
              <div className='buttons-container'>
                <button type="submit" disabled={isSubmitting}>
                  SALVAR
                </button>

                <button
                  type="button"
                  onClick={handleReset}
                  disabled={!dirty || isSubmitting}
                >
                  RESET
                </button>

                {equipment || serviceequipment ? (
                  <button type="button" onClick={onNew}>
                    LIMPAR
                  </button>
                ) : null}

                {serviceequipment && (
                  <button
                    type="button"
                    onClick={() => onDelete(serviceequipment.uuid)}
                    className="delete"
                  >
                    EXCLUIR
                  </button>
                )}
              </div>
            </Row>
          </ul>
        </FormStyled>
      )}
    </Formik>
  );
};

ServiceForm.propTypes = {
  equipments: PropTypes.arrayOf(
    PropTypes.shape({
      uuid: PropTypes.string,
      code: PropTypes.string,
    })
  ),
  employees: PropTypes.arrayOf(
    PropTypes.shape({
      uuid: PropTypes.string,
      name: PropTypes.string,
    })
  ),
  employee: PropTypes.shape({
    uuid: PropTypes.string,
    name: PropTypes.string,
  }),
  partners: PropTypes.arrayOf(
    PropTypes.shape({
      uuid: PropTypes.string,
      name: PropTypes.string,
    })
  ),
  equipment: PropTypes.shape({
    uuid: PropTypes.string,
  }),
  partner: PropTypes.shape({}),
  onDelete: PropTypes.func.isRequired,
  onNew: PropTypes.func,
  handleChangeWeekFrequency: PropTypes.func,
  serviceequipment: PropTypes.shape({
    uuid: PropTypes.string,
    cost: PropTypes.number,
    description: PropTypes.string,
    employee: PropTypes.shape({
      uuid: PropTypes.string,
      name: PropTypes.string,
    }),
    mobilization: PropTypes.number,
    other_cost: PropTypes.number,
    service: PropTypes.shape({
      observation: PropTypes.string,
      opening_date: PropTypes.string,
      closing_date: PropTypes.string,
      type: PropTypes.string,
      partner: PropTypes.shape({}),
      weekly_frequency: PropTypes.arrayOf(PropTypes.number),
    }),
  }),
  serviceequipmentemployees: PropTypes.arrayOf(
    PropTypes.shape({
      uuid: PropTypes.string,
      description: PropTypes.string,
    })
  ),
  bases: PropTypes.arrayOf(
    PropTypes.shape({
      uuid: PropTypes.string,
      description: PropTypes.string,
    })
  ),
  base: PropTypes.shape({
    uuid: PropTypes.string,
    description: PropTypes.string,
  }),
  weeklyFrequency: PropTypes.arrayOf(PropTypes.number),
};

ServiceForm.defaultProps = {
  serviceequipment: null,
  equipments: [],
  partners: [],
  employees: [],
  employee: null,
  serviceequipmentemployees: [],
  equipment: null,
  partner: null,
  onNew: null,
  bases: [],
  base: null,
  weeklyFrequency: [0, 1, 2, 3, 4, 5, 6],
};

export default ServiceForm;
