import React, { Component } from 'react';
import ReactLoading from 'react-loading';
import { toast } from 'react-toastify';
import swal from '@sweetalert/with-react';
import PropTypes from 'prop-types';
import PieceForm from './form';
import API from '../../services/api';

// Styles
import { SubContainer } from '../../css/styles/wrapper';

class PieceContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      piece: null,
      pieces: [],
      loading: false,
    };

    this.handleCreation = this.handleCreation.bind(this);
    this.getList();
  }

  refPieces = React.createRef();

  getList = async value => {
    this.setState({ loading: true });
    const { onPiecesList } = this.props;

    try {
      const response = await API.get('/pieces');
      this.setState({ pieces: response.data, loading: false });
      onPiecesList(response.data, value);
    } catch (err) {
      toast.error('Um erro aconteceu durante o carregamento!');
      this.setState({ loading: false });
    }
  };

  handleCreation = async piece => {
    const { onPieceSubmit } = this.props;

    const data = {
      description: piece.description.toUpperCase(),
      total: piece.total
    };

    try {
      const response = await API.post('/pieces', {
        ...data,
      });

      this.getList(response.data);
      this.handleNew();
      toast.success('Peça criada com sucesso');
      onPieceSubmit();
    } catch (err) {
      toast.error('Um erro aconteceu durante o cadastro!');
    }
  };

  handleEdit = object => {
    this.setState({ piece: object });
  };

  handleNew = () => {
    this.setState({ piece: null });
  };

  handleUpdate = async object => {
    const { onPieceSubmit } = this.props;
    const { piece } = this.state;

    try {
      await API.put(`pieces/${piece.uuid}`, { ...object });

      this.getList();
      this.handleNew();
      toast.success('Peça atualizado com sucesso.');
      onPieceSubmit();
    } catch (err) {
      toast.error('Um erro aconteceu durante a atualização!');
    }
  };

  handleDelete = async () => {
    const { onPieceSubmit } = this.props;
    const { piece } = this.state;

    try {
      const confirmDelete = await swal({
        dangerMode: true,
        text: 'Confirma a exclusão da peça?',
        buttons: {
          cancel: 'Não',
          confirm: 'Sim',
        },
      });

      if (confirmDelete) {
        await API.delete(`pieces/${piece.uuid}`);

        this.getList();
        this.handleNew();
        toast.success('Peça deletado com sucesso!');
        onPieceSubmit();
      }
    } catch (err) {
      toast.error('Não é possível excluir peças relacionadas a serviços!');
    }
  };

  render() {
    const { pieceForm } = this.props;
    const { piece, pieces, loading } = this.state;

    return (
      <SubContainer visible={pieceForm}>
        <div ref={this.refPiece} />
        {loading ? (
          <ReactLoading type="spin" color="#011826" height={40} width={40} />
        ) : (
          <PieceForm
            piece={piece}
            pieces={pieces}
            onCreation={this.handleCreation}
            onEdit={this.handleEdit}
            onUpdate={this.handleUpdate}
            onNew={this.handleNew}
            onDelete={this.handleDelete}
          />
        )}
      </SubContainer>
    );
  }
}

PieceContainer.propTypes = {
  pieceForm: PropTypes.bool.isRequired,
  onPiecesList: PropTypes.func.isRequired,
  onPieceSubmit: PropTypes.func.isRequired,
};

export default PieceContainer;
