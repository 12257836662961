import React from 'react';
import moment from 'moment';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import Select from 'react-select';

import {
  FilterFormStyled,
  FilterRow,
  FilterColumn,
} from '../../css/styles/filter';
import { SelectStyled } from '../../css/styles/select';

const handleValidate = ({ begin, end }) => {
  const errors = {};

  if (!begin) errors.begin = 'OBRIGATÓRIO';

  if (moment(begin).isAfter(end))
    errors.end = 'DATA FINAL NÃO PODE SER MENOR QUE INICIAL';

  if (!end) errors.end = 'OBRIGATÓRIO';

  return errors;
};

const handleSubmit = props => async (values, { setSubmitting }) => {
  await props.onList({ ...values });

  setSubmitting(false);
};

const MaintenanceFilterForm = props => {
  const { begin, end, equipments, bases } = props;

  const typeOptions = [
    {
      value: 'PREVENTIVA',
      label: 'PREVENTIVA',
    },
    {
      value: 'CORRETIVA',
      label: 'CORRETIVA',
    },
    {
      value: 'PREVENTIVA-CORRETIVA',
      label: 'PREVENTIVA/CORRETIVA',
    },
  ];

  return (
    <Formik
      enableReinitialize={false}
      initialValues={{
        begin: begin || moment().format('YYYY-MM-DD'),
        end: end || moment().format('YYYY-MM-DD'),
        type: '',
        equipment: null,
        base: null,
      }}
      validate={handleValidate}
      onSubmit={handleSubmit(props)}
    >
      {({
        values,
        errors,
        touched,
        dirty,
        setFieldValue,
        handleChange,
        handleBlur,
        handleReset,
        isSubmitting,
      }) => (
        <FilterFormStyled>
          <h1>FILTROS</h1>

          <ul>
            <FilterRow>
              <FilterColumn>
                <strong>DATA INICIAL</strong>
                <input
                  id="begin"
                  name="begin"
                  type="date"
                  value={values.begin}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />

                {errors.begin && touched.begin && <span>{errors.begin}</span>}
              </FilterColumn>

              <FilterColumn>
                <strong>DATA FINAL</strong>
                <input
                  id="end"
                  name="end"
                  type="date"
                  value={values.end}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />

                {errors.end && touched.end && <span>{errors.end}</span>}
              </FilterColumn>
            </FilterRow>

            <FilterRow>
              <FilterColumn>
              <strong>BASE</strong>
                <Select
                  styles={SelectStyled}
                  id="base"
                  name="base"
                  value={values.base}
                  placeholder="Selecione uma base"
                  onChange={selectedOption => {
                    setFieldValue('base', selectedOption);
                  }}
                  isSearchable
                  options={bases}
                  isLoading={false}
                  getOptionLabel={item => item.description}
                  getOptionValue={item => item.uuid}
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  loadingMessage={() => 'Buscando bases'}
                  noOptionsMessage={() => 'Não há bases para exibir'}
                />
              </FilterColumn>
            </FilterRow>

            <FilterRow>
              <FilterColumn>
                <strong>TIPO</strong>
                <select
                  id="type"
                  name="type"
                  value={values.type}
                  onChange={handleChange}
                  onBlur={handleBlur}
                >
                  <option value="" />
                  {typeOptions.map(type => (
                    <option key={type.value} value={type.value}>
                      {type.label}
                    </option>
                  ))}
                </select>

                {errors.type && touched.type && <span>{errors.type}</span>}
              </FilterColumn>
            </FilterRow>

            <FilterRow>
              <FilterColumn>
                <strong>VEÍCULO</strong>
                <Select
                  styles={SelectStyled}
                  id="equipment"
                  value={values.equipment}
                  placeholder="Selecione um veículo"
                  onChange={selectedOption =>
                    setFieldValue('equipment', selectedOption)
                  }
                  isSearchable
                  options={equipments}
                  getOptionLabel={item => item.code}
                  getOptionValue={item => item.uuid}
                  name="equipment"
                  isLoading={false}
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  loadingMessage={() => 'Buscando veículos'}
                  noOptionsMessage={() => 'Não há veículos cadastrados'}
                />

                {errors.equipment && touched.equipment && (
                  <span>{errors.equipment}</span>
                )}
              </FilterColumn>
            </FilterRow>

            <FilterRow>
              <div className="buttons-container">
                <button type="submit" disabled={isSubmitting}>
                  BUSCAR
                </button>

                <button
                  type="button"
                  onClick={handleReset}
                  disabled={isSubmitting || !dirty}
                >
                  RESET
                </button>
              </div>
            </FilterRow>
          </ul>
        </FilterFormStyled>
      )}
    </Formik>
  );
};

MaintenanceFilterForm.propTypes = {
  equipments: PropTypes.arrayOf(PropTypes.shape(PropTypes.string)),
  begin: PropTypes.string,
  end: PropTypes.string,
};

MaintenanceFilterForm.defaultProps = {
  equipments: [],
  begin: null,
  end: null,
};

export default MaintenanceFilterForm;
