import React from 'react';

import { Formik } from 'formik';

import Button from '@material-ui/core/Button';

import PropTypes from 'prop-types';

import { Column, FormStyled, Row } from '../../css/styles/form';

const handleValidate = () => {
  const errors = {};

  return errors;
};

const handleSubmit = props => async (values, { setSubmitting, resetForm }) => {
  await props.onCreation({ ...values });

  setSubmitting(false);
  resetForm();
};

const PieceIventoryForm = props => {
  const { classes, movements } = props;

  return (
    <Formik
      enableReinitialize
      initialValues={{
        document: '',
      }}
      validate={handleValidate(props)}
      onSubmit={handleSubmit(props)}
    >
      {({
        values,
        handleChange,
        handleBlur,
        handleReset,
        isSubmitting,
        setFieldValue,
        dirty,
        errors,
        touched,
      }) => (
        <FormStyled>
          <h1>FINALIZAÇÃO</h1>

          <ul>
          <Row>
            <Column>
              <strong>DOCUMENTO</strong>
              <input
                id="document"
                name="document"
                type="text"
                variant="outlined"
                value={values.document}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {errors.document && touched.document && (
                <span>{errors.document}</span>
              )}
            </Column>
          </Row>
          <Row>
            <div className="buttons-container">
              <button
                type="submit"
                disabled={isSubmitting || !(movements && movements[0])}
              >
                FINALIZAR
              </button>
              <button
                type="button"
                onClick={handleReset}
                disabled={!dirty || isSubmitting}
              >
                RESET
              </button>
            </div>
          </Row>
          </ul>
        </FormStyled>
      )}
    </Formik>
  );
};

PieceIventoryForm.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default PieceIventoryForm;

