import React from 'react';
import moment from 'moment';
import { IoMdTrash } from 'react-icons/io';

import PropTypes from 'prop-types';

// Styles

import { ListStyled, ListItem } from '../../../css/styles/list';

const listItem = props =>
  props.serviceequipmentemployees.map(item => (
    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
    <ListItem
      color="#011826"
      strongColor="#fff"
      key={item.uuid}
      onClick={() => props.onEdit(item)}
    >
      <div className="info">
        <div>
          <strong className="title">DATA: </strong>
          <strong>{moment(item.createdAt).format('DD/MM/YYYY')}</strong>
        </div>

        <div>
          <strong className="title">FUNCIONÁRIO: </strong>
          <strong>{item.employee.name}</strong>
        </div>

        <div>
          <strong className="title">DESCRIÇÃO: </strong>
          <strong>{item.description}</strong>
        </div>
      </div>

      <button
        type="button"
        onClick={e => {
          e.stopPropagation();

          props.onDelete(item.uuid);
        }}
        className="delete"
      >
        <IoMdTrash size={20} color="#bf1523" />
      </button>
    </ListItem>
  ));

function serviceEquipmentEmployeeList(props) {
  const { serviceequipmentemployees } = props;

  return (
    <>
      {serviceequipmentemployees && serviceequipmentemployees.length > 0 ? (
        <ListStyled>{listItem(props)}</ListStyled>
      ) : (
        <strong>LISTA VAZIA</strong>
      )}
    </>
  );
}

serviceEquipmentEmployeeList.propTypes = {
  serviceequipmentemployees: PropTypes.arrayOf(
    PropTypes.shape({
      uuid: PropTypes.string,

      createdAt: PropTypes.string,

      description: PropTypes.string,
    })
  ),
};

serviceEquipmentEmployeeList.defaultProps = {
  serviceequipmentemployees: [],
};

export default serviceEquipmentEmployeeList;
