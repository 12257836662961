import React, { Component, Fragment } from 'react';

import { toast } from 'react-toastify';

import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';

import io from 'socket.io-client';

import swal from '@sweetalert/with-react';

import PieceIventoryForm from './form';

import PieceIventoryList from './pieceInventoryList';
import {
  Wrapper,
  Container,
  Content,
  SubContainer,
} from '../../css/styles/wrapper';

import MovementForm from './movementForm';

import PieceContainer from '../Piece';

import Parameters from '../../services/parameters';

import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

import API from '../../services/api';
import PieceInventoryFilterForm from './pieceInventoryFilterForm';
import moment from 'moment';

const styles = theme => ({
  root: {
    width: '90%',

    margin: theme.spacing.unit * 3,

    paddingBottom: theme.spacing.unit * 2,

    overflowX: 'auto',
  },
});

class PieceInventoryContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      inventories: [],

      loadingList: true,

      pieceForm: false,

      piece: null,

      pieces: [{ uuid: null, code: 'CARREGANDO' }],

      pieceUuid: null,

      document: null,

      movementList: [],
    };

    // piece invenotry BIND

    this.handleInventoryCreation = this.handleInventoryCreation.bind(this);

    this.handleInventoryDelete = this.handleInventoryDelete.bind(this);

    // piece BIND

    this.handlePiecesList = this.handlePiecesList.bind(this);

    this.handlePieceFormState = this.handlePieceFormState.bind(this);
  }

  refTicket = React.createRef();

  componentDidMount() {
    this.registerToSocket();

    this.getListOptions();

    this.getTodosList();
  }

  registerToSocket = () => {
    const socket = io(Parameters.URL_API);

    socket.on('schedule', () => this.getTodosList());
  };

  getListOptions = async () => {
    try {
      const responsePieces = await API.get(`/pieces`);

      // eslint-disable-next-line no-unused-vars
      this.setState(prevState => ({
        pieces: responsePieces.data,
      }));
    } catch (error) {
      toast.error('Erro ao carregar a lista de documentos!');
    }
  };

  handleInventoryCreation = async object => {
    const { movementList } = this.state;
    const { document } = object;

    const data = {
      document,
      list: movementList,
    };
    try {
      await API.post(`/pieceinventories`, { ...data });
      await this.getTodosList();
      await this.getListOptions();

      this.setState({
        // snackBarOpen: true,
        piece: null,
        pieceUuid: null,
        movementList: [],
      });

      toast.success('Documento de movimentação de estoque criado com sucesso!');
    } catch (error) {
      toast.error('Um erro aconteceu durante a criação do documento!');
    }
  };

  handleInventoryDelete = async movementUuid => {
    try {
      const confirmDelete = await swal({
        dangerMode: true,

        text: 'Confirma a exclusão da movimentação?',

        buttons: {
          cancel: 'Não',

          confirm: 'Sim',
        },
      });

      if (confirmDelete) {
        await API.delete(`pieceinventories/${movementUuid}`);

        // this.setState({ snackBarOpen: true });

        await this.getTodosList();
        await this.getListOptions();

        toast.success('Movimentação excluída com sucesso!');
      }
    } catch (error) {
      toast.error('Um erro aconteceu durante a exclusão!');
    }
  };

  handleNew = () => {
    this.setState({
      // snackBarOpen: true,
      piece: null,
      pieceUuid: null,
      movementList: [],
    });

    this.scrollToMyRef(this.refTicket);
  };

  handleAddMovement = movement => {
    if (movement.pieceUuid && movement.quantity !== 0) {
      const { movementList, pieces } = this.state;
      const list = [...movementList]; // Clonando a lista existente
      const data = {
        ...movement,
        piece: pieces.find(piece => piece.uuid === movement.pieceUuid),
      };
      list.push(data);

      this.setState({
        movementList: list,
        piece: null,
      });
    }
  };

  handleDeleteMovement = index => {
    const { movementList } = this.state;

    const list = movementList;
    list.splice(index, 1);

    this.setState({ movementList: list });
  };

  scrollToMyRef = myRef => {
    window.scrollTo(0, myRef.current.offsetTop);
  };
  
  getTodosList = async values => {
    this.setState({
      loadingList: true,
    });
    
    if (values) {
      await this.setState({
        pieceUuid: values.piece || null,
        document: values.document ? values.document : null,
      });
    }
    
    const { pieceUuid, document } = this.state;
    const response = await API.get(
      `pieceinventories/${pieceUuid || null}/${document || null}`
    );
    
    this.setState({
      inventories: response.data,

      loadingList: false,
    });
  };

  handlePiecesList = (pieces, piece) => {
    // eslint-disable-next-line no-unused-vars
    this.setState(prevState => ({
      pieces,
      piece,
    }));
  };

  handlePieceFormState() {
    const { pieceForm } = this.state;

    // eslint-disable-next-line no-unused-vars
    this.setState(prevState => ({
      pieceForm: !pieceForm,
    }));
  }

  exportToCSV = (csvData, fileName) => {
    const fileType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

    const fileExtension = '.xlsx';

    const ws = XLSX.utils.json_to_sheet(csvData);

    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };

    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

    const data = new Blob([excelBuffer], { type: fileType });

    FileSaver.saveAs(data, fileName + fileExtension);
  };

  generateExcelData = data => {
    // const {
    //   refuels,
    //   refuelFilter,
    // } = this.state;
  
    console.log(data)
    const arrayExcel = [];

    data.forEach((reportLine, index) => {
      const { total, description } = reportLine;
  
      const PEÇA = description;
      const TOTAL_EM_ESTOQUE = total || 0;
  
      const objeto = {
        PEÇA,
        TOTAL_EM_ESTOQUE
      };
  
      arrayExcel.push(objeto);
    });
    // const SUBTOTAL = arrayExcel.reduce((price, acprice) =>
    //   price + acprice.TOTAL, 0
  
    // )
    // arrayExcel.push({SUBTOTAL})
    console.log(data)
    return arrayExcel;
  };


  render() {

    const {
      inventories,
      pieces,
      piece,
      pieceForm,
      movementList,
      loadingList,
    } = this.state;

    let excelData = null;

    let nomeArquivo = `${moment(new Date()).format('DDMMYYYYHHmmss')}_RELATORIO_ESTOQUE_PECAS`;


    if (pieces) {
      excelData = this.generateExcelData(pieces);
    }

    return (
      <Wrapper>
        <Container>
          <header>
            <h1>ESTOQUE DE PEÇAS</h1>
            <div className="financial-buttons-container">
            
              <button type="button" onClick={this.handleNew}>NOVO</button>
            
              
              {excelData && (
                <button
                  type="button"
                  onClick={() => this.exportToCSV(excelData, nomeArquivo)}
                >
                  EXCEL
                </button>
              )}
            </div>
          </header>
          <Content>
            <PieceInventoryFilterForm
              onList={this.getTodosList}
              pieces={pieces}
            />
            <PieceIventoryList
              inventories={inventories}
              loadingList={loadingList}
              onDelete={this.handleInventoryDelete}
            />
            <div ref={this.refTicket} />

            <hr />
            <SubContainer visible={true}>
              <div ref={this.refMaintenancePiece} />

              <nav>
                <h1>ESTOQUE</h1>
                <button
                  type="button"
                  onClick={() => this.handlePieceFormState()}
                >
                  PEÇA
                </button>
              </nav>

              <PieceContainer
                onPiecesList={this.handlePiecesList}
                onPieceSubmit={() => this.handlePieceFormState()}
                pieceForm={pieceForm}
              />
            </SubContainer>

            <MovementForm
              onAdd={this.handleAddMovement}
              onDelete={this.handleDeleteMovement}
              pieces={pieces}
              piece={piece}
              movements={movementList}
              onNew={this.handleNew}
            />

            <PieceIventoryForm
              onCreation={this.handleInventoryCreation}
              pieces={pieces}
              piece={piece}
              movements={movementList}
            />
          </Content>
        </Container>
      </Wrapper>
    );
  }
}

PieceInventoryContainer.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(PieceInventoryContainer);

