import React from 'react';

import { Formik } from 'formik';

import PropTypes from 'prop-types';

import Select from 'react-select';

// Styles

import { FormStyled, Row, Column } from '../../../css/styles/form';

import { SelectStyled } from '../../../css/styles/select';

const handleValidate = ({ employee }) => {
  const errors = {};

  if (!employee) errors.employee = 'OBRIGATÓRIO';

  return errors;
};

const handleSubmit = props => async (values, { setSubmitting, resetForm }) => {
  if (props.serviceequipmentemployee) {
    await props.onUpdate({ ...values });
  } else {
    await props.onCreation({ ...values });
  }

  setSubmitting(false);
  resetForm();
};

const ServiceEquipmentEmployeeForm = props => {
  const { onNew, employees, employee, serviceequipmentemployee } = props;

  return (
    <Formik
      enableReinitialize
      initialValues={{
        employee: serviceequipmentemployee
          ? serviceequipmentemployee.employee
          : employee || null,

        description: serviceequipmentemployee
          ? serviceequipmentemployee.description
          : '',
      }}
      validate={handleValidate}
      onSubmit={handleSubmit(props)}
    >
      {({
        values,

        handleReset,

        handleBlur,

        handleChange,

        errors,

        touched,

        setFieldValue,

        isSubmitting,

        dirty,
      }) => (
        <FormStyled>
          <h1>
            {serviceequipmentemployee
              ? 'ALTERAR FUNCIONÁRIO DO SERVIÇO'
              : 'NOVO FUNCIONÁRIO DO SERVIÇO'}
          </h1>

          <ul>
            <Row>
              <Column>
                <strong>FUNCIONÁRIO</strong>

                <Select
                  styles={SelectStyled}
                  id="employee"
                  value={values.employee}
                  placeholder="Selecione um funcionário"
                  onChange={selectedOption =>
                    setFieldValue('employee', selectedOption)
                  }
                  isSearchable
                  options={employees}
                  getOptionLabel={item => item.name}
                  getOptionValue={item => item.uuid}
                  name="employee"
                  isLoading={false}
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  loadingMessage={() => 'Buscando funcionários'}
                  noOptionsMessage={() => 'Não há funcionários cadastrados'}
                />

                {errors.employee && touched.employee && (
                  <span>{errors.employee}</span>
                )}
              </Column>
            </Row>

            <Row>
              <Column>
                <strong>DESCRIÇÃO</strong>

                <textarea
                  id="description"
                  name="description"
                  type="text"
                  value={values.description}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />

                {errors.description && touched.description && (
                  <span>{errors.description}</span>
                )}
              </Column>
            </Row>

            <Row>
              <div className="buttons-container">
                <button type="submit" disabled={isSubmitting}>
                  SALVAR
                </button>

                <button
                  type="button"
                  onClick={handleReset}
                  disabled={!dirty || isSubmitting}
                >
                  RESET
                </button>

                {serviceequipmentemployee && (
                  <button
                    type="button"
                    onClick={onNew}
                    disabled={
                      (!dirty && !serviceequipmentemployee) || isSubmitting
                    }
                  >
                    LIMPAR
                  </button>
                )}
              </div>
            </Row>
          </ul>
        </FormStyled>
      )}
    </Formik>
  );
};

ServiceEquipmentEmployeeForm.propTypes = {
  employees: PropTypes.arrayOf(
    PropTypes.shape({
      uuid: PropTypes.string,
      name: PropTypes.string,
    })
  ),

  employee: PropTypes.shape({
    uuid: PropTypes.string,
    name: PropTypes.string,
  }),

  serviceequipmentemployee: PropTypes.shape({
    description: PropTypes.string,

    employee: PropTypes.shape({
      uuid: PropTypes.string,
      name: PropTypes.string,
    }),
  }),

  onNew: PropTypes.func.isRequired,
};

ServiceEquipmentEmployeeForm.defaultProps = {
  employees: [],

  employee: null,

  serviceequipmentemployee: null,
};

export default ServiceEquipmentEmployeeForm;
