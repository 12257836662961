const configSystem = {
  // Não colocar barra no final.
  // URL_API: 'http://localhost:4000',

  // TESTE:
  // URL_API: 'https://servi-sa.herokuapp.com',

  // PRODUÇÃO:
  URL_API: process.env.REACT_APP_API_KEY,
};

export default configSystem;
