/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  max-width: 900px;
  max-height: 90vh;
  overflow-x: auto;
  border-radius: 4px;
  -webkit-box-shadow: 0px 6px 14px -2px rgba(0, 0, 0, 0.34);
  -moz-box-shadow: 0px 6px 14px -2px rgba(0, 0, 0, 0.34);
  box-shadow: 0px 6px 14px -2px rgba(0, 0, 0, 0.34);
`;

export const Table = styled.table`
  width: 100%;
  border: 1px solid #193441;
`;

export const Tbody = styled.tbody``;

export const Tr = styled.tr`
  &:first-child {
    td {
      background: #011826;
      position: sticky;
      top: 0;
      z-index: 2;
      strong {
        color: #fff;
      }
      small {
        color: #fff;
      }
    }
  }
  & + tr {
    border-top: 1px solid #fff;
  }
`;

export const Td = styled.td`
  padding: 8px 0;
  text-align: center;
  min-width: 154px;
  max-width: 154px;
  &.equipment {
    font-weight: bold;
    font-size: 14px;
    background: #011826;
    color: #fff;
    border-top: 1px solid #fff;
    position: sticky;
    left: 0;
    z-index: 1;
  }
  div {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 8px;
  }
`;

export const Button = styled.button`
  ${props => {
    if((props.isHoliday || (props.weeklyFrequency &&
      !props.weeklyFrequency.includes(props.currentDate))) &&
      !props.isExtraDay
    ) {
      return 'background: #c9c9c9';
    } else if (props.serviceType === 'No radar' || (props.isExtraDay && props.serviceType === 'No radar')) {
    return 'background:#3d85c6';
  } else if (props.serviceType === 'Pré-agendado' || (props.isExtraDay && props.serviceType === 'Pré-agendado')) {
    return 'background:#f1c232';
  } else if(props.serviceType === 'Confirmado' || (props.isExtraDay && props.serviceType === 'Confirmado')){
      return `background: #6aa84f`;
    };
  }};
  height: 20px;
  width: 100%;
  padding: 15px;
  color: #fff;
  border-radius: 0;

  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 12px;

  white-space: nowrap;
`;
