function replaceComma(quantity) {
  if (quantity) {
    const quantityStringfy = quantity.toString();

    const quantityFormatted = quantityStringfy.replace(',', '.');

    return quantityFormatted;
  }

  return null;
}

export default replaceComma;
