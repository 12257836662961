import React, { Component } from 'react';
import ReactLoading from 'react-loading';
import { toast } from 'react-toastify';
import swal from '@sweetalert/with-react';
import PropTypes from 'prop-types';
import EmployeeForm from './form';
import API from '../../services/api';

// Styles
import { SubContainer } from '../../css/styles/wrapper';

// import EmployeeList from './list';

class EmployeeContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      employee: null,
      employees: [],
      loading: false,
    };

    this.handleCreation = this.handleCreation.bind(this);
    this.getList();
  }

  refEmployees = React.createRef();

  getList = async value => {
    this.setState({ loading: true });
    const { onEmployeesList } = this.props;

    try {
      const response = await API.get('/employees');
      this.setState({ employees: response.data, loading: false });
      
        onEmployeesList(response.data, value);
      
    } catch (err) {
      toast.error('Um erro aconteceu durante o carregamento!');
      this.setState({ loading: false });
    }
  };

  handleCreation = async value => {
    const { onEmployeeSubmit } = this.props;

    const data = {
      name: value.name.toUpperCase(),
      telephone: value.telephone || null,
      cnh: value.cnh || null,
      address: value.address || null,
      nickname: value.nickname || null,
      document: value.document || null,
      account: value.account || null,
      account_verifying_digit: value.account_verifying_digit || null,
      branch: value.branch || null,
      bank: value.bank || null,
      key: value.key || null,
    };

    try {
      const response = await API.post('employees', { ...data });

      this.getList(response.data);
      this.handleNew();
      toast.success('Funcionário criado com sucesso!');
      onEmployeeSubmit();
    } catch (err) {
      toast.error('Um erro aconteceu durante o cadastro!');
    }
  };

  handleEdit = object => {
    this.setState({ employee: object });
  };

  handleNew = () => {
    this.setState({ employee: null });
  };

  handleUpdate = async value => {
    
    const { onEmployeeSubmit } = this.props;
    const { employee } = this.state;

    const data = {
      name: value.name.toUpperCase(),
      telephone: value.telephone || null,
      cnh: value.cnh || null,
      address: value.address || null,
      nickname: value.nickname || null,
      document: value.document || null,
      account: value.account || null,
      account_verifying_digit: value.account_verifying_digit || null,
      branch: value.branch || null,
      bank: value.bank || null,
      key: value.key || null,
    };

    try {
      await API.put(`/employees/${employee.uuid}`, { ...data });

      this.getList();
      this.handleNew();
      toast.success('Funcionário atualizada com sucesso.');
      onEmployeeSubmit();
    } catch (err) {
      toast.error('Um erro aconteceu durante a atualização!');
    }
  };

  handleDelete = async () => {
    const { onEmployeeSubmit } = this.props;
    const { employee } = this.state;

    try {
      const confirmDelete = await swal({
        dangerMode: true,
        text: 'Confirma a exclusão do funcionário?',
        buttons: {
          cancel: 'Não',
          confirm: 'Sim',
        },
      });

      if (confirmDelete) {
        await API.delete(`employees/${employee.uuid}`);

        this.getList();
        this.handleNew();
        toast.success('Funcionário deletado com sucesso!');
        onEmployeeSubmit();
      }
    } catch (err) {
      toast.error(
        'Não é possível excluir funcionários relacionados a serviços ou manutenções!'
      );
    }
  };

  render() {
    const { employeeForm, employeeList } = this.props;
    const { employee, employees, loading } = this.state;

    return (
      <SubContainer visible={employeeForm}>
        <div ref={this.refEmployees} />
        {loading ? (
          <ReactLoading type="spin" color="#011826" height={40} width={40} />
        ) : (
          <EmployeeForm
          employeeList={employeeList}
            employee={employee}
            employees={employees}
            onCreation={this.handleCreation}
            onEdit={this.handleEdit}
            onUpdate={this.handleUpdate}
            onNew={this.handleNew}
            onDelete={this.handleDelete}
            team={this.props.team}
          />
        )}
      </SubContainer>
    );
  }
}

EmployeeContainer.propTypes = {
  employeeForm: PropTypes.bool.isRequired,
  onEmployeesList: PropTypes.func.isRequired,
  onEmployeeSubmit: PropTypes.func.isRequired,
};

export default EmployeeContainer;
