import React, { Component } from 'react';
import moment from 'moment';
import ReactLoading from 'react-loading';
import { toast } from 'react-toastify';
import io from 'socket.io-client';
import swal from '@sweetalert/with-react';
import { PDFDownloadLink } from '@react-pdf/renderer';
import MaintenanceForm from './form';
import MaintenanceFilterForm from './filterForm';
import MaintenanceCloseForm from './closeForm';
import MaintenanceList from './list';
import EquipmentContainer from '../Equipment';
import MaintenancePieceContainer from './MaintenancePiece';
import MaintenanceEmployeeContainer from './MaintenanceEmployee';
import MaintenanceDocument, { OilChangePDF } from './document';
import API from '../../services/api';
import Parameters from '../../services/parameters';

// Styles
import { Wrapper, Container, Content } from '../../css/styles/wrapper';
import MaintanceToMaintenanceServiceContainer from './MaintenanceToMaintenanceService';
import MaintenanceToMaintenancePlanFreqContainer from './MaintenanceToMaintenancePlanFreq';

class MaintenanceContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      maintenances: [],

      maintenance: null,

      base: null,

      bases: [],

      maintenancePlanFreq: null,

      maintenancePlanFreqs: [],

      loading: true,

      maintenancePieces: [],

      maintenanceEmployees: [],

      maintenanceToMaintenanceServices: [],

      maintenancePieceForm: true,

      maintenanceEmployeeForm: true,

      maintenanceToMaintenanceServiceForm: true,

      equipmentForm: false,

      equipment: null,

      equipmentUuid: null,

      equipments: [],

      maintenanceToMaintenancePlanFreqForm: true,

      maintenanceToMaintenancePlanFreqs: [],

      type: null,

      begin: moment.utc().format('YYYY-MM-DD'),

      end: moment.utc().format('YYYY-MM-DD'),

      closedList: false,
    };

    // MAITENANCE BIND

    this.handleCreation = this.handleCreation.bind(this);

    this.handleUpdate = this.handleUpdate.bind(this);

    this.handleClose = this.handleClose.bind(this);

    this.handleEdit = this.handleEdit.bind(this);

    this.handleMaintenanceGet = this.handleMaintenanceGet.bind(this);

    this.handleDelete = this.handleDelete.bind(this);

    this.handleNew = this.handleNew.bind(this);

    // EQUIPMENT BIND

    this.handleEquipmentsList = this.handleEquipmentsList.bind(this);

    this.handleEquipmentFormState = this.handleEquipmentFormState.bind(this);

    // MAINTENANCEPIECE BIND

    this.handleMaintenancePiecesList = this.handleMaintenancePiecesList.bind(
      this
    );

    this.handleMaintenancePieceFormState = this.handleMaintenancePieceFormState.bind(
      this
    );

    // MAINTENANCEEMPLOYYE BIND

    this.handleMaintenanceEmployeesList = this.handleMaintenanceEmployeesList.bind(
      this
    );

    this.handleMaintenanceEmployeeFormState = this.handleMaintenanceEmployeeFormState.bind(
      this
    );

    // MAINTENANCETOMAINTENANCESERVICE BIND

    this.handleMaintenanceToMaintenanceServicesList = this.handleMaintenanceToMaintenanceServicesList.bind(
      this
    );

    this.handleMaintenanceToMaintenanceServiceFormState = this.handleMaintenanceToMaintenanceServiceFormState.bind(
      this
    );

    // MAINTENANCETOMAINTENANCEPLANFREQ BIND

    this.handleMaintenanceToMaintenancePlanFreqsList = this.handleMaintenanceToMaintenancePlanFreqsList.bind(
      this
    );

    this.handleMaintenanceToMaintenancePlanFreqFormState = this.handleMaintenanceToMaintenancePlanFreqFormState.bind(
      this
    );
  }

  refMaintenance = React.createRef();

  componentDidMount() {
    this.registerToSocket();
    this.getEquipments();
    this.getList();
    this.getBases();
    this.getMaintenancePlanFreqs();
  }

  registerToSocket = () => {
    const socket = io(Parameters.URL_API);

    socket.on('financial', () => this.getList());
  };

  scrollToMyRef = myRef => {
    window.scrollTo(0, myRef.current.offsetTop);
  };

  getList = async values => {
    this.setState({ loading: true });

    if (values) {
      await this.setState({
        begin: values.begin || moment().format('YYYY-MM-DD'),
        end: values.end || moment().format('YYYY-MM-DD'),
        type: values.type || null,
        equipmentUuid: values.equipment ? values.equipment.uuid : null,
        baseUuid: values.base ? values.base.uuid : null,
      });
    }

    try {
      const { begin, end, type, equipmentUuid, closedList, baseUuid } = this.state;

      const response = await API.get(
        `maintenances/${closedList}/${closedList ? begin : null}/${
          closedList ? end : null
        }/${type || null}/${equipmentUuid || null}/${baseUuid || null}`
      );

      this.setState({
        maintenances: response.data,
        loading: false,
      });
    } catch (err) {
      toast.error('Um erro aconteceu durante o carregamento!');
      this.setState({
        loading: false,
      });
    }
  };

  getMaintenancePlanFreqs = async () => {
    this.setState({
      loading: true,
    });

    try {
      const response = await API.get('/maintenanceplanfreqs');
      this.setState({
        maintenancePlanFreqs: response.data,
        loading: false,
      });
    } catch (err) {
      toast.error('Um erro aconteceu durante o carregamento!');
    }
  };

  getBases = async value => {
    this.setState({
      loading: true,
    });
    const { onBasesList } = this.props;

    try {
      const response = await API.get('/bases');
      this.setState({
        bases: response.data,
        loading: false,
      });
      // onBasesList(response.data, value);
    } catch (err) {
      toast.error('Um erro aconteceu durante o carregamento!');
      this.setState({
        loading: false,
      });
    }
  };

  handleCreation = async object => {
    const data = {
      baseUuid: object?.base?.uuid,

      opening_description: object?.opening_description,

      opening_date: `${object?.opening_date}T${object?.time}`,

      type: object?.type,

      observation: object?.observation || null,

      equipmentUuid: object?.equipment?.uuid,

      situation: object?.situation,

      km: object?.km,

      timer01: object?.timer01,

      timer02: object?.timer02 || null,

      services: (object?.services && object?.services?.toUpperCase()) || null,

      status: 'ABERTA',
    };

    try {
      const response = await API.post('/maintenances', { ...data });
      this.handleEdit(response.data);
      await this.getList();
      this.handleNew();
      toast.success('Manutenção criada com sucesso!');
    } catch (err) {
      toast.error('Um erro aconteceu durante o cadastro!');
    }
  };

  handleEdit = async object => {
    this.setState({
      equipmentForm: false,

      equipment: object.equipment,

      maintenancePieceForm: false,

      maintenanceEmployeeForm: false,

      maintenanceToMaintenanceServiceForm: false,

      maintenanceToMaintenancePlanFreqForm: false,

      maintenance: null,

      observation: object?.observation? object.observation : null,
    });

    this.handleMaintenanceGet(object.uuid);
    this.handleMaintenanceEmployeesList(object.uuid);
    this.handleMaintenanceToMaintenanceServicesList(object.uuid);
    this.handleMaintenanceToMaintenancePlanFreqsList(object.uuid);
    this.scrollToMyRef(this.refMaintenance);
  };

  handleUpdate = async object => {
    const { maintenance } = this.state;

    try {
      const response = await API.put(`/maintenances/${maintenance?.uuid}`, {
        equipmentUuid: object?.equipment?.uuid,
        baseUuid: object?.base?.uuid,
        opening_description: object?.opening_description?.toUpperCase(),
        opening_date: `${object?.opening_date}T${object?.time}`,
        type: object?.type,
        observation: object?.observation?.toUpperCase(),
        situation: object?.situation,
        km: object?.km,
        timer01: object?.timer01,
        timer02: object?.timer02 ? object.timer02 : null,
        services: object?.services && object?.services?.toUpperCase(),
      });

      this.getEquipments();
      this.handleEdit(response.data);
      await this.getList();
      this.handleNew();
      toast.success('Manutenção atualizada com sucesso!');
    } catch (err) {
      toast.error('Um erro aconteceu durante a atualização!');
    }
  };

  handleDelete = async uuid => {
    try {
      const confirmDelete = await swal({
        dangerMode: true,

        text: 'Confirma a exclusão da manutenção?',

        buttons: {
          cancel: 'Não',

          confirm: 'Sim',
        },
      });

      if (confirmDelete) {
        await API.delete(`maintenances/${uuid}`);
        this.getEquipments();
        await this.getList();
        this.handleNew();
        toast.success('Manutenção deletada com sucesso!');
      }
    } catch (err) {
      toast.error('Um erro aconteceu durante a exclusão!');
    }
  };

  getEquipments = async () => {
    try {
      const response = await API.get('/equipments');
      const equipments = response.data;

      this.setState({ equipments });
    } catch (err) {
      toast.error('Erro ao carregar a lista de equipamentos.');
    }
  };

  handleClose = async object => {
    const { maintenance } = this.state;

    const data = {
      closing_description: object?.closing_description,

      closing_date: `${object?.closing_date}T${object?.time}`,

      status: 'FECHADA',
    };

    try {
      await API.put(`/maintenances/${maintenance?.uuid}`, { ...data });

      this.getEquipments();
      await this.getList();
      this.handleNew();
      toast.success('Manutenção fechada com sucesso!');
    } catch (err) {
      toast.error('Erro ao fechar manutenção.');
    }
  };

  handleClosedList = async () => {
    await this.setState(prevState => ({
      closedList: !prevState.closedList,
      maintenance: null,
      equipment: null,
      equipmentForm: false,
      maintenancePieceForm: false,
      maintenanceEmployeeForm: false,
    }));

    this.getList();
  };

  handleNew = () => {
    this.setState({
      maintenance: null,
      equipment: null,
      equipmentForm: false,
      maintenancePieceForm: false,
      maintenanceEmployeeForm: false,
    });

    this.scrollToMyRef(this.refMaintenance);
  };

  handleMaintenanceGet = async uuid => {
    try {
      const response = await API.get(`/maintenances/${uuid}`);
      
      this.setState({
        maintenance: response?.data?.[0],
      });
    } catch (err) {
      toast.error('Um erro aconteceu durante o carregamento!');
    }
  };

  // EQUIPMENTS HELPERS

  handleEquipmentsList = (equipments, equipment) => {
    this.setState({
      equipments,

      equipment,
    });
  };

  handleEquipmentFormState() {
    const { equipmentForm } = this.state;

    this.setState({
      equipmentForm: !equipmentForm,
    });
  }

  // MAINTENANCEPIECES HELPERS

  handleMaintenancePiecesList = maintenancePieces => {
    this.setState({
      maintenancePieces,
    });
  };

  handleMaintenancePieceFormState() {
    const { maintenancePieceForm } = this.state;

    this.setState({
      maintenancePieceForm: !maintenancePieceForm,
      maintenanceEmployeeForm: false,
      maintenanceToMaintenanceServiceForm: false,
      maintenanceToMaintenancePlanFreqForm: false,
    });
  }

  // MAINTENANCEEMPLOYEES HELPERS

  handleMaintenanceEmployeesList = async maintenanceUuid => {
    this.setState({
      loading: true,
    });

    try {
      const response = await API.get(
        `/maintenanceemployees/${maintenanceUuid}`
      );

      this.setState({
        maintenanceEmployees: response.data,
        loading: false,
      });
    } catch (err) {
      toast.error('Um erro aconteceu durante o carregamento!');
      this.setState({ loading: false });
    }
  };

  handleMaintenanceEmployeeFormState() {
    const { maintenanceEmployeeForm } = this.state;

    this.setState({
      maintenanceEmployeeForm: !maintenanceEmployeeForm,
      maintenancePieceForm: false,
      maintenanceToMaintenanceServiceForm: false,
      maintenanceToMaintenancePlanFreqForm: false,
    });
  }

  // MAINTENANCEEMPLOYEES HELPERS

  handleMaintenanceToMaintenanceServicesList = async maintenanceUuid => {
    this.setState({
      loading: true,
    });

    try {
      const response = await API.get(
        `/maintenancetomaintenanceservices/${maintenanceUuid}`
      );

      this.setState({
        maintenanceToMaintenanceServices: response.data,
        loading: false,
      });
    } catch (err) {
      toast.error('Um erro aconteceu durante o carregamento!');
      this.setState({ loading: false });
    }
  };

  handleMaintenanceToMaintenancePlanFreqsList = async maintenanceUuid => {
    this.setState({
      loading: true,
    });

    try {
      const response = await API.get(
        `/maintenancetomaintenanceplanfreqs/${maintenanceUuid}`
      );

      this.setState({
        maintenanceToMaintenancePlanFreqs: response.data,
        loading: false,
      });
    } catch (err) {
      toast.error('Um erro aconteceu durante o carregamento!');
      this.setState({ loading: false });
    }
  };

  handleMaintenanceToMaintenanceServiceFormState() {
    const { maintenanceToMaintenanceServiceForm } = this.state;

    this.setState({
      maintenanceEmployeeForm: false,
      maintenancePieceForm: false,
      maintenanceToMaintenanceServiceForm: !maintenanceToMaintenanceServiceForm,
      maintenanceToMaintenancePlanFreqForm: false,
    });
  }

  handleMaintenanceToMaintenancePlanFreqFormState() {
    const { maintenanceToMaintenancePlanFreqForm } = this.state;

    this.setState({
      maintenanceEmployeeForm: false,
      maintenancePieceForm: false,
      maintenanceToMaintenanceServiceForm: false,
      maintenanceToMaintenancePlanFreqForm: !maintenanceToMaintenancePlanFreqForm,
    });
  }

  render() {
    const {
      equipments,
      maintenances,
      maintenance,
      base,
      bases,
      maintenancePlanFreq,
      maintenancePlanFreqs,
      equipmentForm,
      loading,
      equipment,
      begin,
      end,
      closedList,
      maintenancePieceForm,
      maintenanceEmployeeForm,
      maintenanceToMaintenanceServiceForm,
      maintenanceToMaintenancePlanFreqForm,
      maintenancePieces,
      maintenanceEmployees,
      maintenanceToMaintenanceServices,
      maintenanceToMaintenancePlanFreqs,
    } = this.state;

    let oilChange = null;
    let oilChangeEquipments = [];

    if (equipments) {
      oilChange = equipments.filter(
        equipment => equipment.maintenances.length > 0
      );

      oilChangeEquipments = oilChange.map(equipment => {
        let manutencoesPreventivas = equipment?.maintenances?.filter(
          maintenance => {
            return (
              maintenance?.type !== 'CORRETIVA' &&
              maintenance?.status === 'FECHADA'
            );
          }
        );
        manutencoesPreventivas = manutencoesPreventivas.sort((a, b) => {
          return a.createdAt < b.createdAt;
        });
        return {
          ...equipment,
          preventiveMaintenances: manutencoesPreventivas,
        };
      });
    }

    return (
      <Wrapper>
        <Container>
          <header>
            <h1>MANUTENÇÕES</h1>
            <div className="buttons-container">
              <button type="button" onClick={this.handleNew}>
                NOVO
              </button>
              <button type="button" onClick={this.handleClosedList}>
                {closedList ? 'ABERTAS' : 'FECHADAS'}
              </button>
            </div>
          </header>

          <Content>
            <MaintenanceFilterForm
              onList={this.getList}
              equipments={equipments}
              bases={bases}
              begin={begin}
              end={end}
            />
            {oilChangeEquipments.length > 0 && (
              <div className="last-wrap">
                <PDFDownloadLink
                  document={
                    <OilChangePDF oilChangeEquipments={oilChangeEquipments} />
                  }
                  fileName={`TROCA_DE_ÓLEO-${moment(new Date()).format(
                    'DD_MM_YYYY'
                  )}.pdf`}
                  style={{
                    height: '40px',
                    borderRadius: '4px',
                    padding: '0 15px',
                    boxSizing: 'border-box',
                    background: '#59d99d',
                    fontSize: '16px',
                    fontWeight: 'bold',
                    color: '#fff',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  {({ blob, url, loading, error }) =>
                    loading ? 'CRREGANDO...' : 'PDF T. ÓLEO'
                  }
                </PDFDownloadLink>
              </div>
            )}
            {!loading ? (
              <>
                {maintenances && maintenances[0] ? (
                  <>
                    <MaintenanceList
                      maintenances={maintenances}
                      loading={loading}
                      onEdit={this.handleEdit}
                      onDelete={this.handleDelete}
                    />
                  </>
                ) : (
                  <strong>LISTA VAZIA</strong>
                )}
              </>
            ) : (
              <ReactLoading
                type="spin"
                color="#011826"
                height={40}
                width={40}
              />
            )}

            <nav>
              <h1>MANUTENÇÃO</h1>
              <div className="buttons-container">
                {maintenance && (
                  <PDFDownloadLink
                    document={
                      <MaintenanceDocument
                        maintenance={maintenance? maintenance : null}
                        maintenanceToMaintenanceServices={
                          maintenanceToMaintenanceServices? maintenanceToMaintenanceServices : null
                        }
                        maintenanceToMaintenancePlanFreqs={
                          maintenanceToMaintenancePlanFreqs? maintenanceToMaintenancePlanFreqs : null
                        }
                      />
                    }
                    fileName={`OS-${maintenance?.equipment?.code}-Nº:${maintenance?.number}-${maintenance?.type}.pdf`}
                    style={{
                      height: '40px',
                      borderRadius: '4px',
                      padding: '0 15px',
                      boxSizing: 'border-box',
                      background: '#59d99d',
                      fontSize: '16px',
                      fontWeight: 'bold',
                      color: '#fff',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    {({ blob, url, loading, error }) =>
                    loading ? 'CRREGANDO...' : 'PDF'
                  }
                  </PDFDownloadLink>
                )}

                <button
                  type="button"
                  onClick={() => this.handleEquipmentFormState()}
                >
                  VEÍCULO
                </button>
              </div>
            </nav>

            <EquipmentContainer
              onEquipmentsList={this.handleEquipmentsList}
              onEquipmentSubmit={() => this.handleEquipmentFormState()}
              equipmentForm={equipmentForm}
            />

            <div ref={this.refMaintenance} />

            <MaintenanceForm
              onCreation={this.handleCreation}
              onUpdate={this.handleUpdate}
              onNew={this.handleNew}
              maintenance={maintenance}
              equipments={equipments}
              equipment={equipment}
              base={base}
              bases={bases}
            />

            {maintenance && (
              <>
                <MaintenanceCloseForm
                  onClose={this.handleClose}
                  maintenance={maintenance}
                />

                <nav>
                  <div className="container-full">
                    <button
                      type="button"
                      onClick={() => this.handleMaintenancePieceFormState()}
                    >
                      PEÇAS DE MANUTENÇÃO
                    </button>

                    <button
                      type="button"
                      onClick={() => this.handleMaintenanceEmployeeFormState()}
                    >
                      FUNCIONÁRIOS DE MANUTENÇÃO
                    </button>

                    <button
                      type="button"
                      onClick={() =>
                        this.handleMaintenanceToMaintenanceServiceFormState()
                      }
                    >
                      SERVIÇOS DE MANUTENÇÃO
                    </button>
                    <button
                      type="button"
                      onClick={() =>
                        this.handleMaintenanceToMaintenancePlanFreqFormState()
                      }
                    >
                      FREQUÊNCIAS DE MANUTENÇÃO
                    </button>
                  </div>
                </nav>

                <MaintenancePieceContainer
                  maintenance={maintenance}
                  maintenancePieces={maintenancePieces}
                  onMaintenancePiecesList={this.handleMaintenancePiecesList}
                  onMaintenancePieceSubmit={
                    this.handleMaintenancePieceFormState
                  }
                  maintenancePieceForm={maintenancePieceForm}
                  onMaintenancePieceEdit={this.handleEdit}
                />

                <MaintenanceEmployeeContainer
                  maintenance={maintenance}
                  maintenanceEmployees={maintenanceEmployees}
                  onMaintenanceEmployeesList={
                    this.handleMaintenanceEmployeesList
                  }
                  onMaintenanceEmployeeSubmit={
                    this.handleMaintenanceEmployeeFormState
                  }
                  maintenanceEmployeeForm={maintenanceEmployeeForm}
                  onMaintenanceEmployeeEdit={this.handleEdit}
                />

                <MaintanceToMaintenanceServiceContainer
                  maintenance={maintenance}
                  maintenanceToMaintenanceServices={
                    maintenanceToMaintenanceServices
                  }
                  maintenanceToMaintenanceServiceForm={
                    maintenanceToMaintenanceServiceForm
                  }
                  onMaintenanceToMaintenanceServiceSubmit={
                    this.handleMaintenanceToMaintenanceServiceFormState
                  }
                  onMaintenanceToMaintenanceServiceList={
                    this.handleMaintenanceToMaintenanceServicesList
                  }
                />

                <MaintenanceToMaintenancePlanFreqContainer
                  maintenance={maintenance}
                  maintenancePlanFreqs={maintenancePlanFreqs}
                  maintenancePlanFreq={maintenancePlanFreq}
                  maintenanceToMaintenancePlanFreqs={
                    maintenanceToMaintenancePlanFreqs
                  }
                  maintenanceToMaintenancePlanFreqForm={
                    maintenanceToMaintenancePlanFreqForm
                  }
                  onMaintenanceToMaintenancePlanFreqSubmit={
                    this.handleMaintenanceToMaintenancePlanFreqFormState
                  }
                  onMaintenanceToMaintenancePlanFreqList={
                    this.handleMaintenanceToMaintenancePlanFreqsList
                  }
                  onMaintenanceToMaintenancePlanFreqEdit={this.handleEdit}
                />
              </>
            )}
          </Content>
        </Container>
      </Wrapper>
    );
  }
}

export default MaintenanceContainer;
