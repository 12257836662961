import React from 'react';
import PropTypes from 'prop-types';

// Components
import SchedulerByEquipments from './schedulerByEquipments';
import SchedulerByEmployees from './schedulerByEmployees';

function Scheduler({ begin, end, equipments, toggle, onEdit,  holidays, extraDays, bases }) {

  return (
      toggle === 'equipments' ?
      <SchedulerByEquipments
        equipments={equipments}
        begin={begin}
        end={end}
        onEdit={onEdit}
        toggle={toggle}
        holidays={holidays}
        extraDays={extraDays}
        bases={bases}
      />
      :
      toggle === 'employees' &&
      <SchedulerByEmployees
        equipments={equipments}
        begin={begin}
        end={end}
        onEdit={onEdit}
        toggle={toggle}
        holidays={holidays}
        extraDays={extraDays}
        bases={bases}
      />
  );
}

Scheduler.propTypes = {
  begin: PropTypes.string,
  end: PropTypes.string,
  equipments: PropTypes.arrayOf(PropTypes.shape({})),
  toggle: PropTypes.string,
  onEdit: PropTypes.func.isRequired,
  holidays: PropTypes.arrayOf(PropTypes.shape({})),
  extraDays: PropTypes.arrayOf(PropTypes.shape({})),
};

Scheduler.defaultProps = {
  begin: null,
  end: null,
  equipments: [],
  toggle: 'equipments',
  holidays: [],
  extraDays: [],
  bases: [],
};

export default Scheduler;
