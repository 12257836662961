import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { SubContainer } from '../../../css/styles/wrapper';
import { toast } from 'react-toastify';
import MaintenanceToMaintenancePlanFreqForm from './form';
import MaintenanceToMaintenancePlanFreqList from './list';

import API from '../../../services/api';
import swal from 'sweetalert';

class MaintenanceToMaintenancePlanFreqContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      maintenanceToMaintenancePlanFreq: null,
      maintenanceToMaintenancePlanFreqs: [],
      maintenancePlanFreq: null,
      maintenancePlanFreqs: [],
      maintenancePlanFreqForm: false,
    };
  }

  refMaintenancePlanFreqs = React.createRef();

  scrolltoMyRef = myRef => {
    window.scrollTo(0, myRef.current.offsetTop);
  };

  // handleMaintenancePlanFreqFormState() {
  //   const { maintenancePlanFreqForm } = this.state;

  //   this.setState({
  //     maintenancePlanFreqForm: !maintenancePlanFreqForm,
  //   });
  // }

  getList = async () => {
    const { maintenance } = this.props;

    try {
      await API.get(`/maintenancetomaintenanceplanfreqs/${maintenance.uuid}`);
    } catch (err) {
      toast.error('Um erro aconteceu durante o carregamento!');
    }
  };

  handleCreation = async object => {
    const {
      maintenance,
      onMaintenanceToMaintenancePlanFreqSubmit,
      onMaintenanceToMaintenancePlanFreqList,
    } = this.props;

    const data = {
      observation: object.observation.toUpperCase(),
      maintenanceplanfreqUuid: object.maintenancePlanFreq.uuid,
      maintenanceUuid: maintenance.uuid,
    };

    try {
      await API.post('/maintenancetomaintenanceplanfreqs', { ...data });
      onMaintenanceToMaintenancePlanFreqList(maintenance.uuid);
      this.handleNew();
      toast.success('Frequência de manutenção criada com sucesso!');
      await onMaintenanceToMaintenancePlanFreqSubmit();
    } catch (err) {
      toast.error('Um erro aconteceu durante o cadastro');
    }
  };

  handleEdit = object => {
    this.setState({
      maintenanceToMaintenancePlanFreq: object,
      maintenancePlanFreq: null,
    });
  };

  handleNew = () => {
    this.setState({
      maintenanceToMaintenancePlanFreq: null,
      maintenancePlanFreq: null,
    });
  };

  handleUpdate = async object => {
    const {
      onMaintenanceToMaintenancePlanFreqSubmit,
      onMaintenanceToMaintenancePlanFreqList,
      maintenance,
    } = this.props;
    const { maintenanceToMaintenancePlanFreq } = this.state;

    const data = {
      observation: object.observation.toUpperCase() || null,
      maintenanceplanfreqUuid: object.maintenancePlanFreq.uuid || null,
      maintenanceUuid: maintenance.uuid || null,
    };

    try {
      await API.put(
        `/maintenancetomaintenanceplanfreqs/${maintenanceToMaintenancePlanFreq.uuid}`,
        { ...data }
      );

      onMaintenanceToMaintenancePlanFreqList(maintenance.uuid);
      this.handleNew();
      toast.success('Frequência de manutenção alterada com sucesso!');
      await onMaintenanceToMaintenancePlanFreqSubmit();
    } catch (err) {
      toast.error('Um erro aconteceu durante o cadastro');
    }
  };

  handleDelete = async object => {
    const {
      onMaintenanceToMaintenancePlanFreqSubmit,
      onMaintenanceToMaintenancePlanFreqList,
      maintenance,
    } = this.props;

    try {
      const confirmDelete = await swal({
        dangerMode: true,
        title: 'Deseja excluir esta frequência de manutenção?',
        buttons: {
          cancel: 'Não',
          confirm: 'Sim',
        },
      });

      if (confirmDelete) {
      await API.delete(`/maintenancetomaintenanceplanfreqs/${object.uuid}`);

      onMaintenanceToMaintenancePlanFreqList(maintenance.uuid);
      this.handleNew();
      toast.success('Frequência de manutenção deletada com sucesso!');
      await onMaintenanceToMaintenancePlanFreqSubmit();
      }
    } catch (err) {
      toast.error('Um erro aconteceu durante a exclusão!');
    }
  };

  handleMaintenancePlanFreqsList = (
    maintenancePlanFreqs,
    maintenancePlanFreq
  ) => {
    this.setState({
      maintenancePlanFreqs,
      maintenancePlanFreq,
      maintenanceToMaintenancePlanFreq: null,
    });

    console.log(maintenancePlanFreqs);
  };
  render() {
    const {
      maintenanceToMaintenancePlanFreqs,
      maintenanceToMaintenancePlanFreqForm,
      maintenancePlanFreqs,
      maintenancePlanFreq,
      
    } = this.props;

    const { maintenanceToMaintenancePlanFreq } = this.state;

    return (
      <SubContainer visible={maintenanceToMaintenancePlanFreqForm}>
        <div ref={this.refMaintenancePlanFreqs} />
        <nav>
          <h1>FREQUENCIAS DE MANUTENÇÃO</h1>
        </nav>

        <MaintenanceToMaintenancePlanFreqList
          onEdit={this.handleEdit}
          onDelete={this.handleDelete}
          maintenanceToMaintenancePlanFreqs={maintenanceToMaintenancePlanFreqs}
          maintenanceToMaintenancePlanFreq={maintenanceToMaintenancePlanFreq}
          onMaintenancePlanFreqsList={this.handleMaintenancePlanFreqsList}
          maintenancePlanFreqs={maintenancePlanFreqs}
          maintenancePlanFreq={maintenancePlanFreq}
        />
        <MaintenanceToMaintenancePlanFreqForm
          onUpdate={this.handleUpdate}
          onNew={this.handleNew}
          onCreation={this.handleCreation}
          maintenanceToMaintenancePlanFreq={maintenanceToMaintenancePlanFreq}
          maintenanceToMaintenancePlanFreqs={maintenanceToMaintenancePlanFreqs}
          maintenancePlanFreqs={maintenancePlanFreqs}
          maintenancePlanFreq={maintenancePlanFreq}
        />
      </SubContainer>
    );
  }
}

MaintenanceToMaintenancePlanFreqContainer.propTypes = {
  maintenance: PropTypes.shape({
    uuid: PropTypes.string,
  }).isRequired,
  maintenanceToMaintenancePlanFreqs: PropTypes.arrayOf(
    PropTypes.shape({
      uuid: PropTypes.string,
      observation: PropTypes.string,
      maintenancePlanFreq: PropTypes.shape({
        uuid: PropTypes.string,
        frequency: PropTypes.string,
      }),
      maintenance: PropTypes.shape({
        uuid: PropTypes.string,
      }),
    })
  ),
  maintenanceToMaintenancePlanFreqForm: PropTypes.bool.isRequired,
  onMaintenanceToMaintenancePlanFreqList: PropTypes.func.isRequired,
  onMaintenanceToMaintenancePlanFreqSubmit: PropTypes.func.isRequired,
};

export default MaintenanceToMaintenancePlanFreqContainer;

