import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { IoMdTrash } from 'react-icons/io';

// Styles
import { ListStyled, ListItem } from "../../css/styles/list";

const listItem = props => props.holidays.map(item => (
  <ListItem
    color="#011826"
    strongColor="#fff"
    key={item.uuid}
    onClick={() => props.onEdit(item)}
  >
    <div className="info">
      <div>
        <strong className="title">DESCRIÇÃO: </strong>
        <strong>{item.description}</strong>
      </div>

      <div>
        <strong className="title">DATA: </strong>
        <strong>{moment(item.date).format('DD/MM/YYYY')}</strong>
      </div>
    </div>

    <button
      type="button"
      onClick={e => {
        e.stopPropagation();

        props.onDelete(item.uuid);
      }}
      className="delete"
    >
      <IoMdTrash size={20} color="#bf1523" />
    </button>
  </ListItem>
));

function holidayList(props) {
  const { holidays } = props;

  return (
    <>
      {holidays && holidays.length > 0 ? (
        <ListStyled>{listItem(props)}</ListStyled>
      ) : (
        <strong>LISTA VAZIA</strong>
      )}
    </>
  );
}

holidayList.propTypes = {
  holidays: PropTypes.arrayOf(
    PropTypes.shape({
      uuid: PropTypes.string,
      description: PropTypes.string,
      date: PropTypes.string,
    }),
  ),
}

holidayList.defaultProps = {
  holidays: [],
}

export default holidayList;
