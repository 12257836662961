import React from 'react';
import moment from 'moment';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import Select from 'react-select';

import {
  FilterFormStyled,
  FilterRow,
  FilterColumn,
} from '../../css/styles/filter';
import { SelectStyled } from '../../css/styles/select';

const handleValidate = ({ employee }) => {
  const errors = {};

  // if (!employee) errors.employee = 'OBRIGATÓRIO';
  // return errors;
};

const handleSubmit = props => async (values, { setSubmitting, resetForm }) => {
  await props.onList({ ...values });

  setSubmitting(false);
  resetForm();
};

const EmployeeFilterForm = props => {
  const { onNew, onDelete, employee, employees, document, telephone, cnh } = props;
  return (
    <Formik
      enableReinitialize
      initialValues={{
        employee: employee ? employee.uuid : '',
        document: document ?? '',
        telephone: telephone ?? '',
        cnh: cnh ?? '',
      }}
      validate={handleValidate}
      onSubmit={handleSubmit(props)}
    >
      {({
        values,
        handleChange,
        handleBlur,
        handleReset,
        errors,
        touched,
        setFieldValue,
        isSubmitting,
        dirty,
      }) => (
        <FilterFormStyled>
          <h1>FILTROS</h1>

          <FilterRow>
            <FilterColumn>
              <strong>EMPREGADO</strong>
              <Select
                name="employee"
                value={employees.find(
                  item => item.uuid === values.employee || null
                )}
                placeholder="Selecione um empregado"
                onChange={e => setFieldValue('employee', e) }
                onBlur={handleBlur}
                styles={SelectStyled}
                options={employees}
                isClearable={true}
                formatOptionLabel={(item, { context }) =>
                  context === 'menu' ? (
                    <div
                      style={{
                        ...SelectStyled,
                        display: 'flex',
                        justifyContent: 'space-between',
                        width: '100%',
                      }}
                    >
                      <div>{item.name}</div>
                      <div>{`${item.document ??
                        'DOCUMENTO NÃO CADASTRADO'}`}</div>
                    </div>
                  ) : (
                    item.name
                  )
                }
                components={{
                  IndicatorSeparator: () => null,
                }}
                getOptionLabel={item => (
                  <div
                    style={{
                      ...SelectStyled,
                      display: 'flex',
                      justifyContent: 'space-between',
                      width: '100%',
                    }}
                  >
                    <div>{item.name}</div>
                    <div>{`${item.document ??
                      'DOCUMENTO NÃO CADASTRADO'}`}</div>
                  </div>
                )}
                getOptionValue={item => item.name}
                loadingMessage={() => 'Buscando empregados'}
                noOptionsMessage={() => 'Não há empregados para exibir'}
              />
              {/* {touched.employee && errors.employee && (
                <div>{errors.employee}</div>
              )} */}
            </FilterColumn>
          </FilterRow>
          <FilterRow>
            <FilterColumn>
              <strong>DOCUMENTO</strong>
              <input
                id="document"
                name="document"
                type="text"
                value={values.document}
                placeholder='Digite o documento do empregado'
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </FilterColumn>
          </FilterRow>
          <FilterRow>
            <FilterColumn>
              <strong>TELEFONE</strong>
              <input
                id="telephone"
                name="telephone"
                type="text"
                value={values.telephone}
                placeholder='Digite o telefone do empregado'
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </FilterColumn>
          </FilterRow>
          <FilterRow>
            <FilterColumn>
              <strong>CNH</strong>
              <input
                id="cnh"
                name="cnh"
                type="text"
                value={values.cnh}
                placeholder='Digite a CNH do empregado'
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </FilterColumn>
          </FilterRow>

          <FilterRow>
            <div className="buttons-container">
              <button type="submit" disabled={isSubmitting}>
                BUSCAR
              </button>

              <button
                type="button"
                onClick={handleReset}
                disabled={!dirty || isSubmitting}
              >
                RESET
              </button>
            </div>
          </FilterRow>
        </FilterFormStyled>
      )}
    </Formik>
  );
};

EmployeeFilterForm.propTypes = {
  onList: PropTypes.func.isRequired,
  employees: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default EmployeeFilterForm;

