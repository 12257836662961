import React from 'react';
import PropTypes from 'prop-types';

// Styles
import { ListStyled, ListItem } from '../../../css/styles/list';

const listItem = props =>
  props.installments.map(installment => (
    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
    <ListItem
      color="#011826"
      strongColor="#fff"
      key={installment.uuid}
      onClick={() => props.onEdit(installment)}
    >
      <div className="info">
        <div>
          <strong className="title">VALOR:</strong>
          <strong>
            {installment.value.toLocaleString('pt-BR', {
              minimumFractionDigits: 2,
            })}
          </strong>
        </div>

        <div>
          <strong className="title">VENCIMENTO:</strong>
          <strong>
            {new Date(installment.due_date)
              .toLocaleString('pt-BR', {
                timeZone: 'UTC',
              })
              .substring(0, 10)}
          </strong>
        </div>

        <div>
          <strong className="title">VALOR PAGO:</strong>
          <strong>
            {installment.paid_value
              ? installment.paid_value.toLocaleString('pt-BR', {
                  minimumFractionDigits: 2,
                })
              : ''}
          </strong>
        </div>

        <div>
          <strong className="title">DATA PAGAMENTO:</strong>
          <strong>
            {installment.payment_date
              ? new Date(installment.payment_date)
                  .toLocaleString('pt-BR', {
                    timeZone: 'UTC',
                  })
                  .substring(0, 10)
              : ''}
          </strong>
        </div>
      </div>
    </ListItem>
  ));

function InstallmentList(props) {
  const { installments } = props;

  return (
    <>
      {installments && installments.length > 0 ? (
        <ListStyled>
          <header>
            <div className="total">
              <h1>TOTAL:</h1>
              <p>{installments.length}</p>
            </div>
          </header>

          {listItem(props)}
        </ListStyled>
      ) : (
        <strong>LISTA VAZIA</strong>
      )}
    </>
  );
}

InstallmentList.propTypes = {
  installments: PropTypes.arrayOf(
    PropTypes.shape({
      uuid: PropTypes.string,
      value: PropTypes.number,
      due_date: PropTypes.string,
      paid_value: PropTypes.number,
      payment_date: PropTypes.string,
    })
  ),
};

InstallmentList.defaultProps = {
  installments: [],
};

export default InstallmentList;
