import React from 'react';
import moment from 'moment';
import { IoMdTrash } from 'react-icons/io';

import PropTypes from 'prop-types';

import { ListStyled, ListItem } from '../../../css/styles/list';

const listItem = props =>
  props.maintenancePlanFreqs.map(item => (
    <ListItem
      color="#011826"
      strongColor="#fff"
      key={item.uuid}
      onClick={() => props.onEdit(item)}
    >
      
      <div className="info">
        <div>
          <strong className="title">DATA DE CRIAÇÃO: </strong>
          <strong>{moment(item.createdAt).format('DD/MM/YYYY')}</strong>
        </div>
        <div>
          <strong className="title">FREQUÊNCIA: </strong>
          <strong>{item.frequency}</strong>
        </div>

        <div>
          <strong className="title">OBSERVAÇÃO: </strong>
          <strong>{item.observation}</strong>
        </div>
      </div>
      <button
        type="button"
        onClick={e => {
          e.stopPropagation();

          props.onDelete(item.uuid);
        }}
        className="delete"
      >
        <IoMdTrash size={20} color="#bf1523" />
      </button>
    </ListItem>
  ));

function MaintenancePlanFreqsList(props) {
  const { maintenancePlanFreqs } = props;
console.log(maintenancePlanFreqs,'aquiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiii')
  return (
    <>
      {maintenancePlanFreqs && maintenancePlanFreqs.length > 0 ? (
        <ListStyled>{listItem(props)}</ListStyled>
      ) : (
        <strong>LISTA VAZIA</strong>
      )}
    </>
  );
}

MaintenancePlanFreqsList.propTypes = {
  maintenancePlanFreqs: PropTypes.arrayOf(
    PropTypes.shape({
      uuid: PropTypes.string,
      maintenancePlanUuid: PropTypes.string,
      frequency: PropTypes.string,
      observation: PropTypes.string,
    })
  ),
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

MaintenancePlanFreqsList.defaultProps = {
  maintenancePlanFreqs: [],
};

export default MaintenancePlanFreqsList;

