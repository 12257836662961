import styled from 'styled-components';

import { Form } from 'formik';

export const FilterFormStyled = styled(Form)`
  width: 100%;

  max-width: 900px;

  padding: 32px 64px;

  margin-bottom: 16px;

  box-sizing: border-box;

  border-radius: 4px;

  -webkit-box-shadow: 0px 6px 14px -2px rgba(0, 0, 0, 0.34);

  -moz-box-shadow: 0px 6px 14px -2px rgba(0, 0, 0, 0.34);

  box-shadow: 0px 6px 14px -2px rgba(0, 0, 0, 0.34);

  background: #fff;

  z-index: 3;

  hr {
    height: 1px;

    width: 100%;

    background: #ddd;

    margin: 32px 0;

    border: 0;
  }

  h1 {
    color: #000;

    font-size: 20px;

    font-weight: bold;
  }

  ul {
    display: flex;

    flex-direction: column;

    align-items: center;

    justify-content: center;
  }

  @media screen and (max-width: 768px) {
    padding: 24px;
  }

  @media screen and (max-width: 520px) {
    h1 {
      text-align: center;
    }
  }
`;

export const FilterRow = styled.li`
  width: 100%;

  display: flex;

  align-items: start;

  justify-content: center;

  gap: 16px;

  .buttons-container {
    display: flex;
    gap: 16px;
  }

  button {
    height: 40px;

    min-width: 132px;

    border-radius: 4px;

    padding: 0 15px;

    box-sizing: border-box;

    background: #59d99d;

    font-size: 16px;

    font-weight: bold;

    color: #fff;

    margin-top: 32px;

    &:hover {
      background: rgba($color: #59d99d, $alpha: 0.2);
    }

    &.delete {
      background: #bf1523;

      background: rgba($color: '#bf1523', $alpha: 0.2);
    }

    &:disabled {
      background: #ddd;

      color: #999;

      cursor: default;
    }
  }

  @media screen and (max-width: 768px) {
    .buttons-container {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 16px;
      margin-top: 16px;

      button {
        width: 100%;
        margin-top: 0;
      }
    }
  }

  @media screen and (max-width: 520px) {
    flex-direction: column;
    align-items: center;

    .buttons-container {
      display: flex;
      flex-direction: column;
      gap: 16px;

      button {
        width: 100%;
        margin-top: 0;
      }
    }

    button {
      width: 100%;
    }
  }
`;

export const FilterColumn = styled.div`
  width: 100%;

  display: flex;

  flex-direction: column;

  align-items: flex-start;

  justify-content: flex-start;

  margin-top: 32px;

  padding: 0;

  strong {
    font-size: 14px;

    color: #000;

    margin-bottom: 16px;
  }

  fieldset {
    display: flex;
    justify-content: start;
    gap: 0.5rem;
    align-items: center;

    input {
      width: 24px;
      height: 24px;
    }
  }

  input {
    box-sizing: border-box;

    width: 100%;

    height: 40px;

    border: 1px solid #999;

    border-radius: 4px;

    font-size: 16px;

    padding: 0 15px;

    color: #333;

    display: flex;

    &:disabled {
      background: none;

      color: #999;

      cursor: default;
    }
  }

  textarea {
    box-sizing: border-box;

    width: 100%;

    height: 40px;

    border: 1px solid #999;

    border-radius: 4px;

    font-size: 16px;

    color: #333;

    padding: 15px 0 0 15px;

    font-size: 16px;

    color: #333;

    outline: none;

    font-family: Arial, Helvetica, sans-serif;

    &:disabled {
      background: none;

      color: #999;

      cursor: default;
    }
  }

  select {
    box-sizing: border-box;

    width: 100%;

    height: 40px;

    background: #fff;

    border: 1px solid #999;

    border-radius: 4px;

    font-size: 16px;

    color: #333;

    padding: 0 15px;

    &:disabled {
      background: none;

      background: #ddd;

      cursor: default;
    }
  }

  span {
    margin-top: 8px;

    color: #bf1523;

    font-size: 14px;

    font-weight: bold;
  }

  @media screen and (max-width: 520px) {
    margin-top: 16px;
  }
`;
