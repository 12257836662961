import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import Select from 'react-select';

// Styles
import { FormStyled, Row, Column } from '../../css/styles/form';
import { SelectStyled } from '../../css/styles/select';

const handleValidate = ({ name }) => {
  const errors = {};
  if (!name) errors.name = 'OBRIGATÓRIO';
  return errors;
};

const handleSubmit = props => async (values, { setSubmitting, resetForm }) => {
  if (!props.employee) {
    await props.onCreation({ ...values });
  } else {
    await props.onUpdate({ ...values });
  }
  setSubmitting(false);
  resetForm();
};

const EmployeeForm = props => {
  const {
    onDelete,
    onNew,
    onEdit,
    employee,
    employees,
    team,
    employeeList,
  } = props;
  const authPayment =
    team === 'GERENCIA' || team === 'FINANCIA' || team === 'PAGAMENTO';

  return (
    <Formik
      enableReinitialize
      initialValues={{
        name: employee ? employee.name : '',
        telephone: employee ? employee.telephone : '',
        cnh: employee ? employee.cnh : '',
        address: employee ? employee.address : '',
        nickname: employee ? employee.nickname : '',
        document: employee ? employee.document : '',
        account: employee ? employee.account : '',
        account_verifying_digit: employee
          ? employee.account_verifying_digit
          : '',
        branch: employee ? employee.branch : '',
        bank: employee ? employee.bank : '',
        key: employee ? employee.key : '',
      }}
      validate={handleValidate}
      onSubmit={handleSubmit(props)}
    >
      {({
        values,
        handleChange,
        handleBlur,
        handleReset,
        errors,
        touched,
        setFieldValue,
        isSubmitting,
        dirty,
      }) => (
        <FormStyled>
          <h1>NOVO FUNCIONÁRIO</h1>

          <ul>
            <Row>
              <Column>
                <strong>NOME</strong>
                <input
                  id="name"
                  name="name"
                  type="text"
                  value={values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />

                {errors.name && touched.name && <span>{errors.name}</span>}
              </Column>
            </Row>
            <Row>
              <Column>
                <strong>NOME SOCIAL</strong>
                <input
                  id="nickname"
                  name="nickname"
                  type="text"
                  value={values.nickname}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />

                {errors.nickname && touched.nickname && (
                  <span>{errors.nickname}</span>
                )}
              </Column>
            </Row>
            <Row>
              <Column>
                <strong>TELEFONE</strong>
                <input
                  id="telephone"
                  name="telephone"
                  type="text"
                  value={values.telephone}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />

                {errors.telephone && touched.telephone && (
                  <span>{errors.telephone}</span>
                )}
              </Column>
            </Row>
            <Row>
              <Column>
                <strong>CNH</strong>
                <input
                  id="cnh"
                  name="cnh"
                  type="text"
                  value={values.cnh}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />

                {errors.cnh && touched.cnh && <span>{errors.cnh}</span>}
              </Column>
            </Row>
            <Row>
              <Column>
                <strong>ENDEREÇO</strong>
                <input
                  id="address"
                  name="address"
                  type="text"
                  value={values.address}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />

                {errors.address && touched.address && (
                  <span>{errors.address}</span>
                )}
              </Column>
            </Row>
            {authPayment && (
              <>
                <Row>
                  <Column>
                    <strong>CPF</strong>
                    <input
                      id="document"
                      name="document"
                      type="text"
                      value={values.document}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      maxLength={14}
                    />

                    {errors.document && touched.document && (
                      <span>{errors.document}</span>
                    )}
                  </Column>
                </Row>
                <Row>
                  <Column>
                    <strong>CONTA</strong>
                    <input
                      id="account"
                      name="account"
                      type="text"
                      value={values.account}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      maxLength={12}
                    />

                    {errors.account && touched.account && (
                      <span>{errors.account}</span>
                    )}
                  </Column>

                  <Column>
                    <strong>DIGITO VERIFICADOR DA CONTA</strong>
                    <input
                      id="account_verifying_digit"
                      name="account_verifying_digit"
                      type="text"
                      value={values.account_verifying_digit}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      maxLength={1}
                    />

                    {errors.account_verifying_digit &&
                      touched.account_verifying_digit && (
                        <span>{errors.account_verifying_digit}</span>
                      )}
                  </Column>
                </Row>
                <Row>
                  <Column>
                    <strong>AGÊNCIA</strong>
                    <input
                      id="branch"
                      name="branch"
                      type="text"
                      value={values.branch}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      maxLength={5}
                    />

                    {errors.branch && touched.branch && (
                      <span>{errors.branch}</span>
                    )}
                  </Column>

                  <Column>
                    <strong>BANCO</strong>
                    <input
                      id="bank"
                      name="bank"
                      type="text"
                      value={values.bank}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      maxLength={3}
                    />

                    {errors.bank && touched.bank && <span>{errors.bank}</span>}
                  </Column>
                </Row>
                <Row>
                  <Column>
                    <strong>CHAVE DE IDENTIFICAÇÃO</strong>
                    <input
                      id="key"
                      name="key"
                      type="text"
                      value={values.key}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      maxLength={20}
                    />

                    {errors.key && touched.key && <span>{errors.key}</span>}
                  </Column>
                </Row>
              </>
             )}

            <Row>
              <div className="buttons-container">
                <button type="submit" disabled={isSubmitting}>
                  SALVAR
                </button>

                <button
                  type="button"
                  onClick={handleReset}
                  disabled={!dirty || isSubmitting}
                >
                  RESET
                </button>

                {employee && (
                  <>
                    <button type="button" onClick={onNew}>
                      LIMPAR
                    </button>
                    {!employeeList && (
                      <button
                        className="delete"
                        type="button"
                        onClick={onDelete}
                      >
                        EXCLUIR
                      </button>
                    )}
                  </>
                )}
              </div>
            </Row>
          </ul>

          {!employeeList && (
            <>
              <hr />
              <ul>
                <Row>
                  <Column>
                    <strong>FUNCIONÁRIO</strong>
                    <Select
                      styles={SelectStyled}
                      id="employee"
                      value={values.employee}
                      placeholder="Selecione um funcionário"
                      onChange={selectedOption => {
                        setFieldValue('employee', selectedOption);
                        onEdit(selectedOption);
                      }}
                      isSearchable
                      options={employees}
                      getOptionLabel={item => item.name}
                      getOptionValue={item => item.uuid}
                      name="employee"
                      isLoading={false}
                      components={{
                        IndicatorSeparator: () => null,
                      }}
                      loadingMessage={() => 'Buscando funcionários'}
                      noOptionsMessage={() => 'Não há funcionários cadastrados'}
                    />
                  </Column>
                </Row>
              </ul>
            </>
          )}
        </FormStyled>
      )}
    </Formik>
  );
};

EmployeeForm.propTypes = {
  employees: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      uuid: PropTypes.string,
    })
  ),
  employee: PropTypes.shape({
    name: PropTypes.string,
    telephone: PropTypes.string,
    cnh: PropTypes.string,
    address: PropTypes.string,
    nickname: PropTypes.string,
    document: PropTypes.string,
    account: PropTypes.string,
    account_verifying_digit: PropTypes.string,
    branch: PropTypes.string,
    bank: PropTypes.string,
    key: PropTypes.string,
  }),
  onDelete: PropTypes.func.isRequired,
  onNew: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
};

EmployeeForm.defaultProps = {
  employees: [],
  employee: null,
};

export default EmployeeForm;
