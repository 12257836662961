import React, { Component } from 'react';
import moment from 'moment';
import { toast } from 'react-toastify';
import API from '../../services/api';
import PaymentEmployeeForm from './paymentEmployeeForm';
import PaymentForm from './paymentForm';
import PaymentEmployeeList from './list';

// Styles
import { Wrapper, Container, Content } from '../../css/styles/wrapper';
import EmployeeContainer from '../Employee';

class PaymentContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      paymentCreditHistoryCode: null,
      paymentEntryForm: null,
      paymentPurpose: null,
      paymentTransactionType: null,
      paymentEmployee: null,
      paymentEmployees: [],
      paymentDate: moment().add(1, 'day').format('YYYY-MM-DD'),
      sequentialFileNumber: '',
      employees: [],
      employee: null,
      downloadLink: '',
      downloadRelesed: false,
      employeeForm: false,
      paymentList: [],
      employeeInformationIsComplete: false,
    };

  };

  refPayment = React.createRef();

  componentDidMount() {
    this.getList();
    this.getPaymentList();
  };

  handlePaymentEmployeeList = ({paymentEmployee, paymentValue}) => {
    paymentValue = parseFloat(paymentValue.replace(',', '.')).toFixed(2);
    paymentEmployee['paymentValue'] = paymentValue;

    this.setState({
      paymentEmployees: [...this.state.paymentEmployees, paymentEmployee],
      paymentEmployee: null,
      employees: [...this.state.employees.filter(item => item.uuid !== paymentEmployee.uuid)],
    });

  };

  handleRemove = (uuid) => {
    const { paymentEmployees } = this.state;

    this.setState({
      employees: [...this.state.employees, paymentEmployees.find(item => item.uuid === uuid)],
      paymentEmployees: paymentEmployees.filter(item => item.uuid !== uuid),
    });
  };

  strFormatter = (str) => {
    let formated_str = str.normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .replace(/[^\w\s]/gi, '');

    return formated_str;
  }

  handleGenerateFile = async ({
    paymentCreditHistoryCode,
    paymentEntryForm,
    paymentPurpose,
    paymentTransactionType,
    paymentDate,
  }) => {

    try {
      this.setState({
        downloadRelesed: false,
      });

      await this.getPaymentList();

      const paymentListLength = this.state.paymentList.length;

      this.setState({
        sequentialFileNumber: paymentListLength > 0 ?
          `${
            Number(
              this.state.paymentList[this.state.paymentList.length - 1].file_key
            ) + 1
          }`
          : '415',
      });

      const data = {
        company: {
          company_account_holder_branch: '3044',
          company_account_verifying_digit: '7',
          company_address: {
            company_address_street: 'RODOVIA PRESIDENTE DUTRA KM 26',
            company_address_number: '108',
            company_address_complement: '',
            company_address_city: 'BARRA MANSA',
            company_address_zip_code: '27338',
            company_address_zip_code_complement: '000',
            company_address_state: 'RJ'
          },
          company_bank_agreement_code: '00333044008300010218',
          company_branch_verifying_digit: '9',
          company_current_account_number: '13001359',
          company_document: '31187412000106',
          company_document_type: '2',
          company_name: 'SERVI-SA AUTO GUINDASTE LOCACACAO',
          company_reserved_use: ''
        },
        payment: {
          payment_credit_history_code: paymentCreditHistoryCode.label.substring(0, 4),
          payment_date: moment(paymentDate).format('DDMMYYYY'),
          payment_due_date: moment(paymentDate).format('DDMMYYYY'),
          payment_real_date: moment(paymentDate).format('DDMMYYYY'),
          payment_entry_form: paymentEntryForm.value,
          payment_purpose: paymentPurpose.value,
          payment_transaction_instruction_code: '00',
          payment_transaction_type: paymentTransactionType.value,
        },
        file: {
          file_sequential_number: this.state.sequentialFileNumber
        },
        transfers: this.state.paymentEmployees.map(employee => ({
          beneficiary_bank_code: employee.bank,
          beneficiary_branch: employee.branch,
          beneficiary_account: employee.account,
          beneficiary_account_verifying_digit: employee.account_verifying_digit,
          beneficiary_name: this.strFormatter(employee.name),
          beneficiary_key: employee.key,
          beneficiary_amount: employee.paymentValue,
          beneficiary_real_amount: employee.paymentValue,
          beneficiary_other_information: '',       //--------OPCIONAL--------
          beneficiary_document_type: '1',
          beneficiary_document: employee.document.replace(/[^0-9]/g, ''),
          beneficiary_rebate_amount: '',           //--------OPCIONAL--------
          beneficiary_late_payment_amount: '',     //--------OPCIONAL--------
          beneficiary_address: {
            beneficiary_address_street: '',        //--------OPCIONAL--------
            beneficiary_address_number: '',        //--------OPCIONAL--------
            beneficiary_address_complement: '',    //--------OPCIONAL--------
            beneficiary_address_neighborhood: '',  //--------OPCIONAL--------
            beneficiary_address_city: '',          //--------OPCIONAL--------
            beneficiary_address_zip_code: '',      //--------OPCIONAL--------
            beneficiary_address_state: ''          //--------OPCIONAL--------
          }
        }))
      };

      const response = await API.post('/cnab', data);

      this.setState({
        downloadLink: response.data.message,
      });

      await API.post('/paymentbatch', {
        type: paymentCreditHistoryCode.label,
        payment_date: paymentDate,
        file_key: this.state.sequentialFileNumber,
        download_link: this.state.downloadLink
      });

      this.setState({
        downloadRelesed: true,
        paymentCreditHistoryCode: paymentCreditHistoryCode,
        paymentEntryForm: paymentEntryForm,
        paymentPurpose: paymentPurpose,
        paymentTransactionType: paymentTransactionType,
        paymentDate: paymentDate,
      });

    } catch (err) {
      toast.error('Um erro aconteceu durante a geração do arquivo!');
    } finally {
      this.setState({
        sequentialFileNumber: '',
      });

      this.getList();
    }
  };

  getList = async () => {
    try {
      const response = await API.get('/employees');
      this.setState({
        employees: response.data
      });
    } catch (err) {
      toast.error('Um erro aconteceu durante o carregamento!');
    };
  };

  getPaymentList = async () => {
    try {
      const response = await API.get('/paymentbatch');

      this.setState({
        paymentList: response.data
      });
    } catch (err) {
      toast.error('Um erro aconteceu durante o carregamento!');
    };
  };

  handleDownload = () => {
    const { downloadLink } = this.state;
    window.open(downloadLink, '_blank');
  };

  handleEmployeesList = (employees, employee) => {
    this.setState({
      employees,
      employee,
      paymentEmployee: null,
    });
  };

  handlePaymentEmployee = paymentEmployee => {
    const requiredFields = [
      'name',
      'document',
      'account',
      'account_verifying_digit',
      'branch',
      'bank',
      'key'
    ];

    const informationIsComplete = requiredFields.every(field => paymentEmployee[field] !== null);

    if (!informationIsComplete) toast.error('O funcionário informado não possui todos os dados!');

    this.setState({
      paymentEmployee,
      employeeInformationIsComplete: informationIsComplete,
    });
  }

  handleEmployeeFormState() {
    const { employeeForm } = this.state;

    this.setState({
      employeeForm: !employeeForm,
      equipmentForm: false,
      partnerForm: false,
    });
  }

  scrollToMyRef = myRef => {
    window.scrollTo(0, myRef.current.offsetTop);
  };

  handleNew = () => {
    this.setState({
      downloadRelesed: true,
      paymentCreditHistoryCode: null,
      paymentEntryForm: null,
      paymentPurpose: null,
      paymentTransactionType: null,
      paymentDate: null,
      paymentEmployees: [],
    });

    this.scrollToMyRef(this.refPayment);
  };

  render() {
    const {
      loading,
      paymentCreditHistoryCode,
      paymentEntryForm,
      paymentPurpose,
      paymentTransactionType,
      paymentEmployee,
      paymentEmployees,
      paymentDate,
      employees,
      downloadLink,
      downloadRelesed,
      employeeForm,
      employeeInformationIsComplete,
    } = this.state;

    return (
      <Wrapper>
        <Container>
          <header>
            <h1>PAGAMENTO FUNCIONÁRIOS</h1>
            <button type="button" onClick={this.handleNew}>
              NOVO
            </button>
          </header>

          <Content>

            <div ref={this.refPayment} />

            <PaymentForm
              onCreation={this.handleGenerateFile}
              onDownload={this.handleDownload}
              paymentCreditHistoryCode={paymentCreditHistoryCode}
              paymentEntryForm={paymentEntryForm}
              paymentPurpose={paymentPurpose}
              paymentTransactionType={paymentTransactionType}
              paymentEmployees={paymentEmployees}
              paymentDate={paymentDate}
              downloadLink={downloadLink}
              downloadRelesed={downloadRelesed}
              loading={loading}
            />

            <nav className="center">
              <div>
                <button
                  type="button"
                  onClick={() => this.handleEmployeeFormState()}
                >
                  FUNCIONÁRIO
                </button>
              </div>
            </nav>

            <EmployeeContainer
              onEmployeesList={this.handleEmployeesList}
              onEmployeeSubmit={() => this.handleEmployeeFormState()}
              employeeForm={employeeForm}
              team={this.props.team}
            />

            <PaymentEmployeeForm
              onCreation={this.handlePaymentEmployeeList}
              handlePaymentEmployee={this.handlePaymentEmployee}
              paymentEmployee={paymentEmployee}
              employees={employees}
              employeeInformationIsComplete={employeeInformationIsComplete}
            />

            <PaymentEmployeeList
              paymentEmployees={paymentEmployees}
              onRemove={this.handleRemove}
            />

          </Content>
        </Container>
      </Wrapper>
    );
  };

};

export default PaymentContainer;
