import React from 'react';
import PropTypes from 'prop-types';

// Styles
import {
  ListStyled,
  ListItem,
  Legend,
  LegendItem,
} from '../../css/styles/list';

const listColor = (listItem, type) => {
  let color = '#333';

  if (type === 'A RECEBER') {
    if (listItem.payment_date) {
      color = '#C2FFDC';
    } else {
      color = '#8BC44A';
    }
  } else if (listItem.payment_date) {
    color = '#F86660';
  } else {
    color = '#F22727';
  }

  return color;
};

const listItem = props =>
  props.financialItems.map(item => (
    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
    <ListItem
      className="payment"
      color={listColor(item, item.financial.type)}
      strongColor={props.accountFilter ? '#fff' : '#000'}
      key={item.uuid}
      onClick={() => props.onEdit(item)}
    >
      <div className="info">
        <div>
          <strong className="title">DATA:</strong>
          <strong>
            {new Date(item.due_date)
              .toLocaleString('pt-BR', {
                timeZone: 'UTC',
              })
              .substring(0, 10)}
          </strong>
        </div>

        <div>
          <strong className="title">PARCEIRO:</strong>
          <strong>{item.financial.partner.name}</strong>
        </div>

        <div>
          <strong className="title">DOCUMENTO:</strong>
          <strong>{item.financial.number}</strong>
        </div>

        <div>
          <strong className="title">VALOR:</strong>
          <strong>
            {item.paid_value || item.value
              ? (item.paid_value || item.value).toLocaleString('pt-BR', {
                  minimumFractionDigits: 2,
                })
              : ''}
          </strong>
        </div>

        <div>
          <strong className="title">PAGAMENTO:</strong>
          <strong>
            {item.payment_date
              ? new Date(item.payment_date)

                  .toLocaleString('pt-BR', {
                    timeZone: 'UTC',
                  })

                  .substring(0, 10)
              : ''}
          </strong>
        </div>

        <div>
          <strong className="title">Nº:</strong>
          <strong>{item.item || ''}</strong>
        </div>
      </div>
    </ListItem>
  ));

function FinancialList(props) {
  const { financialItems } = props;

  return (
    <>
      {financialItems && financialItems.length > 0 ? (
        <>
          <ListStyled>
            <header>
              <Legend>
                <li>
                  <LegendItem color="#F22727" />
                  <p>A pagar</p>
                </li>
                <li>
                  <LegendItem color="#F86660" />
                  <p>Pagas</p>
                </li>
                <li>
                  <LegendItem color="#8BC44A" />
                  <p>A receber</p>
                </li>
                <li>
                  <LegendItem color="#C2FFDC" />
                  <p>Recebidas</p>
                </li>
              </Legend>
              <div className="total">
                <h1>TOTAL:</h1>
                <p>{financialItems.length}</p>
              </div>
            </header>

            {listItem(props)}
          </ListStyled>
        </>
      ) : (
        <strong>LISTA VAZIA</strong>
      )}
    </>
  );
}

FinancialList.propTypes = {
  financialItems: PropTypes.arrayOf(
    PropTypes.shape({
      uuid: PropTypes.string,
      due_date: PropTypes.string,
      paid_value: PropTypes.string,
      value: PropTypes.number,
      payment_date: PropTypes.string,
      financial: PropTypes.shape({
        number: PropTypes.string,
        type: PropTypes.string,
        partner: PropTypes.shape({
          name: PropTypes.string,
        }),
      }),
    })
  ),
};

FinancialList.defaultProps = {
  financialItems: [],
};

export default FinancialList;
