import React from 'react';
import { IoIosRemoveCircle } from "react-icons/io";

import PropTypes from 'prop-types';

// Styles
import { ListStyled, ListItem } from '../../css/styles/list';

const listItem = props =>
  props.paymentEmployees.map(item => (
    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
    <ListItem
      color="#011826"
      strongColor="#fff"
      key={item.uuid}
    >
      <div className="info">
        <div>
          <strong className="title">NOME: </strong>
          <strong>{item.name}</strong>
        </div>

        <div>
          <strong className="title">VALOR: </strong>
          <strong>R$ {item.paymentValue.replace('.', ',')}</strong>
        </div>
      </div>

      <button
        type="button"
        onClick={e => {
          e.stopPropagation();

          props.onRemove(item.uuid);
        }}
        className="delete"
      >
        <IoIosRemoveCircle size={20} color="#bf1523" />
      </button>
    </ListItem>
  ));

function PaymentEmployeeList(props) {
  const { paymentEmployees } = props;

  paymentEmployees.sort((previous, next) => {
    const namePrevious = previous.name.toUpperCase();
    const nameNext = next.name.toUpperCase();

    if (namePrevious < nameNext) {
      return -1;
    }
    if (namePrevious > nameNext) {
      return 1;
    }
    return 0;
  });


  return (
    <>
      {paymentEmployees && paymentEmployees.length > 0 ? (
        <ListStyled>
          <header>
            <div className="total">
              <h1>TOTAL:</h1>
              <p>{paymentEmployees.length}</p>
            </div>
          </header>

          {listItem(props)}
        </ListStyled>
      ) : (
        <strong>LISTA VAZIA</strong>
      )}
    </>
  )
};

PaymentEmployeeList.propTypes = {
  paymentEmployees: PropTypes.arrayOf(PropTypes.object),
};

PaymentEmployeeList.defaultProps = {
  paymentEmployees: [],
};

export default PaymentEmployeeList;
