import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import Select from 'react-select';

// Styles
import { FormStyled, Row, Column } from '../../css/styles/form';
import { SelectStyled } from '../../css/styles/select';

const handleValidate = ({ name }) => {
  const errors = {};
  if (!name) errors.name = 'OBRIGATÓRIO';
  return errors;
};

const handleSubmit = props => async (values, { setSubmitting, resetForm }) => {
  if (!props.partner) {
    await props.onCreation({ ...values });
  } else {
    await props.onUpdate({ ...values });
  }
  setSubmitting(false);
  resetForm();
};

const PartnerForm = props => {
  const { onDelete, onNew, onEdit, partner, partners } = props;

  return (
    <Formik
      enableReinitialize
      initialValues={{
        name: partner ? partner.name : '',
        cnpj: partner ? partner.cnpj : '',
      }}
      validate={handleValidate}
      onSubmit={handleSubmit(props)}
    >
      {({
        values,
        handleChange,
        handleBlur,
        handleReset,
        errors,
        touched,
        setFieldValue,
        isSubmitting,
        dirty,
      }) => (
        <FormStyled>
          <h1>NOVO FORNECEDOR OU CLIENTE</h1>

          <ul>
            <Row>
              <Column>
                <strong>NOME</strong>
                <input
                  id="name"
                  name="name"
                  type="text"
                  value={values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />

                {errors.name && touched.name && <span>{errors.name}</span>}
              </Column>
            </Row>

            <Row>
              <Column>
                <strong>CNPJ OU CPF</strong>
                <input
                  id="cnpj"
                  name="cnpj"
                  type="text"
                  value={values.cnpj}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />

                {errors.cnpj && touched.cnpj && <span>{errors.cnpj}</span>}
              </Column>
            </Row>

            <Row>
              <div className="buttons-container">
                <button type="submit" disabled={isSubmitting}>
                  SALVAR
                </button>

                <button
                  type="button"
                  onClick={handleReset}
                  disabled={!dirty || isSubmitting}
                >
                  RESET
                </button>

                {partner && (
                  <>
                    <button type="button" onClick={onNew}>
                      LIMPAR
                    </button>

                    <button className="delete" type="button" onClick={onDelete}>
                      EXCLUIR
                    </button>
                  </>
                )}
              </div>
            </Row>
          </ul>

          <hr />

          <ul>
            <Row>
              <Column>
                <strong>FORNECEDOR OU CLIENTE</strong>
                <Select
                  styles={SelectStyled}
                  id="partner"
                  value={values.partner}
                  placeholder="Selecione um cliente"
                  onChange={selectedOption => {
                    setFieldValue('partner', selectedOption);
                    onEdit(selectedOption);
                  }}
                  isSearchable
                  options={partners}
                  getOptionLabel={item => item.name}
                  getOptionValue={item => item.uuid}
                  name="partner"
                  isLoading={false}
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  loadingMessage={() => 'Buscando clientes'}
                  noOptionsMessage={() => 'Não há clientes cadastrados'}
                />
              </Column>
            </Row>
          </ul>
        </FormStyled>
      )}
    </Formik>
  );
};

PartnerForm.propTypes = {
  partners: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      cnpj: PropTypes.string,
    })
  ),
  partner: PropTypes.shape({
    name: PropTypes.string,
    cnpj: PropTypes.string,
  }),
  onDelete: PropTypes.func.isRequired,
  onNew: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
};

PartnerForm.defaultProps = {
  partners: [],
  partner: null,
};

export default PartnerForm;
