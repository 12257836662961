import React from 'react';
import { IoMdTrash } from 'react-icons/io';
import PropTypes from 'prop-types';

// Styles
import { ListStyled, ListItem } from '../../../css/styles/list';

const listItem = props =>
  props.maintenancePieces.map(item => (
    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
    <ListItem
      color="#011826"
      strongColor="#fff"
      key={item.uuid}
      onClick={() => props.onEdit(item)}
    >
      <div className="info">
        <div>
          <strong className="title">NOME:</strong>
          <strong>{item.piece?.description}</strong>
        </div>

        <div>
          <strong className="title">QUANTIDADE:</strong>
          <strong>{item.quantity}</strong>
        </div>

        <div>
          <strong className="title">VALOR:</strong>
          <strong>{item.value}</strong>
        </div>

        <div>
          <strong className="title">TOTAL:</strong>
          <strong> R$ {(item.value * item.quantity).toLocaleString()}</strong>
        </div>
      </div>

      <button
        type="button"
        onClick={e => {
          e.stopPropagation();
          props.onDelete(item);
        }}
        className="delete"
      >
        <IoMdTrash size={20} color="#bf1523" />
      </button>
    </ListItem>
  ));

const maintenancePieceList = props => {
  const { maintenancePieces } = props;

  return (
    <>
      {maintenancePieces && maintenancePieces.length > 0 ? (
        <ListStyled>{listItem(props)}</ListStyled>
      ) : (
        <strong>LISTA VAZIA</strong>
      )}
    </>
  );
};

maintenancePieceList.propTypes = {
  maintenancePieces: PropTypes.arrayOf(
    PropTypes.shape({
      uuid: PropTypes.string,
      value: PropTypes.number,
      quantity: PropTypes.number,
      piece: PropTypes.shape({
        description: PropTypes.string,
      }),
    })
  ),
};

maintenancePieceList.defaultProps = {
  maintenancePieces: [],
};

export default maintenancePieceList;
