import React, { Component } from 'react';
import ReactLoading from 'react-loading';
import { toast } from 'react-toastify';
import swal from '@sweetalert/with-react';
import io from 'socket.io-client';
import PropTypes from 'prop-types';
import EquipmentForm from './form';
import API from '../../services/api';
import Parameters from '../../services/parameters';

// Styles
import { SubContainer } from '../../css/styles/wrapper';

class EquipmentContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      equipment: null,
      equipments: [],
      loading: false,
    };

    this.handleCreation = this.handleCreation.bind(this);
    this.getList();
  }

  componentDidMount() {
    this.registerToSocket();
  }

  registerToSocket = () => {
    const socket = io(Parameters.URL_API);
    socket.on('equipment', () => this.getList());
  };

  refEquipment = React.createRef();

  getList = async value => {
    this.setState({ loading: true });
    const { onEquipmentsList } = this.props;

    try {
      const response = await API.get('/equipments');
      this.setState({ equipments: response.data, loading: false });
      onEquipmentsList(response.data, value);
    } catch (err) {
      toast.error('Um erro aconteceu durante o carregamento!');
      this.setState({ loading: false });
    }
  };

  handleCreation = async value => {
    const { onEquipmentSubmit } = this.props;

    const data = {
      code: value?.code?.toUpperCase(),
      plate: value?.plate?.toUpperCase() ?? '',
      model: value?.model?.toUpperCase() ?? '',
      year: value?.year,
      prefix: value?.prefix?.toUpperCase(),
      number: value?.number !== '' ? value?.number : null,
      status: value?.status !== '' ? value?.status : null,
    };

    try {
      const response = await API.post('/equipments', { ...data });

      this.getList(response.data);
      this.handleNew();
      toast.success('Veículo criado com sucesso!');
      onEquipmentSubmit();
    } catch (err) {
      toast.error('Um erro aconteceu durante o cadastro!');
    }
  };

  handleEdit = object => {
    this.setState({ equipment: object });
  };

  handleNew = () => {
    this.setState({ equipment: null });
  };

  handleUpdate = async value => {
    const { onEquipmentSubmit } = this.props;
    const { equipment } = this.state;

    const data = {
      code: value?.code?.toUpperCase(),
      plate: value?.plate?.toUpperCase() ?? '',
      model: value?.model?.toUpperCase() ?? '',
      year: value?.year,
      prefix: value?.prefix?.toUpperCase(),
      number: value?.number !== '' ? value?.number : null,
      status: value?.status !== '' ? value?.status : null,
    };

    try {
      await API.put(`equipments/${equipment.uuid}`, { ...data });

      this.getList();
      this.handleNew();
      toast.success('Veículo atualizado com sucesso.');
      onEquipmentSubmit();
    } catch (err) {
      toast.error('Um erro aconteceu durante a atualização!');
    }
  };

  handleDelete = async () => {
    const { onEquipmentSubmit } = this.props;
    const { equipment } = this.state;

    try {
      const confirmDelete = await swal({
        dangerMode: true,
        text: 'Confirma a exclusão do veículo?',
        buttons: {
          cancel: 'Não',
          confirm: 'Sim',
        },
      });

      if (confirmDelete) {
        await API.delete(`equipments/${equipment.uuid}`);
        toast.success('Veículo deletado com sucesso!');
        this.handleNew();
        this.getList();
        onEquipmentSubmit();
      }
    } catch (err) {
      toast.error('Não é possível excluir veículos relacionados a serviços!');
    }
  };

  render() {
    const { equipmentForm } = this.props;
    const { equipment, equipments, loading } = this.state;

    return (
      <SubContainer visible={equipmentForm}>
        <div ref={this.refEquipment} />
        {loading ? (
          <ReactLoading type="spin" color="#011826" height={40} width={40} />
        ) : (
          <EquipmentForm
            equipment={equipment}
            equipments={equipments}
            onCreation={this.handleCreation}
            onEdit={this.handleEdit}
            onUpdate={this.handleUpdate}
            onNew={this.handleNew}
            onDelete={this.handleDelete}
          />
        )}
      </SubContainer>
    );
  }
}

EquipmentContainer.propTypes = {
  equipmentForm: PropTypes.bool.isRequired,
  onEquipmentsList: PropTypes.func.isRequired,
  onEquipmentSubmit: PropTypes.func.isRequired,
};

export default EquipmentContainer;
