import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { SubContainer } from '../../../css/styles/wrapper';
import MaintenanceServiceGroupContainer from '../../MaintenanceServiceGroup';
import MaintenanceServiceContainer from '../../MaintenanceService';
import MaintenanceToMaintenanceServiceList from './list';
import { toast } from 'react-toastify';
import API from '../../../services/api';
import MaintenanceToMaintenanceServiceForm from './form';
import swal from 'sweetalert';

class MaintanceToMaintenanceServiceContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      maintenanceService: null,
      maintenanceServices: [],
      maintenanceServiceGroup: null,
      maintenanceServiceGroups: [],
      maintenanceToMaintenanceService: null,
      maintenanceToMaintenanceServices: [],
      maintenanceServiceForm: false,
      maintenanceServiceGroupForm: false,
    }

    this.getList();
  }

  refMaintenanceServices = React.createRef();

  scrollToMyRef = myRef => {
    window.scrollTo(0, myRef.current.offsetTop);
  };

  handleMaintenanceServiceFormState() {
    const { maintenanceServiceForm } = this.state;

    this.setState({
      maintenanceServiceForm: !maintenanceServiceForm,
      maintenanceServiceGroupForm: false,
    });
  }

  handleMaintenanceServiceGroupFormState() {
    const { maintenanceServiceGroupForm } = this.state;

    this.setState({
      maintenanceServiceForm: false,
      maintenanceServiceGroupForm: !maintenanceServiceGroupForm,
    });
  }

  getList = async () => {
    const { maintenance } = this.props;
    try {
      await API.get(`/maintenancetomaintenanceservices/${maintenance.uuid}`);

    } catch (err) {
      toast.error('Um erro aconteceu durante o carregamento!');
    }
  }

  handleCreation = async object => {
    const {
      maintenance,
      onMaintenanceToMaintenanceServiceSubmit,
      onMaintenanceToMaintenanceServiceList,
    } = this.props;

    const data = {
      description: object.description.toUpperCase(),
      maintenanceserviceUuid: object.maintenanceService.uuid,
      maintenanceUuid: maintenance.uuid,
    }

    try {
      await API.post('/maintenancetomaintenanceservices', { ...data });

      onMaintenanceToMaintenanceServiceList(maintenance.uuid);
      this.handleNew();
      toast.success('Serviço da manutenção criado com sucesso!');
      onMaintenanceToMaintenanceServiceSubmit();
    } catch (err) {
      toast.error('Um erro aconteceu durante o cadastro!');
    }
  }

  handleEdit = object => {
    this.setState({
      maintenanceToMaintenanceService: object,
      maintenanceService: object.maintenanceservice,
    });
  }

  handleNew = () => {
    this.setState({
      maintenanceToMaintenanceService: null,
      maintenanceService: null,
    });
  }

  handleUpdate = async object => {
    const {
      onMaintenanceToMaintenanceServiceSubmit,
      onMaintenanceToMaintenanceServiceList,
      maintenance,
    } = this.props;
    const { maintenanceToMaintenanceService } = this.state;

    const data = {
      description: object.description.toUpperCase() || null,
      maintenanceserviceUuid: object.maintenanceService.uuid || null,
      maintenanceUuid: maintenance.uuid || null,
    }

    try {
      await API.put(`/maintenancetomaintenanceservices/${maintenanceToMaintenanceService.uuid}`, data);

      onMaintenanceToMaintenanceServiceList(maintenance.uuid);
      this.handleNew();
      toast.success('Serviço de manutenção atualizado com sucesso!');
      onMaintenanceToMaintenanceServiceSubmit();
    } catch (err) {
      toast.error('Um erro aconteceu durante a atualização!');
    }
  }

  handleDelete = async object => {
    const {
      maintenance,
      onMaintenanceToMaintenanceServiceSubmit,
      onMaintenanceToMaintenanceServiceList,
    } = this.props;

    try {
      const confirmDelete = await swal({
        dangerMode: true,
        title: 'Deseja excluir este serviço de manutenção?',
        buttons: {
          cancel: 'Não',
          confirm: 'Sim',
        },
      });

      if (confirmDelete) {
      await API.delete(`/maintenancetomaintenanceservices/${object.uuid}`);

      onMaintenanceToMaintenanceServiceList(maintenance.uuid);
      this.handleNew();
      toast.success('Serviço de manutenção deletado com sucesso!');
      }
      onMaintenanceToMaintenanceServiceSubmit();
    } catch (err) {
      toast.error('Um erro aconteceu durante a exclusão!');
    }
  }

  handleMaintenanceServicesList = (maintenanceServices, maintenanceService) => {
    this.setState({
      maintenanceServices,
      maintenanceService,
      maintenanceToMaintenanceService: null,
    });
  }

  handleMaintenanceServiceGroupsList = (maintenanceServiceGroups, maintenanceServiceGroup) => {

    this.setState({
      maintenanceServiceGroups,
      maintenanceServiceGroup,
      maintenanceToMaintenanceService: null,
    });
  }

  render() {
    const {
      maintenanceToMaintenanceServiceForm,
      maintenanceToMaintenanceServices,
    } = this.props;

    const {
      maintenanceServiceForm,
      maintenanceServiceGroupForm,
      maintenanceService,
      maintenanceServices,
      maintenanceServiceGroups,
      maintenanceToMaintenanceService,
    } = this.state;

    return (
      <SubContainer visible={maintenanceToMaintenanceServiceForm}>
        <div ref={this.refMaintenanceServices} />

        <nav>
          <h1>SERVICOS DE MANUTENÇÃO</h1>

          <div style={{ width: 'fit-content' }}>
            <button
              type="button"
              onClick={() => this.handleMaintenanceServiceFormState()}
            >
              SERVIÇO
            </button>

            <button
              type="button"
              onClick={() => this.handleMaintenanceServiceGroupFormState()}
            >
              GRUPO DE SERVIÇO
            </button>
          </div>

        </nav>

        <MaintenanceServiceGroupContainer
          onMaintenanceServiceGroupsList={this.handleMaintenanceServiceGroupsList}
          maintenanceServiceGroupForm={maintenanceServiceGroupForm}
          onMaintenanceServiceGroupSubmit={() => this.handleMaintenanceServiceGroupFormState()}
        />

        <MaintenanceServiceContainer
          onMaintenanceServicesList={this.handleMaintenanceServicesList}
          maintenanceServiceForm={maintenanceServiceForm}
          onMaintenanceServiceSubmit={() => this.handleMaintenanceServiceFormState()}
          maintenanceServiceGroups={maintenanceServiceGroups}
        />

        <MaintenanceToMaintenanceServiceList
          maintenanceToMaintenanceServices={maintenanceToMaintenanceServices}
          onEdit={this.handleEdit}
          onDelete={this.handleDelete}
        />

        <MaintenanceToMaintenanceServiceForm
          onUpdate={this.handleUpdate}
          onNew={this.handleNew}
          onCreation={this.handleCreation}
          maintenanceToMaintenanceService={maintenanceToMaintenanceService}
          maintenanceToMaintenanceServices={maintenanceToMaintenanceServices}
          maintenanceService={maintenanceService}
          maintenanceServices={maintenanceServices}
        />
      </SubContainer>
    );
  }
}

MaintanceToMaintenanceServiceContainer.propTypes = {
  maintenance: PropTypes.shape({
    uuid: PropTypes.string,
  }).isRequired,
  maintenanceToMaintenanceServices: PropTypes.arrayOf(PropTypes.shape({
    uuid: PropTypes.string,
    description: PropTypes.string,
    maintenanceservice: PropTypes.shape({
      uuid: PropTypes.string,
      description: PropTypes.string,
    }),
  })),
  maintenanceToMaintenanceServiceForm: PropTypes.bool.isRequired,
  onMaintenanceToMaintenanceServiceSubmit: PropTypes.func.isRequired,
  onMaintenanceToMaintenanceServiceList: PropTypes.func.isRequired,
}

export default MaintanceToMaintenanceServiceContainer;
