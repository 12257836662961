import React, { Component } from 'react';

import ReactLoading from 'react-loading';

import swal from 'sweetalert';

import { toast } from 'react-toastify';

import PropTypes from 'prop-types';

import API from '../../../services/api';
import { SubContainer } from '../../../css/styles/wrapper';
import ServiceExtraDaysList from './list';
import ServiceExtraDaysForm from './form';

class ServiceExtraDaysContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      serviceExtraDay: null,
      serviceExtraDays: [],

      // serviceExtraDay: null
    };

    this.handleCreation = this.handleCreation.bind(this);
  }
  componentDidMount() {
    this.getList();
  }

  componentDidUpdate(prevProps) {
    const { serviceequipment } = this.props;
    if (prevProps.serviceequipment !== serviceequipment) {
      this.handleNew();
      this.getList();
    }
  }

  refServiceExtraDays = React.createRef();

  scrollToMyRef = myRef => {
    window.scrollTo(0, myRef.current.offsetTop);
  };
  getList = async () => {
    const { serviceequipment, onServiceExtraDaysList } = this.props;
    console.log(serviceequipment)
    this.setState({
      loading: true,
    });
    
    try {
      const response = await API.get(`serviceextradays/${serviceequipment.serviceUuid}`);
      console.log({try:response.data})
      this.setState({
        loading: false,
        serviceExtraDays: response.data,
      });
      
      onServiceExtraDaysList(response.data);
    } catch (err) {
      toast.error('Uma erro aconteceu durante o carregamento!');
      this.setState({
        loading: false,
      });
    }
  };

  handleCreation = async object => {
    const { serviceequipment } = this.props;

    console.log(object)

    const data = {
      serviceUuid: serviceequipment.serviceUuid,

      description: object.description,

      extraDay: object.extraDay,
    };

    console.log(data.serviceUuid, "--________---")

    try {
      await API.post('/serviceextradays/', {
        ...data,
      });

      
      this.getList();
      this.handleNew();
      toast.success('Dia extra cadastrado com sucesso!');
    } catch (err) {
      toast.error('Um erro aconteceu durante o cadastro!');
    }
  };

  handleEdit = object => {
    this.setState({ serviceExtraDay: object });
  };

  handleNew = () => {
    this.setState({ serviceExtraDay: null });
  };

  handleUpdate = async object => {
    const { serviceequipment } = this.props;

    const { serviceExtraDay } = this.state;

    

    const data = {
      serviceUuid: object.serviceUuid,

      description: object.description,

      extraDay: object.extraDay,
    };
    console.log(data, "oooooooooooooooooooooooooooooooooooooooooooooo")
    try {
      await API.put(`/serviceextradays/${serviceExtraDay.uuid}`, {
        ...data,
      });
      this.getList();
      this.handleNew();
      toast.success('Dia extra de serviço atualizado com sucesso.');
    } catch (error) {
      toast.error('Um erro ocorreu durante a atualização!');
    }
  };

  handleDelete = async uuid => {
    try {
      const confirmDelete = await swal({
        dangerMode: true,

        text: 'Confirma a exclusão do dia extra de serviço?',

        buttons: {
          cancel: 'Não',

          confirm: 'Sim',
        },
      });

      if (confirmDelete) {
        await API.delete(`serviceextradays/${uuid}`);
        this.getList();
        this.handleNew();
        toast.success('Dia extra de serviço deletado com sucesso!');
      }
    } catch {
      toast.error('Um erro aconteceu durante a exclusão!');
    }
  };

  render() {
    const { serviceequipment, services } = this.props;

    const { serviceExtraDay, serviceExtraDays, loading } = this.state;
    return (
      <SubContainer visible>
        <div ref={this.refServiceExtraDays} />
        <header>
          <h1>DIAS EXTRAS DE SERVIÇO</h1>
        </header>

        {loading ? (
          <ReactLoading type="spin" color="#011826" height={40} width={40} />
        ) : (
          <ServiceExtraDaysList
            serviceExtraDays={serviceExtraDays}
            onEdit={this.handleEdit}
            onDelete={this.handleDelete}
          />
        )}

        <ServiceExtraDaysForm
          onUpdate={this.handleUpdate}
          onNew={this.handleNew}
          onCreation={this.handleCreation}
          serviceExtraDays={serviceExtraDays}
          serviceExtraDay={serviceExtraDay}
          services={services}
          serviceequipment={serviceequipment}
        />
      </SubContainer>
    );
  }
}

ServiceExtraDaysContainer.propTypes = {
  service: PropTypes.shape({
    uuid: PropTypes.string,
  }).isRequired,

  onServiceExtraDaysList: PropTypes.func.isRequired,

  service: PropTypes.shape({}),

  services: PropTypes.arrayOf(PropTypes.shape({})),
};

export default ServiceExtraDaysContainer;

