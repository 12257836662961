import React from 'react';
import { IoMdTrash } from 'react-icons/io';
import PropTypes from 'prop-types';

// Styles
import { ListStyled, ListItem } from '../../css/styles/list';

const listItem = props =>
  props.accountTransfers.map(accountTransfer => (
    <ListItem color="#011826" strongColor="#fff" key={accountTransfer.uuid}>
      <div className="info">
        <div>
          <strong className="title">DATA:</strong>
          <strong>
            {new Date(accountTransfer.createdAt).toLocaleString('pt-BR')}
          </strong>
        </div>

        <div>
          <strong className="title">ORIGEM:</strong>
          <strong>{accountTransfer.origin.name}</strong>
        </div>

        <div>
          <strong className="title">DESTINO:</strong>
          <strong>{accountTransfer.destiny.name}</strong>
        </div>

        <div>
          <strong className="title">VALOR:</strong>
          <strong>
            {accountTransfer.qty.toLocaleString('pt-BR', {
              minimumFractionDigits: 2,
            })}
          </strong>
        </div>
      </div>

      <button
        type="button"
        onClick={e => {
          e.stopPropagation();
          props.onDelete(accountTransfer.uuid);
        }}
        className="delete"
      >
        <IoMdTrash size={20} color="#bf1523" />
      </button>
    </ListItem>
  ));

function AccountList(props) {
  const { accountTransfers } = props;

  return (
    <>
      {accountTransfers && accountTransfers.length > 0 ? (
        <ListStyled>
          <header>
            <div className="total">
              <h1>TOTAL:</h1>
              <p>{accountTransfers.length}</p>
            </div>
          </header>

          {listItem(props)}
        </ListStyled>
      ) : (
        <strong>LISTA VAZIA</strong>
      )}
    </>
  );
}

AccountList.propTypes = {
  accountTransfers: PropTypes.arrayOf(
    PropTypes.shape({
      uuid: PropTypes.string,
      createdAt: PropTypes.string,
      qty: PropTypes.number,
      origin: PropTypes.shape({
        name: PropTypes.string,
      }),
      destiny: PropTypes.shape({
        name: PropTypes.string,
      }),
    })
  ),
};

AccountList.defaultProps = {
  accountTransfers: [],
};

export default AccountList;
