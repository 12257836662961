import styled from 'styled-components';

export const ListStyled = styled.ul`
  width: 100%;
  max-width: 900px;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 8px;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  margin: 16px 0;

  header {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    grid-column: span 2;

    div.total {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      h1 {
        font-size: 20px;
        color: #011826;
        margin-right: 8px;
      }

      p {
        color: #333;
        font-size: 20px;
      }
    }
  }

  @media screen and (max-width: 832px) {
    display: flex;
    flex-direction: column;
  }
`;

export const ListItem = styled.li`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 16px 32px;
  border-radius: 10px;
  box-sizing: border-box;
  background: ${props => props.color};
  border: 2px solid #ddd;

  &:hover {
    background: #fff;
    border: 2px solid #333;

    strong {
      color: #333;
    }
  }

  strong {
    font-size: 14px;
    color: ${props => props.strongColor};
  }

  button {
    background: none;
    border: 0;

    border-left: 1px solid #ddd;
    padding-left: 16px;
    margin-left: 16px;

    &:disabled {
      cursor: default;
    }
  }

  a {
    cursor: pointer;
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    text-decoration: none;
  }

  .info {
    width: 100%;
    display: flex;
    gap: 16px;
    flex-direction: column;
    align-items: start;

    div {
      strong {
        font-size: 14px;
        color: ${props => props.strongColor};

        & + strong {
          margin-left: 8px;
          color: #faff00;
        }
      }
    }
  }

  &.payment {
    .info {
      div {
        strong {
          color: #fff;
        }

        .title {
          color: #000;
        }
      }
    }
  }

  &:hover {
    .info {
      div {
        strong {
          color: #0065e9;
        }

        .title {
          color: #333;
        }
      }
    }
  }

  @media screen and (max-width: 832px) {
    display: flex;
    justify-content: space-between;
    padding: 16px 32px;

    .info {
      display: flex;
      gap: 16px;
      flex-direction: column;
      align-items: start;

      div {
        display: flex;
        flex-wrap: wrap;
        padding-left: 4px;
      }
    }
  }

  @media screen and (max-width: 480px) {
    padding: 16px;

    .info {
      div {
        align-items: flex-start;

        strong {
          font-size: 12px;
        }
      }
    }
  }
`;

export const SubListStyled = styled.div`
  width: 100%;
  min-width: 100%;

  display: ${props => (props.visible ? 'grid' : 'none')};
  flex: 1;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 32px;

  margin: 16px 0;
  padding-bottom: 32px;
  border-bottom: 2px solid #0568a6;

  strong {
    font-size: 14px;
    color: #0568a6;
    padding: 8px 0 8px 8px;
    border-left: 1px solid #666;
  }
`;

export const Legend = styled.ul`
  display: flex;
  align-items: center;
  justify-content: center;

  li {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px;
    gap: 8px;

    p {
      margin: 0;
    }
  }

  @media screen and (max-width: 520px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-items: start;
  }
`;

export const LegendItem = styled.div`
  width: 14px;
  height: 14px;
  border-radius: 7px;

  background: ${props => props.color};
`;
