import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import Select from 'react-select';

// Styles
import { FormStyled, Row, Column } from '../../css/styles/form';
import { SelectStyled } from '../../css/styles/select';

const handleValidate = ({ description, maintenanceServiceGroup }) => {
  const errors = {};
  if (!description) errors.description = 'OBRIGATÓRIO';
  if (!maintenanceServiceGroup) errors.maintenanceServiceGroup = 'OBRIGATÓRIO';
  return errors;
};

const handleSubmit = props => async (values, { setSubmitting, resetForm }) => {
  if (!props.maintenanceService) {
    await props.onCreation({ ...values });
  } else {
    await props.onUpdate({ ...values });
  }
  setSubmitting(false);
  resetForm();
};

const MaintenanceServiceForm = props => {
  const {
    maintenanceService,
    maintenanceServices,
    maintenanceServiceGroup,
    maintenanceServiceGroups,
    onEdit,
    onNew,
    onDelete,
  } = props;

  return (
    <Formik
      enableReinitialize
      initialValues={{
        description:
          maintenanceService ?
            maintenanceService.description
            : '',
        maintenanceServiceGroup: maintenanceServiceGroup || null,
      }}
      validate={handleValidate}
      onSubmit={handleSubmit(props)}
    >
      {({
        values,
        handleChange,
        handleBlur,
        handleReset,
        errors,
        touched,
        setFieldValue,
        isSubmitting,
        dirty,
      }) => (
        <FormStyled>
          {
            maintenanceService ? (
              <h1>EDITAR SERVIÇO</h1>
            ) : (
              <h1>NOVO SERVIÇO</h1>
            )
          }

          <ul>
            <Row>
              <Column>
                <strong htmlFor="description">DESCRIÇÃO</strong>
                <input
                  type="text"
                  name="description"
                  value={values.description}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="Descrição"
                />
                {errors.description && touched.description && (
                  <span className="error">{errors.description}</span>
                )}
              </Column>
            </Row>

            <Row>
              <Column>
                <strong>GRUPO DE SERVIÇO</strong>
                <Select
                  styles={SelectStyled}
                  id="maintenanceServiceGroup"
                  value={values.maintenanceServiceGroup}
                  placeholder="Selecione um serviço"
                  onChange={selectedOption => {
                    setFieldValue('maintenanceServiceGroup', selectedOption);
                  }}
                  isSearchable
                  options={maintenanceServiceGroups}
                  getOptionLabel={item => item.description}
                  getOptionValue={item => item.uuid}
                  name="maintenanceServiceGroup"
                  isLoading={false}
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  loadingMessage={() => 'Buscando serviços'}
                  noOptionsMessage={() => 'Não há serviços cadastrados'}
                />
              </Column>
            </Row>

            <Row>
              <div className="buttons-container">
                <button type="submit" disabled={isSubmitting}>
                  SALVAR
                </button>

                <button
                  type="button"
                  onClick={handleReset}
                  disabled={!dirty || isSubmitting}
                >
                  RESET
                </button>

                {maintenanceService && (
                  <>
                    <button type="button" onClick={onNew}>
                      LIMPAR
                    </button>

                    <button className="delete" type="button" onClick={onDelete}>
                      EXCLUIR
                    </button>
                  </>
                )}
              </div>
            </Row>
          </ul>

            <hr />

            <ul>
              <Row>
                <Column>
                  <strong>SERVIÇO</strong>
                  <Select
                    styles={SelectStyled}
                    id="maintenanceService"
                    value={values.maintenanceService}
                    placeholder="Selecione um serviço"
                    onChange={selectedOption => {
                      setFieldValue('maintenanceService', selectedOption);
                      onEdit(selectedOption);
                    }}
                    isSearchable
                    options={maintenanceServices}
                    getOptionLabel={item => item.description}
                    getOptionValue={item => item.uuid}
                    name="maintenanceService"
                    isLoading={false}
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                    loadingMessage={() => 'Buscando serviços'}
                    noOptionsMessage={() => 'Não há serviços cadastrados'}
                  />
                </Column>
              </Row>
            </ul>
        </FormStyled>
      )}
    </Formik>
  )
}

MaintenanceServiceForm.propTypes = {
  maintenanceServiceForm: PropTypes.bool.isRequired,
  maintenanceService: PropTypes.shape({
    uuid: PropTypes.string,
    description: PropTypes.string,
    maintenanceServiceGroupUuid: PropTypes.string,
  }),
  maintenanceServices: PropTypes.arrayOf(
    PropTypes.shape({
      uuid: PropTypes.string,
      description: PropTypes.string,
      maintenanceServiceGroupUuid: PropTypes.string,
    })
  ),
  maintenanceServiceGroup: PropTypes.shape({
    uuid: PropTypes.string,
    description: PropTypes.string,
  }),
  maintenanceServiceGroups: PropTypes.arrayOf(
    PropTypes.shape({
      uuid: PropTypes.string,
      description: PropTypes.string,
    })
  ),
  onCreation: PropTypes.func,
  onEdit: PropTypes.func,
  onUpdate: PropTypes.func,
  onNew: PropTypes.func,
  onDelete: PropTypes.func,
}

MaintenanceServiceForm.defaultProps = {
  maintenanceServiceForm: false,
  maintenanceService: null,
  maintenanceServices: [],
  maintenanceServiceGroup: null,
  maintenanceServiceGroups: [],
}

export default MaintenanceServiceForm;

