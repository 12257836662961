import React from 'react';

import { Formik } from 'formik';

import PropTypes from 'prop-types';

//Styles

import { FormStyled, Row, Column } from '../../../css/styles/form';

import { SelectStyled } from '../../../css/styles/select';

const handleValidate = ({ extraDay }) => {
  const errors = {};

  // if (!description) errors.description = 'OBRIGATÓRIO';
  if (!extraDay) errors.extraDay = 'OBRIGATÓRIO';

  return errors;
};

const handleSubmit = props => async (values, { setSubmitting, resetForm }) => {
  if (props.serviceExtraDay) {
    await props.onUpdate(values);
  } else {
    await props.onCreation(values);
  }

  console.log(values);

  setSubmitting(false);
  resetForm();
};

const ServiceExtraDaysForm = props => {
  const {
    onNew,
    extraDay,
    serviceExtraDay,
    serviceExtraDays,
    serviceUuid,
  } = props;
  return (
    <Formik
      enableReinitialize
      initialValues={{
        serviceUuid: serviceExtraDay ? serviceExtraDay.serviceUuid : '',
        extraDay: serviceExtraDay
          ? serviceExtraDay.extraDay
          : '',
        description: serviceExtraDay ? serviceExtraDay.description : '',
      }}
      validate={handleValidate}
      onSubmit={handleSubmit(props)}
    >
      {({
        values,

        handleReset,

        handleBlur,

        handleChange,

        errors,

        touched,

        setFieldValue,

        isSubmitting,

        dirty,
      }) => (
        <FormStyled>
          <h1>
            {serviceExtraDay
              ? 'ALTERAR DIA EXTRA DO SERVIÇO'
              : 'NOVO DIA EXTRA DE SERVIÇO'}
          </h1>

          <ul>
            <Row>
              <Column>
                <strong>DIA EXTRA</strong>

                <input
                  styles={SelectStyled}
                  id="extraDay"
                  type="date"
                  value={values.extraDay}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />

                {errors.extraDay && touched.extraDay && (
                  <span>{errors.extraDay}</span>
                )}
              </Column>
            </Row>
            <Row>
              <Column>
                <strong>DESCRIÇÃO</strong>
                <textarea
                  type="text"
                  id="description"
                  name="description"
                  value={values.description}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />

                {errors.description && touched.description && (
                  <span>{errors.description}</span>
                )}
              </Column>
            </Row>
            <Row>
              <div className="buttons-container">
                <button type="submit" disabled={isSubmitting}>
                  SALVAR
                </button>

                <button
                  type="button"
                  onClick={handleReset}
                  disabled={!dirty || isSubmitting}
                >
                  RESET
                </button>
                {serviceExtraDays && (
                  <button
                    type="button"
                    onClick={onNew}
                    disabled={(!dirty && !serviceExtraDay) || isSubmitting}
                  >
                    LIMPAR
                  </button>
                )}
              </div>
            </Row>
          </ul>
        </FormStyled>
      )}
    </Formik>
  );
};

ServiceExtraDaysForm.propTypes = {
  services: PropTypes.arrayOf(
    PropTypes.shape({
      uuid: PropTypes.string,
    })
  ),

  service: PropTypes.shape({
    uuid: PropTypes.string,
  }),

  serviceExtraDays: PropTypes.arrayOf(
    PropTypes.shape({
      maintenanceUuid: PropTypes.string,
      extraDay: PropTypes.string,
      description: PropTypes.string,
    })
  ),

  serviceExtraDay: PropTypes.shape({
    uuid: PropTypes.string,
    maintenanceUuid: PropTypes.string,
    extraDay: PropTypes.string,
    description: PropTypes.string,
  }),
};

export default ServiceExtraDaysForm;

