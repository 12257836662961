import React from 'react';
import { IoMdTrash } from 'react-icons/io';
import PropTypes from 'prop-types';

// Styles
import { ListStyled, ListItem } from '../../css/styles/list';

const listItem = props =>
  props.maintenances.map(item => (
    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
    <ListItem
      color="#011826"
      strongColor="#fff"
      key={item.uuid}
      onClick={() => props.onEdit(item)}
    >
      <div className="info">
        <div>
          <strong className="title">NOME:</strong>
          <strong>{item.equipment ? item.equipment?.code : '-'}</strong>
        </div>

        <div>
          <div>
            <strong className="title">BASE</strong>
            <strong>{ item.base ? item.base?.description : '-'}</strong>
          </div>
        </div>

        <div>
          <strong className="title">TIPO:</strong>
          <strong>{item.type}</strong>
        </div>

        <div>
          <strong className="title">SITUAÇÃO:</strong>
          <strong>{item?.situation}</strong>
        </div>

        <div>
          <strong className="title">DATA:</strong>
          <strong>
            {new Date(item.opening_date).toLocaleString('pt-BR', {
              timeZone: 'UTC',
            })}
          </strong>
        </div>
      </div>

      <button
        type="button"
        onClick={e => {
          e.stopPropagation();
          props.onDelete(item.uuid);
        }}
        className="delete"
      >
        <IoMdTrash size={20} color="#bf1523" />
      </button>
    </ListItem>
  ));

function MaintenanceList(props) {
  const { maintenances } = props;

  return (
    <>
      {maintenances && maintenances.length > 0 ? (
        <ListStyled>
          <header>
            <div className="total">
              <h1>TOTAL:</h1>
              <p>{maintenances.length}</p>
            </div>
          </header>

          {listItem(props)}
        </ListStyled>
      ) : (
        <strong>LISTA VAZIA</strong>
      )}
    </>
  );
}

MaintenanceList.propTypes = {
  maintenances: PropTypes.arrayOf(
    PropTypes.shape({
      uuid: PropTypes.string,
      type: PropTypes.string,
      situation: PropTypes.string,
      observation: PropTypes.string,
      opening_date: PropTypes.string,
      closing_date: PropTypes.string,
      equipment: PropTypes.shape({
        code: PropTypes.string,
      }),
    })
  ),
};

MaintenanceList.defaultProps = {
  maintenances: [],
};

export default MaintenanceList;
