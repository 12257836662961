import React from 'react';
import { IoMdTrash } from 'react-icons/io';
import PropTypes from 'prop-types';

// Styles
import { ListStyled, ListItem } from '../../css/styles/list';

const listItem = props =>
  props.tankRefuels.map(tankRefuel => (
    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
    <ListItem
      color="#011826"
      strongColor="#fff"
      key={tankRefuel.uuid}
      onClick={() => props.onEdit(tankRefuel)}
    >
      <div className="info">
        <div>
          <strong className="title">DATA:</strong>
          <strong>
            {new Date(tankRefuel.createdAt).toLocaleString('pt-BR')}
          </strong>
        </div>

        <div>
          <strong className="title">QUANTIDADE:</strong>
          <strong>{tankRefuel.qty}</strong>
        </div>

        <div>
          <strong className="title">TIPO:</strong>
          <strong>{tankRefuel.document}</strong>
        </div>

        <div>
          <strong className="title">VALOR:</strong>
          <strong>{tankRefuel.value}</strong>
        </div>
      </div>

      <button
        type="button"
        onClick={e => {
          e.stopPropagation();
          props.onDelete(tankRefuel.uuid);
        }}
        className="delete"
      >
        <IoMdTrash size={20} color="#bf1523" />
      </button>
    </ListItem>
  ));

function TankList(props) {
  const { tankRefuels } = props;

  return (
    <>
      {tankRefuels && tankRefuels.length > 0 ? (
        <ListStyled>
          <header>
            <div className="total">
              <h1>TOTAL:</h1>
              <p>{tankRefuels.length}</p>
            </div>
          </header>

          {listItem(props)}
        </ListStyled>
      ) : (
        <strong>LISTA VAZIA</strong>
      )}
    </>
  );
}

TankList.propTypes = {
  tankRefuels: PropTypes.arrayOf(
    PropTypes.shape({
      uuid: PropTypes.string,
      createdAt: PropTypes.string,
      qty: PropTypes.number,
      document: PropTypes.string,
      value: PropTypes.number,
    })
  ),
};

TankList.defaultProps = {
  tankRefuels: [],
};

export default TankList;
