export const CustomSelectStyled = {
    control: base => ({
      ...base,
  
      height: 40,
  
      minHeight: 40,
  
      border: '1px solid #999',
  
      fontSize: 16,
  
      color: '#333',
  
      padding: '0 15px',
    }),
  
    container: provided => ({
      ...provided,
  
      width: '100%',
    }),
  
    valueContainer: provided => ({
      ...provided,
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
    }),
  
    input: provided => ({
      ...provided,
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
    }),
  
    placeholder: provided => ({
      ...provided,
  
      color: '#999',
    }),
  
    option: (provided, state) => ({
      ...provided,
  
      color: state.isSelected ? '#333' : '#999',
  
      fontSize: 16,
  
      padding: 10,
    }),
  
  //   isMulti: (provided, state) => ({
  //     ...provided,   
      
  //     marginBottom: "1rem",
  // })
  };