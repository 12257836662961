import axios from 'axios';
import Parameters from './parameters';
import { getToken } from './auth';

const api = axios.create({
  // baseURL: `http://localhost:4000`,
  baseURL: Parameters.URL_API,
});

api.interceptors.request.use(
  async config => {
    const token = getToken();
    // console.log(token);
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  error => {
    // console.log(error);
  }
);

export default api;
