import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import Select from 'react-select';

// Styles
import { FormStyled, Row, Column } from '../../css/styles/form';
import { SelectStyled } from '../../css/styles/select';

const handleValidate = ({ tank, qty, document, value }) => {
  const errors = {};
  if (!tank) errors.tank = 'OBRIGATÓRIO';
  if (!qty) errors.qty = 'OBRIGATÓRIO';
  if (!document) errors.document = 'OBRIGATÓRIO';
  if (!value) errors.value = 'OBRIGATÓRIO';
  return errors;
};

const handleSubmit = props => async (values, { setSubmitting, resetForm }) => {
  if (props.tankRefuel) {
    await props.onUpdate({ ...values });
  } else {
    await props.onCreation({ ...values });
  }
  setSubmitting(false);
  resetForm();
};

const TankForm = props => {
  const { tankRefuel, onNew, tankList, tank } = props;

  return (
    <Formik
      enableReinitialize
      initialValues={{
        document: tankRefuel ? tankRefuel.document : '',
        qty: tankRefuel ? tankRefuel.qty : '',
        value: tankRefuel ? tankRefuel.value : '',
        tank: tank ? tank : null,
      }}
      validate={handleValidate}
      onSubmit={handleSubmit(props)}
    >
      {({
        values,
        handleChange,
        handleBlur,
        errors,
        touched,
        isSubmitting,
        handleReset,
        dirty,
        setFieldValue,
      }) => (
        <FormStyled>
          <h1>
            {tankRefuel ? 'EDITAR ENTRADA DE TANQUE' : 'NOVA ENTRADA TANQUE'}
          </h1>

          <ul>
            <Row>
              <Column>
                <strong>TANQUE</strong>
                <Select
                  styles={SelectStyled}
                  id="tank"
                  value={values.tank}
                  placeholder="Selecione um tanque"
                  onChange={selectedOption => {
                    setFieldValue('tank', selectedOption);
                  }}
                  isSearchable
                  options={tankList}
                  getOptionLabel={item => item.name}
                  getOptionValue={item => item.uuid}
                  name="tank"
                  isLoading={false}
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  loadingMessage={() => 'Buscando tanques'}
                  noOptionsMessage={() => 'Não há tanques cadastrados'}
                />

                {errors.tank && touched.tank && <span>{errors.tank}</span>}
              </Column>
            </Row>

            <Row>
              <Column>
                <strong>LITROS</strong>
                <input
                  id="qty"
                  name="qty"
                  type="number"
                  value={values.qty}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />

                {errors.qty && touched.qty && <span>{errors.qty}</span>}
              </Column>

              <Column>
                <strong>Documento</strong>
                <input
                  id="document"
                  name="document"
                  type="text"
                  value={values.document}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />

                {errors.document && touched.document && (
                  <span>{errors.document}</span>
                )}
              </Column>

              <Column>
                <strong>Valor</strong>
                <input
                  id="value"
                  name="value"
                  type="text"
                  value={values.value}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />

                {errors.value && touched.value && <span>{errors.value}</span>}
              </Column>
            </Row>

            <Row>
              <div className="buttons-container">
                <button
                  type="submit"
                  disabled={isSubmitting || (!dirty && !tankRefuel)}
                >
                  SALVAR
                </button>

                <button
                  type="button"
                  onClick={handleReset}
                  disabled={!dirty || isSubmitting}
                >
                  RESET
                </button>

                {tankRefuel && (
                  <button
                    type="button"
                    onClick={onNew}
                    disabled={(!dirty && !tankRefuel) || isSubmitting}
                  >
                    LIMPAR
                  </button>
                )}
              </div>
            </Row>
          </ul>
        </FormStyled>
      )}
    </Formik>
  );
};

TankForm.propTypes = {
  tankRefuel: PropTypes.shape({
    document: PropTypes.string,
    qty: PropTypes.number,
    value: PropTypes.number,
  }),
  onNew: PropTypes.func.isRequired,
  tankList: PropTypes.arrayOf(
    PropTypes.object
  ),
  tank: PropTypes.shape({
    uuid: PropTypes.string,
    name: PropTypes.string,
  }),
};

TankForm.defaultProps = {
  tankRefuel: null,
  tankList: [],
  tank: null,
};

export default TankForm;
