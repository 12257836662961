import React from 'react';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import Select from 'react-select';

// Styles
import { FormStyled, Row, Column } from '../../../css/styles/form';
import { SelectStyled } from '../../../css/styles/select';

const handleValidate = ({ observation, maintenancePlanFreq }) => {
  const errors = {};

  if (!maintenancePlanFreq) errors.maintenancePlanFreq = 'OBRIGATÓRIO';

  return errors;
};

const handleSubmit = props => async (values, { setSubmitting, resetForm }) => {
  if (props.maintenanceToMaintenancePlanFreq) {
    await props.onUpdate({ ...values });
  } else {
    await props.onCreation({ ...values });
  }

  setSubmitting(false);
  resetForm();
};

const MaintenanceToMaintenancePlanFreqForm = props => {
  const {
    onNew,
    maintenanceToMaintenancePlanFreq,
    maintenancePlanFreq,
    maintenancePlanFreqs,
  } = props;

  return (
    <Formik
      enableReinitialize
      initialValues={{
        observation: maintenanceToMaintenancePlanFreq
          ? maintenanceToMaintenancePlanFreq.observation
          : '',
        maintenancePlanFreq: maintenancePlanFreq? maintenancePlanFreq.frequency : null,
        maintenanceToMaintenancePlanFreq: maintenanceToMaintenancePlanFreq || null,
        maintenancePlanFreqs: maintenancePlanFreqs || []
      }}
      validate={handleValidate}
      onSubmit={handleSubmit(props)}
    >
      {({
        values,
        handleReset,
        handleBlur,
        handleChange,
        errors,
        touched,
        setFieldValue,
        isSubmitting,
        dirty,
      }) => (
        <FormStyled>
          <h1>
            {maintenanceToMaintenancePlanFreq
              ? 'ALTERAR FREQUENCIA DA MANUTENÇÃO'
              : 'CRIAR FREQUENCIA DA MANUTENÇÃO'}
          </h1>
          <ul>
            <Row>
              <Column>
                <strong>FREQUENCIA</strong>
                <Select
                  styles={SelectStyled}
                  id="maintenancePlanFreq"
                  value={values.maintenancePlanFreq}
                  placeholder="Selecione uma frequência"
                  onChange={selectedOption =>
                    setFieldValue('maintenancePlanFreq', selectedOption)
                  }
                  isSearchable
                  options={maintenancePlanFreqs}
                  // defaultValue={{label:
                  //   maintenanceToMaintenancePlanFreq?.maintenanceplanfreq?.frequency, value: maintenanceToMaintenancePlanFreq?.maintenanceplanfreq?.uuid}}
                  getOptionLabel={item => `${item.maintenanceplan.description} - ${item.frequency}`}
                  getOptionValue={item => item.uuid}
                  name="maintenancePlanFreq"
                  isLoading={false}
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  loadingMessage={() => 'Buscando frequências'}
                  noOptionsMessage={() => 'Não há frequências cadastradas'}
                />
                {errors.maintenancePlanFreq && touched.maintenancePlanFreq && (
                  <span>{errors.maintenancePlanFreq}</span>
                )}
              </Column>
            </Row>

            <Row>
              <Column>
                <strong>OBSERVAÇÃO</strong>
                <textarea
                  id="observation"
                  name="observation"
                  value={values.observation}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.observation && touched.observation && (
                  <span>{errors.observation}</span>
                )}
              </Column>
            </Row>

            <Row>
              <div className="buttons-container">
                <button type="submit" disabled={isSubmitting}>
                  SALVAR
                </button>

                <button
                  type="button"
                  onClick={handleReset}
                  disabled={!dirty || isSubmitting}
                >
                  RESET
                </button>

                {maintenanceToMaintenancePlanFreq && (
                  <button
                    type="button"
                    onClick={onNew}
                    disabled={
                      (!dirty && !maintenanceToMaintenancePlanFreq) ||
                      isSubmitting
                    }
                  >
                    LIMPAR
                  </button>
                )}
              </div>
            </Row>
          </ul>
        </FormStyled>
      )}
    </Formik>
  );
};

MaintenanceToMaintenancePlanFreqForm.propTypes = {
  onCreation: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onNew: PropTypes.func.isRequired,
  maintenanceToMaintenancePlanFreq: PropTypes.shape({
    uuid: PropTypes.string,
    observation: PropTypes.string,
  }),

  maintenancePlanFreqs: PropTypes.arrayOf(
    PropTypes.shape({
      uuid: PropTypes.string,
      frequency: PropTypes.string,
    })
  ),

  maintenancePlanFreq: PropTypes.shape({
    uuid: PropTypes.string,
    frequency: PropTypes.string,
  }),

  maintenancePlanFreqs: PropTypes.arrayOf(
    PropTypes.shape({
      uuid: PropTypes.string,
      frequency: PropTypes.string,
    })
  ),
};

MaintenanceToMaintenancePlanFreqForm.defaultProps = {
  maintenanceToMaintenancePlanFreq: null,
  maintenanceToMaintenancePlanFreqs: [],
  maintenancePlanFreq: null,
  maintenancePlanFreqs: [],
};

export default MaintenanceToMaintenancePlanFreqForm;

