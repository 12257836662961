import React from 'react';
import { Formik } from 'formik';
import Select from 'react-select';
import { ListStyled, ListItem } from '../../css/styles/list';
import { SelectStyled } from '../../css/styles/select';
import { Column, FormStyled, Row } from '../../css/styles/form';
import { IoMdTrash } from 'react-icons/io';

const handleValidate = ({ value, quantity, pieceUuid }) => {
  const errors = {};
  if (!value || value === 0 || quantity < 0) errors.value = 'OBRIGATÓRIO';

  if (!quantity || quantity === 0 || quantity < 0) errors.quantity = 'OBRIGATÓRIO';

  if (!pieceUuid) errors.pieceUuid = 'OBRIGATÓRIO';

  return errors;
};

const handleSubmit = props => async (values, { setSubmitting, resetForm }) => {
  await props.onAdd({ ...values });

  setSubmitting(false);
  resetForm();
};

const MovementForm = props => {
  const {
    onNew,
    pieces,
    piece,
    movements,
    onDelete,
  } = props;

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={{
          quantity: 0,
          value: 0,
          pieceUuid: piece ? piece.uuid : null,
        }}
        validate={handleValidate}
        onSubmit={handleSubmit(props)}
      >
        {({
          values,
          errors,
          touched,
          dirty,
          isSubmitting,
          handleChange,
          handleBlur,
          handleReset,
          setFieldValue,
        }) => (
          <FormStyled>
            <h1>NOVO MOVIMENTO</h1>
            <ul>
              <Row>
                <Column>
                  <strong>PEÇA</strong>
                  <Select
                    id="pieceUuid"
                    name="pieceUuid"
                    styles={SelectStyled}
                    value={
                      pieces.find(p => p.uuid === values.pieceUuid) || null
                    }
                    onChange={selectedOption => {
                      setFieldValue(
                        'pieceUuid',
                        selectedOption ? selectedOption.uuid : null
                      );
                    }}
                    isSearchable
                    getOptionLabel={item => (
                      <div
                        style={{
                          ...SelectStyled,
                          display: 'flex',
                          justifyContent: 'space-between',
                          width: '100%',
                        }}
                      >
                        <div>{item.description}</div>
                        <div>{`QTD EM ESTOQUE: ${item.total}`}</div>
                      </div>
                    )}
                    getOptionValue={item => item.description}
                    formatOptionLabel={(item, { context }) =>
                      context === 'menu' ? (
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            width: '100%',
                          }}
                        >
                          <div>{item.description}</div>
                          <div>{`QTD EM ESTOQUE: ${item.total ?? 0}`}</div>
                        </div>
                      ) : (
                        item.description
                      )
                    }
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                    onBlur={handleBlur}
                    options={pieces}
                  />
                  {errors.pieceUuid && touched.pieceUuid && (
                    <span>{errors.pieceUuid}</span>
                  )}
                </Column>
              </Row>
              <Row>
                <Column>
                  <strong>VALOR</strong>
                  <input
                    id="value"
                    name="value"
                    type="number"
                    value={values.value}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.value && touched.value && <span>{errors.value}</span>}
                </Column>
              </Row>

              <Row>
                <Column>
                  <strong>QUANTIDADE</strong>
                  <input
                    id="quantity"
                    name="quantity"
                    type="number"
                    value={values.quantity}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.quantity && touched.quantity && (
                    <span>{errors.quantity}</span>
                  )}
                </Column>
              </Row>
            </ul>

            <Row>
              <div className="buttons-container">
                <button type="submit" disabled={!dirty || isSubmitting}>
                  ADICIONAR
                </button>
                <button
                  type="button"
                  onClick={handleReset}
                  disabled={!dirty || isSubmitting}
                >
                  RESET
                </button>
                {movements && (
                  <>
                    {movements[0] && (
                      <button type="button" onClick={onNew}>
                        LIMPAR
                      </button>
                    )}
                  </>
                )}
              </div>
            </Row>
            {movements && movements.length > 0 && (
              <ListStyled>
                {movements.map((movement, index) => (
                  <ListItem
                    color="#011826"
                    strongColor="#fff"
                    key={`${movement.pieceUuid}-${movement.quantity}`}
                  >
                    <div className="info">
                      <div>
                        <strong className="title">PEÇA:</strong>
                        <strong>{movement.piece?.description}</strong>
                      </div>
                      <div>
                        <strong className="title">VALOR:</strong>
                        <strong>R$ {(movement.value).toLocaleString()}</strong>
                      </div>
                      <div>
                        <strong className="title">VALOR TOTAL:</strong>
                        <strong> R$ {(movement.value * movement.quantity).toLocaleString()}</strong>
                      </div>
                      <div>
                        <strong className="title">QUANTIDADE:</strong>
                        <strong>{movement.quantity}</strong>
                      </div>
                      <div>
                        <strong className="title">QTD EM ESTOQUE:</strong>
                        <strong className="title">
                          {movement.piece?.total || 0}
                        </strong>
                      </div>
                    </div>
                    <button type="button" onClick={() => onDelete(index)}>
                      <IoMdTrash size={20} color="#bf1523" />
                    </button>
                  </ListItem>
                ))}
              </ListStyled>
            )}
          </FormStyled>
        )}
      </Formik>
    </>
  );
};

export default MovementForm;