import React, { Component } from 'react';
import ReactLoading from 'react-loading';
import { toast } from 'react-toastify';
import swal from '@sweetalert/with-react';
import io from 'socket.io-client';
import PropTypes from 'prop-types';
import CostForm from './form';
import API from '../../services/api';
import Parameters from '../../services/parameters';

// Styles
import { SubContainer } from '../../css/styles/wrapper';

class CostContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      cost: null,
      costs: [],
      loading: false,
    };

    this.handleCreation = this.handleCreation.bind(this);
    this.getList();
  }

  componentDidMount() {
    this.registerToSocket();
  }

  registerToSocket = () => {
    const socket = io(Parameters.URL_API);

    socket.on('cost', () => this.getList());
  };

  refCost = React.createRef();

  getList = async value => {
    this.setState({ loading: true });
    const { onCostsList } = this.props;

    try {
      const response = await API.get('/costs');
      this.setState({ costs: response.data, loading: false });
      onCostsList(response.data, value);
    } catch (err) {
      toast.error('Um erro aconteceu durante o carregamento!');
      this.setState({ loading: false });
    }
  };

  handleCreation = async object => {
    const { onCostSubmit } = this.props;

    try {
      const response = await API.post('/costs', { ...object });

      this.getList(response.data);
      this.handleNew();
      toast.success('Centro de custo criado com sucesso');
      onCostSubmit();
    } catch (err) {
      toast.error('Um erro aconteceu durante o cadastro!');
    }
  };

  handleEdit = object => {
    this.setState({ cost: object });
  };

  handleNew = () => {
    this.setState({ cost: null });
  };

  handleUpdate = async object => {
    const { onCostSubmit } = this.props;
    const { cost } = this.state;

    try {
      await API.put(`costs/${cost.uuid}`, { ...object });

      this.getList();
      this.handleNew();
      toast.success('Centro de custo atualizado com sucesso.');
      onCostSubmit();
    } catch (err) {
      toast.error('Um erro aconteceu durante a atualização!');
    }
  };

  handleDelete = async () => {
    const { onCostSubmit } = this.props;
    const { cost } = this.state;

    try {
      const confirmDelete = await swal({
        dangerMode: true,
        text: 'Confirma a exclusão do centro de custo?',
        buttons: {
          cancel: 'Não',
          confirm: 'Sim',
        },
      });

      if (confirmDelete) {
        await API.delete(`costs/${cost.uuid}`);

        this.getList();
        this.handleNew();
        toast.success('Centro de custo deletado com sucesso!');
        onCostSubmit();
      }
    } catch (err) {
      toast.error(
        'Não é possível excluir centros de custo relacionados a serviços!'
      );
    }
  };

  render() {
    const { costForm } = this.props;
    const { cost, costs, loading } = this.state;

    return (
      <SubContainer visible={costForm}>
        <div ref={this.refCost} />
        {loading ? (
          <ReactLoading type="spin" color="#011826" height={40} width={40} />
        ) : (
          <CostForm
            cost={cost}
            costs={costs}
            onCreation={this.handleCreation}
            onEdit={this.handleEdit}
            onUpdate={this.handleUpdate}
            onNew={this.handleNew}
            onDelete={this.handleDelete}
          />
        )}
      </SubContainer>
    );
  }
}

CostContainer.propTypes = {
  costForm: PropTypes.bool.isRequired,
  onCostsList: PropTypes.func.isRequired,
  onCostSubmit: PropTypes.func.isRequired,
};

export default CostContainer;
