import React, { Component } from 'react';
import ReactLoading from 'react-loading';
import swal from 'sweetalert';
import { toast } from 'react-toastify';
import io from 'socket.io-client';
import PropTypes from 'prop-types';
import TankForm from './form';
import TankList from './list';
import API from '../../services/api';
import Parameters from '../../services/parameters';

// Styles
import { SubContainer } from '../../css/styles/wrapper';

class TankContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      tank: null,
      tankRefuels: [],
      tankRefuel: null,
    };

    this.handleCreation = this.handleCreation.bind(this);
  }

  componentDidMount() {
    this.registerToSocket();
    this.getList();
  }

  registerToSocket = () => {
    const socket = io(Parameters.URL_API);

    socket.on('tankRefuel', () => this.getList());
  };

  refTank = React.createRef();

  getTanks = async () => {
    const { onTankList } = this.props;

    try {
      const response = await API.get('/tanks');
      onTankList(response.data);
    } catch (err) {
      console.log(err)
      toast.error('Um erro aconteceu durante o carregamento!');
    }
  };

  getList = async () => {
    this.setState({
      loading: true,
    });

    try {
      const response = await API.get('/tankRefuels');
      this.getTanks();
      this.setState({
        tankRefuels: response.data,
        loading: false,
      });
    } catch (err) {
      console.log(err)
      toast.error('Um erro aconteceu durante o carregamento!');
      this.setState({ loading: false });
    }
  };

  handleCreation = async object => {
    const { onTankRefuelSubmit } = this.props;

    object.value = String(object.value).replace(',', '.');

    const data = {
      ...object,
      tankUuid: object.tank.uuid,
    };

    try {
      await API.post('/tankRefuels', { ...data });

      this.getList();
      this.handleNew();
      toast.success('Tanque criado com sucesso.');
      onTankRefuelSubmit();
      this.setState({ tank: null });
    } catch (err) {
      toast.error('Um erro aconteceu durante o cadastro!');
    }
  };

  handleEdit = async object => {
    this.setState({ tankRefuel: object });
    try {
      const response = await API.get(`/tanks/${object.tankUuid}`);

      this.setState({
        tank: response.data,
      });
    } catch (err) {
      console.log(err)
      toast.error('Um erro aconteceu durante o carregamento!');
    }
  };

  handleNew = () => {
    this.setState({ tankRefuel: null });
  };

  handleUpdate = async object => {
    const { onTankRefuelSubmit } = this.props;
    const { tankRefuel } = this.state;

    object.value = String(object.value).replace(',', '.');

    const data = {
      ...object,
      tankUuid: object.tank.uuid,
    };

    try {
      await API.put(`/tankRefuels/${tankRefuel.uuid}`, { ...data });

      this.getList();
      this.handleNew();
      toast.success('Tanque atualizado com sucesso.');
      onTankRefuelSubmit();
      this.setState({ tank: null });
    } catch (err) {
      console.log(err)
      toast.error('Um erro aconteceu durante a atualização!');
    }
  };

  handleDelete = async uuid => {
    const { onTankRefuelSubmit } = this.props;

    try {
      const confirmDelete = await swal({
        dangerMode: true,
        text: 'Confirma a exclusão de tanque?',
        buttons: {
          cancel: 'Não',
          confirm: 'Sim',
        },
      });

      if (confirmDelete) {
        await API.delete(`/tankRefuels/${uuid}`);
        this.handleNew();
        await this.getList();
        toast.success('Tanque deletado com sucesso!');
        onTankRefuelSubmit();
      }
    } catch (err) {
      toast.error('Um erro aconteceu durante a exclusão!');
    }
  };

  render() {
    const { tankForm } = this.props;
    const { loading, tankRefuels, tankRefuel, tank } = this.state;

    return (
      <SubContainer visible={tankForm}>
        <div ref={this.refTank} />
        <TankForm
          onCreation={this.handleCreation}
          onUpdate={this.handleUpdate}
          onNew={this.handleNew}
          tankRefuel={tankRefuel}
          tankList={this.props.tankList}
          tank={tank}
        />
        {loading ? (
          <ReactLoading type="spin" color="#011826" height={40} width={40} />
        ) : (
          <TankList
            tankRefuels={tankRefuels}
            onEdit={this.handleEdit}
            onDelete={this.handleDelete}
          />
        )}
      </SubContainer>
    );
  }
}

TankContainer.propTypes = {
  tankForm: PropTypes.bool.isRequired,
  onTankList: PropTypes.func.isRequired,
  tank: PropTypes.shape({
    uuid: PropTypes.string,
  }),
  onTankRefuelSubmit: PropTypes.func.isRequired,
};

TankContainer.defaultProps = {
  tank: null,
};

export default TankContainer;
