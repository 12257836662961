import React from 'react';
import { Switch, Route } from 'react-router-dom';

// Views
import Home from './app/views/Home/index';
import MaintenanceContainer from './app/views/Maintenance';
import FinancialContainer from './app/views/Financial';
import RefuelContainer from './app/views/Refuel';
import ServiceContainer from './app/views/Service';
import Usuarios from './app/views/Usuarios';
import NovoUsuario from './app/views/Usuarios/new';
import PaymentContainer from './app/views/Payment';
import MaintenancePlanContainer from './app/views/MaintenancePlans';
import PieceInventoryContainer from './app/views/PieceInventory';
import EmployeePage from './app/views/Employee/employeePage';

export default function Routes({
  authMaintanance,
  authRefuel,
  authFinancial,
  authService,
  authPayment,
  authPieceInventory,
  isAuth,
  teamName,
  useruuid,
  authEmployee,
}) {
  return (
    <Switch>
      {isAuth && (
        <>
          <Route path="/home" render={() => <Home team={teamName} />} />
          <Route path="/usuarios" exact component={Usuarios} />
          <Route path="/usuarios/novo" exact component={NovoUsuario} />
          {authMaintanance && (
            <>
              <Route
                path="/maintenances"
                exact
                component={MaintenanceContainer}
              />
              <Route
                path="/maintenanceplans"
                exact
                component={MaintenancePlanContainer}
              />
            </>
          )}
          {authFinancial && (
            <Route path="/financials" exact component={FinancialContainer} />
          )}

          {authRefuel && (
            <Route
              path="/refuels"
              exact
              render={() => <RefuelContainer useruuid={useruuid} />}
            />
          )}

          {authService && (
            <Route
              path="/services"
              exact
              render={() => <ServiceContainer useruuid={useruuid} />}
            />
          )}
          {authPayment && (
            <Route
              path="/payments"
              exact
              render={() => (
                <PaymentContainer useruuid={useruuid} team={teamName} />
              )}
            />
          )}

          {authPieceInventory && (
            <Route
              path="/inventories"
              exact
              render={() => (
                <PieceInventoryContainer useruuid={useruuid} team={teamName} />
              )}
            />
          )}
          {authEmployee && (
            <Route
            path="/employees"
            exact
            render={() => (
              <EmployeePage useruuid={useruuid} team={teamName} />
            )}
          />
          )}
          <Route path="/" exact render={() => <Home team={teamName} />} />
        </>
      )}
    </Switch>
  );
}
