import React, { useState, useEffect } from 'react';

import { Link } from 'react-router-dom';

import API from '../../../services/api';

import logo from '../../../img/logoServiSaPequeno.png';

import imgUser from '../../../img/user-header.png';

import { UserImage } from './styles';

export default function Header() {
  const [useruuid, setUserUuid] = useState(localStorage.getItem('USER_UUID'));

  const [userPhoto, setUserPhoto] = useState(imgUser);

  useEffect(() => {
    async function load() {
      await setUserUuid(localStorage.getItem('USER_UUID'));
    }

    load();
  }, []);

  useEffect(() => {
    async function getUser() {
      const response = await API.get(`users/${useruuid}`);

      const { photo } = response.data;

      if (photo !== 'photo') {
        setUserPhoto(photo);
      }
    }

    getUser();
  }, [useruuid]);

  function btnMenu() {
    const menu = document.querySelector('.main-menu');

    menu.classList.add('ativo');
  }

  return (
    <header className="main-header">
      <div className="center">
        <Link to="/home">
          <div className="logo">
            <img src={logo} alt="" width="40%" />
          </div>
        </Link>

        <nav className="main-nav" />

        <div className="user-header">
          <UserImage src={userPhoto} className="logo" alt="" />

          <div className="icon-menu" onClick={btnMenu}>
            <span />

            <span />

            <span />
          </div>
        </div>
      </div>
    </header>
  );
}
