import React from 'react';
import { Formik } from 'formik';
import PropTypes from 'prop-types';

import { FormStyled, Row, Column } from '../../css/styles/form';


const handleValidate = ({ description }) => {
  const errors = {};

  if (!description) errors.description = 'OBRIGATÓRIO';
  return errors;
};

const handleSubmit = props => async (values, { setSubmitting }) => {
  if (!props.maintenancePlan) {
    await props.onCreation({ ...values });
  } else {
    await props.onUpdate({ ...values });
  }

  setSubmitting(false);
};

const MaintenancePlanForm = props => {
  const { maintenancePlan, onNew } = props;
  return (
    <Formik
      enableReinitialize
      initialValues={{
        description: maintenancePlan ? maintenancePlan.description : '',
      }}
      validate={handleValidate}
      onSubmit={handleSubmit(props)}
    >
      {({
        values,

        handleReset,

        errors,

        touched,

        handleChange,

        handleBlur,

        handleSubmit,

        isSubmitting,

        dirty,
      }) => (
        <FormStyled onSubmit={handleSubmit}>
          <h1>{maintenancePlan ? 'ALTERAR PLANO' : 'NOVO PLANO'}</h1>

          <ul>
            <Row>
              <Column>
                <strong>DESCRIÇÃO</strong>
                <input
                  id="description"
                  name="description"
                  type="text"
                  value={values.description}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />

                {errors.description && touched.description && (
                  <span>{errors.description}</span>
                )}
              </Column>
            </Row>

            <Row>
              <div className="buttons-container">
                <button type="submit" disabled={isSubmitting}>
                  SALVAR
                </button>

                <button
                  type="button"
                  onClick={handleReset}
                  disabled={!dirty || isSubmitting}
                >
                  RESET
                </button>

                {maintenancePlan && (
                  <button type="button" onClick={onNew}>
                    LIMPAR
                  </button>
                )}
              </div>
            </Row>
          </ul>
        </FormStyled>
      )}
    </Formik>
  );
};

MaintenancePlanForm.propTypes = {
  maintenancePlan: PropTypes.object,
  onCreation: PropTypes.func,
  onUpdate: PropTypes.func,
  onNew: PropTypes.func,
};

export default MaintenancePlanForm;

