import React from 'react';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import Select from 'react-select';

// Styles
import { FormStyled, Row, Column } from '../../../css/styles/form';
import { SelectStyled } from '../../../css/styles/select';

const handleValidate = ({ quantity, value, piece }) => {
  const errors = {};

  if (!quantity) errors.quantity = 'OBRIGATÓRIO';
  if (!value) errors.value = 'OBRIGATÓRIO';
  if (!piece) errors.piece = 'OBRIGATÓRIO';

  return errors;
};

const handleSubmit = props => async (values, { setSubmitting, resetForm }) => {
  if (props.maintenancePiece) {
    await props.onUpdate({ ...values });
  } else {
    await props.onCreation({ ...values });
  }

  setSubmitting(false);
  resetForm();
};

const MaintenancePieceForm = props => {
  const { onNew, pieces, piece, maintenancePiece } = props;

  return (
    <Formik
      enableReinitialize
      initialValues={{
        piece: maintenancePiece ? maintenancePiece.piece : piece || null,

        quantity: maintenancePiece ? maintenancePiece.quantity : '',

        value: maintenancePiece ? maintenancePiece.value : '',
      }}
      validate={handleValidate}
      onSubmit={handleSubmit(props)}
    >
      {({
        values,
        handleReset,
        handleBlur,
        handleChange,
        errors,
        touched,
        setFieldValue,
        isSubmitting,
        dirty,
      }) => (
        <FormStyled>
          <h1>
            {maintenancePiece
              ? 'ALTERAR  VEÍCULO DA MANUTENÇÃO'
              : 'NOVO VEÍCULO DA MANUTENÇÃO'}
          </h1>
          <ul>
            <Row>
              <Column>
                <strong>PEÇA</strong>
                <Select
                  id="piece"
                  name="piece"
                  styles={SelectStyled}
                  value={values.piece}
                  placeholder="Selecione um veículo"
                  onChange={selectedOption =>
                    setFieldValue('piece', selectedOption)
                  }
                  isSearchable
                  options={pieces}
                  getOptionLabel={item => item.description}
                  getOptionValue={item => item.uuid}
                  isLoading={false}
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  loadingMessage={() => 'Buscando veículos'}
                  noOptionsMessage={() => 'Não há veículos cadastrados'}
                  formatOptionLabel={(item, { context }) =>
                    context === 'menu' ? (
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          width: '100%',
                        }}
                      >
                        <div>{item.description}</div>
                        <div>{`QTD EM ESTOQUE: ${item.total ?? 0}`}</div>
                      </div>
                    ) : (
                      item.description
                    )
                  }
                  onBlur={handleBlur}
                />
                {errors.piece && touched.piece && <span>{errors.piece}</span>}
              </Column>
            </Row>

            <Row>
              <Column>
                <h1>
                  {values.piece &&
                    `QUANTIDADE EM ESTOQUE: ${
                      values.piece?.total !== 0 && values.piece?.total !== null
                        ? values.piece?.total
                        : 'PEÇA EM FALTA NO ESTOQUE'
                    }`}
                </h1>
              </Column>
            </Row>

            <Row>
              <Column>
                <strong>QUANTIDADE</strong>

                <input
                  type="number"
                  name="quantity"
                  value={values.quantity}
                  onChange={e => {
                    setFieldValue('quantity', e.target.value);
                  }}
                />

                {errors.quantity && touched.quantity && (
                  <span>{errors.quantity}</span>
                )}
              </Column>

              <Column>
                <strong>VALOR</strong>
                <input
                  id="value"
                  name="value"
                  type="text"
                  value={values.value}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />

                {errors.value && touched.value && <span>{errors.value}</span>}
              </Column>
            </Row>
            {console.log(
              values.piece?.total,
              values.quantity,
            )}
            <Row>
              <div className="buttons-container">
                <button
                  type="submit"
                  disabled={  
                    (maintenancePiece &&
                      values.quantity <= 0 || values.quantity <= 0) ||
                    // values.quantity > values.piece?.total ||
                    !dirty ||
                    isSubmitting
                  }
                >
                  SALVAR
                </button>

                <button
                  type="button"
                  onClick={handleReset}
                  disabled={!dirty || isSubmitting}
                >
                  RESET
                </button>

                {maintenancePiece && (
                  <button
                    type="button"
                    onClick={onNew}
                    disabled={(!dirty && !maintenancePiece) || isSubmitting}
                  >
                    LIMPAR
                  </button>
                )}
              </div>
            </Row>
          </ul>
        </FormStyled>
      )}
    </Formik>
  );
};

MaintenancePieceForm.propTypes = {
  pieces: PropTypes.arrayOf(
    PropTypes.shape({
      uuid: PropTypes.string,
      description: PropTypes.string,
    })
  ),
  piece: PropTypes.shape({
    uuid: PropTypes.string,
    description: PropTypes.string,
  }),
  maintenancePiece: PropTypes.shape({
    quantity: PropTypes.number,
    value: PropTypes.number,
    piece: PropTypes.shape({
      uuid: PropTypes.string,
      description: PropTypes.string,
    }),
  }),
  onNew: PropTypes.func.isRequired,
};

MaintenancePieceForm.defaultProps = {
  pieces: [],
  piece: null,
  maintenancePiece: null,
};

export default MaintenancePieceForm;
