import React from 'react';
import moment from 'moment';
import { IoMdTrash } from 'react-icons/io';

import PropTypes from 'prop-types';

import { ListStyled, ListItem } from '../../../css/styles/list';

const listItem = props =>
  props.serviceExtraDays.map(item => (
    <ListItem
      color="#011826"
      strongColor="#fff"
      key={item.uuid}
      onClick={() => props.onEdit(item)}
    >
      <div className="info">
      <div>
          <strong className="title">DATA DE CRIAÇÃO: </strong>
          <strong>{moment(item.createdAt).format('DD/MM/YYYY')}</strong>
        </div>
        <div>
          <strong className="title">DIA EXTRA: </strong>
          <strong>{moment(item.extraDay).format('DD/MM/YYYY')}</strong>
        </div>

        <div>
          <strong className="title">DESCRIÇÃO: </strong>
          <strong>{item.description}</strong>
        </div>
      </div>
      <button
        type="button"
        onClick={e => {
          e.stopPropagation();

          props.onDelete(item.uuid);
        }}
        className="delete"
      >
        <IoMdTrash size={20} color="#bf1523" />
      </button>
    </ListItem>
  ));

function ServiceExtraDaysList(props) {
  const { serviceExtraDays } = props;

  return (
    <>
      {serviceExtraDays && serviceExtraDays.length > 0 ? (
        <ListStyled>{listItem(props)}</ListStyled>
      ) : (
        <strong>LISTA VAZIA</strong>
      )}
    </>
  );
}

ServiceExtraDaysList.propTypes = {
  serviceExtraDays: PropTypes.arrayOf(
    PropTypes.shape({
      uuid: PropTypes.string,

      createdAt: PropTypes.string,

      description: PropTypes.string,
    })
  ),
};

ServiceExtraDaysList.defaultProps = {
  serviceExtraDays: [],
};

export default ServiceExtraDaysList;

