import React from 'react';
import moment from 'moment';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import Select from 'react-select';

import {
  FilterFormStyled,
  FilterRow,
  FilterColumn,
} from '../../css/styles/filter';
import { SelectStyled } from '../../css/styles/select';

const handleValidate = ({ begin, end }) => {
  const errors = {};

  if (!begin) errors.begin = 'OBRIGATÓRIO';

  if (moment(begin).isAfter(end))
    errors.end = 'DATA FINAL NÃO PODE SER MENOR QUE INICIAL';

  if (!end) errors.end = 'OBRIGATÓRIO';

  return errors;
};

const handleSubmit = props => async (values, { setSubmitting }) => {
  await props.onList({ ...values });

  setSubmitting(false);
};

const FinancialFilterForm = props => {
  const { partners, accounts, costs } = props;

  const typeOptions = [
    {
      label: 'EM ABERTO',
      value: 'EM ABERTO',
    },
    {
      label: 'BAIXADAS',
      value: 'BAIXADAS',
    },
  ];

  return (
    <Formik
      enableReinitialize
      initialValues={{
        begin: new Date().toISOString().substring(0, 10),
        number: '',
        end: new Date().toISOString().substring(0, 10),
        account: null,
        partner: null,
        cost: null,
        installmentType: '',
      }}
      validate={handleValidate}
      onSubmit={handleSubmit(props)}
    >
      {({
        values,
        handleChange,
        handleBlur,
        handleReset,
        dirty,
        errors,
        touched,
        setFieldValue,
        isSubmitting,
      }) => (
        <FilterFormStyled>
          <h1>FILTROS</h1>

          <ul>
            <FilterRow>
              <FilterColumn>
                <strong>VENCIMENTO INICIAL</strong>
                <input
                  id="begin"
                  name="begin"
                  type="date"
                  value={values.begin}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />

                {errors.begin && touched.begin && <span>{errors.begin}</span>}
              </FilterColumn>

              <FilterColumn>
                <strong>VENCIMENTO FINAL</strong>
                <input
                  id="end"
                  name="end"
                  type="date"
                  value={values.end}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />

                {errors.end && touched.end && <span>{errors.end}</span>}
              </FilterColumn>
            </FilterRow>

            <FilterRow>
              <FilterColumn>
                <strong>NÚMERO</strong>
                <input
                  id="number"
                  name="number"
                  type="number"
                  value={values.number}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />

                {errors.number && touched.number && (
                  <span>{errors.number}</span>
                )}
              </FilterColumn>
            </FilterRow>

            <FilterRow>
              <FilterColumn>
                <strong>FORNECEDOR OU CLIENTE</strong>
                <Select
                  styles={SelectStyled}
                  id="partner"
                  value={values.partner}
                  placeholder="Selecione um funcionário"
                  onChange={selectedOption => {
                    setFieldValue('partner', selectedOption);
                  }}
                  isSearchable
                  options={partners}
                  getOptionLabel={item => item.name}
                  getOptionValue={item => item.uuid}
                  name="partner"
                  isLoading={false}
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  loadingMessage={() => 'Buscando funcionários'}
                  noOptionsMessage={() => 'Não há funcionários cadastrados'}
                />

                {errors.partner && touched.partner && (
                  <span>{errors.partner}</span>
                )}
              </FilterColumn>
            </FilterRow>

            <FilterRow>
              <FilterColumn>
                <strong>CONTAS</strong>
                <Select
                  styles={SelectStyled}
                  id="account"
                  value={values.account}
                  placeholder="Selecione um conta"
                  onChange={selectedOption => {
                    setFieldValue('account', selectedOption);
                  }}
                  isSearchable
                  options={accounts}
                  getOptionLabel={item =>
                    `${item.name} (${
                      item.balance
                        ? item.balance.toLocaleString('pt-BR', {
                            minimumFractionDigits: 2,
                          })
                        : '0,00'
                    })`
                  }
                  getOptionValue={item => item.uuid}
                  name="account"
                  isLoading={false}
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  loadingMessage={() => 'Buscando contas'}
                  noOptionsMessage={() => 'Não há contas'}
                />

                {errors.account && touched.account && (
                  <span>{errors.account}</span>
                )}
              </FilterColumn>
            </FilterRow>

            <FilterRow>
              <FilterColumn>
                <strong>CENTROS DE CUSTO</strong>
                <Select
                  styles={SelectStyled}
                  id="cost"
                  value={values.cost}
                  placeholder="Selecione um centro de custo"
                  onChange={selectedOption => {
                    setFieldValue('cost', selectedOption);
                  }}
                  isSearchable
                  options={costs}
                  getOptionLabel={item => item.description}
                  getOptionValue={item => item.uuid}
                  name="cost"
                  isLoading={false}
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  loadingMessage={() => 'Buscando centros de custo'}
                  noOptionsMessage={() => 'Não há centros de custo'}
                />

                {errors.cost && touched.cost && <span>{errors.cost}</span>}
              </FilterColumn>
            </FilterRow>

            <FilterRow>
              <FilterColumn>
                <strong>TIPO</strong>
                <select
                  id="installmentType"
                  name="installmentType"
                  value={!values.account ? values.installmentType : ''}
                  disabled={values.account}
                  onChange={handleChange}
                  onBlur={handleBlur}
                >
                  <option value="" />
                  {typeOptions.map(installmentType => (
                    <option
                      key={installmentType.value}
                      value={installmentType.value}
                    >
                      {installmentType.label}
                    </option>
                  ))}
                </select>

                {errors.installmentType && touched.installmentType && (
                  <span>{errors.installmentType}</span>
                )}
              </FilterColumn>
            </FilterRow>

            <FilterRow>
              <div className="buttons-container">
                <button type="submit" disabled={isSubmitting}>
                  BUSCAR
                </button>

                <button
                  type="button"
                  onClick={handleReset}
                  disabled={isSubmitting || !dirty}
                >
                  RESET
                </button>
              </div>
            </FilterRow>
          </ul>
        </FilterFormStyled>
      )}
    </Formik>
  );
};

FinancialFilterForm.propTypes = {
  accounts: PropTypes.arrayOf(
    PropTypes.shape({
      uuid: PropTypes.string,
      description: PropTypes.string,
    })
  ),
  partners: PropTypes.arrayOf(
    PropTypes.shape({
      uuid: PropTypes.string,
      name: PropTypes.string,
    })
  ),
  costs: PropTypes.arrayOf(
    PropTypes.shape({
      uuid: PropTypes.string,
      description: PropTypes.string,
    })
  ),
};

FinancialFilterForm.defaultProps = {
  accounts: [],
  partners: [],
  costs: [],
};

export default FinancialFilterForm;
