import React, { Fragment } from 'react';
import Loading from '../components/Loading';
import { ListStyled, ListItem } from '../../css/styles/list';
import { IoMdTrash } from 'react-icons/io';
import { FormStyled } from '../../css/styles/form';

const listItem = props =>
  props.inventories.map(item => (
    <ListItem color="#011826" strongColor="#fff" key={item.uuid}>
      <div className="info">
        <div>
          <strong className="title">DATA: </strong>
          <strong>
            {new Date(item.createdAt).toLocaleDateString('pt-BR', {
              timeZone: 'UTC',
            })}
          </strong>
        </div>
        <div>
          <strong className="title">DOC: </strong>
          <strong>
            {item.piece_inventory_history
              ? item.piece_inventory_history.document
              : '-'}
          </strong>
        </div>
        <div>
          <strong className="title">PEÇA: </strong>
          <strong>
            {item.piece
              ? `
                ${item.piece.description}
                
              `
              : '-'}
          </strong>
        </div>
        <div>
          <strong className="title">VALOR UNITÁRIO: </strong>
          <strong>{item.value? `R$ ${item.value && item.value.toLocaleString()}` : '-'}</strong>
        </div>
        <div>
          <strong className="title">VALOR TOTAL: </strong>
          <strong>{item.value? `R$ ${(item.value * item.quantity).toLocaleString()}` : '-'}</strong>
        </div>
        <div>
          <strong className="title">QTD: </strong>
          <strong>{item.quantity || '-'}</strong>
        </div>
        <div>
          <strong className="title">QTD EM ESTOQUE: </strong>
          <strong>{item.piece.total || '-'}</strong>
        </div>
      </div>

      <button
        type="button"
        onClick={e => {
          e.stopPropagation();
          props.onDelete(item.uuid);
        }}
        className="delete"
      >
        <IoMdTrash size={20} color="#bf1523" />
      </button>
    </ListItem>
  ));

function PieceIventoryList(props) {
  const { inventories, loadingList } = props;
  return (
    <>
      {!props.loadingList ? (
        props.inventories.length > 0 ? (
          <ListStyled>
            <header>
              <div className="total">
                <h1>TOTAL:</h1>
                <p>{inventories.length}</p>
              </div>
            </header>

            {listItem(props)}
          </ListStyled>
        ) : (
          <strong>LISTA VAZIA</strong>
        )
      ) : (
        <strong>LISTA VAZIA</strong>
      )}
    </>
  );
}

export default PieceIventoryList;

