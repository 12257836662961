import React, { Component } from 'react';
import ReactLoading from 'react-loading';
import io from 'socket.io-client';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import swal from '@sweetalert/with-react';
import AccountTransferList from './list';
import AccountTransferForm from './form';
import API from '../../services/api';
import Parameters from '../../services/parameters';

// Styles
import { SubContainer } from '../../css/styles/wrapper';

class AccountTransferContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      accounts: [],
      accountTransfers: [],
    };

    this.handleCreation = this.handleCreation.bind(this);
  }

  componentDidMount() {
    this.registerToSocket();
    this.getList();
    this.getAccountList();
  }

  registerToSocket = () => {
    const socket = io(Parameters.URL_API);

    socket.on('accountTransfer', () => this.getList());
  };

  refAccountTransfer = React.createRef();

  getList = async () => {
    this.setState({
      loading: true,
    });

    try {
      const response = await API.get('/accountTransfers');
      this.setState({
        accountTransfers: response.data,
        loading: false,
      });
    } catch (err) {
      toast.error('Um erro aconteceu durante o carregamento!');
      this.setState({ loading: false });
    }
  };

  getAccountList = async () => {
    const { onAccountList } = this.props;

    this.setState({
      loading: true,
    });
    try {
      const response = await API.get('/accounts/');
      this.setState({
        accounts: response.data,
        loading: false,
      });
      onAccountList(response.data);
    } catch (err) {
      toast.error('Um erro aconteceu durante o carregamento!');
      this.setState({ loading: false });
    }
  };

  handleCreation = async object => {
    const { onAccountTransferSubmit } = this.props;

    const data = {
      qty: object.qty,

      originUuid: object.origin.uuid,

      destinyUuid: object.destiny.uuid,
    };

    try {
      await API.post('/accountTransfers', { ...data });

      this.getList();
      this.getAccountList();
      toast.success('Transferência criada com sucesso');
      onAccountTransferSubmit();
    } catch (err) {
      toast.error('Um erro aconteceu durante o cadastro!');
    }
  };

  handleDelete = async uuid => {
    const { onAccountTransferSubmit } = this.props;

    try {
      const confirmDelete = await swal({
        dangerMode: true,
        text: 'Confirma a exclusão da transferência?',
        buttons: {
          cancel: 'Não',
          confirm: 'Sim',
        },
      });

      if (confirmDelete) {
        await API.delete(`accountTransfers/${uuid}`);
        this.getList();
        this.getAccountList();
        toast.success('Transferência deletada com sucesso!');
        onAccountTransferSubmit();
      }
    } catch (err) {
      toast.error('Um erro aconteceu durante a exclusão!');
    }
  };

  render() {
    const { accountTransferForm } = this.props;
    const { loading, accounts, accountTransfers } = this.state;

    return (
      <SubContainer visible={accountTransferForm}>
        <div ref={this.refAccountTransfer} />
        <AccountTransferForm
          onCreation={this.handleCreation}
          accounts={accounts}
        />
        {loading ? (
          <ReactLoading type="spin" color="#011826" height={40} width={40} />
        ) : (
          <AccountTransferList
            accountTransfers={accountTransfers}
            onDelete={this.handleDelete}
          />
        )}
      </SubContainer>
    );
  }
}

AccountTransferContainer.propTypes = {
  accountTransferForm: PropTypes.bool.isRequired,
  onAccountTransferSubmit: PropTypes.func.isRequired,
  onAccountList: PropTypes.func.isRequired,
};

export default AccountTransferContainer;
