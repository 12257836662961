import React, { Component } from 'react';
import ReactLoading from 'react-loading';
import { toast } from 'react-toastify';
import swal from '@sweetalert/with-react';
import PropTypes from 'prop-types';
import EmployeeForm from './form';
import API from '../../services/api';

// Styles
import EmployeeList from './list';
import EmployeeContainer from '.';
import {
  Wrapper,
  Container,
  Content,
  SubContainer,
} from '../../css/styles/wrapper';
import EmployeeFilterForm from './employeeFilter';

class EmployeePage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      employee: null,
      employeeForm: false,
      employeeFilter: true,
      employees: [],
      employeeList: [],
      document: null,
      loading: false,
    };

    this.handleEdit = this.handleEdit.bind(this);

    this.getList();
  }

  componentDidMount() {
    this.getList();
  }

  getList = async value => {
    this.setState({ loading: true });
    // const { onEmployeesList } = this.props;

    try {
      const response = await API.get('/employees');
      this.setState({
        employeeList: response.data,
        employees: response.data,
        loading: false,
      });

      // onEmployeesList(response.data, value);
    } catch (err) {
      toast.error('Um erro aconteceu durante o carregamento!');
      this.setState({ loading: false });
    }
  };

  handleCreation = async value => {
    const { onEmployeeSubmit } = this.props;

    const data = {
      name: value.name.toUpperCase(),
      telephone: value.telephone || null,
      cnh: value.cnh || null,
      address: value.address || null,
      nickname: value.nickname || null,
      document: value.document || null,
      account: value.account || null,
      account_verifying_digit: value.account_verifying_digit || null,
      branch: value.branch || null,
      bank: value.bank || null,
      key: value.key || null,
    };

    try {
      const response = await API.post('employees', { ...data });

      this.getList(response.data);
      this.handleNew();
      toast.success('Funcionário criado com sucesso!');
      // onEmployeeSubmit();
    } catch (err) {
      toast.error('Um erro aconteceu durante o cadastro!');
    }
  };

  getEmployeeList = async values => {
    if (values) {
      await this.setState({
        employee: values.employee || null,
        document: values.document || null,
        telephone: values.telephone || null,
        cnh: values.cnh || null,
      });
    }
    console.log(this.state.document);

    const { employee, document, telephone, cnh } = this.state;
    console.log(document);

    try {
      console.log(values.employee);
      const response = await API.get(
        `employees/${employee?.uuid || null}/${document || null}/${telephone ||
          null}/${cnh || null}`
      );

      this.setState({ employeeList: response?.data || null, loading: false });
      console.log(response);
    } catch (err) {
      toast.error('Um erro aconteceu durante o carregamento!');
      this.setState({ loading: false });
    }
  };

  handleEdit = object => {
    const { employeeForm, employeeFilter } = this.state;
    console.log(
      object,
      '<<<<<---------------------------------------------ooaojsadioasd---'
    );
    this.setState({
      employee: object,
      employeeForm: !employeeForm,
      employeeFilter: !employeeFilter,
    });
  };

  handleEmployeeFormState() {
    const { employeeForm, employeeFilter } = this.state;

    this.setState({
      employeeForm: !employeeForm,
      employeeFilter: !employeeFilter,
    });
  }

  handleEmployeesList = (employees, employee) => {
    this.setState({
      employees,
      employeeList: employees,
      employee: employee,
    });
  };

  handleNew = () => {
    this.setState({
      employee: null,
      employeeForm: false,
      employeeFilter: true,
    });
  };

  handleUpdate = async value => {
    // const { onEmployeeSubmit } = this.props;
    const { employee } = this.state;

    const data = {
      name: value.name.toUpperCase(),
      telephone: value.telephone || null,
      cnh: value.cnh || null,
      address: value.address || null,
      nickname: value.nickname || null,
      document: value.document || null,
      account: value.account || null,
      account_verifying_digit: value.account_verifying_digit || null,
      branch: value.branch || null,
      bank: value.bank || null,
      key: value.key || null,
    };

    try {
      await API.put(`/employees/${employee.uuid}`, { ...data });

      this.getList();
      this.handleNew();
      toast.success('Funcionário atualizada com sucesso.');
      // onEmployeeSubmit();
    } catch (err) {
      toast.error('Um erro aconteceu durante a atualização!');
    }
  };

  handleDelete = async uuid => {
    console.log(uuid);

    try {
      const confirmDelete = await swal({
        dangerMode: true,
        text: 'Confirma a exclusão do funcionário?',
        buttons: {
          cancel: 'Não',
          confirm: 'Sim',
        },
      });

      if (confirmDelete) {
        await API.delete(`employees/${uuid}`);

        this.getList();

        toast.success('Funcionário deletado com sucesso!');
      }
    } catch (err) {
      toast.error(
        'Não é possível excluir funcionários relacionados a serviços ou manutenções!'
      );
    }
  };

  render() {
    const {
      employee,
      employees,
      employeeForm,
      employeeFilter,
      employeeList,
      loading,
    } = this.state;
    // const {employee} = this.props

    return (
      <Wrapper>
        <Container>
          <header>
            <h1>FUNCIONÁRIOS</h1>
            <div className="buttons-container">
              <button
                type="button"
                onClick={() => this.handleEmployeeFormState()}
              >
                {employeeForm ? 'LISTA' : 'NOVO FUNCIONÁRIO'}
              </button>
            </div>
          </header>
          <Content>
            <SubContainer visible>
              <SubContainer visible={employeeFilter}>
                <div ref={this.refEmployees} />
                {loading ? (
                  <ReactLoading
                    type="spin"
                    color="#011826"
                    height={40}
                    width={40}
                  />
                ) : (
                  <EmployeeFilterForm
                    onList={this.getEmployeeList}
                    employees={employees}
                    employeeFilter={employeeFilter}
                    onEmployeeSubmit={() => this.handleEmployeeFormState()}
                  />
                )}
                <EmployeeList
                  employees={employees}
                  employeeList={employeeList}
                  onEdit={this.handleEdit}
                  onNew={this.handleNew}
                  onDelete={this.handleDelete}
                  team={this.props.team}
                  loading={loading}
                />
              </SubContainer>

              <SubContainer visible={employeeForm}>
                <EmployeeForm
                  employeeList={employeeList}
                  employee={employee}
                  employees={employees}
                  onCreation={this.handleCreation}
                  onEdit={this.handleEdit}
                  onUpdate={this.handleUpdate}
                  onNew={this.handleNew}
                  onDelete={this.handleDelete}
                  team={this.props.team}
                />
              </SubContainer>
              {/* <EmployeeContainer
                employeeList={true}
                employeeForm={employeeForm}
                onEmployeesList={this.handleEmployeesList}
                onEmployeeSubmit={() => this.handleEmployeeFormState()}
                employee={employee}
                employees={employees}
              /> */}
            </SubContainer>
          </Content>
        </Container>
      </Wrapper>
    );
  }
}

EmployeePage.propTypes = {
  employeeForm: PropTypes.bool,
  onEmployeeSubmit: PropTypes.func,
  onEmployeesList: PropTypes.func,
  team: PropTypes.object,
};

export default EmployeePage;

