import React, { Component } from 'react';

import ReactLoading from 'react-loading';

import swal from 'sweetalert';

import { toast } from 'react-toastify';

import PropTypes from 'prop-types';

import ServiceEquipmentEmployeeForm from './form';

import ServiceEquipmentEmployeeList from './list';

import API from '../../../services/api';

// Styles

import { SubContainer } from '../../../css/styles/wrapper';

class ServiceEquipmentEmployeeContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,

      serviceequipmentemployees: null,
    };

    this.handleCreation = this.handleCreation.bind(this);
  }

  componentDidMount() {
    this.getList();
  }

  componentDidUpdate(prevProps) {
    const { serviceEquipment } = this.props;
    if (prevProps.serviceEquipment !== serviceEquipment) {
      this.handleNew();
      this.getList();
    }
  }

  refServiceEquipmentEmployee = React.createRef();

  scrollToMyRef = myRef => {
    window.scrollTo(0, myRef.current.offsetTop);
  };

  getList = async () => {
    const { serviceEquipment, onServiceEquipmentEmployeesList } = this.props;
    console.log(serviceEquipment)
    this.setState({
      loading: true,
    });

    try {
      const response = await API.get(
        `serviceequipmentemployees/list/${serviceEquipment.uuid}`
      );

      this.setState({
        loading: false,
        serviceequipmentemployees: response.data,
      });

      onServiceEquipmentEmployeesList(response.data);
    } catch (err) {
      toast.error('Um erro aconteceu durante o carregamento!');
      this.setState({
        loading: false,
      });
    }
  };

  handleCreation = async object => {
    const { serviceEquipment } = this.props;

    const data = {
      description: object.description,

      employeeUuid: object.employee.uuid,

      serviceequipmentUuid: serviceEquipment.uuid,
    };

    try {
      await API.post('/serviceequipmentemployees/', {
        ...data,
      });

      this.getList();

      this.handleNew();
      toast.success('Funcionário do serviço cadastrado com sucesso!');
    } catch (err) {
      toast.error('Um erro aconteceu durante o cadastro!');
    }
  };

  handleEdit = object => {
    this.setState({ serviceequipmentemployee: object });
  };

  handleNew = () => {
    this.setState({ serviceequipmentemployee: null });
  };

  handleUpdate = async object => {
    const { serviceEquipment } = this.props;

    const { serviceequipmentemployee } = this.state;

    const data = {
      description: object.description,

      employeeUuid: object.employee.uuid,

      serviceequipmentUuid: serviceEquipment.uuid,
    };

    try {
      await API.put(
        `/serviceequipmentemployees/${serviceequipmentemployee.uuid}`,
        {
          ...data,
        }
      );

      this.getList();
      this.handleNew();
      toast.success('Funcionário do serviço atualizado com sucesso.');
    } catch (error) {
      toast.error('Um erro aconteceu durante a atualização!');
    }
  };

  handleDelete = async uuid => {
    try {
      const confirmDelete = await swal({
        dangerMode: true,

        text: 'Confirma a exclusão do funcionário do serviço?',

        buttons: {
          cancel: 'Não',

          confirm: 'Sim',
        },
      });

      if (confirmDelete) {
        await API.delete(`serviceequipmentemployees/${uuid}`);
        this.getList();
        this.handleNew();
        toast.success('Funcionário do serviço deletado com sucesso!');
      }
    } catch (err) {
      toast.error('Um erro aconteceu durante a exclusão!');
    }
  };

  render() {
    const { employee, employees } = this.props;

    const {
      serviceequipmentemployee,
      serviceequipmentemployees,
      loading,
    } = this.state;

    return (
      <SubContainer visible>
        <div ref={this.refServiceEquipmentEmployee} />

        <header>
          <h1>FUNCIONÁRIOS DE SERVIÇO</h1>
        </header>

        {loading ? (
          <ReactLoading type="spin" color="#011826" height={40} width={40} />
        ) : (
          <ServiceEquipmentEmployeeList
            serviceequipmentemployees={serviceequipmentemployees}
            onEdit={this.handleEdit}
            onDelete={this.handleDelete}
          />
        )}

        <ServiceEquipmentEmployeeForm
          onUpdate={this.handleUpdate}
          onNew={this.handleNew}
          onCreation={this.handleCreation}
          serviceequipmentemployee={serviceequipmentemployee}
          employees={employees}
          employee={employee}
        />
      </SubContainer>
    );
  }
}

ServiceEquipmentEmployeeContainer.propTypes = {
  serviceEquipment: PropTypes.shape({
    uuid: PropTypes.string,
  }).isRequired,

  onServiceEquipmentEmployeesList: PropTypes.func.isRequired,

  employee: PropTypes.shape({}),

  employees: PropTypes.arrayOf(PropTypes.shape({})),
};

ServiceEquipmentEmployeeContainer.defaultProps = {
  employee: null,

  employees: [],
};

export default ServiceEquipmentEmployeeContainer;
