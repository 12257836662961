import React from 'react';
import { Formik } from 'formik';
import { Column, FormStyled, Row } from '../../css/styles/form';
import Select from 'react-select';
import propTypes from 'prop-types';
import { SelectStyled } from '../../css/styles/select';

const handleValidate = ({ description, date }) => {
  const errors = {};

  if (!description) errors.description = 'OBRIGATÓRIO';
  if (!date) errors.date = 'OBRIGATÓRIO';

  return errors;
};

const handleSubmit = props => async (values, { setSubmitting, resetForm }) => {
  if (props.holiday) {
    await props.onUpdate({ ...values });
  } else {
    await props.onCreation({ ...values });
  }

  setSubmitting(false);
  resetForm();
};

const HolidayForm = props => {
  const { holiday, bases, base, onNew } = props;

  return (
    <Formik
      enableReinitialize
      initialValues={{
        description: holiday ? holiday.description : '',
        date: holiday ? holiday.date : '',
        base: base || null,
      }}
      validate={handleValidate}
      onSubmit={handleSubmit(props)}
    >
      {({
        values,
        handleChange,
        handleBlur,
        handleReset,
        setFieldValue,
        errors,
        touched,
        isSubmitting,
        dirty,
      }) => (
        <FormStyled>
          {holiday ? <h1>ATUALIZAR FERIADO</h1> : <h1>NOVO FERIADO</h1>}

          <ul>
            <Row>
              <Column>
                <strong>BASE</strong>
                <Select
                  styles={SelectStyled}
                  id="base"
                  value={values.base}
                  placeholder="Selecione uma base"
                  onChange={selectedOption => {
                    setFieldValue('base', selectedOption);
                  }}
                  isSearchable
                  options={bases}
                  getOptionLabel={item => item.description}
                  getOptionValue={item => item.uuid}
                  name="base"
                  isLoading={false}
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  loadingMessage={() => 'Buscando bases'}
                  noOptionsMessage={() => 'Não há bases para exibir'}
                />

                {errors.base && touched.base && <span>{errors.base}</span>}
              </Column>
            </Row>
            <Row>
              <Column>
                <strong>DESCRIÇÃO</strong>
                <input
                  id="description"
                  name="description"
                  type="text"
                  value={values.description}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />

                {errors.description && touched.description && (
                  <span>{errors.description}</span>
                )}
              </Column>
            </Row>

            <Row>
              <Column>
                <strong>DATA</strong>
                <input
                  id="date"
                  name="date"
                  type="date"
                  value={values.date}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />

                {errors.date && touched.date && <span>{errors.date}</span>}
              </Column>
            </Row>

            <Row>
              <div className="buttons-container">
                <button type="submit" disabled={isSubmitting || !dirty}>
                  SALVAR
                </button>

                <button
                  type="button"
                  onClick={handleReset}
                  disabled={!dirty || isSubmitting}
                >
                  RESET
                </button>
                {holiday &&(<button type="button" onClick={onNew}>
                      LIMPAR
                    </button>  
                  )}
              </div>
            </Row>
          </ul>
        </FormStyled>
      )}
    </Formik>
  );
};

HolidayForm.propTypes = {
  onCreation: propTypes.func.isRequired,
  onUpdate: propTypes.func.isRequired,
  onNew: propTypes.func.isRequired,
  holiday: propTypes.shape({
    uuid: propTypes.string,
    description: propTypes.string,
    date: propTypes.string,
    baseUuid: propTypes.string | null,
  }),
  holidays: propTypes.arrayOf(
    propTypes.shape({
      uuid: propTypes.string,
      description: propTypes.string,
      date: propTypes.string,
      baseUuid: propTypes.string | null,
    })
  ),
  bases: propTypes.arrayOf(
    propTypes.shape({
      uuid: propTypes.string,
      description: propTypes.string,
    })
  ),
  base: propTypes.shape({
    uuid: propTypes.string,
    description: propTypes.string,
  }),
};

HolidayForm.defaultProps = {
  holiday: null,
  holidays: [],
};

export default HolidayForm;

