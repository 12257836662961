import React, { Component } from 'react';
import ReactLoading from 'react-loading';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import swal from '@sweetalert/with-react';
import moment from 'moment';
import FinancialInstallmentForm from './form';
import FinancialInstallmentList from './list';
import API from '../../../services/api';
import replaceComma from '../../../Utilities/replaceComma';

// Styles
import { SubContainer } from '../../../css/styles/wrapper';

class InstallmentContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,

      installments: null,

      installment: null,

      pay_off: false,
    };

    this.handleCreation = this.handleCreation.bind(this);
  }

  refInstallment = React.createRef();

  componentDidMount() {
    this.getList();
  }

  componentDidUpdate(prevProps) {
    const { financialItem } = this.props;
    if (prevProps.financialItem !== financialItem) {
      this.handleNew();
      this.getList();
    }
  }

  scrollToMyRef = myRef => {
    window.scrollTo(0, myRef.current.offsetTop);
  };

  getList = async () => {
    const { financialItem, onInstallmentsList } = this.props;
    this.setState({ loading: true });

    try {
      const response = await API.get(
        `/installments/${financialItem.financial.uuid}`
      );

      this.setState({
        installments: response.data,
        loading: false,
      });
      onInstallmentsList(response.data);
    } catch (err) {
      toast.error('Um erro aconteceu durante o carregamento!');
      this.setState({ loading: false });
    }
  };

  handleCreation = async object => {
    const { financialItem, onInstallmentSubmit } = this.props;

    const valueFormatted = replaceComma(object.value);

    const paid_value =
      !object.paid_value && object.payment_date
        ? object.value
        : object.paid_value
        ? object.paid_value
        : 0;
    const paidValueFormatted = replaceComma(paid_value);

    const data = {
      value: valueFormatted,
      due_date: moment.utc(object.due_date).format('YYYY-MM-DD'),
      payment_date: object.payment_date
        ? moment.utc(object.payment_date).format('YYYY-MM-DD')
        : null,
      paid_value: paidValueFormatted,
      accountUuid: object.account ? object.account.uuid : null,
      financialUuid: financialItem.financial.uuid,
    };

    try {
      const response = await API.post(`/installments`, { ...data });

      this.getList(response.data);
      this.handleNew();
      onInstallmentSubmit();
      toast.success('Cadastrado com sucesso!');
    } catch (err) {
      toast.error('Um erro aconteceu durante o cadastro!');
    }
  };

  handleNew = () => {
    this.setState({
      pay_off: false,
      installment: null,
    });
  };

  handleUpdate = async object => {
    this.setState(prevState => ({
      pay_off: object.pay_off || prevState,
    }));

    const { onInstallmentSubmit, financialItem } = this.props;
    const { installment, pay_off } = this.state;

    const valueFormatted = replaceComma(object.value);

    const paid_value =
      !object.paid_value && object.payment_date
        ? object.value
        : object.paid_value
        ? object.paid_value
        : 0;
    const paidValueFormatted = replaceComma(paid_value);

    const data = {
      value: valueFormatted,

      due_date: moment.utc(object.due_date).format('YYYY-MM-DD'),

      payment_date: object.payment_date
        ? moment.utc(object.payment_date).format('YYYY-MM-DD')
        : null,

      paid_value: paidValueFormatted,

      accountUuid: object.account ? object.account.uuid : null,

      financialUuid: financialItem.financial.uuid,
    };

    try {
      const response = await API.put(
        `/installments/${installment.uuid}/${pay_off || false}`,
        { ...data }
      );

      await this.getList(response.data);
      this.handleNew();
      onInstallmentSubmit();
      toast.success('Atualizado com sucesso!');
    } catch (error) {
      toast.error('Um erro aconteceu durante a atualização!');
    }
  };

  handleDelete = async () => {
    const { onInstallmentSubmit } = this.props;

    const { installment } = this.state;

    try {
      const confirmDelete = await swal({
        dangerMode: true,

        text: 'Confirma o cancelamento da baixa da parcela?',

        buttons: {
          cancel: 'Não',

          confirm: 'Sim',
        },
      });

      if (confirmDelete) {
        await API.put(`/installments/cancelPayment/${installment.uuid}`, {});

        await this.getList();
        this.handleNew();
        onInstallmentSubmit();
        toast.success('Cancelamento efetuado com sucesso!');
      }
    } catch (err) {
      toast.error('Um erro aconteceu durante a exclusão!');
    }
  };

  handleEdit = object => {
    this.setState({
      installment: object,
    });
    this.scrollToMyRef(this.refInstallment);
  };

  render() {
    const { installmentForm, accounts } = this.props;

    const {
      maintenanceEmployee,
      installment,
      pay_off,
      installments,
      loading,
    } = this.state;

    return (
      <SubContainer visible={installmentForm}>
        <div ref={this.refInstallment} />

        <FinancialInstallmentForm
          accounts={accounts}
          onUpdate={this.handleUpdate}
          onNew={this.handleNew}
          onCreation={this.handleCreation}
          maintenanceEmployee={maintenanceEmployee}
          installment={installment}
          onBaixa={this.handleDelete}
          pay_off={pay_off}
        />

        {loading ? (
          <ReactLoading type="spin" color="#011826" height={40} width={40} />
        ) : (
          <FinancialInstallmentList
            installments={installments}
            onEdit={this.handleEdit}
          />
        )}
      </SubContainer>
    );
  }
}

InstallmentContainer.propTypes = {
  installmentForm: PropTypes.bool.isRequired,
  accounts: PropTypes.arrayOf(
    PropTypes.shape({
      uuid: PropTypes.string,
      name: PropTypes.string,
      balance: PropTypes.number,
    })
  ).isRequired,
  financialItem: PropTypes.shape({
    financial: PropTypes.shape({
      uuid: PropTypes.string,
    }),
  }).isRequired,
  onInstallmentSubmit: PropTypes.func.isRequired,
  onInstallmentsList: PropTypes.func.isRequired,
};

export default InstallmentContainer;
