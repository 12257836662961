import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;

  background: #f2f2f2;

  display: flex;

  align-items: flex-start;

  justify-content: center;
`;

export const Container = styled.div`
  width: 100%;

  max-width: 1120px;

  display: flex;

  flex-direction: column;

  align-items: center;

  justify-content: center;

  padding: 0 32px 64px 32px;

  box-sizing: border-box;

  header {
    width: 100%;

    padding: 0 16px;

    box-sizing: border-box;

    margin: 32px 0;

    display: flex;

    gap: 16px;

    align-items: center;

    justify-content: space-between;

    h1 {
      color: #333;

      font-size: 20px;

      font-weight: bold;

      margin: 0;
    }

    .buttons-container, .financial-buttons-container {
      display: flex;
      gap: 16px;
    }

    button {
      height: 40px;

      min-width: 112px;

      border-radius: 4px;

      padding: 0 15px;

      box-sizing: border-box;

      background: #59d99d;

      font-size: 14px;

      font-weight: bold;

      color: #fff;

      &:hover {
        background: rgba($color: #59d99d, $alpha: 0.2);
      }
    }

    button.excel {
      display: flex;

      align-items: center;

      justify-content: center;

      height: 40px;

      border-radius: 4px;

      padding: 0 15px;

      box-sizing: border-box;

      background: #59d99d;

      font-size: 16px;

      font-weight: bold;

      color: #fff;

      &:hover {
        background: rgba($color: #59d99d, $alpha: 0.2);
      }

      &:disabled {
        background: #ddd;

        color: #999;

        cursor: default;
      }
    }

    a {
      display: flex;

      align-items: center;

      justify-content: center;

      height: 40px;

      border-radius: 4px;

      padding: 0 15px;

      box-sizing: border-box;

      background: #59d99d;

      font-size: 16px;

      font-weight: bold;

      color: #fff;

      &:hover {
        background: rgba($color: #59d99d, $alpha: 0.2);
      }
    }
  }

  @media screen and (max-width: 832px) {
    header.financial {
      flex-direction: column;
      text-align: center;
    }
  }

  @media screen and (max-width: 520px) {
    header {
      flex-direction: column;
      text-align: center;

      .buttons-container {
        width: 100%;
      }

      button {
        width: 100%;
      }

      &.financial {
        .financial-buttons-container {
          width: 100%;
          flex-direction: column;
        }
      }
    }
  }
`;

export const Content = styled.div`
  width: 100%;

  max-width: 900px;

  display: flex;

  flex-direction: column;

  align-items: center;

  justify-content: center;

  p {
    margin: 32px 0;
    font-size: 14px;
    color: #011826;
  }

  nav {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px;

    &.center {
      align-items: center;
      justify-content: center;
    }

    div {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 16px;

      button {
        height: 40px;

        min-width: 132px;

        border-radius: 4px;

        padding: 0 15px;

        box-sizing: border-box;

        background: #59d99d;

        font-size: 14px;

        font-weight: bold;

        color: #fff;

        &:hover {
          background: rgba($color: #59d99d, $alpha: 0.2);
        }
      }
    }

    .buttons-container {
      width: fit-content;

      button, a {
        min-width: 112px;
      }
    }

    .container-full, .container-grid {
      display: flex;
      width: 100%;

      button {
        width: 100%;
      }
    }
  }

  @media screen and (max-width: 768px) {
    nav {
      .container-full {
        flex-direction: column;
      }

      .container-grid {
        display: grid;
        grid-template-columns: 1fr 1fr;
        width: 100%;

        button {
          width: 100%;
        }
      }
    }
  }

  @media screen and (max-width: 520px) {
    nav {
      flex-direction: column;

      div {
        width: 100%;
        flex-direction: column;

        button {
          width: 100%;
        }

        &.container-grid {
          display: flex;
          flex-direction: column;
        }
      }

      .buttons-container {
        width: 100%;
        display: flex;
        flex-direction: row;
        gap: 16px;

        button, a {
          width: 90%;
        }
      }
    }
  }
`;

export const SubContainer = styled.div`
  width: 100%;

  display: ${props => (props.visible ? 'flex' : 'none')};

  flex-direction: column;

  align-items: center;

  justify-content: center;

  box-sizing: border-box;

  gap: 16px;

  header {
    width: 100%;

    box-sizing: border-box;

    display: flex;

    align-items: center;

    justify-content: space-between;

    h1 {
      color: #333;

      font-size: 20px;

      font-weight: bold;

      margin: 0;

      padding: 0;
    }

    a {
      display: flex;

      align-items: center;

      justify-content: center;

      height: 40px;

      border-radius: 4px;

      padding: 0 15px;

      box-sizing: border-box;

      background: #59d99d;

      font-size: 16px;

      font-weight: bold;

      color: #fff;

      &:hover {
        background: rgba($color: #59d99d, $alpha: 0.2);
      }
    }
  }

  nav {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    gap: 16px;

    button {
      height: 40px;

      min-width: 132px;

      border-radius: 4px;

      padding: 0 15px;

      box-sizing: border-box;

      background: #59d99d;

      font-size: 14px;

      font-weight: bold;

      color: #fff;

      &:hover {
        background: rgba($color: #59d99d, $alpha: 0.2);
      }
    }
  }

  @media screen and (max-width: 520px) {
    text-align: center;

    nav {
      flex-direction: column;

      button {
        width: 100%;
      }
    }
  }
`;

export const SubContent = styled.div`
  width: 100%;

  display: flex;

  flex-direction: column;

  align-items: center;

  justify-content: center;
`;
