import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import Select from 'react-select';

// Styles
import { FormStyled, Row, Column } from '../../css/styles/form';
import { SelectStyled } from '../../css/styles/select';

const handleValidate = ({ description }) => {
  const errors = {};
  if (!description) errors.description = 'OBRIGATÓRIO';
  return errors;
};

const handleSubmit = props => async (values, { setSubmitting, resetForm }) => {
  if (!props.maintenanceServiceGroup) {
    await props.onCreation({ ...values });
  } else {
    await props.onUpdate({ ...values });
  }

  setSubmitting(false);
  resetForm();
}

const MaintenanceServiceGroupForm = props => {
  const {
    maintenanceServiceGroup,
    maintenanceServiceGroups,
    onEdit,
    onNew,
    onDelete,
  } = props;

  return (
    <Formik
      enableReinitialize
      initialValues={{
        description:
          maintenanceServiceGroup ? maintenanceServiceGroup.description : '',
      }}
      validate={handleValidate}
      onSubmit={handleSubmit(props)}
    >
      {({
        values,
        handleChange,
        handleBlur,
        handleReset,
        errors,
        touched,
        setFieldValue,
        isSubmitting,
        dirty,
      }) => (
        <FormStyled>
          {
            maintenanceServiceGroup ? (
              <h1>EDITAR GRUPO DE SERVIÇO</h1>
            ) : (
              <h1>NOVO GRUPO DE SERVIÇO</h1>
            )
          }

          <ul>
            <Row>
              <Column>
                <strong htmlFor="description">DESCRIÇÃO</strong>
                <input
                  type="text"
                  name="description"
                  value={values.description}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="Descrição"
                />
                {errors.description && touched.description && (
                  <span className="error">{errors.description}</span>
                )}
              </Column>
            </Row>
            <Row>
              <div className="buttons-container">
                <button type="submit" disabled={isSubmitting}>
                  SALVAR
                </button>

                <button
                  type="button"
                  onClick={handleReset}
                  disabled={!dirty || isSubmitting}
                >
                  RESET
                </button>

                {maintenanceServiceGroup && (
                  <>
                    <button type="button" onClick={onNew}>
                      LIMPAR
                    </button>

                    <button className="delete" type="button" onClick={onDelete}>
                      EXCLUIR
                    </button>
                  </>
                )}
              </div>
            </Row>
          </ul>

            <hr />

            <ul>
              <Row>
                <Column>
                  <strong>GRUPO DE SERVIÇO</strong>
                  <Select
                    styles={SelectStyled}
                    id="maintenanceServiceGroup"
                    value={values.maintenanceServiceGroup}
                    placeholder="Selecione um grupo"
                    onChange={selectedOption => {
                      setFieldValue('maintenanceServiceGroup', selectedOption);
                      onEdit(selectedOption);
                    }}
                    isSearchable
                    options={maintenanceServiceGroups}
                    getOptionLabel={item => item.description}
                    getOptionValue={item => item.uuid}
                    name="maintenanceServiceGroup"
                    isLoading={false}
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                    loadingMessage={() => 'Buscando grupos'}
                    noOptionsMessage={() => 'Não há grupos cadastrados'}
                  />
                </Column>
              </Row>
            </ul>
        </FormStyled>
      )}
    </Formik>
  )
}

MaintenanceServiceGroupForm.propTypes = {
  maintenanceServiceGroupForm: PropTypes.bool.isRequired,
  maintenanceServiceGroup: PropTypes.shape({
    uuid: PropTypes.string,
    description: PropTypes.string,
  }),
  maintenanceServiceGroups: PropTypes.arrayOf(
    PropTypes.shape({
      uuid: PropTypes.string,
      description: PropTypes.string,
    })),
  onCreation: PropTypes.func,
  onEdit: PropTypes.func,
  onUpdate: PropTypes.func,
  onNew: PropTypes.func,
  onDelete: PropTypes.func,
}

MaintenanceServiceGroupForm.defaultProps = {
  maintenanceServiceGroupForm: false,
  maintenanceServiceGroup: null,
  maintenanceServiceGroups: [],
}

export default MaintenanceServiceGroupForm;
